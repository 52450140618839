import { useEffect, useState } from 'react';
import { devicesFields } from 'src/routes/Devices/components/AddEditDeviceForm/utils';
import {
  NEW_BED_ID,
  NEW_ROOM_ID,
} from 'src/redux/data/rooms/modules/constants';

export const useLocationFields = (
  form,
  roomsList,
  deviceListRow,
  selectedRoomId,
  selectedBedId,
) => {
  const [bedOptions, setBedOptions] = useState([]);
  const [roomOptions, setRoomOptions] = useState([]);
  const [newRoom, setNewRoom] = useState('');
  const [newBed, setNewBed] = useState('');
  const [
    isReAssignDeviceConfirmationVisible,
    setIsReAssignDeviceConfirmationVisible,
  ] = useState(false);

  useEffect(() => {
    if (deviceListRow?.roomId !== undefined) {
      const room = roomOptions.find(r => r.id === deviceListRow?.roomId);
      setBedOptions(room?.beds);
      form.setFieldValue(devicesFields.BED_ID, deviceListRow?.bedId);
    } else {
      setBedOptions([]);
    }
  }, [deviceListRow?.roomId, deviceListRow?.bedId, roomOptions]);

  // When room field changes
  useEffect(() => {
    if (!form.getFieldValue(devicesFields.ROOM_ID)) {
      return;
    }
    const room = form.getFieldValue(devicesFields.ROOM_ID);
    setBedOptions(roomOptions.find(r => r.value === room)?.beds || []);
    setNewBed('');
    form.setFieldValue(devicesFields.BED_ID, undefined);
  }, [form.getFieldValue(devicesFields.ROOM_ID)]);

  useEffect(() => {
    if (roomsList.length > 0) {
      setRoomOptions(roomsList);
    }
  }, [roomsList]);

  // When bed field changes
  useEffect(() => {
    if (
      !form.getFieldValue(devicesFields.BED_ID) ||
      form.getFieldValue(devicesFields.BED_ID) === deviceListRow?.bedId
    ) {
      return;
    }
    const bed = form.getFieldValue(devicesFields.BED_ID);
    if (bedOptions?.find(b => b.value === bed)?.deviceId) {
      setIsReAssignDeviceConfirmationVisible(true);
    }
  }, [form.getFieldValue(devicesFields.BED_ID)]);

  const addRoom = e => {
    e.preventDefault();
    if (newRoom.localeCompare('') === 0) return;
    setRoomOptions([...roomsList, { label: newRoom, value: NEW_ROOM_ID }]);
    form.setFieldValue(devicesFields.ROOM_ID, NEW_ROOM_ID);
    setNewRoom('');
  };

  const setRoomInput = event => {
    setNewRoom(event.target.value);
  };

  const addBed = e => {
    e.preventDefault();
    if (newBed.localeCompare('') === 0) return;
    setBedOptions([
      ...(roomOptions.find(r => r.value === selectedRoomId)?.beds || []),
      { label: newBed, value: NEW_BED_ID, deviceId: undefined },
    ]);
    form.setFieldValue(devicesFields.BED_ID, NEW_BED_ID), setNewRoom('');
    setNewBed('');
  };

  const setBedInput = event => {
    setNewBed(event.target.value);
  };

  return {
    addRoom,
    setRoomInput,
    addBed,
    setBedInput,
    roomOptions,
    newRoom,
    bedOptions,
    newBed,
    isReAssignDeviceConfirmationVisible,
    setIsReAssignDeviceConfirmationVisible,
  };
};
