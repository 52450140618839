import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import { selectors } from './modules/slice';
import dayjs from 'dayjs';

const mapStateToProps = (state: RootState) => {
  const lastDayAbnormalitiesData = selectors.getLastDayAbnormalitiesData(state);
  const patientsData = lastDayAbnormalitiesData.map(patient => {
    const {
      patientData,
      patientId,
      location,
      dashboardLink,
      gender,
      isConsented,
    } = patient;
    const patientMainInfo = patientData?.split(`\n`);

    return {
      patientId,
      location,
      gender,
      dashboardLink,
      isConsented,
      patientName: patientMainInfo[0] ?? '',
      patientAge: dayjs().diff(dayjs(patientMainInfo[2]), 'years'),
    };
  });
  return {
    patientsData,
  };
};

const mapActionCreators = {};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
