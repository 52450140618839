import {
  createSlice,
  createSelector,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from 'src/redux/store';
import { DATA_FETCHING_STATUS, DATA_STATE_KEY } from '../../constants';
import { ResponseInterface } from '../../types';
import { EmrMappingsInitialState } from './types';
import {
  OperatorTenantType,
  FacilityType,
} from 'src/routes/Customers/components/OperatorsTab/modules/types';
import { MapFacilitiesPayload } from 'src/services/types';

export const STATE_KEY = 'emrMappings';

export const INITIAL_STATE: EmrMappingsInitialState = {
  operatorTenants: { data: null, status: null },
  emrFacilitiesList: { data: null, status: null },
  facilityTenantDict: { data: null, status: null },
  facilityFormStatus: null,
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    setOperatorTenantsList(
      state,
      action: PayloadAction<ResponseInterface<OperatorTenantType[]>>,
    ) {
      state.operatorTenants = {
        ...action.payload,
      };
    },
    setOperatorTenantsStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.operatorTenants = {
        ...state.operatorTenants,
        status: action.payload,
      };
    },
    setFacilitiesList(
      state,
      action: PayloadAction<ResponseInterface<FacilityType[]>>,
    ) {
      state.emrFacilitiesList = { ...action.payload };
    },
    setFacilitiesStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.emrFacilitiesList = {
        ...state.emrFacilitiesList,
        status: action.payload,
      };
    },
    setFacilityFormStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.facilityFormStatus = action.payload;
    },
  },
});

export const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;

export const selectors = {
  isLoadingOperatorTenants: createSelector(
    getState,
    state => state.operatorTenants.status === DATA_FETCHING_STATUS.LOADING,
  ),
  isLoadingFacilities: createSelector(
    getState,
    state => state.emrFacilitiesList.status === DATA_FETCHING_STATUS.LOADING,
  ),
  selectOperatorTenants: createSelector(
    getState,
    state => state.operatorTenants.data,
  ),
  selectFacilties: createSelector(
    getState,
    state => state.emrFacilitiesList.data,
  ),
};

const extraActions = {
  getFacilities: createAction(`${STATE_KEY}/getFacilities`),
  getOperatorTenants: createAction(`${STATE_KEY}/getOperatorTenants`),
  mapFacilities: createAction<MapFacilitiesPayload>(
    `${STATE_KEY}/mapFacilities`,
  ),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
