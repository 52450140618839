import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from 'src/redux/store';
import { DATA_FETCHING_STATUS, DATA_STATE_KEY } from '../../constants';
import { ResponseInterface } from '../../types';
import { CustomersSdkActivityResponse } from 'src/routes/Customers/modules/types';
import { API_STATUS } from 'src/utils/api-constants';

export const STATE_KEY = 'sdk';

export const INITIAL_STATE: {
  sdkCustomersActivity: {
    data?: CustomersSdkActivityResponse[] | null;
    status?: keyof typeof DATA_FETCHING_STATUS | null;
  };
} = {
  sdkCustomersActivity: {
    data: null,
    status: API_STATUS.INITIAL,
  },
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    setSdkCustomersActivity(
      state,
      action: PayloadAction<ResponseInterface<CustomersSdkActivityResponse[]>>,
    ) {
      state.sdkCustomersActivity = { ...action.payload };
    },
    setSdkCustomersActivityStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.sdkCustomersActivity = {
        ...state.sdkCustomersActivity,
        status: action.payload,
      };
    },
  },
});

export const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;

export const selectors = {
  selectSdkCustomersActivity: createSelector(
    getState,
    state => state.sdkCustomersActivity.data,
  ),
  isSdkCustomersActivityLoading: createSelector(
    getState,
    state => state.sdkCustomersActivity.status === DATA_FETCHING_STATUS.LOADING,
  ),
};

const extraActions = {
  getAllSdkCustomersActivity: createAction(`${STATE_KEY}/getAllCustomerAccess`),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
