import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import ConfirmationModal from 'src/components/Modal/ConfirmationModal';
import { SubTitle, PageWrapper } from 'src/components/styled';
import { hasAnyPermissions, hasSAPermissions } from 'src/utils/permissions';
import permissions from 'src/permissions';
import Table from 'src/components/CrudCommonComponents/Table';
import { deepEqual } from 'src/utils/comparators';
import {
  USERS_TABLE_ACTIONS_KEYS,
  userFormModes,
  usersActionKeysArray,
  USER_STATUS,
  systemAdmin,
  userTableActionsMessages,
} from '../../../components/UserCrudComponents/constants';
import UserModal from 'src/components/UserCrudComponents/UserModal';
import { USER_TYPES, DEFAULT_PAGINATION } from 'src/utils/constants';
import {
  getUserPageColumns,
  getUserTableActions,
  getColumnsPermissionMap,
  userTableData,
} from './utils';
import { tableSorterWithEmptyString } from 'src/utils/sorters/utils';
import { useCalculateTableHeight } from 'src/utils/hooks/useCalculateTableHeight';

const UsersPage = ({
  fetchAllUsers,
  usersList,
  getTenantsList,
  isLoading,
  inviteUser,
  updateUser,
  intl,
  modalStatus,
  setModalStatus,
  sendMessageForResetPassword,
  resendInvitation,
  loggedInUserType,
  loggedInUserId,
  toggleUserActivation,
  currentCustomer,
  getCustomers,
  customersList,
  horizontalLayout = false,
}) => {
  const [activeRow, setActiveRow] = useState({});
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [tableParams, setTableParams] = useState({
    current: DEFAULT_PAGINATION.PAGE,
    pageSize: DEFAULT_PAGINATION.LIMIT,
    total: usersList?.length || 0,
    sortBy: {
      columnKey: 'userName',
      order: 'ascend',
    },
    showSizeChanger: false,
    searchKeyword: '',
  });
  const { current, pageSize, searchKeyword, total, sortBy } = tableParams;

  const [isActivationConfirmationVisible, setIsActivationConfirmationVisible] =
    useState(false);
  const [tableData, setTableData] = useState([]);
  const columns = getUserPageColumns(intl);
  const COLUMNS_PERMISSION_MAP = getColumnsPermissionMap(intl);
  const actions = getUserTableActions(
    intl,
    loggedInUserId,
    setActiveRow,
    setIsEditModalVisible,
    setIsActivationConfirmationVisible,
    sendMessageForResetPassword,
    resendInvitation,
  );

  const userType = loggedInUserType?.name;
  const loggedInUserIsTA = userType === USER_TYPES.TENANT_ADMIN;
  const activeRowIsTA = activeRow?.userType?.name === USER_TYPES.TENANT_ADMIN;
  const activeRowIsCurrentUser = activeRow?.id === loggedInUserId;
  const tableHeight = useCalculateTableHeight(currentCustomer);

  useEffect(() => {
    if (hasAnyPermissions(permissions.VIEW_DEVICES_TENANT_COLUMN)) {
      getTenantsList();
    }
    if (!currentCustomer?.id && hasSAPermissions()) {
      getCustomers();
    }
    fetchAllUsers(currentCustomer?.id);
  }, [currentCustomer?.id, fetchAllUsers, getTenantsList, getCustomers]);

  useEffect(() => {
    const filterdActionsByStatusUserMap = {
      [USER_STATUS.ACTIVE]: usersActionKeysArray.filter(
        action =>
          ![
            USERS_TABLE_ACTIONS_KEYS.ACTIVE,
            USERS_TABLE_ACTIONS_KEYS.RESEND_INVITE,
          ].includes(action),
      ),
      [USER_STATUS.INACTIVE]: usersActionKeysArray.filter(
        action =>
          ![
            USERS_TABLE_ACTIONS_KEYS.INACTIVE,
            USERS_TABLE_ACTIONS_KEYS.RESEND_INVITE,
          ].includes(action),
      ),
      [USER_STATUS.PENDING]: usersActionKeysArray.filter(
        action =>
          ![
            USERS_TABLE_ACTIONS_KEYS.ACTIVE,
            USERS_TABLE_ACTIONS_KEYS.INACTIVE,
            USERS_TABLE_ACTIONS_KEYS.RESET_PASSWORD,
          ].includes(action),
      ),
    };

    if (isLoading) {
      setTableData([]);
      return;
    }

    let usersListData = userTableData(usersList, customersList);

    if (searchKeyword?.length) {
      usersListData = usersListData.filter(user =>
        user.email.toLowerCase().includes(searchKeyword.toLowerCase()),
      );
    }

    tableSorterWithEmptyString(usersListData, sortBy, { numeric: true });

    setTableParams(prevState => ({
      ...prevState,
      total: usersListData.length,
    }));
    setTableData(
      usersListData
        .slice((current - 1) * pageSize, current * pageSize)
        .map(user => ({
          key: user.id,
          actions: filterdActionsByStatusUserMap[user.status],
          ...user,
        })),
    );
  }, [usersList, isLoading, current, pageSize, searchKeyword, sortBy]);

  const columnsMap = {
    [systemAdmin]: columns,
  };

  const columnsMapHandler = {
    get(columnsMapCreator, userTypeProperty) {
      return columnsMapCreator[userTypeProperty] || columns;
    },
  };

  const proxyColumnsMap = new Proxy(columnsMap, columnsMapHandler);

  const cols = proxyColumnsMap[userType];
  Object.keys(COLUMNS_PERMISSION_MAP).forEach(permission => {
    const hasPermission = hasAnyPermissions(permission);
    if (hasPermission) {
      cols.splice(2, 0, ...COLUMNS_PERMISSION_MAP[permission]);
    }
  });

  const handleTableChange = (pagination, _filters, sorter) => {
    setTableParams(prevState => ({
      ...prevState,
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 25,
      sortBy: {
        columnKey: sorter.columnKey,
        order: sorter.order,
      },
    }));
  };

  const handleSearch = keyword => {
    setTableParams(prevState => ({
      ...prevState,
      current: 1,
      searchKeyword: keyword,
    }));
  };

  return (
    <PageWrapper>
      <SubTitle>
        <FormattedMessage
          {...messages.usersCounter}
          values={{ usersCount: usersList.length }}
        />
      </SubTitle>
      <Table
        horizontalLayout={horizontalLayout}
        scroll={{ y: tableHeight }}
        loading={isLoading}
        data={tableData}
        columns={
          currentCustomer || !hasSAPermissions()
            ? columns.filter(col => col.key !== 'customer')
            : columns
        }
        withSearch
        searchFunction={handleSearch}
        searchPlaceholder={intl.formatMessage(messages.searchPlaceholder)}
        addButtonText={intl.formatMessage(messages.add)}
        addButtonPermission={permissions.ORGANIZATION_INVITATIONS_CREATE}
        addButtonOnClick={() => setIsInviteModalVisible(true)}
        actions={actions}
        pagination={
          total > pageSize && {
            ...tableParams,
            total: total,
          }
        }
        onChange={handleTableChange}
      />
      {isInviteModalVisible && (
        <UserModal
          isModalVisible={isInviteModalVisible}
          setIsModalVisible={value => setIsInviteModalVisible(value)}
          onSubmit={invitedUserData => {
            inviteUser({
              formData: invitedUserData,
              customerId: currentCustomer?.id,
            });
          }}
          mode={userFormModes.ADD}
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
          fields={{
            firstName: true,
            lastName: true,
            email: true,
            userType: true,
          }}
          currentCustomer={currentCustomer}
        />
      )}
      <ConfirmationModal
        isModalVisible={isActivationConfirmationVisible}
        setIsModalVisible={value => setIsActivationConfirmationVisible(value)}
        onOk={() => {
          toggleUserActivation({
            userId: activeRow.id,
            locked: activeRow.status === USER_STATUS.ACTIVE,
            customerId: currentCustomer?.id,
          });
        }}
        message={intl.formatMessage(
          activeRow.status === USER_STATUS.ACTIVE
            ? userTableActionsMessages.inactivateConfirmation
            : userTableActionsMessages.activateConfirmation,
        )}
      />
      {isEditModalVisible && (
        <UserModal
          isModalVisible={isEditModalVisible}
          setIsModalVisible={value => setIsEditModalVisible(value)}
          onSubmit={editedUserData => {
            updateUser({
              userId: activeRow.id,
              data: editedUserData,
              customerId: currentCustomer?.id,
              userStatus: activeRow.status,
            });
          }}
          currentUser={activeRow}
          mode={userFormModes.EDIT}
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
          fields={{
            firstName: true,
            lastName: true,
            email: true,
            userType: true,
            phone: {
              disabled:
                !!loggedInUserIsTA && activeRowIsTA && !activeRowIsCurrentUser,
            },
            mfaPhone: activeRow.status === USER_STATUS.ACTIVE,
          }}
          currentCustomer={currentCustomer}
        />
      )}
    </PageWrapper>
  );
};
const messages = defineMessages({
  usersCounter: {
    defaultMessage:
      '{usersCount } {usersCount, select, 1 {user} other {users}}',
  },
  searchPlaceholder: {
    defaultMessage: 'Search',
  },
  add: {
    defaultMessage: '+ Add',
  },
  edit: {
    defaultMessage: 'Edit',
  },
  delete: {
    defaultMessage: 'Delete',
  },
  deleteConfirmation: {
    defaultMessage: 'Are you sure you want to delete?',
  },
  resetPassword: {
    defaultMessage: 'Reset Password',
  },
  resendInvite: {
    defaultMessage: 'Resend invite',
  },
  tenant: {
    defaultMessage: 'Tenant',
  },
});

UsersPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  usersList: PropTypes.array.isRequired,
  fetchAllUsers: PropTypes.func.isRequired,
  inviteUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  modalStatus: PropTypes.string.isRequired,
  setModalStatus: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  sendMessageForResetPassword: PropTypes.func.isRequired,
  resendInvitation: PropTypes.func.isRequired,
  deleteInvitation: PropTypes.func.isRequired,
  getTenantsList: PropTypes.func.isRequired,
  loggedInUserType: PropTypes.object.isRequired,
  loggedInUserId: PropTypes.string,
  toggleUserActivation: PropTypes.func,
  currentCustomer: PropTypes.object,
  getCustomers: PropTypes.func,
  customersList: PropTypes.array,
  setTableParams: PropTypes.func,
  tableParams: PropTypes.object,
  horizontalLayout: PropTypes.bool,
};

export default injectIntl(
  React.memo(UsersPage, (oldProps, newProps) => deepEqual(oldProps, newProps)),
);
