import { connect, ConnectedProps } from 'react-redux';

import {
  actions as alertActions,
  selectors as alertSelectors,
} from 'src/redux/data/alerts';
import { RootState } from 'src/redux/store';
import { UUID } from 'src/types/utility';

import { NoteCounterMetadata } from 'src/types/notes';

type AlertCardItem = {
  alertId: UUID;
  deviceId: UUID | null;
  patientId: UUID | null;
  notesCount?: NoteCounterMetadata;
};

const mapStateToProps = (state: RootState) => ({
  alertItems: alertSelectors.selectLastDayActiveAlerts(state).map(
    alert =>
      ({
        alertId: alert.id,
        deviceId: alert.deviceId,
        patientId: alert.patientId,
        notesCount: alert.notesCount,
      }) as AlertCardItem,
  ),
  areAlertsLoading: alertSelectors.areAlertsLoading(state),
});

const mapActionCreators = {
  suppressAlertType: alertActions.suppressAlertType,
  unSuppressAlertType: alertActions.unSuppressAlertType,
  clearAllPatientAlerts: alertActions.clearAllPatientAlerts,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;

export default Connector;
