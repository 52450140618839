import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { Checkbox, Form, Input } from 'antd';

import { SubTitle } from 'src/components/styled';
import { emailPattern, tenantFormFields } from 'src/utils/constants';
import SelectBox from 'src/components/general-ui/SelectBox';
import Connector from '../containers/TenantModalConnector';
import {
  cityLookup,
  countryOptions,
  getCountryName,
  getTimezoneUtcOffsetStr,
} from 'src/utils/country-city-timezones/utils';
import {
  EachColumn,
  EachRow,
  FormSection,
  StyledForm,
  StyledModal,
} from 'src/routes/Tenants/components/styled';
import {
  getTenantTypeOptions,
  tenantModalModes,
} from 'src/routes/Tenants/components/utils';
import { deepEqual } from 'src/utils/comparators';
import SelectBoxForm from 'src/components/general-ui/SelectBoxForm';

const TenantModal = ({
  isModalVisible,
  setIsModalVisible,
  onOk,
  mode,
  modalStatus,
  setModalStatus,
  intl,
  tenantListRow,
  getOperators,
  operatorsList,
  parentTenantList,
  currentCustomer,
  getCustomers,
  customersList,
  getAddTenantsParentList,
}) => {
  const tenantCountryCode = tenantListRow?.address?.countryCode;
  const tenantCity = tenantListRow?.address?.city;

  const operatorsOptionList = operatorsList?.map(operator => ({
    value: operator.id,
    label: operator.name,
  }));

  const customersOptionList = customersList?.map(customer => ({
    value: customer.id,
    label: customer.name,
  }));

  const mtmOptions = useMemo(
    () =>
      parentTenantList?.map(mtm => ({
        value: mtm.id,
        label: mtm.name,
      })),
    [parentTenantList],
  );

  const [form] = Form.useForm();

  const [selectedCountry, setCountry] = useState(tenantCountryCode);
  const [citiesList, setCitiesList] = useState(null);
  const [selectedCity, setCity] = useState(tenantCity);

  const modeToPropsMap = useMemo(
    () => ({
      [tenantModalModes.ADD]: {
        title: intl.formatMessage(messages.addTenant),
        okButtonText: intl.formatMessage(messages.add),
        initialValues: {
          [tenantFormFields.PARENT_TENANT_ID]: null,
          [tenantFormFields.ENABLE_CPX]: false,
          [tenantFormFields.GROUP]: false,
          [tenantFormFields.OPERATOR_ID]: null,
        },
      },
      [tenantModalModes.EDIT]: {
        title: intl.formatMessage(messages.editTenant),
        okButtonText: intl.formatMessage(messages.apply),
        initialValues: {
          [tenantFormFields.NAME]: tenantListRow?.name,
          [tenantFormFields.ADDRESS1]: tenantListRow?.address?.address1,
          [tenantFormFields.COUNTRY_CODE]: tenantListRow?.address?.countryCode,
          [tenantFormFields.CITY]: tenantListRow?.address?.city,
          [tenantFormFields.STATE]: tenantListRow?.address?.state,
          [tenantFormFields.REGION]: tenantListRow?.address?.region,
          [tenantFormFields.ENABLE_CPX]: tenantListRow?.isEnableCPX || false,
          [tenantFormFields.TIMEZONE_UTCOFFSET_STR]:
            tenantListRow?.timeZoneOffset,
          [tenantFormFields.TIMEZONE_ID]: tenantListRow?.timeZoneId,
          [tenantFormFields.GROUP]: tenantListRow?.group,
          [tenantFormFields.OPERATOR_ID]: tenantListRow?.operatorId ?? null,
          [tenantFormFields.CUSTOMER_ID]: tenantListRow?.customerId ?? null,
        },
      },
    }),
    [tenantListRow, intl],
  );

  useEffect(() => {
    form.setFieldsValue({ ...modeToPropsMap[mode].initialValues });
  }, [tenantListRow, modeToPropsMap, form, mode]);

  useEffect(() => {
    setCountry(tenantListRow?.address?.countryCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantCountryCode]);

  useEffect(() => {
    const citiesByCountry = cityLookup(getCountryName(selectedCountry));

    citiesByCountry &&
      setCitiesList(
        citiesByCountry.map(city => ({
          ...city,
          value: city.id,
          label: city.name,
        })),
      );
  }, [selectedCountry]);

  useEffect(() => {
    setCity(tenantListRow?.address?.city);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantCity]);

  useEffect(() => {
    if (isModalVisible) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible]);

  useEffect(() => {
    if (mode === tenantModalModes.ADD) {
      form.resetFields();
      form.setFieldsValue({ [tenantFormFields.GROUP]: false });
      setCountry(null);
      setCitiesList(null);
      setCity(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    if (!currentCustomer?.id) {
      getCustomers();
      return;
    }
    form.setFieldsValue({ customerId: currentCustomer?.id });
  }, [currentCustomer?.id, getCustomers]);

  // When customer field changes
  useEffect(() => {
    if (!form.getFieldValue(tenantFormFields.CUSTOMER_ID)) {
      return;
    }
    getOperators({
      customerId: form.getFieldValue(tenantFormFields.CUSTOMER_ID),
      limit: 1000000,
    });
    getAddTenantsParentList({
      customerID: form.getFieldValue(tenantFormFields.CUSTOMER_ID),
      groupFilter: true,
    });
  }, [getOperators, form.getFieldValue(tenantFormFields.CUSTOMER_ID)]);

  const onCitySelect = cityOption => {
    const { timezone, city, iso2 } = cityOption;
    const utcOffsetStr = getTimezoneUtcOffsetStr(timezone);

    form.setFieldsValue({
      city: city,
      countryCode: iso2,
      timeZoneOffset: utcOffsetStr,
      timeZoneId: timezone,
    });
  };

  const onTenantTypeSelect = tenantType => {
    form.setFieldsValue({
      group: !!tenantType,
    });
    if (!tenantType) {
      form.setFieldsValue({
        parentTenantId: null,
      });
    }
  };

  return (
    <StyledModal
      width={982}
      title={modeToPropsMap[mode].title}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      okButtonText={modeToPropsMap[mode].okButtonText}
      onOk={() => {
        form.submit();
      }}
      destroyOnClose
      modalStatus={modalStatus}
      setModalStatus={setModalStatus}
    >
      <StyledForm
        form={form}
        layout="vertical"
        onFinish={values => {
          onOk(values);
        }}
        initialValues={modeToPropsMap[mode].initialValues}
        validateMessages={tenantFormValidateMessages}
      >
        {(_values, formInstance) => {
          return (
            <>
              <SubTitle>{intl.formatMessage(messages.general)}</SubTitle>
              <FormSection>
                <EachColumn>
                  <Form.Item
                    name={tenantFormFields.NAME}
                    label={intl.formatMessage(messages.nameFieldLabel)}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={tenantFormFields.GROUP}
                    label={intl.formatMessage(messages.tenantType)}
                  >
                    <SelectBox
                      onSelect={onTenantTypeSelect}
                      onClear={() =>
                        form.setFieldsValue({ [tenantFormFields.GROUP]: false })
                      }
                      disabled={mode === tenantModalModes.EDIT}
                      options={getTenantTypeOptions(intl)}
                      placeholder={intl.formatMessage(
                        messages.parentTenantIdFieldLabel,
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    name={tenantFormFields.PARENT_TENANT_ID}
                    label={intl.formatMessage(
                      messages.parentTenantIdFieldLabel,
                    )}
                    hidden={form.getFieldValue(tenantFormFields.GROUP)}
                    dependencies={[tenantFormFields.CUSTOMER_ID]}
                  >
                    <SelectBox
                      disabled={
                        !form.getFieldValue(tenantFormFields.CUSTOMER_ID)
                      }
                      onSelect={parentTenantId =>
                        form.setFieldsValue({ parentTenantId })
                      }
                      onClear={() =>
                        form.setFieldsValue({ parentTenantId: null })
                      }
                      width="100%"
                      defaultValue={tenantListRow?.parentTenantId}
                      options={mtmOptions}
                      placeholder={intl.formatMessage(
                        messages.parentTenantIdFieldLabel,
                      )}
                    />
                  </Form.Item>
                </EachColumn>
                <EachColumn>
                  <Form.Item
                    name={tenantFormFields.CUSTOMER_ID}
                    label={intl.formatMessage(messages.customer)}
                    dependencies={[tenantFormFields.OPERATOR_ID]}
                    rules={[{ required: true }]}
                  >
                    {!currentCustomer?.id && (
                      <SelectBoxForm
                        onClear={() =>
                          form.setFieldsValue({
                            [tenantFormFields.CUSTOMER_ID]: null,
                          })
                        }
                        width="100%"
                        disabled={mode === tenantModalModes.EDIT}
                        options={customersOptionList}
                        placeholder={intl.formatMessage(messages.customer)}
                      />
                    )}
                    {currentCustomer?.id && (
                      <SelectBoxForm
                        disabled
                        width="100%"
                        defaultValue={currentCustomer?.id}
                        options={[
                          {
                            value: currentCustomer.id,
                            label: currentCustomer.name,
                          },
                        ]}
                        placeholder={intl.formatMessage(messages.customer)}
                      />
                    )}
                  </Form.Item>
                  <Form.Item
                    name={tenantFormFields.OPERATOR_ID}
                    label={intl.formatMessage(messages.operator)}
                    dependencies={[tenantFormFields.CUSTOMER_ID]}
                  >
                    <SelectBox
                      onSelect={operatorId => {
                        form.setFieldsValue({ operatorId: operatorId });
                      }}
                      disabled={
                        !form.getFieldValue(tenantFormFields.CUSTOMER_ID)
                      }
                      onClear={() => form.setFieldsValue({ operatorId: '' })}
                      width="100%"
                      defaultValue={tenantListRow?.operatorId ?? ''}
                      options={operatorsOptionList}
                      placeholder={intl.formatMessage(messages.operator)}
                    />
                  </Form.Item>
                  <Form.Item
                    name={tenantFormFields.ENABLE_CPX}
                    label={intl.formatMessage(messages.enableCPX)}
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </Form.Item>
                </EachColumn>
              </FormSection>
              <SubTitle>{intl.formatMessage(messages.address)}</SubTitle>
              <FormSection>
                <EachColumn>
                  <Form.Item
                    name={tenantFormFields.COUNTRY_CODE}
                    label={intl.formatMessage(messages.countryFieldLabel)}
                    rules={[{ required: true }]}
                  >
                    <SelectBox
                      handleChange={value => {
                        form.setFieldsValue({ city: null, countryCode: value });
                        setCountry(value);
                        setCity(null);
                      }}
                      onClear={() => {
                        form.setFieldsValue({ countryCode: null, city: null });
                        setCountry(null);
                        setCity(null);
                      }}
                      selectedItem={selectedCountry}
                      options={countryOptions}
                      placeholder={intl.formatMessage(messages.searchCountry)}
                    />
                  </Form.Item>
                  <Form.Item
                    name={tenantFormFields.ADDRESS1}
                    label={intl.formatMessage(messages.address1FieldLabel)}
                  >
                    <Input />
                  </Form.Item>
                </EachColumn>
                <EachColumn>
                  <Form.Item
                    name={tenantFormFields.CITY}
                    label={intl.formatMessage(messages.cityFieldLabel)}
                    rules={[{ required: true }]}
                  >
                    <SelectBox
                      handleChange={(value, cityObj) => {
                        onCitySelect(cityObj);
                      }}
                      selectedItem={selectedCity}
                      disabled={!selectedCountry}
                      options={selectedCountry ? citiesList : []}
                      placeholder={intl.formatMessage(messages.searchCity)}
                      notFoundContent={intl.formatMessage(
                        messages.notFoundCity,
                      )}
                    />
                  </Form.Item>
                  <EachRow>
                    <Form.Item
                      className={'timezone-id'}
                      label={intl.formatMessage(messages.timezoneLabel)}
                      name={tenantFormFields.TIMEZONE_ID}
                    >
                      <Input bordered={false} readOnly />
                    </Form.Item>
                    <Form.Item
                      className={'timezone-offset'}
                      label={intl.formatMessage(messages.timezoneOffsetLabel)}
                      name={tenantFormFields.TIMEZONE_UTCOFFSET_STR}
                    >
                      <Input bordered={false} readOnly />
                    </Form.Item>
                  </EachRow>
                </EachColumn>
              </FormSection>
              {mode === tenantModalModes.ADD && (
                <>
                  <SubTitle>
                    {intl.formatMessage(messages.userDetails)}
                  </SubTitle>
                  <FormSection>
                    <EachColumn>
                      <Form.Item
                        name={tenantFormFields.FIRST_NAME}
                        label={intl.formatMessage(messages.firstNameFieldLabel)}
                        rules={[
                          {
                            required:
                              form.getFieldValue(tenantFormFields.GROUP) ===
                              true,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name={tenantFormFields.EMAIL}
                        label={intl.formatMessage(messages.emailFieldLabel)}
                        rules={[
                          {
                            required:
                              form.getFieldValue(tenantFormFields.GROUP) ===
                              true,
                            pattern: emailPattern,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </EachColumn>
                    <EachColumn>
                      <Form.Item
                        name={tenantFormFields.LAST_NAME}
                        label={intl.formatMessage(messages.lastNameFieldLabel)}
                        rules={[
                          {
                            required:
                              form.getFieldValue(tenantFormFields.GROUP) ===
                              true,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name={tenantFormFields.PHONE}
                        label={intl.formatMessage(messages.phoneFieldLabel)}
                      >
                        <Input />
                      </Form.Item>
                    </EachColumn>
                  </FormSection>
                </>
              )}
            </>
          );
        }}
      </StyledForm>
    </StyledModal>
  );
};

const messages = defineMessages({
  general: {
    defaultMessage: 'General',
  },
  addTenant: {
    defaultMessage: 'Add Tenant',
  },
  editTenant: {
    defaultMessage: 'Edit Tenant',
  },
  apply: {
    defaultMessage: 'Apply',
  },
  add: {
    defaultMessage: 'Add',
  },
  searchCountry: {
    defaultMessage: 'Search country...',
  },
  notFoundCity: {
    defaultMessage: 'Please choose a city close to your area.',
  },
  searchCity: {
    defaultMessage: 'Search city...',
  },
  nameFieldLabel: {
    defaultMessage: 'Tenant Name',
  },
  firstNameFieldLabel: {
    defaultMessage: 'First Name',
  },
  lastNameFieldLabel: {
    defaultMessage: 'Last Name',
  },
  phoneFieldLabel: {
    defaultMessage: 'Phone',
  },
  emailFieldLabel: {
    defaultMessage: 'Email',
  },
  countryFieldLabel: {
    defaultMessage: 'Country',
  },
  cityFieldLabel: {
    defaultMessage: 'City',
  },
  zipCodeFieldLabel: {
    defaultMessage: 'Zip Code',
  },
  address1FieldLabel: {
    defaultMessage: 'Address1',
  },
  address: {
    defaultMessage: 'Address',
  },
  userDetails: {
    defaultMessage: 'User details ',
  },
  enableCPX: {
    defaultMessage: 'CPX Enabled',
  },
  timezoneLabel: {
    defaultMessage: 'Timezone',
  },
  timezoneOffsetLabel: {
    defaultMessage: 'UTC',
  },
  tenantType: {
    defaultMessage: 'Tenant type',
  },
  parentTenantIdFieldLabel: {
    defaultMessage: 'Parent tenant',
  },
  tenantLabel: {
    defaultMessage: 'Tenant',
  },
  groupManagerLabel: {
    defaultMessage: 'Multi-Tenant Manager',
  },
  operator: {
    defaultMessage: 'Operator',
  },
  customer: {
    defaultMessage: 'Customer',
  },
});

export const tenantFormValidateMessages = {
  required: '${label} is required!',
  pattern: { mismatch: '${label} is not a valid ${label}' },
};

TenantModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  currentCustomer: PropTypes.object,
  modalStatus: PropTypes.string.isRequired,
  setModalStatus: PropTypes.func.isRequired,
  tenantListRow: PropTypes.object,
  getOperators: PropTypes.func,
  getCustomers: PropTypes.func,
  getAddTenantsParentList: PropTypes.func,
  operatorsList: PropTypes.array,
  customersList: PropTypes.array,
  parentTenantList: PropTypes.array,
  getTenantsList: PropTypes.func.isRequired,
};

TenantModal.defaultProps = {
  tenantListRow: {},
};

export default Connector(
  injectIntl(
    React.memo(TenantModal, (oldProps, newProps) =>
      deepEqual(oldProps, newProps),
    ),
  ),
);
