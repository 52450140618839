import { connect, ConnectedProps } from 'react-redux';

import {
  actions as customersActions,
  selectors as customersSelectors,
} from 'src/redux/data/customers';
import {
  actions as tenantDataActions,
  selectors as tenantDataSelectors,
} from 'src/redux/data/tenant';
import {
  actions as deviceActions,
  selectors as deviceSelectors,
} from 'src/redux/data/device';
import { RootState } from 'src/redux/store';
import {
  actions as operatorsActions,
  selectors as operatorsSelectors,
} from 'src/redux/data/operators';
import {
  actions as roomActions,
  selectors as roomSelectors,
} from 'src/redux/data/rooms';
import { API_STATUS } from 'src/utils/api-constants';
const getRoomList = (state: RootState) => {
  const roomsList = roomSelectors.getRoomList(state);
  roomsList.forEach(room => room.beds.map(b => ({ room, ...b })));
  return roomsList.map(room => ({
    id: room.id,
    label: room.name,
    value: room.id,
    beds: room?.beds.map(bed => ({
      label: bed.name,
      value: bed.id,
      deviceId: bed.deviceId,
    })),
  }));
};

const mapStateToProps = (state: RootState) => ({
  customersList: customersSelectors.selectCustomersList(state),
  operatorsList: operatorsSelectors.selectOperatorsList(state),
  tenantsByCustomerList: tenantDataSelectors.getAddTenantsParentList(state),
  locationMountOptions: deviceSelectors.selectLocationMountOptions(state),
  roomsList: getRoomList(state),
  areRoomsListLoading: roomSelectors.getIsLoading(state),
  areOperatorsLoading:
    operatorsSelectors.selectOperatorsListStatus(state) === API_STATUS.LOADING,
  areCustomersLoading:
    customersSelectors.selectCustomersListStatus(state) === API_STATUS.LOADING,
  areTenantsByCustomerListLoading:
    tenantDataSelectors.getAddTenantsParentListStatus(state) ===
    API_STATUS.LOADING,
  areLocationMountOptionsLoading:
    deviceSelectors.selectLocationMountOptionsStatus(state) ===
    API_STATUS.LOADING,
});

const mapActionCreators = {
  getCustomers: customersActions.searchCustomers,
  getOperators: operatorsActions.getOperatorsList,
  getTenantsByCustomerOrOperator: tenantDataActions.getAddTenantsParentList,
  getLocationMountOptions: deviceActions.getLocationMountOptions,
  getRoomsList: roomActions.onLoadRoomsList,
};
const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
