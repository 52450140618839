import { CollatorOptions } from 'intl';
import { getIntl } from 'src/utils/languageUtils';
import { patientPageColumnsMessages } from 'src/routes/NursesStation/components/MonitoredPersons/constants';

export const sortWithEmptyString = (
  str1: string | undefined,
  str2: string | undefined,
  option?: CollatorOptions | undefined,
): number => {
  if (!str1) {
    return 1;
  }

  if (!str2) {
    return -1;
  }

  return option
    ? str1.localeCompare(str2, 'en', option)
    : str1.localeCompare(str2);
};

export const sortWithEmptyNumbers = (
  nr1: number | undefined,
  nr2: number | undefined,
): number => {
  return (nr1 || 0) - (nr2 || 0);
};

export const sortRoomsAndBedsWithEmptyString = (
  roomName1: string | undefined,
  bedname1: string | undefined,
  roomName2: string | undefined,
  bedname2: string | undefined,
  option?: CollatorOptions | undefined,
): number => {
  const concatRoomAndBed1 = (roomName1 || '') + ' ' + (bedname1 || '');
  const concatRoomAndBed2 = (roomName2 || '') + ' ' + (bedname2 || '');

  if (!roomName1 && !bedname1) {
    return 1;
  }

  if (!roomName2 && !bedname2) {
    return -1;
  }

  return option
    ? concatRoomAndBed1.localeCompare(concatRoomAndBed2, 'en', option)
    : concatRoomAndBed1.localeCompare(concatRoomAndBed2);
};
export const sortMonitorPatients = (
  data1: string | boolean | undefined,
  data2: string | boolean | undefined,
  columnName: string,
  option?: CollatorOptions | undefined,
): number => {
  const intl = getIntl(patientPageColumnsMessages);
  let str1 = data1;
  let str2 = data2;
  if (columnName === 'isConsented') {
    str1 = data1
      ? intl.formatMessage(patientPageColumnsMessages.yesLabel)
      : intl.formatMessage(patientPageColumnsMessages.noLabel);
    str2 = data2
      ? intl.formatMessage(patientPageColumnsMessages.yesLabel)
      : intl.formatMessage(patientPageColumnsMessages.noLabel);
  }

  return sortWithEmptyString(str1 as string, str2 as string, option);
};

export const tableSorterWithEmptyString = (
  data = [],
  sortBy: { order: string; columnKey: string },
  option?: CollatorOptions | undefined,
) => {
  const { order = '', columnKey = '' } = sortBy || {};

  if (order === 'ascend') {
    data?.sort((a, b) =>
      sortWithEmptyString(a[columnKey], b[columnKey], option),
    );
  }
  if (order === 'descend') {
    data?.sort((a, b) =>
      sortWithEmptyString(b[columnKey], a[columnKey], option),
    );
  }
};
