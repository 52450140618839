import {
  all,
  takeLatest,
  call,
  fork,
  take,
  cancel,
  spawn,
  select,
  put,
  race,
} from 'typed-redux-saga/macro';
import { eventChannel } from 'redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';

import { actions as appActions } from 'src/redux/data/app';
import { actions as alertActions } from 'src/redux/data/alerts';
import { actions as sessionsActions } from 'src/redux/data/sessions';
import { actions as notesActions } from 'src/redux/data/notes';
import { actions as deviceActions } from 'src/redux/data/device';
import { initMonitor } from 'src/redux/data/monitor/modules/monitorUtils';
import { getDevicesList } from 'src/redux/data/device/modules/saga';
import { selectors as deviceSelectors } from 'src/redux/data/device/modules/slice';
import {
  fetchAlertSettings,
  fetchAlertThresholds,
  fetchAlerts,
} from 'src/redux/data/alerts/modules/saga';
import { fetchPatientsList } from 'src/redux/data/patient/modules/saga';
import { Alert } from 'src/types/alerts';
import { AlertsMap } from 'src/redux/data/alerts/modules/types';
import { actions } from './slice';
import { CURRENT_ALERTS } from './tabConfig';

const DEFAULT_REFRESH_INTERVAL = 5000; //60 * 1000; // 60s

function setUpIntervalChannel(intervalMs: number) {
  return eventChannel(emitter => {
    const refreshIntervalId = setInterval(() => {
      emitter(true);
    }, intervalMs);

    return () => {
      clearInterval(refreshIntervalId);
    };
  });
}

function* monitorInternetConnected() {
  while (true) {
    // This will retrigger alert sounds
    // TODO: Find a way to make sounds agnostic to this
    yield* take(appActions.onInternetConnection);
    yield* call(fetchAlerts, []);
  }
}

function* initContinousData() {
  try {
    yield* call(getDevicesList);
    const devices = yield* select(deviceSelectors.getDevicesList);
    const deviceIds = devices.map(device => device.manufacturerId);

    yield* put(sessionsActions.onFetchSessions());
    yield* spawn(initMonitor, deviceIds, true);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in initContinousData: ', e);
    yield* put(sessionsActions.fetchSessionsFailed());
    // TODO: Notify user on error
    // notifyUserByActionTypeAndCode(action.type, action.payload, error);
  }
}

function* refreshDeviceCount() {
  const refreshChannel = yield* call(
    setUpIntervalChannel,
    DEFAULT_REFRESH_INTERVAL,
  );

  while (true) {
    const raceResult = yield* race({
      channel: take(refreshChannel),
    });
    if (!raceResult.channel) {
      refreshChannel.close();
      return;
    }
    yield* put(deviceActions.fetchDeviceConnectionForCurrentTenant());
  }
}

function* onLoadAlertSidebar() {
  try {
    yield* call(fetchAlertSettings);
    yield* all([
      call(fetchAlertThresholds),
      call(fetchAlerts, []),
      call(fetchPatientsList),
      put(notesActions.fetchPatientsNoteCounterMetadata([])),
    ]);
    const monitorInternetConnectedId = yield* fork(monitorInternetConnected);
    yield* spawn(initContinousData);
    yield* take(actions.alertSidebarUnmounted);
    yield* cancel(monitorInternetConnectedId);
    // TODO: this must be latest
    refreshDeviceCount();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in onLoadAlertSidebar: ', e);
  }
}

function* onNewMQTTAlert(
  action: PayloadAction<{
    alertsList: Alert[];
    alertsMap: AlertsMap;
  }>,
) {
  const { alertsList } = action.payload;

  if (alertsList.length && alertsList.find(a => a.status === 'ON')) {
    yield* put(actions.changeSidebarTab(CURRENT_ALERTS));
  }
}

export default function* watchAlertSidebarActions() {
  yield* all([
    takeLatest(actions.alertSidebarMounted, onLoadAlertSidebar),
    takeLatest(alertActions.gotAlertsFromAws, onNewMQTTAlert),
  ]);
}
