import { connect, ConnectedProps } from 'react-redux';

import {
  selectors as operatorsSelectors,
  actions as operatorsActions,
} from 'src/redux/data/operators';
import { RootState } from 'src/redux/store';

const mapStateToProps = (state: RootState) => ({
  operatorsList: operatorsSelectors.selectOperatorsList(state),
  isLoading: operatorsSelectors.isLoadingOperators(state),
  editableData: operatorsSelectors.selectEditableData(state),
  tableParams: operatorsSelectors.selectTableParams(state),
  deviceCount: operatorsSelectors.selectOperatorsDeviceCount(state) || [],
});

const mapActionCreators = {
  setOperatorForm: operatorsActions.setOperatorForm,
  setCurrentOperator: operatorsActions.setCurrentOperator,
  deleteOperator: operatorsActions.deleteOperatorById,
  getOperators: operatorsActions.getOperatorsList,
  setTableParams: operatorsActions.setTableParams,
  getOperatorsDeviceCount: operatorsActions.getOperatorsDeviceCount,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
