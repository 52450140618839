import React from 'react';
import { PropsFromRedux } from 'src/routes/Customers/components/TenantsTab/Connector';
import Connector from './Connector';
import { TenantsTableContainer } from './styled';
import TenantsPageWrapper from 'src/routes/Tenants/components/TenansPageWrapper';
import { CreateCustomerPayload } from 'src/routes/Customers/modules/types';

type Props = PropsFromRedux & {
  currentCustomer: CreateCustomerPayload;
};
const TenantsTab = ({ currentCustomer }: Props) => {
  return (
    <TenantsTableContainer>
      <TenantsPageWrapper
        currentCustomer={currentCustomer}
        horizontalLayout={true}
      />
    </TenantsTableContainer>
  );
};

export default Connector(TenantsTab);
