import { connect } from 'react-redux';

import {
  selectors as tenantDataSelectors,
  actions as tenantDataActions,
} from 'src/redux/data/tenant';
import {
  selectors as operatorsSelectors,
  actions as operatorsActions,
} from 'src/redux/data/operators';
import {
  selectors as customersSelectors,
  actions as customersActions,
} from 'src/redux/data/customers';
import { selectors as loggedInUserDataSelectors } from 'src/redux/data/loggedInUser';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';
import { NETEERA_TENANT } from 'src/utils/constants';

const mapStateToProps = state => {
  const tenants = tenantDataSelectors.getPaginatedTenants(state).data;
  const tenantsDeviceCounters =
    tenantDataSelectors.selectTenantsDeviceCounters(state) ?? {};
  const tenantsMonitoringDeviceCounters =
    tenantDataSelectors.selectTenantsMonitoringDeviceCounters(state) ?? {};
  const tenantsOperators =
    tenantDataSelectors.selectTenantsOperatorsData(state)?.data ?? [];

  const tenantsList = tenants.map(tenant => {
    const deviceInfo = tenantsDeviceCounters?.[tenant.id] ?? {
      deviceCount: 0,
      lastDayConnectedCount: 0,
    };
    const monitoringDeviceInfo = tenantsMonitoringDeviceCounters?.[
      tenant.id
    ] ?? {
      monitoredDeviceCount: 0,
    };
    const matchedOperator = tenantsOperators.find(
      elem => elem.id === tenant.operatorId,
    );
    return {
      operatorName: matchedOperator?.name ?? '',
      ...tenant,
      devicesNo: deviceInfo.deviceCount,
      connectedDevicesNo: deviceInfo.lastDayConnectedCount,
      monitoringDevicesNo: monitoringDeviceInfo?.monitoredDeviceCount,
    };
  });
  return {
    tenantsList,
    tenantsTotalCount:
      tenantDataSelectors.getPaginatedTenants(state).pageMetadata.totalResults,
    excludedFromDeleteTenants: [
      loggedInUserDataSelectors.getUserTenantId(state),
      NETEERA_TENANT,
    ],
    mtmTenants: tenantDataSelectors
      .getTenantsList(state)
      .filter(tenant => tenant.group === true),
    isLoading:
      tenantDataSelectors.getStatus(state) === DATA_FETCHING_STATUS.LOADING ||
      tenantDataSelectors.selectTenantsMonitoringDeviceCounters(state) ===
        DATA_FETCHING_STATUS.LOADING ||
      tenantDataSelectors.selectTenantsDeviceStatus(state) ===
        DATA_FETCHING_STATUS.LOADING,
    modalStatus: tenantDataSelectors.getModalStatus(state),
    operatorsList: operatorsSelectors.selectOperatorsList(state),
    customersList: customersSelectors.selectCustomersList(state),
  };
};

const mapActionCreators = {
  ...tenantDataActions,
  getOperators: operatorsActions.getOperatorsList,
  getCustomers: customersActions.searchCustomers,
};

const Connector = connect(mapStateToProps, mapActionCreators);
export default Connector;
