import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import { actions, selectors } from '../../modules/slice';
import { API_STATUS } from 'src/utils/api-constants';

const mapStateToProps = (state: RootState) => ({
  eventsSummaryData: selectors.getEventsSummaryData(state),
  isLoading: selectors.getEventsSummaryStatus(state) === API_STATUS.LOADING,
});

const mapActionCreators = {
  loadSummaryEventsData: actions.loadSummaryEventsData,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
