import React from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import { FooterContainer, StyledEmailLink } from './styled';
import { deepEqual } from 'src/utils/comparators';

type Props = {
  intl: IntlShape;
};

const FooterComponent = ({ intl }: Props): JSX.Element => (
  <FooterContainer>
    {intl.formatMessage(messages.footerText, {
      data: (
        <StyledEmailLink href="mailto:support@neteera.com">
          {intl.formatMessage(messages.neteeraEmail)}
        </StyledEmailLink>
      ),
    })}
  </FooterContainer>
);

const messages = defineMessages({
  footerText: {
    defaultMessage: 'Confidential & Proprietary | Contact {data}',
  },
  neteeraEmail: {
    defaultMessage: 'support@Neteera.com',
  },
});

export default injectIntl(
  React.memo(FooterComponent, (oldProps, newProps) =>
    deepEqual(oldProps, newProps),
  ),
);
