import { connect, ConnectedProps } from 'react-redux';
import {
  actions as deviceActions,
  selectors as deviceSelectors,
} from 'src/redux/data/device';
import { RootState } from 'src/redux/store';
import { API_STATUS } from 'src/utils/api-constants';

const mapStateToProps = (state: RootState) => ({
  fwCounters: deviceSelectors.selectFwVersionCounters(state),
  isLoading:
    deviceSelectors.selectFwVersionCountersStatus(state) === API_STATUS.LOADING,
});

const mapActionCreators = {
  getFwCounters: deviceActions.getFwCounters,
};
const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
