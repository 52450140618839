import {
  createSlice,
  createAction,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from 'src/redux/store';
import { actions as authActions } from '../../auth/modules/slice';
import { DATA_STATE_KEY } from '../../constants';
import { LoggedInUser } from './types';
import { forceSystemAdminTimezone } from './utils';
import { getTimezoneId } from 'src/utils/timeUtils';
import { GetPhiRegionalRestrictionResponse } from 'src/services/types';

export const STATE_KEY = 'loggedInUser';

export const INITIAL_STATE: {
  loggedInUser: LoggedInUser | undefined;
  userRegionalAccess: boolean;
} = {
  loggedInUser: undefined,
  userRegionalAccess: false,
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    loginUser: (state, action: PayloadAction<LoggedInUser>) => {
      state.loggedInUser = forceSystemAdminTimezone(action.payload);
    },
    getUserPhiRegionalAccessSuccess(
      state,
      action: PayloadAction<GetPhiRegionalRestrictionResponse>,
    ) {
      const { regionalAccess } = action.payload;
      state.userRegionalAccess = regionalAccess;
    },
  },
  extraReducers: builder => {
    builder.addCase(authActions.logoutFinish, state => {
      state.loggedInUser = undefined;
    });
  },
});

export const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;

export const selectors = {
  getUserEmail: createSelector(getState, state => state.loggedInUser?.email),
  getUserId: createSelector(getState, state => state.loggedInUser?.userId),
  getUserFullName: createSelector(
    getState,
    state =>
      `${state.loggedInUser?.firstName || ''} ${
        state.loggedInUser?.lastName || ''
      }`,
  ),
  getUserTenantId: createSelector(
    getState,
    state => state.loggedInUser?.tenantId,
  ),
  getCurrentTenantId: createSelector(
    getState,
    state => state.loggedInUser?.tenantDetails?.tenantId || '',
  ),
  getUserTenant: createSelector(
    getState,
    state => state.loggedInUser?.tenantDetails,
  ),
  getLoggedInUserType: createSelector(
    getState,
    state => state.loggedInUser?.userType,
  ),
  getLoggedInUserCustomerId: createSelector(
    getState,
    state => state.loggedInUser?.customerId,
  ),
  getUserTenantName: createSelector(
    getState,
    state => state.loggedInUser?.tenantDetails?.tenantName || '',
  ),

  getUserTenantTimezone: createSelector(getState, state =>
    getTimezoneId(state.loggedInUser?.tenantDetails?.timeZoneId),
  ),
  selectRegionalAccess: createSelector(
    getState,
    state => state.userRegionalAccess,
  ),
};

const extraActions = {
  getUserPhiRegionalAccess: createAction(
    `${STATE_KEY}/getUserPhiRegionalAccess`,
  ),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
