import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { defineMessages, injectIntl, IntlShape, useIntl } from 'react-intl';
import { SelectProps } from 'antd';
import { RefSelectProps } from 'antd/lib/select';

import { StyledSelect } from './styled';
import { styled } from 'styled-components';

type SingleSelectItem = {
  label: string;
  value: string;
};

type Props<T extends SingleSelectItem> = SelectProps<T['value'], T> & {
  selectedItem?: T['value'];
  options: T[];
  handleChange: (selectedValue: T['value'], option: T) => void;
  customOptionRender?: (item: T) => JSX.Element;
  selectRef?: React.Ref<RefSelectProps> | undefined;
  intl: IntlShape;
};

const SelectBox = <T extends SingleSelectItem>({
  selectedItem,
  options,
  handleChange,
  placeholder,
  customOptionRender,
  selectRef,
  className,
  ...rest
}: Props<T>): JSX.Element => {
  const intl = useIntl();

  const selectProps: SelectProps<T['value'], T> = {
    style: { width: '100%' },
    value: selectedItem,
    placeholder: placeholder || intl.formatMessage(messages.search),
    filterOption: (input, option) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase()),
    allowClear: true,
    suffixIcon: <SearchOutlined />,
    showSearch: true,
    popupClassName: className,
    ...rest,
    // @ts-ignore Since mode is single only value returned here is T
    onChange: handleChange,
    ...(customOptionRender ? { optionLabelProp: 'label' } : { options }),
  };

  if (customOptionRender) {
    return (
      <div>
        <StyledSelect ref={selectRef} {...selectProps}>
          {options.map(option => customOptionRender(option))}
        </StyledSelect>
      </div>
    );
  }

  return (
    <div>
      <StyledSelect ref={selectRef} {...selectProps} />
    </div>
  );
};

const messages = defineMessages({
  search: {
    defaultMessage: 'Search',
  },
});

export const StyledSelectBox: typeof SelectBox = styled(SelectBox)`
  .ant-select-item {
    font-size: 0.75rem;
  }
`;

export default injectIntl(StyledSelectBox);
