export const getTableCellValue = value => {
  if (!value && typeof value === 'number') {
    return 0;
  }
  if (!value && typeof value !== 'boolean') {
    return '';
  }
  if (typeof value === 'boolean' || typeof value === 'number') {
    return value.toString();
  }
  if (typeof value === 'string') {
    return value;
  }
  const children = value?.props?.children;
  if (children instanceof Array) {
    return children.map(getTableCellValue).join('');
  }
  // for custom components, value need to be displayed in the CSV file should have `value` name
  if (!children && value?.props && 'value' in value.props) {
    return value.props.value;
  }
  return getTableCellValue(children);
};

export const getCsvData = (withCsvExport, mergedColumns, data) => {
  if (!withCsvExport) {
    return {};
  }
  const csvHeaders = mergedColumns
    ?.filter(elem => elem.key !== 'actions')
    .map(({ key, title }) => ({
      key,
      label: title,
    }));
  const csvData = data?.map(eachData => {
    return mergedColumns
      .filter(elem => elem.key !== 'actions')
      .reduce((acc, eachColumn) => {
        return {
          ...acc,
          [eachColumn.key]: eachColumn?.render
            ? getTableCellValue(eachColumn.render(null, eachData))
            : eachData[eachColumn.dataIndex],
        };
      }, {});
  });
  return {
    csvData,
    csvHeaders,
  };
};

export const extractSelectOptions = (data = [], label, value) =>
  data?.map(item => ({
    label: item[label],
    value: item[value],
  }));
