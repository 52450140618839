import { all, call, put, takeLatest } from 'typed-redux-saga/macro';

import {
  actionSuccessNotification,
  notificationsPlacementTypes,
  notifyUserByActionTypeAndCode,
} from 'src/utils/errorHandling/notifications';
import BackendService from 'src/services/BackendService';
import { actions } from './slice';

export function* loadRoomsList(
  action?: ReturnType<typeof actions.onLoadRoomsList>,
) {
  try {
    const { tenantId = undefined } = action?.payload ?? {};
    yield* put(actions.onRoomListLoading());
    const { data } = yield* call(BackendService.getRooms, tenantId);
    yield* put(actions.onLoadRoomListSuccess(data.rooms));
  } catch (e) {
    yield* put(actions.onLoadRoomListFail());
    // TODO: Handle errors
    // notifyUserByActionTypeAndCode(action.type, e);
  }
}

function* newCreateRoom(action: ReturnType<typeof actions.onCreateRoom>) {
  try {
    yield* call(BackendService.createRoom, action.payload);
    yield* call(loadRoomsList);
  } catch (error) {
    notifyUserByActionTypeAndCode(action.type, action.payload.name, error);
  }
}
// TODO check this for cleaning, seems it's not more used
function* newEditRoom(action: ReturnType<typeof actions.onEditRoom>) {
  const updatedRoom = action.payload;

  try {
    yield* call(BackendService.updateRoom, updatedRoom.id, updatedRoom);
    yield* call(loadRoomsList);
  } catch (error) {
    notifyUserByActionTypeAndCode(action.type, updatedRoom.name, error);
  }
}

function* newAddBed(action: ReturnType<typeof actions.onCreateBed>) {
  const { room, bed } = action.payload;

  try {
    yield* call(BackendService.createBedInRoom, {
      roomId: room.id,
      name: bed.name,
    });
    yield* call(loadRoomsList);
  } catch (error) {
    notifyUserByActionTypeAndCode(action.type, room.name, error);
  }
}

function* assignBed(action: ReturnType<typeof actions.onAssignBed>) {
  const { room, bed } = action.payload;
  if (!bed.id) {
    return;
  }
  try {
    yield* call(BackendService.assignDeviceToBed, {
      bedId: bed.id,
      deviceId: bed.deviceId as string,
    });
    yield* call(loadRoomsList);
  } catch (error) {
    notifyUserByActionTypeAndCode(action.type, room.name, error);
  }
}

function* onDeleteRoom(action: ReturnType<typeof actions.onDeleteRoom>) {
  const roomId = action.payload;
  try {
    yield* call(BackendService.deleteRoom, roomId);
    yield* call(loadRoomsList);
    actionSuccessNotification(
      action.type,
      null,
      notificationsPlacementTypes.TOP_RIGHT,
    );
  } catch (error) {
    notifyUserByActionTypeAndCode(action.type, roomId, error);
  }
}

export default function* watchRoomActions() {
  yield* all([
    takeLatest(actions.onLoadRoomsList, loadRoomsList),
    takeLatest(actions.onCreateRoom, newCreateRoom),
    takeLatest(actions.onEditRoom, newEditRoom),
    takeLatest(actions.onDeleteRoom, onDeleteRoom),
    takeLatest(actions.onCreateBed, newAddBed),
    takeLatest(actions.onAssignBed, assignBed),
  ]);
}
