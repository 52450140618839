import { connect, ConnectedProps } from 'react-redux';

import {
  selectors as customersSelectors,
  actions as customersActions,
} from 'src/redux/data/customers';
import {
  selectors as tenantSelectors,
  actions as tenantActions,
} from 'src/redux/data/tenant';
import {
  selectors as deviceSelectors,
  actions as deviceActions,
} from 'src/redux/data/device';
import { RootState } from 'src/redux/store';

const mapStateToProps = (state: RootState) => ({
  customersList: customersSelectors.selectCustomersList(state),
  tenantsByCustomerList: tenantSelectors.getAddTenantsParentList(state),
  locationMountOptions: deviceSelectors.selectLocationMountOptions(state),
});

const mapActionCreators = {
  getCustomers: customersActions.searchCustomers,
  getTenantsByCustomer: tenantActions.getAddTenantsParentList,
  getLocationMountOptions: deviceActions.getLocationMountOptions,
};
const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
