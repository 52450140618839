import React from 'react';
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
  IntlShape,
} from 'react-intl';
import { Popconfirm, Space, Tooltip, Typography } from 'antd';

import { displayInTimezone } from 'src/utils/timeUtils';
import AlertIndicator from 'src/components/AlertIndicator';
import AlertNotes from '../AlertNotes';
import Connector, { Props } from './Connector';
import {
  CardWrapper,
  RoomDetails,
  PatientName,
  StyledIcon,
  StyledDeleteIcon,
  CardContent,
  CardDetails,
  StyledTimestamp,
  StyledThresholdMetric,
  StyledTenantName,
  DeleteIconWrapper,
  AlertNotesWrapper,
  BoldItem,
  StyledDisconnectionEventType,
  CardActions,
  CardItems,
  ItemLabel,
} from './styled';
import { isTechnicalAlert } from 'src/redux/data/alerts/modules/typeGuards';
import AccessControl from 'src/components/AccessControl';
import permissions from 'src/permissions';
import { ToolTipMessages } from 'src/translations/messages/toolTipMessages';
import { deepEqual } from 'src/utils/comparators';
import { getValueOrNA } from 'src/utils/dataUtils';
import { ALERT_STATUS_ENUM } from 'src/utils/constants';
import { ALERT_METRIC_ENUM } from 'src/redux/data/constants';

const { Paragraph } = Typography;

type AllProps = Props & { intl: IntlShape };

const AlertCard = ({
  intl,
  alertId,
  alert,
  deviceRoomAndBed,
  patient,
  tenant,
  loggedInUserTenantId,
  notesCount,
  timezone,
  onPatientNameClick,
  onSuppressAlertClick,
  onUnSuppressAlertClick,
  onDeleteAlertClick,
}: AllProps): JSX.Element | null => {
  if (!alert) {
    return null;
  }

  const { startTime, thresholdMetric, status } = alert;
  const isAlertSuppressed = status === ALERT_STATUS_ENUM.SUPPRESSED;
  const confirm = () => {
    onDeleteAlertClick(alertId);
  };

  if (isTechnicalAlert(alert?.thresholdMetric)) {
    return (
      <CardWrapper>
        <CardItems>
          {tenant && <StyledTenantName>{tenant.name}</StyledTenantName>}
          <CardContent>
            <CardDetails>
              <StyledDisconnectionEventType>
                <FormattedMessage {...messages[thresholdMetric]} />
              </StyledDisconnectionEventType>
            </CardDetails>
          </CardContent>
          <Space size={0}>
            <StyledTimestamp>
              {displayInTimezone(startTime, timezone, 'HH:mm')}
            </StyledTimestamp>
            <AlertIndicator {...alert} />
          </Space>
        </CardItems>

        <CardActions isTechnicalAlert={true}>
          {isAlertSuppressed && (
            <AccessControl permissions={[permissions.ALERT_UN_SUPPRESS]}>
              <StyledIcon
                type={'un-suppress-alert-icon'}
                onClick={() => onUnSuppressAlertClick(alertId)}
              />
            </AccessControl>
          )}
          {!isAlertSuppressed && (
            <AccessControl permissions={[permissions.ALERT_SUPPRESS]}>
              <StyledIcon
                type={'suppress-alert-icon'}
                onClick={() => onSuppressAlertClick(alertId)}
              />
            </AccessControl>
          )}
          <AccessControl permissions={[permissions.ALERT_CLEAR_ALL]}>
            <Popconfirm
              title={intl.formatMessage(messages.confirmDelete)}
              onConfirm={confirm}
              okText="Yes"
              cancelText="No"
              placement="right"
            >
              <DeleteIconWrapper>
                <StyledDeleteIcon type="clear-alert-icon" />
              </DeleteIconWrapper>
            </Popconfirm>
          </AccessControl>
        </CardActions>
      </CardWrapper>
    );
  }

  if (!deviceRoomAndBed) {
    return null;
  }

  const { roomName, bedName } = deviceRoomAndBed;
  const { firstName = '', lastName = '', isConsented } = patient || {};
  const displayRoomName = getValueOrNA(roomName);
  const displayBedName = getValueOrNA(bedName);
  const isPositionChangeAlert =
    thresholdMetric === ALERT_METRIC_ENUM.POSITION_CHANGE;

  return (
    <Tooltip
      title={<FormattedMessage {...ToolTipMessages.patientNameAlertCard} />}
    >
      <CardWrapper>
        <CardItems>
          {tenant && (
            <StyledTenantName ellipsis={{ rows: 2, tooltip: tenant.name }}>
              {tenant.name}
            </StyledTenantName>
          )}
          <RoomDetails>
            <Paragraph
              ellipsis={{
                rows: 1,
                tooltip: displayRoomName,
              }}
            >
              <BoldItem>{displayRoomName}</BoldItem>
            </Paragraph>

            <Paragraph
              ellipsis={{
                rows: 1,
                tooltip: displayBedName,
              }}
            >
              <ItemLabel>
                <FormattedMessage {...messages.bed} />
              </ItemLabel>
              <BoldItem>{displayBedName}</BoldItem>
            </Paragraph>
          </RoomDetails>
          <PatientName
            onClick={event => {
              if (!isConsented) {
                event.preventDefault();
                return;
              }
              onPatientNameClick();
            }}
          >
            <Paragraph
              ellipsis={{
                rows: 1,
                tooltip: `${firstName} ${lastName}`,
              }}
            >{`${firstName} ${lastName}`}</Paragraph>
          </PatientName>
          <CardContent>
            <CardDetails>
              <StyledTimestamp>
                {displayInTimezone(startTime, timezone, 'HH:mm')}
              </StyledTimestamp>
              <AlertIndicator {...alert} />
              <StyledThresholdMetric>
                <FormattedMessage {...messages[thresholdMetric]} />
              </StyledThresholdMetric>
            </CardDetails>
          </CardContent>
        </CardItems>
        <CardActions>
          {isAlertSuppressed && !isPositionChangeAlert && (
            <AccessControl permissions={[permissions.ALERT_UN_SUPPRESS]}>
              <StyledIcon
                type={'un-suppress-alert-icon'}
                onClick={() => onUnSuppressAlertClick(alertId)}
              />
            </AccessControl>
          )}

          {!isAlertSuppressed && !isPositionChangeAlert && (
            <AccessControl permissions={[permissions.ALERT_SUPPRESS]}>
              <StyledIcon
                type={'suppress-alert-icon'}
                onClick={() => onSuppressAlertClick(alertId)}
              />
            </AccessControl>
          )}

          {patient?.id && (
            <AlertNotesWrapper>
              <AlertNotes
                counterMetadata={notesCount}
                patientId={patient.id}
                deviceId={deviceRoomAndBed.deviceId}
                // tenant.id is used when the user is MTM and loggedInUserTenantId is used for HP
                tenantId={tenant?.id || loggedInUserTenantId}
              />
            </AlertNotesWrapper>
          )}

          {!isPositionChangeAlert && (
            <AccessControl permissions={[permissions.ALERT_CLEAR_ALL]}>
              <Popconfirm
                title={intl.formatMessage(messages.confirmDelete)}
                onConfirm={confirm}
                okText="Yes"
                cancelText="No"
                placement="right"
              >
                <DeleteIconWrapper>
                  <StyledDeleteIcon type="clear-alert-icon" />
                </DeleteIconWrapper>
              </Popconfirm>
            </AccessControl>
          )}
        </CardActions>
      </CardWrapper>
    </Tooltip>
  );
};

const messages = defineMessages({
  HR: {
    defaultMessage: 'HR',
  },
  RR: {
    defaultMessage: 'RR',
  },
  HR_BASELINE: {
    defaultMessage: 'HR',
  },
  RR_BASELINE: {
    defaultMessage: 'RR',
  },
  LONG_OUT_OF_BED: {
    defaultMessage: 'BedExit Duration',
  },
  POSITION_CHANGE: {
    defaultMessage: 'Position change required',
  },
  BED_EXIT: {
    defaultMessage: 'BedExit',
  },
  BED_TIME_BASELINE: {
    defaultMessage: 'Bed time',
  },
  BED_EXIT_FREQUENCY: {
    defaultMessage: 'BedExit Frequency',
  },
  DEVICE_DISCONNECTED: {
    defaultMessage: 'Disconnected device',
  },
  ALL_DEVICES_DISCONNECTED: {
    defaultMessage: 'All devices are disconnected',
  },
  bed: {
    defaultMessage: 'Bed',
  },
  confirmDelete: {
    defaultMessage: 'Are you sure you want to delete this alert?',
  },
});

export default Connector(
  injectIntl(
    React.memo(AlertCard, (oldProps, newProps) =>
      deepEqual(oldProps, newProps),
    ),
  ),
);
