import { IntlShape, MessageDescriptor } from 'react-intl';

export const getTableColumns = (
  intl: IntlShape,
  messages: Record<string, MessageDescriptor>,
) => {
  return [
    {
      title: messages.fwColumnHeader
        ? intl.formatMessage(messages.fwColumnHeader)
        : '',
      dataIndex: 'version',
      key: 'version',
    },
    {
      title: messages.devicesCountColumnHeader
        ? intl.formatMessage(messages.devicesCountColumnHeader)
        : '',
      dataIndex: 'deviceCount',
      key: 'deviceCount',
    },
  ];
};
