import React from 'react';
import TenantsPage from 'src/routes/Tenants/components/Component';
import { CreateCustomerPayload } from 'src/routes/Customers/modules/types';

type Props = {
  currentCustomer: CreateCustomerPayload | null | undefined;
  horizontalLayout: boolean;
};
const TenantsPageWrapper = ({ currentCustomer, horizontalLayout }: Props) => {
  return (
    <TenantsPage
      // @ts-ignore TODO: need to re-write TenantsPage to *.tsx
      currentCustomer={currentCustomer}
      horizontalLayout={horizontalLayout}
    />
  );
};

export default TenantsPageWrapper;
