import React, { Dispatch, SetStateAction } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyledText } from 'src/components/Sidebars/styled';
import { MODAL_MODES } from 'src/utils/constants';
import {
  StyledActionButton,
  StyledCustomersListContainer,
  StyledCustomersListItem,
  StyledCustomersListItemsWrapper,
  StyledCustomersListWrapper,
  StyledSearchbar,
} from 'src/routes/Customers/components/CustomersList/styled';
import {
  CreateCustomerPayload,
  CustomerModalType,
} from 'src/routes/Customers/modules/types';
import LoadingOverlay from 'src/components/general-ui/LoadingOverlay';

type Props = {
  customersList: CreateCustomerPayload[];
  currentCustomer: CreateCustomerPayload;
  isLoading?: boolean;
  withSearch?: boolean;
  onAdd: Dispatch<SetStateAction<CustomerModalType>>;
  onCustomerClick: (customer: CreateCustomerPayload) => void;
  searchCustomers: (searchKeyword: string) => void;
};

const CustomersList = ({
  customersList = [],
  isLoading = false,
  withSearch = true,
  onAdd,
  onCustomerClick,
  currentCustomer,
  searchCustomers,
}: Props) => {
  return (
    <StyledCustomersListContainer>
      {withSearch && (
        <StyledSearchbar
          onChange={event => {
            searchCustomers(event.target.value);
          }}
        />
      )}
      <StyledActionButton
        onClick={() => onAdd({ mode: MODAL_MODES.ADD, visible: true })}
      >
        <FormattedMessage {...messages.ADD} />
      </StyledActionButton>
      <StyledCustomersListWrapper>
        {isLoading && <LoadingOverlay />}
        <StyledCustomersListItemsWrapper>
          {!isLoading &&
            customersList?.map(customerItem => (
              <StyledCustomersListItem
                onClick={() => onCustomerClick(customerItem)}
                isActive={currentCustomer?.id === customerItem?.id}
                key={customerItem.id}
              >
                {customerItem?.name}
              </StyledCustomersListItem>
            ))}
        </StyledCustomersListItemsWrapper>

        {!customersList?.length && !isLoading && (
          <StyledText>
            <FormattedMessage {...messages.noCustomersFound} />
          </StyledText>
        )}
      </StyledCustomersListWrapper>
    </StyledCustomersListContainer>
  );
};

const messages = defineMessages({
  ADD: {
    defaultMessage: '+ Add Customer',
  },
  noCustomersFound: {
    defaultMessage: 'No customers found',
  },
});
export default CustomersList;
