import { styled } from 'styled-components';
import { SpacedRow } from '../styled';
import TransparentButton from '../general-ui/Buttons/TransparentButton';
import { CSVLink } from 'react-csv';

export const ActionButton = styled(TransparentButton)`
  color: ${props => props.theme.colors.actionButtonColor};
  padding: 0;
  white-space: nowrap;

  &:hover:not(:disabled) {
    text-decoration: underline;
  }

  &:disabled {
    color: ${props => props.theme.colors.actionButtonDisabledColor};
  }
`;

export const Separator = styled.hr`
  height: ${props => props.theme.fonts.normalFontSize};
  background-color: ${props => props.theme.colors.actionsSeparatorColor};
  margin: 10px;
`;

export const StyledSpacedRow = styled(SpacedRow)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: 50%;
  position: relative;
`;

export const StyledTableContainer = styled.div`
  &.horizontal-layout {
    display: flex;
    flex-direction: column;
    height: 100%;
    .table-search-bar-wrapper {
      margin-left: auto;
      align-items: self-end;
      .ant-input-group-wrapper {
        max-width: 170px;
      }
    }
    .ant-table-wrapper {
      margin-top: 30px;
    }
  }
  .table-search-bar-wrapper {
    gap: 1rem;
  }
  .ant-table-cell {
    color: ${props => props.theme.colors.defaultTextColor};
    min-width: 150px;
    width: max-content;
    overflow: hidden;
    white-space: nowrap;
  }

  .ant-table-cell-scrollbar {
    all: unset;
    display: none;
  }

  .ant-table-thead {
    th {
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .ant-table-body {
    overflow: auto !important;
    margin-bottom: auto;
    width: auto;
    scrollbar-width: thin;
    scrollbar-color: ${props => props.theme.colors.scrollbarBackgroundColor}
      transparent;
    scrollbar-gutter: stable;
  }

  .rowHighlightedGreen {
    background-color: ${props => props.theme.colors.highlightRowLightGreen};
  }

  .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover {
    background: initial;
  }

  .ant-table-placeholder {
    height: 6rem;

    .ant-table-expanded-row-fixed {
      overflow: initial !important;
    }
  }

  .ant-table-container {
    * {
      box-shadow: none;
      -webkit-box-shadow: none;
    }
  }

  .ant-table-ping-right:not(.ant-table-has-fix-right)
    .ant-table-container::after {
    box-shadow: none;
    -webkit-box-shadow: none;
  }

  .ant-table-ping-left:not(.ant-table-has-fix-left)
    .ant-table-container::before {
    box-shadow: none;
    -webkit-box-shadow: none;
  }

  .ant-table-header {
    background-color: ${props => props.theme.colors.MainContentBackground};
  }
`;
export const ExportCsvButton = styled(CSVLink)`
  background: ${props => props.theme.colors.coloredButtonDarkBlue};
  color: ${props => props.theme.colors.coloredButtonTextColor};
  font-weight: bold;
  font-size: 0.7rem;
  white-space: nowrap;
  padding: 5px 10px;
  border-radius: 4px;
  &:hover,
  &:active {
    opacity: 0.7;
    color: ${props => props.theme.colors.coloredButtonTextColor};
  }

  :focus {
    opacity: 0.9;
  }
`;
export const StyledButtonsContainer = styled('div')`
  display: flex;
  gap: 1rem;
`;
export const StyledComponentsContainer = styled('div')`
  display: flex;
  gap: 1rem;
`;
export const StyledTableAndButtonsContainer = styled('div')`
  display: flex;
  gap: 1rem;
`;

export const ExportCsvButtonCustom = styled(TransparentButton)`
  background: ${props => props.theme.colors.coloredButtonDarkBlue};
  color: ${props => props.theme.colors.coloredButtonTextColor};
  font-weight: bold;
  font-size: 0.7rem;
  white-space: nowrap;
  ${props => (!props.isLoading ? 'cursor: pointer;' : 'cursor: initial;')}
  :hover,
  :active {
    opacity: 0.7;
  }

  :focus {
    opacity: 0.9;
  }
`;
