import { defineMessages, MessageDescriptor } from 'react-intl';
import TenantsTab from '../components/TenantsTab';
import DetailsTab from '../components/DetailsTab';
import DevicesTab from '../components/DevicesTab';
import OperatorsTab from '../components/OperatorsTab';
import UsersTab from '../components/UsersTab';

export type CustomerDashboardTabs = {
  key: 'details' | 'devices' | 'tenants' | 'users' | 'operators';
  labelMessage: MessageDescriptor;
  component: React.ElementType;
};
const messages = defineMessages({
  details: {
    defaultMessage: 'Details',
  },
  devices: {
    defaultMessage: 'Devices',
  },
  tenants: {
    defaultMessage: 'Tenants',
  },
  users: {
    defaultMessage: 'Users',
  },
  operators: {
    defaultMessage: 'Operators',
  },
});

export const CUSTOMER_TABS: CustomerDashboardTabs[] = [
  {
    key: 'details',
    labelMessage: messages.details,
    component: DetailsTab,
  },
  {
    key: 'devices',
    labelMessage: messages.devices,
    component: DevicesTab,
  },
  {
    key: 'tenants',
    labelMessage: messages.tenants,
    component: TenantsTab,
  },
  {
    key: 'users',
    labelMessage: messages.users,
    component: UsersTab,
  },
  {
    key: 'operators',
    labelMessage: messages.operators,
    component: OperatorsTab,
  },
];

export const DEFAULT_TAB: CustomerDashboardTabs['key'] = 'details';
