import React from 'react';
import { deepEqual } from 'src/utils/comparators';
import Connector, { IProps } from './Connector';
import GraphComponent from './GraphComponent';

const Graph = ({
  metadata,
  data,
  thresholds,
  alerts,
  patientId,
}: IProps): JSX.Element => {
  return (
    <GraphComponent
      patientId={patientId}
      metadata={metadata}
      data={data}
      thresholds={thresholds}
      alerts={alerts}
    />
  );
};

export default Connector(
  React.memo(Graph, (oldProps, newProps) => deepEqual(oldProps, newProps)),
);
