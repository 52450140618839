import React, { Suspense, useRef } from 'react';
import { useIsVisible } from 'src/utils/hooks/useIsVisible';
import LoadingOverlay from 'src/components/general-ui/LoadingOverlay';

type Props = {
  children: JSX.Element;
  style?: React.CSSProperties;
};
const RenderOnViewportEntry = ({ children, ...rest }: Props): JSX.Element => {
  const elemRef = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisible(elemRef);
  return (
    <div {...rest} ref={elemRef}>
      {isVisible && (
        <Suspense fallback={<LoadingOverlay />}>{children}</Suspense>
      )}
    </div>
  );
};

export default RenderOnViewportEntry;
