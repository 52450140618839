import {
  all,
  call,
  put,
  select,
  takeLatest,
  delay,
} from 'typed-redux-saga/macro';

import { actions, getState } from './slice';
import { DATA_FETCHING_STATUS } from '../../constants';
import BackendService from 'src/services/BackendService';
import { notifyUserByActionTypeAndCode } from 'src/utils/errorHandling/notifications';
import { DEFAULT_PAGINATION } from 'src/utils/constants';
import { RootState } from 'src/redux/store';

function* getOperatorsList(
  action: ReturnType<typeof actions.getOperatorsList>,
) {
  const { searchKeyword } = action.payload ?? { searchKeyword: '' };

  if (searchKeyword?.length) {
    yield* delay(500);
  }

  try {
    yield* put(
      actions.setOperatorsList({
        data: [],
        status: DATA_FETCHING_STATUS.LOADING,
      }),
    );

    const {
      data: { data, metadata },
    } = yield* call(BackendService.searchOperators, {
      ...action.payload,
    });

    yield* all([
      put(
        actions.setOperatorsList({
          data: data,
          status: DATA_FETCHING_STATUS.SUCCESS,
        }),
      ),
      put(
        actions.setTableParams({
          current: metadata?.page?.page ?? 1,
          pageSize: metadata?.page?.limit ?? DEFAULT_PAGINATION.LIMIT,
          total: metadata?.page.totalResults,
        }),
      ),
    ]);
  } catch (e) {
    console.error('error in search search results of Operators: ', e);
    yield* put(
      actions.setOperatorsList({
        data: [],
        status: DATA_FETCHING_STATUS.ERROR,
      }),
    );
  }
}

function* createOperator(action: ReturnType<typeof actions.createOperator>) {
  const { operatorForm } = yield* select(getState);

  const customerId = action.payload;

  const metadata = yield* select((state: RootState) => ({
    page: state.data.operators.tableParams.current,
    limit: state.data.operators.tableParams.pageSize,
  }));

  // TODO: rewrite this
  const pageParams = { payload: { ...metadata, customerId }, type: '' };

  try {
    yield* put(actions.setFormStatus(DATA_FETCHING_STATUS.LOADING));
    const newOperator = yield* call(BackendService.createOperator, {
      ...operatorForm,
      customerId: customerId,
    });
    yield* all([
      put(actions.setFormStatus(DATA_FETCHING_STATUS.SUCCESS)),
      put(actions.resetFormAndCurrentOperator()),
    ]);
    if (newOperator.data.emrMappings)
      yield* put(actions.setCurrentOperator(newOperator.data));
    yield* call(getOperatorsList, pageParams);
  } catch (e) {
    console.error('error in fetchOperatorsList: ', e);
    notifyUserByActionTypeAndCode(action.type, e, e);
  }
}

function* editOperator(action: ReturnType<typeof actions.editOperator>) {
  const { operatorForm, currentOperator } = yield* select(getState);

  const operatorId = currentOperator?.id;
  const customerId = action.payload;

  const metadata = yield* select((state: RootState) => ({
    page: state.data.operators.tableParams.current,
    limit: state.data.operators.tableParams.pageSize,
  }));

  // TODO: rewrite this
  const pageParams = { payload: { ...metadata, customerId }, type: '' };

  try {
    yield* put(actions.setFormStatus(DATA_FETCHING_STATUS.LOADING));
    if (operatorForm && operatorId)
      yield* call(
        BackendService.updateOperator,
        operatorId,
        // groupTenantId: '8f9e7c8b-1853-4b5e-8102-22f8281e3641',
        { ...operatorForm, customerId: customerId },
      );
    yield* all([
      put(actions.setFormStatus(DATA_FETCHING_STATUS.SUCCESS)),
      put(actions.resetFormAndCurrentOperator()),
    ]);
    yield* call(getOperatorsList, pageParams);
  } catch (e) {
    console.error('error in fetchOperatorsList: ', e);
    notifyUserByActionTypeAndCode(action.type, e, e);
  }
}

function* deleteOperator(
  action: ReturnType<typeof actions.deleteOperatorById>,
) {
  const { operatorId, customerId } = action.payload;
  const metadata = yield* select((state: RootState) => ({
    page: state.data.operators.tableParams.current,
    limit: state.data.operators.tableParams.pageSize,
  }));

  // TODO: rewrite this
  const pageParams = { payload: { ...metadata, customerId }, type: '' };
  try {
    yield* call(BackendService.deleteOperator, operatorId);
    yield* call(getOperatorsList, pageParams);
  } catch (e) {
    console.error('error in fetchOperatorsList: ', e);
    notifyUserByActionTypeAndCode(action.type, e, e);
  }
}

function* getOperatorsDeviceCount(
  action: ReturnType<typeof actions.getOperatorsDeviceCount>,
) {
  const customerId = action.payload;

  try {
    put(actions.setOperatorsDeviceCountStatus(DATA_FETCHING_STATUS.LOADING));
    const { data } = yield* call(
      BackendService.getOperatorsDeviceCount,
      customerId,
    );

    yield* put(
      actions.setOperatorsDeviceCount({
        data: data.deviceCounts,
        status: DATA_FETCHING_STATUS.SUCCESS,
      }),
    );
  } catch (e) {
    console.error('error in fetchOperatorsDeviceCount: ', e);
    put(actions.setOperatorsDeviceCountStatus(DATA_FETCHING_STATUS.ERROR)),
      notifyUserByActionTypeAndCode(action.type, e, e);
  }
}

export default function* watchCusomterActions() {
  yield* all([
    takeLatest(actions.getOperatorsList, getOperatorsList),
    takeLatest(actions.createOperator, createOperator),
    takeLatest(actions.editOperator, editOperator),
    takeLatest(actions.deleteOperatorById, deleteOperator),
    takeLatest(actions.getOperatorsDeviceCount, getOperatorsDeviceCount),
  ]);
}
