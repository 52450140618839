import React from 'react';
import { styled } from 'styled-components';
import { CheckCircleOutlined } from '@ant-design/icons';

import { defineMessages } from 'react-intl';
import { countriesListMap } from 'src/utils/country-city-timezones/utils';
import { TableParamsType } from 'src/types/table';
import { SortOrder } from 'src/types/utility';
import { sortWithEmptyString } from 'src/utils/sorters/utils';

export const StyledCheckmark = styled(CheckCircleOutlined)`
  color: ${props => props.theme.colors.activeUserColor};
`;

export const getTenantAddress = record =>
  [...Object.values(record.address || {}).filter(value => !!value)].join(', ');

export const getTenantColumns = (intl, messages, currentCustomer) => [
  {
    title: intl.formatMessage(messages.name),
    dataIndex: 'name',
    key: 'name',
    filtered: true,
    onFilter: (value, record) =>
      record.name.toLowerCase().includes(value.toLowerCase()),
    sorter: true,
    defaultSortOrder: 'ascend',
  },
  !currentCustomer
    ? {
        ...{
          title: intl.formatMessage(messages.customerLabel),
          dataIndex: 'customerName',
          key: 'customerName',
          sorter: true,
        },
      }
    : null,
  {
    title: intl.formatMessage(messages.operatorLabel),
    dataIndex: 'operatorName',
    key: 'operatorName',
    sorter: (a, b) =>
      currentCustomer
        ? sortWithEmptyString(a.operatorName, b.operatorName)
        : true,
  },
  {
    title: intl.formatMessage(messages.tenantTypeLabel),
    dataIndex: 'type',
    key: 'type',
    render: (_, record) => {
      return record?.group
        ? intl.formatMessage(messages.groupManagerLabel)
        : intl.formatMessage(messages.name);
    },
  },
  {
    title: intl.formatMessage(messages.address),
    dataIndex: 'address',
    key: 'address',

    render: (_, record) => {
      const address = getTenantAddress(record);
      return address ? <div>{address}</div> : null;
    },
    sorter: (a, b) =>
      sortWithEmptyString(getTenantAddress(a), getTenantAddress(b)),
  },
  {
    title: intl.formatMessage(messages.devicesLabel),
    dataIndex: 'devicesNo',
    key: 'devicesNo',
    sorter: (a, b) => a.devicesNo - b.devicesNo,
  },
  {
    title: intl.formatMessage(messages.connectedLabel),
    dataIndex: 'connectedDevicesNo',
    key: 'connectedDevicesNo',
    sorter: (a, b) => a.connectedDevicesNo - b.connectedDevicesNo,
  },
  {
    title: intl.formatMessage(messages.monitoringLabel),
    dataIndex: 'monitoringDevicesNo',
    key: 'monitoringDevicesNo',
    sorter: (a, b) => a.monitoringDevicesNo - b.monitoringDevicesNo,
  },
];

export const getCurrentOrder = sorter => {
  if (sorter.order === 'descend') {
    return SortOrder.desc;
  }
  if (sorter.order === 'ascend') {
    return SortOrder.asc;
  }
  return '';
};
export const getTenantTableactions = (
  intl,
  messages,
  excludedFromDeleteTenants,
  setIsConfirmationModalVisible,
  setActiveActionRow,
  setIsEditModalVisible,
  setModalMode,
) => [
  {
    key: 'delete',
    name: intl.formatMessage(messages.delete),
    isDisabled: ({ record }) => excludedFromDeleteTenants.includes(record.id),
    onClick: (_, { record }) => {
      setIsConfirmationModalVisible(true);
      setActiveActionRow(record);
    },
  },
  {
    key: 'edit',
    name: intl.formatMessage(messages.edit),
    onClick: (_, { record }) => {
      setActiveActionRow(record);
      setIsEditModalVisible(true);
      setModalMode(tenantModalModes.EDIT);
    },
  },
];
export const TENANT_TYPES = {
  GROUP: 'group',
  TENANT: 'parentTenantId',
};
export const getTenantTypeOptions = intl => [
  {
    id: TENANT_TYPES.TENANT,
    label: intl.formatMessage(messages.tenantLabel),
    // value: TENANT_TYPES.TENANT,
    value: false,
  },
  {
    id: TENANT_TYPES.GROUP,
    label: intl.formatMessage(messages.groupManagerLabel),
    // value: TENANT_TYPES.GROUP,
    value: true,
  },
];

const messages = defineMessages({
  tenantLabel: {
    defaultMessage: 'Tenant',
  },
  groupManagerLabel: {
    defaultMessage: 'Multi-Tenant Manager',
  },
});

export const tenantModalModes = {
  ADD: 'ADD',
  EDIT: 'EDIT',
};
