import { connect, ConnectedProps } from 'react-redux';

import {
  selectors as operatorsSelectors,
  actions as operatorsActions,
} from 'src/redux/data/operators';
import { RootState } from 'src/redux/store';

const mapStateToProps = (state: RootState) => ({
  isLoadingForm: operatorsSelectors.isLoadingForm(state),
  currentOperator: operatorsSelectors.selectCurrentOperator(state),
  operatorForm: operatorsSelectors.selectEditableData(state),
});

const mapActionCreators = {
  createOperator: operatorsActions.createOperator,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
