import { styled } from 'styled-components';

export const UsersUnderCustomersWrapper = styled.div`
  margin: 0.3rem -1.3rem;

  .table-search-bar-wrapper {
    flex-direction: column;
    align-items: flex-end;
    margin-top: -120px;

    .ant-input-group-wrapper {
      max-width: 169px;
    }
  }
`;
