export const createCustomerFormValidateMessages = {
  pattern: { mismatch: '${label} is not a valid email' },
};
export const customerFormFields = {
  NAME: 'name',
  ADDRESS: 'address',
  ENABLED: 'enabled',
  CONTACT_NAME: 'contactName',
  CONTACT_EMAIL: 'contactEmail',
  IP_ADDRESSES: 'ipAddresses',
  THROUGHPUT: 'throughput',
  PHI_ACCESS: 'regionalAccess',
};
