import { defineMessages } from 'react-intl';

export const advancedSearchMessages = defineMessages({
  formTitle: {
    defaultMessage: 'Advanced Search',
  },
  applyBtn: {
    defaultMessage: 'Apply',
  },
  resetBtn: {
    defaultMessage: 'Reset',
  },
  selectPlaceholder: {
    defaultMessage: 'Select',
  },
  typePlaceholder: {
    defaultMessage: 'Type',
  },
});

export const devicesAdvancedSearchFields = {
  CUSTOMER_ID: 'customerId',
  TENANT_ID: 'tenantId',
  ROOM_NAME: 'roomName',
  BED_NAME: 'bedName',
  MOUNT_TYPE: 'mountingType',
  WIRING_TYPE: 'wiringType',
  ELECTRICAL_OUTLET_REPLACEMENT: 'electricalOutletReplacement',
  MANUFACTURER_ID: 'manufacturerId',
  DEVICE_ID: 'deviceId',
  LAST_CONNECTION_DATE: 'lastModifiedDate',
  ACTIVATION_DATE: 'activationDate',
};

export const DevicesFilterOptions = [
  { value: 'allDevices', label: 'All Devices' },
  { value: 'allLocations', label: 'All Locations' },
  { value: 'mountOnly', label: 'Mount Only' },
];

export const getEnabledFields = (currentCustomer: boolean) => ({
  allDevices: {
    [devicesAdvancedSearchFields.CUSTOMER_ID]: !currentCustomer,
    [devicesAdvancedSearchFields.TENANT_ID]: true,
    [devicesAdvancedSearchFields.MANUFACTURER_ID]: true,
    [devicesAdvancedSearchFields.LAST_CONNECTION_DATE]: true,
    [devicesAdvancedSearchFields.ACTIVATION_DATE]: true,
  },
  allLocations: {
    [devicesAdvancedSearchFields.CUSTOMER_ID]: !currentCustomer,
    [devicesAdvancedSearchFields.TENANT_ID]: true,
    [devicesAdvancedSearchFields.ROOM_NAME]: true,
    [devicesAdvancedSearchFields.BED_NAME]: true,
    [devicesAdvancedSearchFields.MOUNT_TYPE]: true,
    [devicesAdvancedSearchFields.WIRING_TYPE]: true,
    [devicesAdvancedSearchFields.ELECTRICAL_OUTLET_REPLACEMENT]: true,
    [devicesAdvancedSearchFields.DEVICE_ID]: true,
  },
  mountOnly: {
    [devicesAdvancedSearchFields.CUSTOMER_ID]: !currentCustomer,
    [devicesAdvancedSearchFields.TENANT_ID]: true,
    [devicesAdvancedSearchFields.ROOM_NAME]: true,
    [devicesAdvancedSearchFields.BED_NAME]: true,
    [devicesAdvancedSearchFields.MOUNT_TYPE]: true,
    [devicesAdvancedSearchFields.WIRING_TYPE]: true,
    [devicesAdvancedSearchFields.ELECTRICAL_OUTLET_REPLACEMENT]: true,
    [devicesAdvancedSearchFields.DEVICE_ID]: true,
  },
});

export const devicesAdvancedSearchLabels = defineMessages({
  customerName: {
    defaultMessage: 'Customer Name',
  },
  tenantName: {
    defaultMessage: 'Tenant Name',
  },
  roomName: {
    defaultMessage: 'Room Name',
  },
  bedName: {
    defaultMessage: 'Bed Name',
  },
  mountType: {
    defaultMessage: 'Mount Type',
  },
  wiringType: {
    defaultMessage: 'Wiring Type',
  },
  electricalOutletReplacement: {
    defaultMessage: 'Electrical Outlet Replacement',
  },
  deviceSN: {
    defaultMessage: 'Device S/N',
  },
  lastConnectionDate: {
    defaultMessage: 'Last Connection Date',
  },
  activationDate: {
    defaultMessage: 'Activation Date',
  },
});
