import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'src/redux/store';
import * as R from 'fp-ts/lib/Record';
import * as A from 'fp-ts/lib/Array';
import { trivial } from 'fp-ts/lib/Ord';

import {
  selectors as patientSelectors,
  actions as patientActions,
} from 'src/redux/data/patient';
import { selectors as alertSelectors } from 'src/redux/data/alerts';
import {
  actions as sessionActions,
  selectors as sessionSelectors,
} from 'src/redux/data/sessions';
import { selectors as deviceSelectors } from 'src/redux/data/device';
import { selectors as roomSelectors } from 'src/redux/data/rooms';
import {
  actions as monitorActions,
  selectors as monitorSelectors,
} from 'src/redux/data/monitor';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';
import { DeviceMonitorStatus } from 'src/types/devices';
import { UUID } from 'src/types/utility';
import { Alert } from 'src/types/alerts';
import { extractLatestPatientSession } from 'src/redux/data/dataUtils';
import {
  DeviceStatusFilterOption,
  FilterItem,
  STATUS_FILTERS,
} from '../../modules/filterUtils';
import { NurseStationDevice } from '../../modules/types';
import {
  sortMonitorPatients,
  sortWithEmptyString,
} from 'src/utils/sorters/utils';
import { MonitoredPatient, PatientStatus } from './types';

const getDeviceStatus = (
  device: NurseStationDevice,
): DeviceStatusFilterOption | undefined => {
  const a = R.collect(trivial)(
    (status: DeviceStatusFilterOption, item: FilterItem) => ({
      ...item,
      status,
    }),
  )(STATUS_FILTERS).sort((a, b) => a.displayOrder - b.displayOrder);

  for (const statusFilter of a) {
    if (statusFilter.filter(device)) {
      return statusFilter.status;
    }
  }

  return undefined;
};

const getPatientCurrentAlert = (
  alerts: Alert[],
  patientId: UUID,
): Alert[] | undefined =>
  alerts.filter(
    alert =>
      ['ON', 'SUPPRESSED'].includes(alert.status) &&
      alert.patientId === patientId,
  );
const filterPatientEventLogAlerts = (
  alerts: Alert[],
  patientId: UUID,
): Alert[] =>
  A.filter<Alert>(
    alert => ['OFF'].includes(alert.status) && alert.patientId === patientId,
  )(alerts);

const selectMonitoredPatients = (
  state: RootState,
  searchKeyword: string | null,
): MonitoredPatient[] => {
  const patients = patientSelectors.getPatientsList(state);
  const sessions = sessionSelectors.selectSessions(state);
  const devices = deviceSelectors.getDevicesList(state);
  const rooms = roomSelectors.getRoomList(state);
  const monitorDevices = monitorSelectors.getDevices(state);
  const alerts = alertSelectors.selectAlerts(state);

  const { sortBy } = patientSelectors.selectTableParams(state);
  const mappedPatients = patients.map(patient => {
    const currentPatientAlert = getPatientCurrentAlert(alerts, patient.id);
    const eventLogPatientAlerts = filterPatientEventLogAlerts(
      alerts,
      patient.id,
    );
    const patientSession = extractLatestPatientSession(patient.id, sessions);
    const isPatientDeviceDischarging = patientSession?.deviceId
      ? monitorSelectors.getIsStopContinuousLoading(
          state,
          patientSession?.deviceId,
        )
      : false;

    if (!patientSession) {
      return {
        ...patient,
        currentAlert: currentPatientAlert,
        eventLogAlerts: eventLogPatientAlerts.length,
      };
    }

    const patientDevice = devices.find(
      d => d.manufacturerId === patientSession.deviceId,
    );

    const monitorDevice = monitorDevices[patientSession.deviceId];

    const room = rooms.find(room =>
      room.beds?.find(bed => bed.deviceId === patientSession.deviceId),
    );
    const bed = room?.beds?.find(
      bed => bed.deviceId === patientSession.deviceId,
    );

    const patientStatus = isPatientDeviceDischarging
      ? PatientStatus.DISCHARGING
      : patientSession.endTime
        ? PatientStatus.DISCHARGED
        : PatientStatus.ASSIGNED;
    const isAssigned = patientStatus === PatientStatus.ASSIGNED;

    const locationName =
      isAssigned && room && bed ? `${room.name} ${bed.name}` : '';
    const roomName = isAssigned ? room?.name : '';
    const bedName = isAssigned ? bed?.name : '';

    if (!patientDevice && !monitorDevice) {
      return {
        ...patient,
        locationName,
        roomName,
        bedName,
        patientStatus,
        currentAlert: currentPatientAlert,
        eventLogAlerts: eventLogPatientAlerts.length,
        patientHasData: !!patientSession.deviceId,
      };
    }

    const isDeviceConnected = !!patientDevice?.connectionStatus.connected;

    const extendedDevice = {
      ...patientSession,
      deviceName: patientDevice?.name,
      patientName: patient.fullName,
      patientHasConsent: patient.isConsented ?? false,
      status: monitorDevice?.status || null,
      continuousData: monitorDevice?.continuousData,
      certificateExists: patientDevice?.certificateExists,
      isDeviceConnected: isDeviceConnected,
      isDeviceActive:
        isDeviceConnected &&
        monitorDevice?.status === DeviceMonitorStatus.ACTIVE,
      roomName,
      bedName,
    };
    const deviceStatus = isAssigned
      ? getDeviceStatus(extendedDevice)
      : undefined;

    return {
      ...patient,
      locationName,
      roomName,
      bedName,
      patientStatus,
      deviceStatus,
      isPatientDeviceDischarging,
      patientHasData: !!patientSession.deviceId,
      deviceId: patientDevice?.inf?.deviceId,
      currentAlert: currentPatientAlert,
      eventLogAlerts: eventLogPatientAlerts.length,
    };
  });

  let patientsListTableData = mappedPatients;

  if (searchKeyword?.length) {
    patientsListTableData = mappedPatients.filter(patient =>
      patient.fullName.toLowerCase().includes(searchKeyword.toLowerCase()),
    );
  }

  if (sortBy.order === 'ascend') {
    return patientsListTableData.sort((a, b) =>
      sortMonitorPatients(
        // @ts-ignore TODO: fix it later
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        a[sortBy.columnKey],
        // @ts-ignore TODO: fix it later
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        b[sortBy.columnKey],
        // @ts-ignore TODO: fix it later
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        sortBy.columnKey,
        {
          numeric: true,
        },
      ),
    );
  }
  if (sortBy.order === 'descend') {
    return patientsListTableData.sort((a, b) =>
      sortMonitorPatients(
        // @ts-ignore TODO: fix it later
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        b[sortBy.columnKey],
        // @ts-ignore TODO: fix it later
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        a[sortBy.columnKey],
        // @ts-ignore TODO: fix it later
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        sortBy.columnKey,
        {
          numeric: true,
        },
      ),
    );
  }
  return patientsListTableData.sort((a, b) =>
    // @ts-ignore TODO: fix it later
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    sortWithEmptyString(a.locationName, b.locationName, { numeric: true }),
  );
};

const mapStateToProps = (
  state: RootState,
  ownProps: { searchKeyword: string | null },
) => ({
  patientsList: selectMonitoredPatients(state, ownProps.searchKeyword),
  allPatientsList: patientSelectors.getPatientsList(state),
  isLoading: patientSelectors.getStatus(state) === DATA_FETCHING_STATUS.LOADING,
  modalStatus: patientSelectors.getModalStatus(state),
  areMonitoredPersonsPageLoading:
    alertSelectors.areHistoricalAlertsLoading(state) ||
    patientSelectors.isPatientListLoading(state) ||
    sessionSelectors.areSessionsLoading(state) ||
    deviceSelectors.isDeviceListLoading(state) ||
    roomSelectors.getIsLoading(state),
  tableParams: patientSelectors.selectTableParams(state),
});

const mapActionCreators = {
  fetchAllPatients: patientActions.fetchAllPatients,
  fetchPatientsSessions: sessionActions.fetchPatientsLatestSessions,
  createPatient: patientActions.createPatient,
  updatePatient: patientActions.updatePatient,
  setModalStatus: patientActions.setModalStatus,
  stopContinuousSession: monitorActions.onClickStopContinuous,
  setTableParams: patientActions.setTableParams,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
