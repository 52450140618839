import React, { useEffect } from 'react';
import Connector, { PropsFromRedux } from './Connector';
import HistogramComponent from 'src/routes/IndividualDashboard/components/DashboardTab/HrRrHistogram/HistogramComponent';

type Props = PropsFromRedux;

const HrRrHistogram = ({
  histogramData,
  patient,
  getHistogramData,
  clearHistogramData,
  isHistogramDataLoading,
}: Props): JSX.Element => {
  const patientId = patient?.id;

  useEffect(() => {
    if (patientId) {
      getHistogramData(patientId);
    }
    return () => {
      clearHistogramData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  return (
    <HistogramComponent
      histogramData={histogramData}
      isHistogramDataLoading={isHistogramDataLoading}
    />
  );
};

export default Connector(HrRrHistogram);
