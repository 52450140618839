import React, { useEffect, useState } from 'react';
import {
  StyledButton,
  StyledCustomerForm,
  Wrapper,
  StyledPrimaryBtn,
  StyledCancelBtn,
  StyledButtonsContainer,
} from 'src/routes/Customers/components/DetailsTab/styled';
import CustomerForm from 'src/routes/Customers/components/CustomerForm';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import Connector, { PropsFromRedux } from './Connector';
import { deepEqual } from 'src/utils/comparators';
import {
  CreateCustomerPayload,
  CustomerTypeWithAccessData,
} from 'src/routes/Customers/modules/types';
import LoadingOverlay from 'src/components/general-ui/LoadingOverlay';
import { FormInstance } from 'antd/es/form/hooks/useForm';

type Props = PropsFromRedux & {
  currentCustomer: CreateCustomerPayload;
  isAddMode?: boolean;
  intl: IntlShape;
};
const DetailsTab = ({
  currentCustomer,
  isAddMode,
  currentCustomerAccessData,
  editCustomer,
  getCustomerAccess,
  isLoading,
  areRequestsCompleted,
  intl,
}: Props) => {
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [form, setForm] =
    useState<FormInstance<CustomerTypeWithAccessData> | null>(null);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setIsFormDisabled(true);
  }, [currentCustomer]);

  useEffect(() => {
    if (!currentCustomer?.id) {
      return;
    }
    getCustomerAccess(currentCustomer.id ?? '');
  }, [currentCustomer?.id]);

  return (
    <Wrapper>
      <StyledCustomerForm>
        {isLoading && <LoadingOverlay />}
        {areRequestsCompleted && (
          <CustomerForm
            {...{
              ...currentCustomer,
              ...currentCustomerAccessData?.data,
            }}
            onFormMount={(form: FormInstance<CustomerTypeWithAccessData>) =>
              setForm(form)
            }
            setIsFormValid={setIsFormValid}
            isFromDisabled={isFormDisabled}
            isAddMode={isAddMode}
          />
        )}
        <StyledButton
          className={'edit-button'}
          onClick={() => setIsFormDisabled(false)}
          disabled={!isFormDisabled}
        >
          {intl.formatMessage(messages.editCustomerLabel)}
        </StyledButton>
      </StyledCustomerForm>
      <StyledButtonsContainer>
        <StyledCancelBtn
          disabled={isFormDisabled}
          onClick={() => {
            if (!form) {
              return;
            }
            form.setFieldsValue({
              ...currentCustomer,
              ...currentCustomerAccessData?.data,
              ipAddresses: (currentCustomerAccessData?.data?.ipAddresses ?? [])
                .filter(elem => !!elem)
                .join(`\n`),
            });
            setIsFormDisabled(true);
          }}
        >
          {intl.formatMessage(messages.cancel)}
        </StyledCancelBtn>
        <StyledPrimaryBtn
          disabled={isFormDisabled || !isFormValid}
          onClick={() =>
            editCustomer({
              customerId: currentCustomer?.id ?? '',
              ...(form?.getFieldsValue() as Omit<
                CustomerTypeWithAccessData,
                'customerId'
              >),
            })
          }
        >
          {intl.formatMessage(messages.save)}
        </StyledPrimaryBtn>
      </StyledButtonsContainer>
    </Wrapper>
  );
};

const messages = defineMessages({
  editCustomerLabel: {
    defaultMessage: 'Edit Customer',
  },
  save: {
    defaultMessage: 'Save',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
});
export default Connector(
  injectIntl(
    React.memo(DetailsTab, (oldProps, newProps) =>
      deepEqual(oldProps, newProps),
    ),
  ),
);
