import {
  all,
  takeLatest,
  call,
  put,
  select,
  delay,
} from 'typed-redux-saga/macro';

import BackendService, {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
} from 'src/services/BackendService';
import {
  actionSuccessNotification,
  notifyUserByActionTypeAndCode,
} from 'src/utils/errorHandling/notifications';
import permissions from 'src/permissions';
import { hasAnyPermissions, hasAllPermissions } from 'src/utils/permissions';
import { MODAL_STATUS } from 'src/components/Modal/constants';
import { actions as deviceActions } from 'src/routes/Devices/modules/slice';
import { actions, getAdminDevicesListParams } from './slice';
import {
  DATA_FETCHING_STATUS,
  DEVICE_DEFAULT_PROTOCOL_TYPE,
} from '../../constants';
import dataActions from '../../dataActions';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { SerialNumber, UUID } from 'src/types/utility';
import { RootState } from 'src/redux/store';
import { USER_TYPES } from 'src/utils/constants';
import { filterDuplicates } from 'src/utils/dataUtils';
import {
  getDevicesForLocations,
  getLocationDataForDevices,
  isSysAdmin,
} from 'src/redux/data/device/modules/utils';
import { parseMountOptions } from './utils';
import { FetchAdminLocationsDevicesResponse } from 'src/services/types';
import { AxiosResponse } from 'axios';
import { ConnectedDevices } from 'src/types/devices';
import { prepareFiltersForQueryParamsObj } from 'src/services/utils';
import {
  NEW_BED_ID,
  NEW_ROOM_ID,
} from 'src/redux/data/rooms/modules/constants';
import { Room } from 'src/types/rooms';
import { CreateBedPayload, CreateRoomPayload } from './types';

function* createRoom(action: ReturnType<typeof actions.createDevice>) {
  const { room, bed, tenantId } = action.payload;

  if (!room) {
    return;
  }
  const payload: CreateRoomPayload = {
    name: room.label,
    beds: [
      {
        name: bed?.label,
      },
    ],
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  if (tenantId && isSysAdmin()) {
    payload.tenantId = tenantId;
  }
  return yield* call(BackendService.createRoom, payload);
}

function* addBed(action: ReturnType<typeof actions.createDevice>) {
  const { tenantId, bed, room } = action.payload;
  if (!bed?.label || !room?.id) {
    return;
  }

  const payload: CreateBedPayload = {
    roomId: room.id,
    name: bed.label,
  };
  if (tenantId && isSysAdmin()) {
    payload.tenantId = tenantId;
  }
  return yield* call(BackendService.createBedInRoom, payload);
}

function* getRoomAndBedApiResponse(
  action: ReturnType<typeof actions.createDevice>,
) {
  let bedApiResponse:
    | AxiosResponse<Room>
    | AxiosResponse<{ id: string; name: string }>
    | undefined = undefined;
  const { bed, room } = action.payload;
  if (hasAllPermissions(permissions.VIEW_ROOMS_DEVICE_MODAL)) {
    if (bed) {
      if (room?.value === NEW_ROOM_ID) {
        bedApiResponse = yield* call(createRoom, action);
      } else {
        if (bed?.value === NEW_BED_ID) {
          bedApiResponse = yield* call(addBed, action);
        }
      }
    }
  }
  return bedApiResponse;
}

export function* getUserDevicesList(
  action: ReturnType<typeof actions.getUserDevicesList>,
) {
  const userType = yield* select(loggedInUserSelectors.getLoggedInUserType);
  const isTenantAdmin = userType?.name === USER_TYPES.TENANT_ADMIN;
  if (isSysAdmin()) {
    yield* call(getAdminDevicesList, action);
    return;
  }
  if (isTenantAdmin) {
    yield* call(getTenantAdminDevicesList, action);
    return;
  }
  if (
    hasAnyPermissions(
      permissions.VIEW_DEVICES_MONITOR_ACTION,
      permissions.VIEW_DEVICES_SIDE_MENU_BUTTON,
    )
  ) {
    yield* call(getDevicesList, action);
    return;
  }
  yield* put(actions.setStatus(DATA_FETCHING_STATUS.ERROR));
}

export function* getTenantAdminDevicesList(
  action?: ReturnType<typeof actions.getAdminDevicesList>,
) {
  try {
    const params = action?.payload ?? {
      viewMode: 'allDevices',
      page: DEFAULT_PAGE,
      limit: DEFAULT_LIMIT,
    };
    const { viewMode = 'allDevices' } = params;
    if (viewMode === 'allDevices') {
      yield* call(getAllDevicesList, params);
      return;
    }
    yield* call(getLocationAndDevices, params);
  } catch (e) {
    console.error('error in getDevicesList: ', e);
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.ERROR));
  }
}

export function* getDevicesList() {
  try {
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.LOADING));
    const { data } = yield* call(BackendService.getDevices);
    yield* put(
      actions.setDevicesList({
        data: data?.data,
        metadata: data?.metadata?.page,
      }),
    );
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.SUCCESS));
  } catch (e) {
    console.error('error in getDevicesList: ', e);
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.ERROR));
  }
}

function* getAllDevicesList(params: getAdminDevicesListParams) {
  try {
    const { page, limit, searchKeyword = '', filters, sortBy } = params;
    if (searchKeyword) {
      yield* delay(500);
    }
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.LOADING));
    const { data } = yield* call(BackendService.getTenantAdminDevicesList, {
      paginationParams: { page, limit },
      searchKeyword,
      filters: prepareFiltersForQueryParamsObj(filters),
      sortBy,
    });
    yield* put(
      actions.setDevicesList({
        // this map needs to be removed after we integrate new endpoints in the SA devices
        data: (data?.data ?? []).map(device => ({
          ...device,
          manufacturerId: device.deviceId ?? '',
        })),
        metadata: data?.metadata?.page,
      }),
    );
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.SUCCESS));
  } catch (e) {
    console.error('error in getAdminDevicesList: ', e);
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.ERROR));
  }
}
function* getLocationAndDevices(params: getAdminDevicesListParams) {
  try {
    const { page, limit, searchKeyword = '', filters, sortBy } = params;
    if (searchKeyword) {
      yield* delay(500);
    }
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.LOADING));
    const { data } = yield* call(BackendService.getLocationAndDevices, {
      paginationParams: { page, limit },
      searchKeyword,
      filters: prepareFiltersForQueryParamsObj(filters),
      sortBy,
    });
    yield* put(
      actions.setDevicesList({
        // this map needs to be removed after we integrate new endpoints in the SA devices
        data: data?.data,
        metadata: data?.metadata?.page,
      }),
    );
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.SUCCESS));
  } catch (e) {
    console.error('error in getLocationAndDevices: ', e);
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.ERROR));
  }
}

function* getAdminAllDevicesList(params: getAdminDevicesListParams) {
  try {
    const { page, limit, searchKeyword = '', filters, sortBy } = params;
    let deviceLocationsResponse;
    let monitoringDaysResponse;
    if (searchKeyword) {
      yield* delay(500);
    }
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.LOADING));
    const devicesResponse = yield* call(BackendService.adminGetDevices, {
      paginationParams: { page, limit },
      searchKeyword,
      filters: prepareFiltersForQueryParamsObj(filters),
      sortBy,
    });
    const paginatedDevicesIds = filterDuplicates(
      devicesResponse.data.data.reduce(
        (acc: UUID[], device) => [...acc, device.manufacturerId || ''],
        [],
      ),
    ).filter(elem => !!elem);
    const paginatedCustomerIds = filterDuplicates(
      devicesResponse.data.data.reduce(
        (acc: UUID[], device) => [...acc, device.customerId || ''],
        [],
      ),
    ).filter(elem => !!elem);
    const { data: customersNames } = yield* call(
      BackendService.searchCustomers,
      {
        customerIds: paginatedCustomerIds,
      },
    );
    if (paginatedDevicesIds?.length) {
      deviceLocationsResponse = yield* call(
        BackendService.adminGetLocationsDevices,
        {
          paginationParams: { page, limit },
          searchKeyword: '',
          filters: [
            {
              prop: 'deviceId',
              values: paginatedDevicesIds,
            },
          ],
        },
      );
      monitoringDaysResponse = yield* call(
        BackendService.adminGetMonitoringDaysDevices,
        paginatedDevicesIds,
      );
    }

    const devicesAndLocationsData = getLocationDataForDevices(
      devicesResponse,
      deviceLocationsResponse,
      monitoringDaysResponse,
      customersNames?.data,
    );

    yield* put(
      actions.setDevicesList({
        data: devicesAndLocationsData,
        metadata: devicesResponse.data.metadata.page,
      }),
    );
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.SUCCESS));
  } catch (e) {
    console.error('error in getAdminDevicesList: ', e);
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.ERROR));
  }
}
function* getAdminAllLocationsDevicesList(params: getAdminDevicesListParams) {
  try {
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.LOADING));
    const { page, limit, searchKeyword = '', filters, sortBy } = params;
    let devicesDataResponse;
    let monitoringDaysResponse;
    const locationsResponse = yield* call(
      BackendService.adminGetLocationsDevices,
      {
        paginationParams: { page, limit },
        searchKeyword,
        filters: prepareFiltersForQueryParamsObj(filters),
        sortBy,
      },
    );
    const currentPageLocationsDeviceIds = filterDuplicates(
      locationsResponse.data.data.reduce(
        (acc: UUID[], device) => [...acc, device.deviceId || ''],
        [],
      ),
    ).filter(elem => !!elem);
    if (currentPageLocationsDeviceIds?.length) {
      devicesDataResponse = yield* call(BackendService.adminGetDevices, {
        paginationParams: { page, limit },
        filters: [
          {
            prop: 'manufacturerId',
            values: currentPageLocationsDeviceIds,
          },
        ],
      });
      monitoringDaysResponse = yield* call(
        BackendService.adminGetMonitoringDaysDevices,
        currentPageLocationsDeviceIds,
      );
    }
    const locationsAndDevicesData = getDevicesForLocations(
      locationsResponse,
      devicesDataResponse,
      monitoringDaysResponse,
    );
    yield* put(
      actions.setDevicesList({
        data: locationsAndDevicesData,
        metadata: locationsResponse.data.metadata.page,
      }),
    );
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.SUCCESS));
  } catch (e) {
    console.error('error in getAdminDevicesList: ', e);
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.ERROR));
  }
}

function* getAdminDevicesList(
  action: ReturnType<typeof actions.getAdminDevicesList>,
) {
  try {
    const params = action.payload;
    const { viewMode = 'allDevices' } = params;
    if (viewMode === 'allDevices') {
      yield* call(getAdminAllDevicesList, params);
      return;
    }
    yield* call(getAdminAllLocationsDevicesList, params);
  } catch (e) {
    console.error('error in getAdminDevicesList: ', e);
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.ERROR));
  }
}

function* searchAdminDevicesListByTenantId(action: any) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { payload } = action;
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.LOADING));
    const devicesResponse = yield* call(
      BackendService.getAdminDevicesByTenantId,
      payload,
    );

    yield* put(
      actions.setDevicesList({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data: devicesResponse?.data?.data,
        metadata: devicesResponse?.data?.metadata?.page,
      }),
    );
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.SUCCESS));
  } catch (e) {
    console.error('error in search searchAdminDevicesListByTenantId: ', e);
    yield* put(
      actions.setDevicesList({
        data: [],
        metadata: {
          totalResults: 0,
          page: 0,
          limit: 0,
        },
      }),
    );
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.ERROR));
    // notifyUserByActionTypeAndCode(action.type, null, e);
  }
}

function* searchAllDevicesAdmin(
  action: ReturnType<typeof actions.searchAllDevicesAdmin>,
) {
  const { payload } = action;

  if (!payload) {
    yield* put(
      actions.setDevicesList({
        data: [],
        metadata: {
          totalResults: 0,
          page: 0,
          limit: 0,
        },
      }),
    );
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.SUCCESS));
    return;
  }
  yield* delay(500);
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.LOADING));
    const devicesResponse = yield* call(
      BackendService.adminSearchAllDevices,
      payload,
    );
    yield* put(
      actions.setDevicesList({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data: devicesResponse?.data?.data,
        metadata: devicesResponse?.data?.metadata?.page,
      }),
    );
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.SUCCESS));
  } catch (e) {
    console.error('error in search searchAdminDevicesListByTenantId: ', e);
    yield* put(
      actions.setDevicesList({
        data: [],
        metadata: {
          totalResults: 0,
          page: 0,
          limit: 0,
        },
      }),
    );
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.ERROR));
    notifyUserByActionTypeAndCode(action.type, null, e);
  }
}

function* createDevice(action: ReturnType<typeof actions.createDevice>) {
  const devicePageMetadata = yield* select((state: RootState) => ({
    page: state.data.device.devicesList.pageMetadata.page,
    limit: state.data.device.devicesList.pageMetadata.limit,
  }));

  const pageParams = { payload: devicePageMetadata, type: '' };
  const { bed, room, manufacturerId, ...editableDeviceData } = action.payload;
  try {
    yield* put(actions.setModalStatus(MODAL_STATUS.SUBMITTING));
    const bedApiResponse = yield* call(getRoomAndBedApiResponse, action);
    const newCreatedBedId =
      (bedApiResponse as AxiosResponse<Room>)?.data?.beds?.[0]?.id ??
      bedApiResponse?.data?.id;
    const createDeviceRequest = newCreatedBedId
      ? {
          ...editableDeviceData,
          bedId: newCreatedBedId,
          manufacturerId,
          deviceProtocolType: DEVICE_DEFAULT_PROTOCOL_TYPE,
        }
      : {
          ...editableDeviceData,
          manufacturerId,
          deviceProtocolType: DEVICE_DEFAULT_PROTOCOL_TYPE,
        };

    yield* call(BackendService.adminCreateDevice, createDeviceRequest);
    yield* call(getUserDevicesList, pageParams);
    yield* put(actions.setModalStatus(MODAL_STATUS.SUCCESS));
  } catch (error) {
    console.error('error in createDevice: ', error);
    yield* put(actions.setModalStatus(MODAL_STATUS.ERROR));
    notifyUserByActionTypeAndCode(action.type, manufacturerId, error);
  }
}

function* deleteDeviceCertificate(manufacturerId: SerialNumber) {
  yield* call(BackendService.deleteDeviceCertificate, manufacturerId);
}

function* deleteDevice(action: ReturnType<typeof actions.deleteDevice>) {
  const { manufacturerId, certificateExists } = action.payload;

  const devicePageMetadata = yield* select((state: RootState) => ({
    page: state.data.device.devicesList.pageMetadata.page,
    limit: state.data.device.devicesList.pageMetadata.limit,
  }));

  const paginationAction = { payload: devicePageMetadata, type: '' };

  try {
    if (certificateExists) {
      yield* call(deleteDeviceCertificate, manufacturerId);
    }
    yield* call(BackendService.adminDeleteDevice, manufacturerId);
    yield* call(getUserDevicesList, paginationAction);
  } catch (error) {
    console.error('error in deleteDevice: ', error);
    notifyUserByActionTypeAndCode(action.type, manufacturerId, error);
  }
}

function* editDevice(action: ReturnType<typeof actions.editDevice>) {
  const { manufacturerId, bed, room, ...editDeviceData } = action.payload;
  const devicePageMetadata = yield* select((state: RootState) => ({
    page: state.data.device.devicesList.pageMetadata.page,
    limit: state.data.device.devicesList.pageMetadata.limit,
  }));

  const paginationAction = { payload: devicePageMetadata, type: '' };

  try {
    yield* put(actions.setModalStatus(MODAL_STATUS.SUBMITTING));
    // create room/bed if necessary
    const bedApiResponse = yield* call(getRoomAndBedApiResponse, action);
    const newCreatedBedId =
      (bedApiResponse as AxiosResponse<Room>)?.data?.beds?.[0]?.id ??
      (bedApiResponse as AxiosResponse<{ id: string; name: string }>)?.data?.id;
    if (
      hasAllPermissions(
        permissions.VIEW_DEVICES_ADD_BUTTON,
        permissions.VIEW_DEVICES_TENANT_ACTIONS,
        permissions.VIEW_DEVICES_TENANT_COLUMN,
        permissions.VIEW_DEVICES_EDIT_DEVICE_ACTION,
        permissions.VIEW_DEVICES_DELETE_DEVICE_ACTION,
      )
    ) {
      yield* call(
        BackendService.adminUpdateDevice,
        manufacturerId,
        newCreatedBedId
          ? {
              ...editDeviceData,
              bedId: newCreatedBedId,
            }
          : { ...editDeviceData },
      );
    } else {
      yield* call(
        BackendService.updateDevice,
        manufacturerId,
        newCreatedBedId
          ? {
              ...editDeviceData,
              bedId: newCreatedBedId,
            }
          : { ...editDeviceData },
      );
    }
    yield* call(getUserDevicesList, paginationAction);
    yield* put(actions.setModalStatus(MODAL_STATUS.SUCCESS));
  } catch (error) {
    console.error('error in editDevice: ', error);
    yield* put(actions.setModalStatus(MODAL_STATUS.ERROR));
    notifyUserByActionTypeAndCode(action.type, manufacturerId, error);
  }
}

function* assignDevice(action: ReturnType<typeof actions.assignDevice>) {
  const { manufacturerId, tenantSelect } = action.payload;
  const devicePageMetadata = yield* select((state: RootState) => ({
    page: state.data.device.devicesList.pageMetadata.page,
    limit: state.data.device.devicesList.pageMetadata.limit,
  }));

  const paginationAction = { payload: devicePageMetadata, type: '' };
  try {
    yield* call(
      BackendService.adminUpdateDeviceTenant,
      manufacturerId,
      tenantSelect,
    );
    yield* call(getUserDevicesList, paginationAction);
  } catch (error) {
    console.error('error in assignDevice: ', error);
    notifyUserByActionTypeAndCode(action.type, manufacturerId, error);
  }
}

function* detachDevice(action: ReturnType<typeof actions.detachDevice>) {
  const manufacturerId = action.payload;
  const devicePageMetadata = yield* select((state: RootState) => ({
    page: state.data.device.devicesList.pageMetadata.page,
    limit: state.data.device.devicesList.pageMetadata.limit,
  }));

  const paginationAction = { payload: devicePageMetadata, type: '' };

  try {
    yield* call(BackendService.adminUpdateDeviceTenant, manufacturerId, null);
    yield* call(getUserDevicesList, paginationAction);
  } catch (error) {
    console.error('error in detachDevice: ', error);
    notifyUserByActionTypeAndCode(action.type, manufacturerId, error);
  }
}

function* fetchDeviceConnectionForCurrentTenant() {
  const tenantId = yield* select(loggedInUserSelectors.getCurrentTenantId);

  const continuousDisconnectionSeconds = 0;

  try {
    const { data } = yield* call(
      BackendService.getDeviceConnectionInfoByTenantId,
      tenantId,
      continuousDisconnectionSeconds,
    );

    yield* put(actions.fetchDeviceConnectionInfoSuccess(data));
  } catch (error) {
    console.error('error in fetchDeviceConnectionInfo: ', error);
  }
}

function* fetchMTMDeviceConnectionInfo(
  action: ReturnType<typeof actions.fetchMTMDeviceConnectionInfo>,
) {
  const continuousDisconnectionSeconds = action.payload;

  try {
    const { data } = yield* call(
      BackendService.fetchMTMDeviceConnectionInfo,
      continuousDisconnectionSeconds,
    );
    const mappedResponse = {};
    data.data.forEach(elem => {
      // @ts-ignore need to fix this
      mappedResponse[elem.tenantId] = { ...elem };
    });
    yield* put(actions.fetchMTMDeviceConnectionInfoSuccess(mappedResponse));
  } catch (error) {
    console.error('error in fetchMTMDeviceConnectionInfo: ', error);
  }
}

function* updateDeviceConnectionInfo() {
  const DEFAULT_CONTINUOUS_DISC_SEC = 24 * 60 * 60; // 24h;
  const userType = yield* select(loggedInUserSelectors.getLoggedInUserType);

  if (userType?.name === USER_TYPES.HEALTH_PROFESSIONAL) {
    yield* put(actions.fetchDeviceConnectionForCurrentTenant());
  }

  if (userType?.name === USER_TYPES.MULTI_TENANT_MANAGER) {
    yield* put(
      actions.fetchMTMDeviceConnectionInfo(DEFAULT_CONTINUOUS_DISC_SEC),
    );
  }
}

function* resetContinuous(action: ReturnType<typeof actions.resetContinuous>) {
  const deviceId = action.payload;

  try {
    yield* put(actions.resetContinuousStatus(DATA_FETCHING_STATUS.LOADING));
    yield* call(BackendService.resetContinuous, deviceId);

    actionSuccessNotification(action.type, deviceId);

    yield* put(actions.resetContinuousStatus(DATA_FETCHING_STATUS.SUCCESS));
  } catch (error) {
    yield* put(actions.resetContinuousStatus(DATA_FETCHING_STATUS.ERROR));
    notifyUserByActionTypeAndCode(action.type, deviceId, error);
  }
}
function* getLocationMountOptions(
  action: ReturnType<typeof actions.getLocationMountOptions>,
) {
  try {
    yield* put(
      actions.setLocationMountOptionsStatus(DATA_FETCHING_STATUS.LOADING),
    );
    const { data } = yield* call(BackendService.getLocationMountOptions);
    yield* put(
      actions.getLocationMountOptionsSuccess({
        data: parseMountOptions(data),
        status: DATA_FETCHING_STATUS.SUCCESS,
      }),
    );
  } catch (error) {
    console.error('error in getLocationMountOptions: ', error);
    yield* put(
      actions.setLocationMountOptionsStatus(DATA_FETCHING_STATUS.ERROR),
    );
    notifyUserByActionTypeAndCode(action.type, null, error);
  }
}
function* getSpecificDeviceData(
  action: ReturnType<typeof actions.getSpecificDeviceData>,
) {
  try {
    yield* put(
      actions.setSpecificDeviceDataStatus(DATA_FETCHING_STATUS.LOADING),
    );
    const { data } = yield* call(BackendService.getDevices, action.payload);
    yield* put(
      actions.getSpecificDeviceDataSuccess({
        data: data?.data?.[0] ?? null,
      }),
    );
    yield* put(
      actions.setSpecificDeviceDataStatus(DATA_FETCHING_STATUS.SUCCESS),
    );
  } catch (error) {
    console.error('error in getSpecificDeviceData: ', error);
    yield* put(actions.setSpecificDeviceDataStatus(DATA_FETCHING_STATUS.ERROR));
    notifyUserByActionTypeAndCode(action.type, null, error);
  }
}
function* getFwCounters(action: ReturnType<typeof actions.getFwCounters>) {
  try {
    const currentCustomerId = action.payload;
    yield* put(
      actions.setFwVersionCountersStatus(DATA_FETCHING_STATUS.LOADING),
    );
    const { data } = yield* call(
      BackendService.getFwVersionCounters,
      currentCustomerId,
    );
    yield* put(
      actions.getFwVersionCountersSuccess(
        data?.deviceCountByVersionResponseList,
      ),
    );
    yield* put(
      actions.setFwVersionCountersStatus(DATA_FETCHING_STATUS.SUCCESS),
    );
  } catch (error) {
    console.error('error in getLocationMountOptions: ', error);
    yield* put(actions.setFwVersionCountersStatus(DATA_FETCHING_STATUS.ERROR));
    notifyUserByActionTypeAndCode(action.type, null, error);
  }
}
function* getTenantLocationInfo(
  action: ReturnType<typeof actions.getTenantLocationInfo>,
) {
  try {
    const tenantId = action.payload;
    yield* put(
      actions.setTenantDeviceLocationStatus(DATA_FETCHING_STATUS.LOADING),
    );
    const [locationsResponse, connectedTenantDevices] = yield* all([
      call(BackendService.adminGetLocationsDevices, {
        paginationParams: { page: 0, limit: 1000000 },
        searchKeyword: '',
        filters: prepareFiltersForQueryParamsObj({ tenantId }),
      }),
      call(BackendService.getTenantConnectedDevices, tenantId),
    ]);
    const locationResponseData = (
      locationsResponse as AxiosResponse<
        FetchAdminLocationsDevicesResponse,
        any
      >
    )?.data?.data;
    const connectedDeviceIds: Array<UUID> = [];
    (
      connectedTenantDevices as AxiosResponse<ConnectedDevices, any>
    )?.data.connectedDevices.map(({ manufacturerId }) =>
      connectedDeviceIds.push(manufacturerId),
    );
    const locationInfo = locationResponseData?.map(
      ({ deviceId, bedId, bedName, roomId, roomName }) => {
        const isCurrentDeviceConnected = connectedDeviceIds.includes(deviceId);
        return {
          bedId,
          roomId,
          bedName,
          roomName,
          deviceId,
          isConnected: isCurrentDeviceConnected,
        };
      },
    );
    yield* put(actions.getTenantDeviceLocationSuccess(locationInfo));
    yield* put(
      actions.setTenantDeviceLocationStatus(DATA_FETCHING_STATUS.SUCCESS),
    );
  } catch (error) {
    console.error('error in getLocationMountOptions: ', error);
    yield* put(
      actions.setTenantDeviceLocationStatus(DATA_FETCHING_STATUS.ERROR),
    );
    notifyUserByActionTypeAndCode(action.type, null, error);
  }
}

export default function* watchTenantActions() {
  yield* all([
    takeLatest(actions.getUserDevicesList, getUserDevicesList),
    takeLatest(actions.getDevicesList, getDevicesList),
    takeLatest(actions.getAdminDevicesList, getAdminDevicesList),
    takeLatest(actions.deleteDevice, deleteDevice),
    takeLatest(actions.editDevice, editDevice),
    takeLatest(actions.createDevice, createDevice),
    takeLatest(actions.assignDevice, assignDevice),
    takeLatest(actions.detachDevice, detachDevice),
    takeLatest(deviceActions.onLoadDevicesListPage, getUserDevicesList),
    takeLatest(
      dataActions.onLoadGroupDevices,
      searchAdminDevicesListByTenantId,
    ),
    takeLatest(actions.searchAllDevicesAdmin, searchAllDevicesAdmin),
    takeLatest(dataActions.onLoadManagment, getDevicesList),
    takeLatest(dataActions.monitorPageMounted, getDevicesList),
    takeLatest(
      actions.fetchMTMDeviceConnectionInfo,
      fetchMTMDeviceConnectionInfo,
    ),
    takeLatest(
      actions.fetchDeviceConnectionForCurrentTenant,
      fetchDeviceConnectionForCurrentTenant,
    ),
    takeLatest(actions.updateDeviceConnectionInfo, updateDeviceConnectionInfo),
    takeLatest(actions.resetContinuous, resetContinuous),
    takeLatest(actions.getLocationMountOptions, getLocationMountOptions),
    takeLatest(actions.getFwCounters, getFwCounters),
    takeLatest(actions.getTenantLocationInfo, getTenantLocationInfo),
    takeLatest(actions.getSpecificDeviceData, getSpecificDeviceData),
  ]);
}
