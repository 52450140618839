import React, { useEffect } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import Connector, { PropsFromRedux } from './Connector';
import { Wrapper } from './styled';
import { USER_TYPES } from 'src/utils/constants';
import { UUID } from 'src/types/utility';

type Props = PropsFromRedux & {
  loggedInUserType: { id: UUID; name: string } | undefined;
};

const PhiRestrictionBadge = ({
  loggedInUserType,
  userHasRegionalAccess,
  getUserPhiRegonalAccess,
}: Props): JSX.Element => {
  const userTypeWithRegionalAccess =
    loggedInUserType &&
    [USER_TYPES.HEALTH_PROFESSIONAL, USER_TYPES.MULTI_TENANT_MANAGER].includes(
      loggedInUserType?.name,
    );

  useEffect(() => {
    if (userTypeWithRegionalAccess) getUserPhiRegonalAccess();
  }, [getUserPhiRegonalAccess, userTypeWithRegionalAccess]);

  if (userHasRegionalAccess) {
    return (
      <Wrapper>
        <FormattedMessage {...messages.phiRestriction} />
      </Wrapper>
    );
  }

  return <></>;
};

const messages = defineMessages({
  phiRestriction: {
    defaultMessage: 'PHI Restriction',
  },
});

export default Connector(PhiRestrictionBadge);
