import { IntlShape } from 'react-intl';

import { MODAL_MODES } from 'src/utils/constants';
import { wizardMessages } from '../OperatorsTable/constants';
import OperatorForm from './steps/OperatorForm';
import FacilitiesFieldMapping from './steps/FacilityMapping';
import EmrFrequencySettings from './steps/EmrFrequencySettings';
import { UUID } from 'src/types/utility';

type StepsData = {
  key: number;
  title: string;
  content: React.FC<{ customerId: UUID }>;
}[];

export const getWizardSteps = (
  intl: IntlShape,
  modalMode: string,
): StepsData => [
  {
    key: 1,
    title:
      modalMode === MODAL_MODES.ADD
        ? intl.formatMessage(wizardMessages.addOperator)
        : intl.formatMessage(wizardMessages.editOperator),
    content: OperatorForm,
  },
  {
    key: 2,
    title: intl.formatMessage(wizardMessages.mapFacilities),
    content: FacilitiesFieldMapping,
  },
  {
    key: 3,
    title: intl.formatMessage(wizardMessages.emrUpdateFrequency),
    content: EmrFrequencySettings,
  },
];
