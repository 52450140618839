import { all, takeLatest, call, put } from 'typed-redux-saga/macro';
import BackendService from 'src/services/BackendService';
import { actions } from './slice';

function* getUserPhiRegionalAccess() {
  try {
    const { data } = yield* call(BackendService.getPhiRegionalAccess);
    yield* put(actions.getUserPhiRegionalAccessSuccess(data));
  } catch (e) {
    console.error('error in getUserPhiRegionalAccessSuccess: ', e);
  }
}

export default function* watchLoggedInUserActions() {
  yield* all([
    takeLatest(actions.getUserPhiRegionalAccess, getUserPhiRegionalAccess),
  ]);
}
