import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import {
  defaultFormFieldsRulesArray as defaultRulesArray,
  emailPattern,
} from 'src/utils/constants';
import { Form, Input } from 'antd';
import {
  createCustomerFormValidateMessages,
  customerFormFields,
} from 'src/routes/Customers/components/CustomerForm/utils';
import {
  EachColumn,
  StyledForm,
  StyledTitle,
} from 'src/routes/Customers/components/CustomerForm/styled';
import CheckboxForm from 'src/components/general-ui/CheckboxForm';
import SelectBox from 'src/components/general-ui/SelectBoxForm';
import { FormInstance } from 'antd/es/form/hooks/useForm';
import { countryOptions } from 'src/utils/country-city-timezones/utils';
import { CustomerTypeWithAccessData } from 'src/routes/Customers/modules/types';

type Props = {
  ipAddresses?: string[];
  throughput?: string | null;
  enabled?: boolean;
  intl: IntlShape;
  isAddMode?: boolean;
  isFromDisabled?: boolean;
  name?: string;
  address?: string;
  contactName?: string;
  contactEmail?: string;
  regionalAccess?: string[];
  onFormMount: (form: FormInstance<CustomerTypeWithAccessData>) => void;
  setIsFormValid?: Dispatch<SetStateAction<boolean>>;
};
const CustomerForm = ({
  intl,
  name = '',
  address = '',
  ipAddresses = [],
  throughput = null,
  contactName = '',
  contactEmail = '',
  enabled,
  regionalAccess,
  isFromDisabled = false,
  onFormMount,
  setIsFormValid,
}: Props) => {
  const [form] = Form.useForm<CustomerTypeWithAccessData>();
  const { TextArea } = Input;

  useEffect(() => {
    onFormMount(form);
  }, [form]);

  return form ? (
    <StyledForm
      className={'add-customer-form'}
      form={form}
      layout="vertical"
      disabled={isFromDisabled}
      scrollToFirstError
      validateMessages={createCustomerFormValidateMessages}
    >
      {_values => {
        setIsFormValid?.(
          !form.getFieldsError().some(({ errors }) => !!errors.length) &&
            !!form.getFieldValue(customerFormFields.NAME),
        );
        return (
          <>
            <EachColumn>
              <Form.Item
                name={customerFormFields.NAME}
                label={intl.formatMessage(labelMessages.name)}
                initialValue={name}
                rules={defaultRulesArray}
              >
                <Input
                  placeholder={intl.formatMessage(labelMessages.name)}
                  autoComplete="off"
                />
              </Form.Item>

              <Form.Item
                initialValue={contactName}
                className={'point-of-contact-name'}
                name={customerFormFields.CONTACT_NAME}
                label={intl.formatMessage(labelMessages.contactName)}
              >
                <Input
                  placeholder={intl.formatMessage(labelMessages.contactName)}
                  // autoComplete="off"
                />
              </Form.Item>
              <Form.Item
                initialValue={contactEmail}
                name={customerFormFields.CONTACT_EMAIL}
                label={intl.formatMessage(labelMessages.contactEmail)}
                rules={[
                  {
                    pattern: emailPattern,
                  },
                ]}
              >
                <Input
                  placeholder={intl.formatMessage(labelMessages.contactEmail)}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item
                name={customerFormFields.ADDRESS}
                label={intl.formatMessage(labelMessages.address)}
                initialValue={address}
              >
                <Input
                  placeholder={intl.formatMessage(labelMessages.address)}
                  autoComplete="off"
                />
              </Form.Item>
            </EachColumn>
            <EachColumn>
              <StyledTitle>
                {intl.formatMessage(labelMessages.ApiAccessTitle)}
              </StyledTitle>
              <Form.Item
                name={customerFormFields.ENABLED}
                valuePropName="checked"
                initialValue={enabled}
              >
                {/* @ts-ignore Form.Item component will take care of passing props in from */}
                <CheckboxForm
                  label={intl.formatMessage(labelMessages.ActiveLabel)}
                  disabled={isFromDisabled}
                />
              </Form.Item>
              <Form.Item
                initialValue={ipAddresses.filter(elem => !!elem).join(`\n`)}
                name={customerFormFields.IP_ADDRESSES}
                label={intl.formatMessage(labelMessages.IpAddressesLabel)}
                dependencies={[customerFormFields.ENABLED]}
              >
                <TextArea
                  disabled={
                    !form.getFieldValue(customerFormFields.ENABLED) ||
                    isFromDisabled
                  }
                  placeholder={intl.formatMessage(
                    labelMessages.IpAddressesPlaceholder,
                  )}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item
                initialValue={throughput}
                name={customerFormFields.THROUGHPUT}
                label={intl.formatMessage(labelMessages.AllowedLoadLabel)}
                dependencies={[customerFormFields.ENABLED]}
              >
                <SelectBox
                  disabled={
                    !form.getFieldValue(customerFormFields.ENABLED) ||
                    isFromDisabled
                  }
                  placeholder={intl.formatMessage(
                    labelMessages.AllowedLoadLabel,
                  )}
                  options={[
                    {
                      value: 'LOW',
                      label: intl.formatMessage(labelMessages.allowedLoadLow),
                    },
                    {
                      value: 'HIGH',
                      label: intl.formatMessage(labelMessages.allowedLoadHigh),
                    },
                  ]}
                />
              </Form.Item>
              <StyledTitle>
                {intl.formatMessage(labelMessages.PhiLabel)}
              </StyledTitle>
              <Form.Item
                name={customerFormFields.PHI_ACCESS}
                initialValue={regionalAccess?.filter(country => !!country)}
              >
                <SelectBox
                  mode={'multiple'}
                  placeholder={intl.formatMessage(labelMessages.PhiPlaceholder)}
                  disabled={isFromDisabled}
                  options={countryOptions}
                />
              </Form.Item>
            </EachColumn>
          </>
        );
      }}
    </StyledForm>
  ) : null;
};

const labelMessages = defineMessages({
  name: {
    defaultMessage: 'Customer name',
  },
  contactName: {
    defaultMessage: 'Point Of Contact Name',
  },
  contactEmail: {
    defaultMessage: 'Point Of Contact Email',
  },
  address: {
    defaultMessage: 'HQ Address',
  },
  EDIT: {
    defaultMessage: 'Edit Customer',
  },
  ApiAccessTitle: {
    defaultMessage: 'Api Access',
  },
  ActiveLabel: {
    defaultMessage: 'Active',
  },
  IpAddressesLabel: {
    defaultMessage: 'Allowed IP Addresses (one address per line)',
  },
  IpAddressesPlaceholder: {
    defaultMessage: 'Allowed IP Addresses',
  },
  AllowedLoadLabel: {
    defaultMessage: 'Allowed Load',
  },
  PhiLabel: {
    defaultMessage: 'PHI Regional Access',
  },
  PhiPlaceholder: {
    defaultMessage: 'Select Countries',
  },
  allowedLoadLow: {
    defaultMessage: '5K/minute',
  },
  allowedLoadHigh: {
    defaultMessage: '10K/minute',
  },
});
export default injectIntl(CustomerForm);
