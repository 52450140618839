import { connect } from 'react-redux';
import {
  selectors as userDataSelectors,
  actions as userDataActions,
} from 'src/redux/data/user';
import {
  selectors as tenantDataSelectors,
  actions as tenantDataActions,
} from 'src/redux/data/tenant';
import {
  selectors as customersSelectors,
  actions as customersActions,
} from 'src/redux/data/customers';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import Component from '../components/Component';
import { DATA_FETCHING_STATUS } from '../../../redux/data/constants';
import { USER_TYPES } from 'src/utils/constants';

const getUsersList = state => {
  const existingUsersList = userDataSelectors.getUsersList(state);
  const invitedUserList = userDataSelectors.getInvitedUsersList(state);
  const usersList = existingUsersList.concat(invitedUserList);
  const tenantsList = tenantDataSelectors.getTenantsList(state);
  let usersDisplayList = usersList;
  if (tenantsList.length > 0) {
    usersDisplayList = usersList.map(user => ({
      ...user,
      tenantName: tenantsList.find(tenant => tenant.id === user.tenantId)?.name,
    }));
  }
  return usersDisplayList;
};

const mapStateToProps = state => {
  const allUserTypes = userDataSelectors.getUserTypes(state);
  const usersList = getUsersList(state);

  return {
    userTypes: allUserTypes
      ?.map(userType => ({
        id: userType.id,
        name: userType.name,
      }))
      .filter(userType => userType.name !== USER_TYPES.MULTI_TENANT_MANAGER),
    usersList,
    customersList: customersSelectors.selectCustomersList(state),
    isLoading:
      userDataSelectors.getStatus(state) === DATA_FETCHING_STATUS.LOADING,
    modalStatus: userDataSelectors.getModalStatus(state),
    loggedInUserType: loggedInUserSelectors.getLoggedInUserType(state),
    loggedInUserId: loggedInUserSelectors.getUserId(state),
  };
};

const mapActionCreators = {
  ...userDataActions,
  getCustomers: customersActions.searchCustomers,
  getTenantsList: tenantDataActions.getTenantsList,
};

export default connect(mapStateToProps, mapActionCreators)(Component);
