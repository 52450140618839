import { defineMessages } from 'react-intl';

export const customerMessages = defineMessages({
  // error
  createCustomerError: {
    defaultMessage: 'Could not create customer',
  },

  // success
  createCustomerSuccess: {
    defaultMessage: 'Customer created successfully',
  },
});
