import { styled } from 'styled-components';

import Table from 'src/components/CrudCommonComponents/Table';

export const Wrapper = styled.div`
  padding: 1.3rem;

  .ant-table-wrapper {
    width: 50%;
  }
`;

export const StyledSdkTable = styled(Table)`
  table .ant-table-row td.ant-table-cell {
    padding: 2rem;
  }
`;
