import styled from 'styled-components';
import { Form } from 'antd';

export const StyledForm = styled(Form)`
  width: 400px;
`;
export const StyledTitle = styled.div`
  padding-bottom: 7px;
`;
export const EachColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
`;
