import { messages, intl } from '../messages';
import { actions as customersActions } from 'src/redux/data/customers';

export const customersErrorNotificationMessagesMap = {
  [customersActions.createCustomer.type]: (fileName, _) => ({
    message: intl.formatMessage(messages.createCustomerError),
    description: `${intl.formatMessage(
      messages.createCustomerError,
    )} ${fileName}`,
  }),
};

export const customersSuccessNotificationMessagesMap = {
  [customersActions.createCustomer.type]: fileName => ({
    message: intl.formatMessage(messages.createCustomerSuccess),
    description: `${intl.formatMessage(
      messages.createCustomerSuccess,
    )} ${fileName}`,
  }),
};
