import { connect, ConnectedProps } from 'react-redux';

const mapStateToProps = () => ({});

const mapActionCreators = {};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
