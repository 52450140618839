import { connect, ConnectedProps } from 'react-redux';

import {
  selectors as customersSelectors,
  actions as customersActions,
} from 'src/redux/data/customers';
import { RootState } from 'src/redux/store';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';

const mapStateToProps = (state: RootState) => ({
  currentCustomerAccessData:
    customersSelectors.selectCurrentCustomersAccess(state),
  isLoading:
    customersSelectors.selectCustomersListStatus(state) ===
      DATA_FETCHING_STATUS.LOADING ||
    customersSelectors.selectCustomersAccessStatus(state) ===
      DATA_FETCHING_STATUS.LOADING,
  areRequestsCompleted:
    customersSelectors.selectCustomersListStatus(state) ===
      DATA_FETCHING_STATUS.SUCCESS &&
    customersSelectors.selectCustomersAccessStatus(state) ===
      DATA_FETCHING_STATUS.SUCCESS,
});

const mapActionCreators = {
  editCustomer: customersActions.editCustomer,
  getCustomerAccess: customersActions.getCustomerAccess,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
