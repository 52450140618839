import React from 'react';

import {
  userPageColumnsMessages,
  userTableActionsMessages,
  USERS_TABLE_ACTIONS_KEYS,
  StyledStatusColumn,
  USER_STATUS,
} from 'src/components/UserCrudComponents/constants';
import permissions from 'src/permissions';
import { sortWithEmptyString } from 'src/utils/sorters/utils';

const getCustomerName = (record, customersList) =>
  customersList?.find(customer => customer.id === record.customerId)?.name ??
  '-';

const getPermissionCellData = record => {
  return (
    (record.permissions ??
      record.selectedSubGroups
        ?.map(({ name }) => name)
        .sort((a, b) => sortWithEmptyString(a, b, { numeric: true }))) ||
    []
  );
};

export const userTableData = (data, customersList) => {
  return data?.map(record => ({
    key: record.id,
    id: record.id,
    userType: record.userType?.name ?? '-',
    userTypeId: record.userType?.id,
    customer: getCustomerName(record, customersList),
    customerId: record.customerId,
    tenantName: record.tenantName ?? '-',
    tenantId: record.tenantId,
    username: record.email ?? '-',
    permissions: getPermissionCellData(record)[0],
    permissionsList: getPermissionCellData(record),
    status: record.status ?? '-',
    groups: record.groups,
    firstName: record.firstName,
    lastName: record.lastName,
    email: record.email,
    phone: record.phone,
    mfaPhone: record.mfaPhone,
    mfa: record.mfa,
  }));
};

export const getUserPageColumns = intl => [
  {
    title: intl.formatMessage(userPageColumnsMessages.userType),
    dataIndex: 'userType',
    key: 'userType',
    sorter: true,
  },
  {
    title: intl.formatMessage(userPageColumnsMessages.customer),
    dataIndex: 'customer',
    key: 'customer',
    sorter: true,
  },
  {
    title: intl.formatMessage(userPageColumnsMessages.username),
    dataIndex: 'username',
    key: 'username',
    sorter: true,
  },
  {
    title: intl.formatMessage(userPageColumnsMessages.permissions),
    dataIndex: 'permissions',
    key: 'permissions',
    sorter: true,
    render: (_, record) =>
      record?.permissionsList?.map(name => <p key={name}>{name}</p>) ?? '-',
  },
  {
    title: intl.formatMessage(userPageColumnsMessages.status),
    dataIndex: 'status',
    key: 'status',
    sorter: true,
    render: (_, record) => (
      <StyledStatusColumn status={record.status}>
        {record.status}
      </StyledStatusColumn>
    ),
  },
];

export const getColumnsPermissionMap = intl => ({
  [permissions.VIEW_DEVICES_TENANT_COLUMN]: [
    {
      title: intl.formatMessage(userTableActionsMessages.tenant),
      dataIndex: 'tenantName',
      key: 'tenantName',
      sorter: true,
    },
  ],
});

export const getUserTableActions = (
  intl,
  loggedInUserId,
  setActiveRow,
  setIsEditModalVisible,
  setIsActivationConfirmationVisible,
  sendMessageForResetPassword,
  resendInvitation,
) => [
  {
    key: USERS_TABLE_ACTIONS_KEYS.EDIT,
    name: intl.formatMessage(userTableActionsMessages.edit),
    isDisabled: ({ record }) => record.status === USER_STATUS.PENDING,
    onClick: (_, { record }) => {
      setActiveRow(record);
      setIsEditModalVisible(true);
    },
  },
  {
    key: USERS_TABLE_ACTIONS_KEYS.ACTIVE,
    name: intl.formatMessage(userTableActionsMessages.activate),
    onClick: (_, { record }) => {
      setActiveRow(record);
      setIsActivationConfirmationVisible(true);
    },
  },
  {
    key: USERS_TABLE_ACTIONS_KEYS.INACTIVE,
    name: intl.formatMessage(userTableActionsMessages.inactivate),
    onClick: (_, { record }) => {
      setActiveRow(record);
      setIsActivationConfirmationVisible(true);
    },
  },
  {
    key: USERS_TABLE_ACTIONS_KEYS.RESET_PASSWORD,
    name: intl.formatMessage(userTableActionsMessages.resetPassword),
    onClick: (_, { record }) => {
      setActiveRow(record);
      sendMessageForResetPassword({ email: record?.email });
    },
    isDisabled: ({ record }) => record.locked,
    tooltipText: ({ record }) =>
      record.locked &&
      intl.formatMessage(userTableActionsMessages.resetPasswordDisabledText),
  },
  {
    key: USERS_TABLE_ACTIONS_KEYS.RESEND_INVITE,
    name: intl.formatMessage(userTableActionsMessages.resendInvite),
    onClick: (_, { record }) => {
      setActiveRow(record);
      resendInvitation(record);
    },
  },
];
