import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  devicesCounter: {
    defaultMessage:
      '{devicesCount } {devicesCount, select, 1 {device} other {devices}}',
  },
  devices: {
    defaultMessage: 'Devices',
  },
  searchPlaceholder: {
    defaultMessage: 'Search by id or name',
  },
  deleteConfirmation: {
    defaultMessage: 'Are you sure you want to delete?',
  },
  detachConfirmation: {
    defaultMessage:
      "Are you sure you want to detach this device from it's current tenant?",
  },
  resetConfirmation: {
    defaultMessage: 'Are you sure you want to reset this device?',
  },
  advancedSearchTitleBtn: {
    defaultMessage: 'Advanced Search ({totalFieldsCount})',
  },
  fwVersionCountersTitleBtn: {
    defaultMessage: 'FW Versions Counter',
  },
  devicesFilter: {
    defaultMessage: 'Advanced Search',
  },
  manufacturerId: {
    defaultMessage: 'S/N',
  },
  name: {
    defaultMessage: 'Name',
  },
  customer: {
    defaultMessage: 'Customer',
  },
  operator: {
    defaultMessage: 'Operator',
  },
  location: {
    defaultMessage: 'Location',
  },
  connectivityStatus: {
    defaultMessage: 'Connectivity Status',
  },
  lastConnectionTime: {
    defaultMessage: 'Last Connection Time',
  },
  monitoringDays: {
    defaultMessage: 'Monitoring days',
  },
  activationDate: {
    defaultMessage: 'Activation date',
  },
  mountingType: {
    defaultMessage: 'Mounting Type',
  },
  wiringType: {
    defaultMessage: 'Wiring Type',
  },
  electricalOutletReplacement: {
    defaultMessage: 'Electrical Outlet Replacement',
  },
  tenantHistory: {
    defaultMessage: 'Tenants History',
  },
  notes: {
    defaultMessage: 'Notes',
  },
  tenant: {
    defaultMessage: 'Tenant',
  },
  actions: {
    defaultMessage: 'Actions',
  },
  deleteDevice: {
    defaultMessage: 'Delete device',
  },
  resetDevice: {
    defaultMessage: 'Reset',
  },
  editDevice: {
    defaultMessage: 'Edit device',
  },
  detachTenant: {
    defaultMessage: 'Detach tenant',
  },
  assignTenant: {
    defaultMessage: 'Assign tenant',
  },
  dashboard: {
    defaultMessage: 'Dashboard',
  },
  version: {
    defaultMessage: 'FW version',
  },
  group: {
    defaultMessage: 'Group',
  },
  info: {
    defaultMessage: 'Info',
  },
  room: {
    defaultMessage: 'Room',
  },
  bed: {
    defaultMessage: 'Bed',
  },
  exportCsvButtonLabel: {
    defaultMessage: 'Export CSV',
  },
});
