import { defineMessages } from 'react-intl';

export const operatorsTableMessages = defineMessages({
  operatorName: {
    defaultMessage: 'Name',
  },
  organizationId: {
    defaultMessage: 'Org UID',
  },
  emr: {
    defaultMessage: 'EMR',
  },
  integrationType: {
    defaultMessage: 'Type',
  },
  numberOfBuidlings: {
    defaultMessage: '# of buildings',
  },
  syncedTenantsCount: {
    defaultMessage: 'Synced Tenants',
  },
  numberOfDevices: {
    defaultMessage: 'Devices',
  },
});

export const operatorsTableActions = defineMessages({
  edit: {
    defaultMessage: 'Edit',
  },
  delete: {
    defaultMessage: 'Delete',
  },
  addOperator: {
    defaultMessage: '+ Add Operator',
  },
});

export const wizardMessages = defineMessages({
  previous: {
    defaultMessage: 'Previous',
  },
  next: {
    defaultMessage: 'Next',
  },
  done: {
    defaultMessage: 'Done',
  },
  addOperator: {
    defaultMessage: 'Add Operator',
  },
  editOperator: {
    defaultMessage: 'Edit Operator',
  },
  mapFacilities: {
    defaultMessage: 'Map Facilites',
  },
  emrUpdateFrequency: {
    defaultMessage: 'EMR Update Frequency',
  },
});
