/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { ColumnsType } from 'antd/lib/table';
import { IntlShape, defineMessages } from 'react-intl';

import { CustomersSdkActivityResponse } from '../Customers/modules/types';
import { sortWithEmptyNumbers } from 'src/utils/sorters/utils';

const ALLOWED_VALUES = {
  LOW: '5K/minute',
  HIGH: '10K/minute',
};

export const getSdkColumns = (
  intl: IntlShape,
): ColumnsType<CustomersSdkActivityResponse & { customerName: string }> => [
  {
    title: intl.formatMessage(messages.customer),
    dataIndex: 'customerName',
    key: 'customerName',
    filtered: true,
    onFilter: (value, record) =>
      // @ts-ignore type error because of table is plain js
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      record?.customerName?.toLowerCase().includes(value?.toLowerCase()),
    sorter: (a, b) => a.customerName?.localeCompare(b.customerName),
    render: (_, record) => record?.customerName ?? '-',
  },
  {
    title: intl.formatMessage(messages.status),
    dataIndex: 'status',
    key: 'status',
    sorter: (a, b) =>
      a.apiAccessEnabled
        .toString()
        ?.localeCompare(b.apiAccessEnabled.toString()),
    render: (_, record) =>
      record?.apiAccessEnabled
        ? intl.formatMessage(messages.active)
        : intl.formatMessage(messages.inactive),
  },
  {
    title: intl.formatMessage(messages.allowedLoad),
    dataIndex: 'allowedLoad',
    key: 'allowedLoad',
    sorter: (a, b) => a.throughput?.localeCompare(b.throughput),
    render: (_, record) => (record && ALLOWED_VALUES[record.throughput]) ?? '-',
  },
  {
    title: intl.formatMessage(messages.currentUsage),
    dataIndex: 'currentUsage',
    key: 'currentUsage',
    sorter: (a, b) =>
      sortWithEmptyNumbers(a.apiCallsLast24Hours, b.apiCallsLast24Hours),
    render: (_, record) =>
      record
        ? intl.formatMessage(messages.apiCallsLastMinuteMessage, {
            count: (record?.apiCallsLastMinute ?? 0) / 1000,
          })
        : '-',
  },
];

export const messages = defineMessages({
  customer: {
    defaultMessage: 'Customer',
  },
  status: {
    defaultMessage: 'Status',
  },
  allowedLoad: {
    defaultMessage: 'Allowed Load',
  },
  currentUsage: {
    defaultMessage: 'Current Usage',
  },
  active: {
    defaultMessage: 'Active',
  },
  inactive: {
    defaultMessage: 'Inactive',
  },
  apiCallsLastMinuteMessage: {
    defaultMessage: '{count}K/minute',
  },
});
