import { styled } from 'styled-components';
import { Title } from 'src/components/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: ${props => props.theme.fonts.primary};
  background-color: ${props =>
    props.theme.colors.individualDashboardBackground};
  color: #252525;
  padding: 1.4rem 1.25rem;
  gap: 1.4rem;
  max-width: 1500px;
  margin: auto;
  overflow: auto;
  .bed-occupancy-graph,
  .hr-rr-histogram {
    height: 50%;
  }
  .ant-table-wrapper {
    margin-top: -1.2rem;
    .ant-table-cell {
      min-width: 1rem !important;
      width: auto;
      white-space: normal;
    }
  }
  .medical-reports-loading {
    position: fixed;
    top: 0;
    right: 0;
  }
`;
export const ReportSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EachRow = styled.div`
  display: flex;
`;
export const MainTitle = styled(Title)`
  font-size: 1.4rem;
  margin-bottom: 0;
`;
export const DetailedInfoContainer = styled.div`
  margin-bottom: 0;
`;

export const ComponentWrapper = styled.div`
  position: relative;
`;
