import React from 'react';
import DevicesPage from 'src/routes/Devices/components/Component';
import { CreateCustomerPayload } from 'src/routes/Customers/modules/types';

const DeviceWrapper = ({
  horizontalLayout,
  currentCustomer,
}: {
  horizontalLayout: boolean;
  currentCustomer: CreateCustomerPayload | null | undefined;
}) => {
  return (
    <DevicesPage
      horizontalLayout={horizontalLayout}
      currentCustomer={currentCustomer}
    />
  );
};

export default DeviceWrapper;
