import { CacheAxiosResponse } from 'axios-cache-interceptor/src/cache/axios';
import { CacheRequestConfig } from 'axios-cache-interceptor';
import { Method } from 'axios/index';
import { jwtDecode } from 'jwt-decode';
import { QueryFilter } from './types';
import dayjs from 'dayjs';

const SLASHES_REGEX = /^\/|\/$/g;

export const getHeaderInterpreter = (
  headers?: CacheAxiosResponse['headers'],
) => {
  if (headers && headers['x-my-custom-header']) {
    const seconds = Number(headers['x-my-custom-header']);
    if (seconds < 1) {
      return 'dont cache';
    }
    return seconds;
  }

  return { cache: 10000, stale: 10000 };
  // return 'not enough headers';
};

export const getBuildGeneratorKey = ({
  baseURL = '',
  url = '',
  method = 'get',
  params,
  data,
  headers,
}: CacheRequestConfig) => {
  // Remove trailing slashes to avoid generating different keys for the "same" final url.
  const formattedBaseUrl = baseURL && baseURL.replace(SLASHES_REGEX, '');
  const formattedUrl = url && url.replace(SLASHES_REGEX, '');
  const formattedMethod = method && (method.toLowerCase() as Method);

  return {
    url:
      formattedBaseUrl +
      (formattedBaseUrl && formattedUrl ? '/' : '') +
      formattedUrl,
    method: formattedMethod,
    params: params as unknown,
    // add authorization in key in order to detect another request for different sub-tenant
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    authorization: headers?.Authorization,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    data,
  };
};

const sha256Hash = async (data: string) => {
  const utf8 = new TextEncoder().encode(data);
  return crypto.subtle.digest('SHA-256', utf8).then(hashBuffer => {
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map(bytes => bytes.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  });
};

export const getAccessValidationHeaderValue = async (
  accessJwt: string,
): Promise<string> => {
  const { exp, tenantId } = jwtDecode<{ exp: number; tenantId: string }>(
    accessJwt,
  );
  const data = `${exp}:${tenantId}`;
  const sha256Data = await sha256Hash(data);
  const base64EncodedData = Buffer.from(sha256Data).toString('base64');
  return base64EncodedData;
};

export const prepareFiltersForQueryParamsObj = (
  filters: Record<string, string | Date> | undefined,
  dateFields?: string[],
): QueryFilter => {
  if (!filters) {
    return [];
  }
  const mappedFilters: QueryFilter = [];
  Object.keys(filters).forEach(key => {
    if (filters[key] === null || filters[key] === undefined) {
      return;
    }
    const values = dateFields?.includes(key)
      ? ([dayjs(filters[key]).format('YYYY-MM-DD')] as string[])
      : ([filters[key] ?? ''] as string[]);
    mappedFilters.push({
      prop: key,
      values,
    });
  });

  return [...mappedFilters];
};
