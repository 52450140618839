import { GenericThresholdMetrics } from 'src/types/alerts';

export const DATA_STATE_KEY = 'data';

export const DATA_FETCHING_STATUS = Object.freeze({
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  INITIAL: 'INITIAL',
});

export const DEVICE_DEFAULT_PROTOCOL_TYPE = 'IOT';

export const ALERT_METRIC_ENUM = Object.freeze({
  HR: 'HR',
  RR: 'RR',
  BED_EXIT: 'BED_EXIT',
  LONG_OUT_OF_BED: 'LONG_OUT_OF_BED',
  POSITION_CHANGE: 'POSITION_CHANGE',
  BED_TIME_BASELINE: 'BED_TIME_BASELINE',
  BED_EXIT_FREQUENCY: 'BED_EXIT_FREQUENCY',
  HR_BASELINE: 'HR_BASELINE',
  RR_BASELINE: 'RR_BASELINE',
  DEVICE_DISCONNECTED: 'DEVICE_DISCONNECTED',
  ALL_DEVICES_DISCONNECTED: 'ALL_DEVICES_DISCONNECTED',
});

export const medicalALertThresholdMetricsArray: (keyof GenericThresholdMetrics)[] =
  [
    ALERT_METRIC_ENUM.HR,
    ALERT_METRIC_ENUM.RR,
    ALERT_METRIC_ENUM.HR_BASELINE,
    ALERT_METRIC_ENUM.RR_BASELINE,
    ALERT_METRIC_ENUM.LONG_OUT_OF_BED,
    ALERT_METRIC_ENUM.BED_EXIT_FREQUENCY,
    ALERT_METRIC_ENUM.BED_TIME_BASELINE,
    ALERT_METRIC_ENUM.BED_EXIT,
    ALERT_METRIC_ENUM.POSITION_CHANGE,
  ];

export const ALERT_METRIC_PREPOSITION_ENUM = Object.freeze({
  BELOW: 'BELOW',
  ABOVE: 'ABOVE',
});

export const TRESHHOLDS_METRICS_ENUM = {
  HR: 'HR',
  RR: 'RR',
};
export const TRESHHOLDS_TYPES_ENUM = {
  BELOW: 'BELOW',
  BETWEEN: 'BETWEEN',
  ABOVE: 'ABOVE',
};
