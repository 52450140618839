export const roundToNDecimals = (number: number, decimals = 2): number =>
  decimals !== 0
    ? Math.round((number + Number.EPSILON) * Math.pow(10, decimals)) /
      Math.pow(10, decimals)
    : Math.round(number);

export const filterDuplicates = <T>(x: T[]): T[] => Array.from(new Set(x));

export const getUniqueListBy = <T, K extends keyof T>(
  key: K,
  arr: T[],
): T[] => [...new Map(arr.map(item => [item[key], item])).values()];

export const generateRange = (start: number, end: number): number[] => [
  ...Array(end - start + 1).keys(),
];

export const getValueOrNA = (value?: string) => value ?? 'N/A';

export const removeDuplicatesFromArrayOfObjects = <T>(
  myArr: T[],
  keys: (keyof T)[],
): T[] =>
  myArr.filter(
    (obj1, i, arr) =>
      arr.findIndex(obj2 => keys.every(key => obj2[key] === obj1[key])) === i,
  );
