import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import { BedOcuppancyBoxWrapper, StyledTabs } from './styled';
import BedOccupancyHistogram from '../BedOccupancyHistogram';

const BedOccupancyComponent = ({
  intl,
  hoursInBedData,
  numberOfBedExitAlertsData,
  patientId,
  isLoading,
}) => {
  return (
    <BedOcuppancyBoxWrapper
      className="bed-occupancy-graph"
      data-cy={`bed-occupancy-graph-loading=${isLoading.toString()}`}
    >
      <StyledTabs
        items={[
          {
            label: intl.formatMessage(messages.bedOccupancy),
            key: 'BedOccupancy',
            children: (
              <BedOccupancyHistogram
                hoursInBedData={patientId ? hoursInBedData : []}
                numberOfBedExitAlertsData={
                  patientId ? numberOfBedExitAlertsData : []
                }
                isLoading={isLoading}
              />
            ),
          },
        ]}
      />
    </BedOcuppancyBoxWrapper>
  );
};

BedOccupancyComponent.propTypes = {
  intl: PropTypes.object.isRequired,
  patientId: PropTypes.string,
  hoursInBedData: PropTypes.array.isRequired,
  numberOfBedExitAlertsData: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

BedOccupancyComponent.defaultProps = {
  patient: {},
};

const messages = defineMessages({
  bedOccupancy: {
    defaultMessage: 'Bed occupancy',
  },
});

export default injectIntl(BedOccupancyComponent);
