import { styled } from 'styled-components';
import TransparentButton from './TransparentButton';

const CancelButton = styled(TransparentButton)`
  background: ${props => props.theme.colors.cancelButtonBackground};
  color: ${props => props.theme.colors.defaultTextColor};
  border: 1px solid ${props => props.theme.colors.cancelButtonBorder};
  font-size: 0.7rem;
  padding: 0.2rem 0.5rem;
  &:hover,
  &:active {
    border: 1px solid ${props => props.theme.colors.cancelButtonBorder};
    opacity: 0.7;
    outline: none;
  }

  :focus {
    border: 1px solid ${props => props.theme.colors.cancelButtonBorderFocused};
  }
`;

export default CancelButton;
