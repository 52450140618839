import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from 'src/redux/store';
import { MODAL_STATUS } from 'src/components/Modal/constants';
import {
  BlockPatientConsent,
  MQTTMsgPatientUpdate,
  Patient,
  PatientWithFullName,
} from 'src/types/patients';
import { UUID } from 'src/types/utility';
import { DATA_FETCHING_STATUS, DATA_STATE_KEY } from '../../constants';
import {
  CreatePatientPayload,
  DeletePatientPayload,
  UpdatePatientConsentPayload,
  UpdatePatientPayload,
} from './types';
import { mergeArraysWithUniqueIds } from '../../dataUtils';
import { DEFAULT_PAGINATION } from 'src/utils/constants';
import { SorterResult } from 'antd/lib/table/interface';
import { MonitoredPatient } from 'src/routes/NursesStation/components/MonitoredPersons/types';

export const STATE_KEY = 'patient';

type PaginationParams = {
  current: number;
  pageSize: number;
  total?: number;
  sortBy: SorterResult<MonitoredPatient>;
  showSizeChanger?: boolean;
};

export const INITIAL_STATE: {
  patientList: PatientWithFullName[];
  status: string;
  modalStatus: string;
  tableParams: PaginationParams;
} = {
  patientList: [],
  status: DATA_FETCHING_STATUS.LOADING,
  modalStatus: MODAL_STATUS.INITIAL,
  tableParams: {
    current: DEFAULT_PAGINATION.PAGE,
    pageSize: 25,
    sortBy: {
      columnKey: 'locationName',
      order: 'ascend',
    },
    showSizeChanger: false,
  },
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    fetchAllPatientsSuccess(state, action: PayloadAction<Patient[]>) {
      state.patientList = mergeArraysWithUniqueIds(
        state.patientList,
        action.payload.map(p => ({
          ...p,
          fullName: `${p.firstName || ''} ${p.lastName || ''}`,
        })),
      );
      state.status = DATA_FETCHING_STATUS.SUCCESS;
    },
    updatePatientData(state, action: PayloadAction<Patient>) {
      const { firstName, lastName, id } = action.payload;
      state.patientList = state.patientList.map(patient =>
        patient.id === id
          ? {
              ...action.payload,
              fullName: `${firstName || ''} ${lastName || ''}`,
            }
          : { ...patient },
      );
    },
    fetchAllPatientsFail(state) {
      state.status = DATA_FETCHING_STATUS.ERROR;
    },
    setStatus(state, action: PayloadAction<string>) {
      state.status = action.payload;
    },
    setModalStatus(state, action: PayloadAction<string>) {
      state.modalStatus = action.payload;
    },
    gotPatientUpdatesFromAws: (
      state,
      action: PayloadAction<MQTTMsgPatientUpdate>,
    ) => {
      const { patientId, isConsented } = action.payload;

      const patientToBeUpdated = state.patientList.find(
        patient => patient.id === patientId,
      );

      if (patientToBeUpdated) {
        state.patientList = mergeArraysWithUniqueIds(state.patientList, [
          {
            ...patientToBeUpdated,
            isConsented,
          },
        ]);
      }
    },
    blockPatientConsent: (
      state,
      action: PayloadAction<BlockPatientConsent>,
    ) => {
      const { id, isConsentBlocked } = action.payload;

      const patientToBeUpdated = state.patientList.find(
        patient => patient.id === id,
      );
      if (!patientToBeUpdated) {
        return;
      }
      state.patientList = mergeArraysWithUniqueIds(state.patientList, [
        {
          ...patientToBeUpdated,
          isConsentBlocked,
        },
      ]);
    },
    setTableParams: (state, action: PayloadAction<PaginationParams>) => {
      // @ts-ignore TODO: fix it later
      state.tableParams = { ...state.tableParams, ...action.payload };
    },
  },
  extraReducers: {},
});

const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;
const getPatientId = (_state: RootState, patientId: UUID) => patientId;

export const selectors = {
  getPatientsList: createSelector(getState, state => state.patientList),

  selectPatient: createSelector(getState, getPatientId, (state, patientId) =>
    state.patientList.find(patient => patient.id === patientId),
  ),

  getStatus: createSelector(getState, state => state.status),
  isPatientListLoading: createSelector(
    getState,
    state => state.status === DATA_FETCHING_STATUS.LOADING,
  ),
  getModalStatus: createSelector(getState, state => state.modalStatus),

  selectTableParams: createSelector(getState, state => state.tableParams),
};

const extraActions = {
  fetchAllPatients: createAction(`${STATE_KEY}/fetchAllPatients`),
  deletePatient: createAction<DeletePatientPayload>(
    `${STATE_KEY}/deletePatient`,
  ),
  createPatient: createAction<CreatePatientPayload>(
    `${STATE_KEY}/createPatient`,
  ),
  updatePatient: createAction<UpdatePatientPayload>(
    `${STATE_KEY}/updatePatient`,
  ),
  updatePatientConsent: createAction<UpdatePatientConsentPayload>(
    `${STATE_KEY}/updatePatientConsent`,
  ),
  setTableParams: createAction<PaginationParams>(`${STATE_KEY}/setTableParams`),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
