import React from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import {
  BoldItem,
  ExtraInfo,
  LastUpdateContainer,
  MedicalConditionCard,
  MedicalConditionContainer,
  ShortDesc,
  StyledPrimaryBtn,
  Title,
  TopHeader,
} from './styled';
import { PatientMedicalCondition } from 'src/routes/IndividualDashboard/modules/dashboard/types';
import { Dayjs } from 'dayjs';

type Props = {
  medicalConditions: PatientMedicalCondition[];
  intl: IntlShape;
  showExpandButton?: boolean;
  medicalConditionsLength?: number;
  onExpandHandler?: () => void;
  lastUpdated: Dayjs | null;
};

const MedicalConditionsContent = ({
  medicalConditions,
  medicalConditionsLength,
  intl,
  onExpandHandler,
  showExpandButton = false,
  lastUpdated = null,
}: Props): JSX.Element => (
  <>
    <TopHeader>
      <ShortDesc>
        {intl.formatMessage(messages.medicalConditionsLabel)}
        <BoldItem>
          {medicalConditionsLength ?? medicalConditions?.length}
        </BoldItem>
      </ShortDesc>
      {showExpandButton && (
        <StyledPrimaryBtn onClick={() => onExpandHandler?.()}>
          {intl.formatMessage(messages.expand)}
        </StyledPrimaryBtn>
      )}
    </TopHeader>
    {!!medicalConditions?.length && (
      <MedicalConditionContainer>
        {medicalConditions.map(({ display, code }) => (
          <MedicalConditionCard key={code}>
            <Title>{display}</Title>
            <ExtraInfo>
              {intl.formatMessage(messages.code)}
              {code}
            </ExtraInfo>
          </MedicalConditionCard>
        ))}
      </MedicalConditionContainer>
    )}
    {lastUpdated && (
      <LastUpdateContainer>
        {intl.formatMessage(messages.lastUpdated)}{' '}
        {lastUpdated.format('DD MMM YYYY, HH:mm')}
      </LastUpdateContainer>
    )}
  </>
);

const messages = defineMessages({
  medicalConditionsLabel: {
    defaultMessage: 'Number Of Medical Conditions:',
  },
  expand: {
    defaultMessage: 'Expand',
  },
  code: {
    defaultMessage: 'Code:',
  },
  lastUpdated: {
    defaultMessage: 'Last update:',
  },
});

export default injectIntl(MedicalConditionsContent);
