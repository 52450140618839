import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'typed-redux-saga/macro';

import { actions } from './slice';
import BackendService from 'src/services/BackendService';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { getAlertLogsUpdated } from 'src/utils/alertHelpers';
import { AlertLog } from 'src/types/alerts';
import { processBedOccupancyData } from 'src/routes/IndividualDashboard/modules/dashboard/data-processors';
import {
  partitionHistogramData,
  processHistogramData,
} from 'src/routes/IndividualDashboard/modules/dashboard/histogram-processor';
import dayjs from 'dayjs';
import { notifyUserByActionTypeAndCode } from 'src/utils/errorHandling/notifications';
import {
  getMedicalReportsHistogramResponse,
  getMedicalReportsMainGraphDataResponse,
} from 'src/services/types';
import { BedOccupancy } from 'src/types/individual-dashboard';
import { API_STATUS } from 'src/utils/api-constants';
import { AxiosError, HttpStatusCode } from 'axios';
import { getLocation, replace } from 'connected-react-router';
import { getLocaleFromUrlPath } from 'src/utils/languageUtils';
import { ACCESS_DENIED_PHI_PROTECTION } from 'src/components/AppLayout/utils';

function* redirectToHomePageIfForbidden(e: AxiosError, actionType: string) {
  const locationObj = yield* select(getLocation);
  const statusCode = e?.response?.status;
  const errorCode = (e?.response?.data as { code: string })?.code;
  if (
    statusCode === HttpStatusCode.Forbidden &&
    errorCode !== ACCESS_DENIED_PHI_PROTECTION
  ) {
    yield* put(replace(`/${getLocaleFromUrlPath(locationObj.pathname)}/`));
    return;
  }
  notifyUserByActionTypeAndCode(actionType, null, e);
}

function* loadIDBData(action: ReturnType<typeof actions.loadIDBData>) {
  try {
    const { date, tenantId, patientId } = action.payload;
    if (!date || !tenantId || !patientId) {
      return;
    }
    yield* put(
      actions.setBedOccupancyStatus({ patientId, status: API_STATUS.LOADING }),
    );
    yield* put(
      actions.setMainGraphStatus({ patientId, status: API_STATUS.LOADING }),
    );
    yield* put(
      actions.setMetricHistogramStatus({
        patientId,
        status: API_STATUS.LOADING,
      }),
    );
    const [
      mainGraphDataResponse,
      bedOccupancyDataResponse,
      histogramDataResponse,
    ] = yield* all([
      call(
        BackendService.getMedicalReportsMainGraphdata,
        date,
        tenantId,
        patientId,
      ),
      call(
        BackendService.getMedicalReportsBedOccupancy,
        date,
        tenantId,
        patientId,
      ),
      call(
        BackendService.getMedicalReportsHistogram,
        date,
        tenantId,
        patientId,
      ),
    ]);

    const mainGraphData =
      mainGraphDataResponse?.data as getMedicalReportsMainGraphDataResponse;
    const bedOccupancies = bedOccupancyDataResponse?.data as BedOccupancy[];
    const histogramData =
      histogramDataResponse?.data as getMedicalReportsHistogramResponse;
    const timezone = (yield* select(
      loggedInUserSelectors.getUserTenantTimezone,
    )) as unknown as string;

    const endTime = dayjs(mainGraphData.mainGraph?.endTime).format();
    const startTime = dayjs(mainGraphData.mainGraph?.startTime).format();
    const updatedAlertLogs = getAlertLogsUpdated(
      mainGraphData?.alertLogs,
      timezone,
    ) as AlertLog[];

    yield* put(
      actions.setAlertsLogData({
        patientId,
        alerts: updatedAlertLogs,
      }),
    );
    yield* put(
      actions.setAlertsData({
        patientId,
        alerts: mainGraphData?.alerts,
      }),
    );
    yield* put(
      actions.getPatientMetricDataSuccess({
        startTime,
        endTime,
        // @ts-ignore TODO: Think about workaround
        hrData: mainGraphData.mainGraph.continuousHr,
        // @ts-ignore TODO: Think about workaround
        rrData: mainGraphData.mainGraph.continuousRr,
        motionData: mainGraphData?.mainGraph?.motion_aggregation,
        postureData: mainGraphData?.mainGraph?.posture_count,
        timezone,
        patientId,
      }),
    );
    yield* put(
      actions.loadBedOccupancyDataSuccess({
        patientId,
        ...processBedOccupancyData(bedOccupancies),
      }),
    );
    yield* put(
      actions.getThresholdsListSuccess({
        patientId,
        thresholds: mainGraphData?.thresholds || [],
      }),
    );
    yield* put(
      actions.getHistogramDataSuccess({
        patientId,
        histogramData: partitionHistogramData({
          // @ts-ignore TODO: Think about workaround
          lastDay: processHistogramData(histogramData.lastDay),
          // @ts-ignore TODO: Think about workaround
          lastWeek: processHistogramData(histogramData.lastWeek),
        }),
      }),
    );

    yield* put(
      actions.setBedOccupancyStatus({ patientId, status: API_STATUS.OK }),
    );
    yield* put(
      actions.setMainGraphStatus({ patientId, status: API_STATUS.OK }),
    );
    yield* put(
      actions.setMetricHistogramStatus({ patientId, status: API_STATUS.OK }),
    );
  } catch (e) {
    const { patientId } = action.payload;
    yield* put(
      actions.setBedOccupancyStatus({ patientId, status: API_STATUS.ERROR }),
    );
    yield* put(
      actions.setMainGraphStatus({ patientId, status: API_STATUS.ERROR }),
    );
    yield* put(
      actions.setMetricHistogramStatus({ patientId, status: API_STATUS.ERROR }),
    );
    console.error('error in loadIDBData ', e);
    yield* call(redirectToHomePageIfForbidden, e as AxiosError, action.type);
  }
}
function* loadSummaryEventsData(
  action: ReturnType<typeof actions.loadSummaryEventsData>,
) {
  try {
    const { date, tenantId } = action.payload;
    if (!date || !tenantId) {
      return;
    }
    const { data } = yield* call(
      BackendService.getMedicalReportsEventsSummary,
      date,
      tenantId,
    );
    const { admissions } = data;
    const abnormality = data['abnormality-indications'];
    const transfer = data['discharge-transfer'];
    yield* put(actions.setEventsSummaryStatus(API_STATUS.LOADING));
    yield* put(
      actions.getEventsSummaryDataSuccess({
        eventsSummary: {
          abnormality: [
            abnormality.previous24Hours,
            abnormality.previous7Days,
            abnormality.previous30Days,
            abnormality.dailyAverage,
          ],
          admissions: [
            admissions.previous24Hours,
            admissions.previous7Days,
            admissions.previous30Days,
            admissions.dailyAverage,
          ],
          dischargeTransfer: [
            transfer.previous24Hours,
            transfer.previous7Days,
            transfer.previous30Days,
            transfer.dailyAverage,
          ],
        },
      }),
    );
    actions.setEventsSummaryStatus(API_STATUS.OK);
  } catch (e) {
    yield* put(actions.setEventsSummaryStatus(API_STATUS.ERROR));
    console.error('error in loadSummaryEventsData ', e);
    yield* call(redirectToHomePageIfForbidden, e as AxiosError, action.type);
  }
}
function* loadDevicesPieChartData(
  action: ReturnType<typeof actions.loadDevicesPieChartData>,
) {
  try {
    const { date, tenantId } = action.payload;
    if (!date || !tenantId) {
      return;
    }
    yield* put(actions.setPieChartSummaryStatus(API_STATUS.LOADING));
    const { data } = yield* call(
      BackendService.getPieChartData,
      date,
      tenantId,
    );
    yield* put(actions.getPieChartDataSuccess(data));
  } catch (e) {
    yield* put(actions.setPieChartSummaryStatus(API_STATUS.ERROR));
    console.error('error in loadDevicesPieChartData: ', e);
    yield* call(redirectToHomePageIfForbidden, e as AxiosError, action.type);
  }
}
function* loadLastDayAbnormalities(
  action: ReturnType<typeof actions.loadLastDayAbnormalities>,
) {
  try {
    yield* put(actions.setLastDayAbnormalitiesStatus(API_STATUS.LOADING));
    const { date, tenantId } = action.payload;
    if (!date || !tenantId) {
      return;
    }
    const { data } = yield* call(
      BackendService.getMedicalReportsDayAbnormalities,
      date,
      tenantId,
    );
    yield* put(actions.getLastDayAbnormalitiesDataSuccess(data));
  } catch (e) {
    console.error('error in loadLastDayAbnormalities ', e);
    yield* put(actions.setLastDayAbnormalitiesStatus(API_STATUS.ERROR));
    yield* call(redirectToHomePageIfForbidden, e as AxiosError, action.type);
  }
}
function* loadAbnormalitiesV2(
  action: ReturnType<typeof actions.loadAbnormalitiesV2>,
) {
  try {
    yield* put(actions.setAbnormalitiesV2Status(API_STATUS.LOADING));
    const { date, tenantId } = action.payload;
    if (!date || !tenantId) {
      return;
    }
    const { data } = yield* call(
      BackendService.getMedicalReportsAbnormalitiesV2,
      date,
      tenantId,
    );
    yield* put(actions.getAbnormalitiesV2DataSuccess(data));
  } catch (e) {
    console.error('error in loadAbnormalitiesV2 ', e);
    yield* put(actions.setAbnormalitiesV2Status(API_STATUS.ERROR));
    yield* call(redirectToHomePageIfForbidden, e as AxiosError, action.type);
  }
}
function* loadLast3DaysAbnormalities(
  action: ReturnType<typeof actions.loadLast3DaysAbnormalities>,
) {
  try {
    yield* put(actions.setLast3DaysAbnormalitiesStatus(API_STATUS.LOADING));
    const { date, tenantId } = action.payload;
    if (!date || !tenantId) {
      return;
    }
    const { data } = yield* call(
      BackendService.getMedicalReports3DaysAbnormalities,
      date,
      tenantId,
    );
    yield* put(actions.getLast3DaysAbnormalitiesDataSuccess(data));
  } catch (e) {
    yield* put(actions.setLast3DaysAbnormalitiesStatus(API_STATUS.ERROR));
    console.error('error in loadLast3DaysAbnormalities: ', e);
    yield* call(redirectToHomePageIfForbidden, e as AxiosError, action.type);
  }
}
function* getReportSettings(
  action: ReturnType<typeof actions.getReportSettings>,
) {
  try {
    const { date, tenantId } = action.payload;
    if (!date || !tenantId) {
      return;
    }
    yield* put(actions.setSettingsStatus(API_STATUS.LOADING));
    const { data } = yield* call(
      BackendService.getMedicalReportsSettings,
      tenantId,
    );
    yield* put(actions.getMedicalReportsSettingsDataSuccess(data));
  } catch (e) {
    console.error('error in getReportSettings ', e);
    yield* put(actions.setSettingsStatus(API_STATUS.ERROR));
    yield* put(
      actions.setSettingsError(
        ((e as AxiosError).response?.data as { code: string })?.code ?? '',
      ),
    );
    yield* call(redirectToHomePageIfForbidden, e as AxiosError, action.type);
  }
}

export default function* medicalReportsActions() {
  yield* all([
    takeLatest(actions.getReportSettings, getReportSettings),
    takeEvery(actions.loadIDBData, loadIDBData),
    takeLatest(actions.loadSummaryEventsData, loadSummaryEventsData),
    takeLatest(actions.loadDevicesPieChartData, loadDevicesPieChartData),
    takeLatest(actions.loadLastDayAbnormalities, loadLastDayAbnormalities),
    takeLatest(actions.loadLast3DaysAbnormalities, loadLast3DaysAbnormalities),
    takeLatest(actions.loadAbnormalitiesV2, loadAbnormalitiesV2),
  ]);
}
