import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from 'src/redux/store';
import { DATA_FETCHING_STATUS, DATA_STATE_KEY } from '../../constants';
import { ResponseInterface } from '../../types';
import { CustomersInitialState } from './types';
import {
  CreateCustomerAccessPayload,
  CreateCustomerPayload,
} from 'src/routes/Customers/modules/types';
import { UUID } from 'src/types/utility';
import { API_STATUS } from 'src/utils/api-constants';
import {
  CUSTOMER_ACCESS_INITIAL_VALUES,
  CUSTOMER_INITIAL_VALUES,
} from 'src/redux/data/customers/modules/constants';
import { GetCustomerQueryParams } from 'src/services/types';

export const STATE_KEY = 'customers';

export const INITIAL_STATE: CustomersInitialState = {
  customersList: { data: null, status: null },
  customerForm: CUSTOMER_INITIAL_VALUES,
  customersAccess: {
    data: CUSTOMER_ACCESS_INITIAL_VALUES,
    status: API_STATUS.INITIAL,
  },
  isLoadingForm: null,
  currentCustomerDeviceCounters: { data: null, status: null },
  currentCustomer: null,
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    setCustomersList(
      state,
      action: PayloadAction<ResponseInterface<CreateCustomerPayload[]>>,
    ) {
      state.customersList = { ...action.payload };
    },
    addCustomer(state, action: PayloadAction<CreateCustomerPayload>) {
      state.customersList.data = [
        action.payload,
        ...(state.customersList.data ?? []),
      ];
    },
    updateCustomerData(state, action: PayloadAction<CreateCustomerPayload>) {
      const { id } = action.payload;
      state.customersList.data = state.customersList.data?.map(customer =>
        customer.id === id
          ? {
              ...action.payload,
            }
          : customer,
      );
    },
    setCustomersAccess(
      state,
      action: PayloadAction<ResponseInterface<CreateCustomerAccessPayload>>,
    ) {
      state.customersAccess = { ...action.payload };
    },
    setCustomersAccessStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.customersAccess = {
        ...state.customersAccess,
        status: action.payload,
      };
    },
    setCustomersStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.customersList = { ...state.customersList, status: action.payload };
    },
    handleChangeForm(state, action: PayloadAction<CreateCustomerPayload>) {
      state.customerForm = { ...state.customerForm, ...action.payload };
    },
    setFormStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.isLoadingForm = action.payload;
    },
    setCustomerForm(state, action: PayloadAction<CreateCustomerPayload>) {
      state.customerForm = action.payload;
    },
  },
});

export const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;

export const selectors = {
  isLoadingCustomers: createSelector(
    getState,
    state => state.customersList.status === DATA_FETCHING_STATUS.LOADING,
  ),
  isLoadingForm: createSelector(
    getState,
    state => state.isLoadingForm === DATA_FETCHING_STATUS.LOADING,
  ),
  selectCustomersList: createSelector(
    getState,
    state => state.customersList.data,
  ),
  selectCustomersListStatus: createSelector(
    getState,
    state => state.customersList.status,
  ),
  selectCustomersAccessStatus: createSelector(
    getState,
    state => state.customersAccess.status,
  ),
  selectEditableData: createSelector(getState, state => state.customerForm),
  selectCurrentCustomersAccess: createSelector(
    getState,
    state => state.customersAccess,
  ),
};

const extraActions = {
  getCustomerAccess: createAction<UUID>(`${STATE_KEY}/getCustomerAccess`),
  createCustomer: createAction<
    Omit<CreateCustomerAccessPayload, 'ipAddresses'> &
      CreateCustomerPayload & { ipAddresses: string }
  >(`${STATE_KEY}/createCustomer`),
  editCustomer: createAction<
    Omit<CreateCustomerAccessPayload, 'ipAddresses'> &
      CreateCustomerPayload & { ipAddresses: string }
  >(`${STATE_KEY}/editCustomer`),
  deleteCustomerById: createAction<UUID>(`${STATE_KEY}/deleteCustomerById`),
  searchCustomers: createAction<GetCustomerQueryParams | undefined>(
    `${STATE_KEY}/searchCustomers`,
  ),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
