import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import { actions, selectors } from '../../modules/slice';
import { API_STATUS } from 'src/utils/api-constants';
import { actions as authActions } from 'src/redux/data/auth';

const mapStateToProps = (state: RootState) => ({
  medicalReportsSettings: selectors.getMedicalReportsSettings(state),
  errorSettings: selectors.getSettingsError(state),
  isLoading: selectors.getSettingsStatus(state) === API_STATUS.LOADING,
});

const mapActionCreators = {
  getReportSettings: actions.getReportSettings,
  logout: authActions.logout,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
