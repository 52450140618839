import { connect, ConnectedProps } from 'react-redux';

import { selectors as userSelectors } from 'src/redux/data/loggedInUser/modules/slice';
import { actions as authActions } from 'src/redux/data/auth/modules/slice';
import { RootState } from 'src/redux/store';
import {
  selectors,
  selectors as medicalReportSelectors,
} from 'src/routes/MedicalReports';
import { API_STATUS } from 'src/utils/api-constants';

const mapStateToProps = (state: RootState) => ({
  loggedInUserName: userSelectors.getUserFullName(state),
  loggedInUserType: userSelectors.getLoggedInUserType(state),
  userTenantName: userSelectors.getUserTenantName(state),
  userTenantTimezone: userSelectors.getUserTenantTimezone(state),
  medicalReportsSettings:
    medicalReportSelectors.getMedicalReportsSettings(state),
  errorSettings: selectors.getSettingsError(state),
  isMedicalReportAccessLoading:
    selectors.getSettingsStatus(state) === API_STATUS.LOADING,
});

const mapActionCreators = {
  logout: authActions.logout,
  onIdle: authActions.onIdle,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
