import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { styled } from 'styled-components';
import { Form } from 'antd';
import Modal from 'src/components/Modal';
import { userFormModes } from './constants';
import UserForm from './UserForm';
import Connector from './Connector';

const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    background-color: ${props => props.theme.colors.defaultBackgroundColor};
  }
  .ant-modal-body {
    overflow: auto !important;
    .ant-form-item-explain-error {
      font-size: 0.7rem;
      min-height: 1rem;
    }
  }
`;

const UserModal = ({
  mode,
  isModalVisible,
  setIsModalVisible,
  onSubmit,
  currentUser,
  intl,
  modalStatus,
  setModalStatus,
  fields,
  userTypes,
  modalMessage,
  getTenantsByCustomer,
  customersList,
  tenantsByCustomerList,
  sdkPermissionsSelectOptions,
  currentCustomer,
  loggedInUserCustomerId,
  loggedInUserTenantId,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (isModalVisible) {
      form.resetFields();
    }
  }, [isModalVisible]);

  const modeToTextMap = {
    [userFormModes.ADD]: {
      title: intl.formatMessage(modalMessage || messages.addUser),
      okButtonText: intl.formatMessage(modalMessage || messages.add),
    },
    [userFormModes.EDIT]: {
      title: intl.formatMessage(messages.editUser),
      okButtonText: intl.formatMessage(messages.apply),
    },
  };

  return (
    <ModalWrapper
      width={982}
      title={modeToTextMap[mode].title}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      okButtonText={modeToTextMap[mode].okButtonText}
      onOk={form.submit}
      bodyStyle={{ overflowY: 'scroll', overflowX: 'hidden' }}
      destroyOnClose
      onClose={form.resetFields}
      modalStatus={modalStatus}
      setModalStatus={setModalStatus}
      forceRender
    >
      <UserForm
        form={form}
        user={currentUser}
        onSubmit={onSubmit}
        mode={mode}
        userTypes={userTypes}
        fields={fields && { ...fields }}
        customersList={customersList}
        getTenantsByCustomer={getTenantsByCustomer}
        tenantsByCustomerList={tenantsByCustomerList}
        sdkPermissionsSelectOptions={sdkPermissionsSelectOptions}
        currentCustomer={currentCustomer}
        loggedInUserCustomerId={loggedInUserCustomerId}
        loggedInUserTenantId={loggedInUserTenantId}
      />
    </ModalWrapper>
  );
};

const messages = defineMessages({
  addUser: {
    defaultMessage: 'Add User',
  },
  editUser: {
    defaultMessage: 'Edit User',
  },
  add: {
    defaultMessage: 'Add',
  },
  apply: {
    defaultMessage: 'Apply',
  },
});

UserModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(Object.values(userFormModes)).isRequired,
  currentUser: PropTypes.object,
  intl: PropTypes.object.isRequired,
  modalStatus: PropTypes.string.isRequired,
  setModalStatus: PropTypes.func.isRequired,
  fields: PropTypes.object,
  userTypes: PropTypes.array,
  modalMessage: PropTypes.object,
  getTenantsByCustomer: PropTypes.func.isRequired,
  customersList: PropTypes.array,
  tenantsByCustomerList: PropTypes.array,
  sdkPermissionsSelectOptions: PropTypes.array,
  currentCustomer: PropTypes.object,
  resetTenantsOptionList: PropTypes.func,
  loggedInUserCustomerId: PropTypes.string,
  loggedInUserTenantId: PropTypes.func,
};

UserModal.defaultProps = {
  currentUser: {},
};

export default Connector(injectIntl(UserModal));
