import Axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import qs from 'qs';

import UmsLogic from 'src/libs/ums-js-logic';
import { Alert } from 'src/types/alerts';
import { DateUTC, SerialNumber, UUID } from 'src/types/utility';
import AppConfig from '../config/AppConfig';
import { resourceCreators, resources } from './resources';
import {
  CreateAlertThresholdResponse,
  CreateBaselineAlertThresholdResponse,
  CreateGroupResponse,
  CreateMeasurementSettingsResponse,
  CreateNotePayload,
  CreateNoteResponse,
  DeleteAlertThresholdResponse,
  FetchAdminDevicesResponse,
  FetchAggregatedContinuousMeasurementsResponse,
  FetchAlertLogsDataResponse,
  FetchAlertSettingsResponse,
  FetchAlertsListResponse,
  FetchAlertThresholdsResponse,
  FetchAlertWithPersonalDetailsResponse,
  FetchBaselineAlertThresholdsResponse,
  FetchBedOccupancyDataResponse,
  FetchConsentResponse,
  FetchDefaultVersionResponse,
  FetchDeviceCredentialsResponse,
  FetchDevicesLatestSessionsResponse,
  FetchDevicesResponse,
  FetchGroupClientsResponse,
  FetchGroupListResponse,
  FetchInvitedUsersResponse,
  FetchMeasurementSettingsResponse,
  FetchMetricHistogramDataResponse,
  GetOperatorsResponse,
  GetTenantsByOperatorIdResponse,
  FetchMTMAttentionListResponse,
  FetchMTMDeviceConnectionInfoResponse,
  FetchPatientAlertTypesResponse,
  FetchPatientNotesReponse,
  FetchPatientsLatestSessionsResponse,
  FetchPatientsNoteCounterMetadataResponse,
  FetchPatientsResponse,
  FetchRoomsResponse,
  FetchStatisticsDataResponse,
  FetchSubscribersResponse,
  FetchSubscriberStatusResponse,
  FetchTenantResponse,
  FetchTenantsResponse,
  FetchUserConsentsResponse,
  FetchUserDetailsResponse,
  FetchUsersResponse,
  FetchUserTypesResponse,
  FetchVersionResponse,
  GetCustomersResponse,
  GetFacilitiesResponse,
  GetPhiRegionalRestrictionResponse,
  MapFacilitiesPayload,
  PaginationParams,
  PatchBedExitAlertStatusResponse,
  QueryFilter,
  StartSessionResponse,
  UpdateAlertThresholdResponse,
  UpdateBaselineAlertThresholdResponse,
  UpdateMeasurementSettingsResponse,
  UpdateSubscribersListPayload,
  UpdateSubscribersPayload,
  UpdateSubscribersResponse,
  getPatientsAlertsToggleSettingsResponse,
  getPatientMedicalConditionResponse,
  getTenantDeviceCouuntersResponse,
  getTenantMonitoringDeviceCouuntersResponse,
  FetchMTMSubtenantsAlertCounters,
  FetchMTMAlertsListResponse,
  FetchMotionIndexMeasurementsResponse,
  FetchDeviceConnectionInfoResponse,
  getMedicalReportsEventsSummaryResponse,
  getMedicalReportsAbnormalitiesResponse,
  getMedicalReportsMainGraphDataResponse,
  getPieChartDataResponse,
  getMedicalReportsAbnormalitiesV2Response,
  getMedicalReportsHistogramResponse,
  getMedicalReportsSettingsResponse,
  GetOperatorsDeviceCountResponse,
  QuerySort,
  GetCustomerQueryParams,
  GetUsersPayload,
  FetchAdminLocationsDevicesResponse,
  FetchDevicesMountOptionsResponse,
  FetchFwVersionsCountResponse,
  FetchAdminMonitoringDaysResponse,
} from './types';
import {
  buildKeyGenerator,
  CacheRequestConfig,
  setupCache,
} from 'axios-cache-interceptor';
import {
  getAccessValidationHeaderValue,
  getBuildGeneratorKey,
  getHeaderInterpreter,
} from 'src/services/utils';

import {
  CreateOperatorPayload,
  OperatorType,
} from 'src/routes/Customers/components/OperatorsTab/modules/types';
import { ActivityAlertSettingsRoot } from 'src/components/AlertSettingsComponents/ActivityAlerts/types';
import { Patient } from 'src/types/patients';
import { DEFAULT_PAGINATION } from 'src/utils/constants';
import {
  CreateCustomerAccessPayload,
  CreateCustomerPayload,
  CustomersSdkActivityResponse,
} from 'src/routes/Customers/modules/types';
import { BedOccupancy } from 'src/types/individual-dashboard';
import { TableParamsType } from 'src/types/table';
import { ConnectedDevices } from 'src/types/devices';
import { Room } from 'src/types/rooms';

/**
|--------------------------------------------------
| The server service - expose interface to the API.
| Every method returns a promise.
|--------------------------------------------------
*/

// TODO: Add response types for all resources
// TODO: Add request types for all resources
// TODO: Add better typing for resources so can respond to ctrl+click

export const DEFAULT_LIMIT = 1000000;
export const DEFAULT_PAGE = 0;

const filterParamsString = (
  filter: QueryFilter,
  useQuestionMark = true,
): string => {
  let paramsString = '';

  filter.forEach((item, index) => {
    const char = !index && useQuestionMark ? '?' : '&';
    paramsString += `${char}filter%5B${index}%5D.prop=${item.prop}`;
    item.values.forEach(value => {
      paramsString += `&filter%5B${index}%5D.values=${value.toString()}`;
    });
  });

  return paramsString;
};

const searchParamString = (searchKeyword: string): string => {
  return `?search=${searchKeyword}`;
};
const sortByParamString = (sort: QuerySort, useQuestionMark = true): string => {
  let paramsString = '';

  sort.forEach((item, index) => {
    const char = !index && useQuestionMark ? '?' : '&';
    paramsString += `${char}sort%5B${index}%5D.prop=${item.prop}&sort%5B${index}%5D.order=${item.order}`;
  });

  return paramsString;
};
const consentParamString = (isConsented: boolean): string => {
  return `?isConsented=${String(isConsented)}`;
};

const paginationAndSearchParamsString = (
  pagination: {
    page?: number;
    limit?: number;
  },
  search = '',
  questionMark = true,
): string => {
  const page: number = pagination?.page || DEFAULT_PAGE;
  const limit: number = pagination?.limit || DEFAULT_LIMIT;
  return search
    ? `${searchParamString(search)}&page=${page}&limit=${limit}`
    : `${questionMark ? '?' : '&'}page=${page}&limit=${limit}`;
};

const beforeRequestInterceptor = async (
  request: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> => {
  // @ts-ignore Note: Custom request also includes requireAuth bool property
  if (!request.headers?.getAuthorization() && request.requireAuth) {
    const token = `Bearer ${UmsLogic.getApiCallToken()}`;
    const accessValidationHeaderValue =
      await getAccessValidationHeaderValue(token);
    request.headers.setAuthorization(token);
    request.headers.set('X-UI-Access-Validation', accessValidationHeaderValue);
  }
  // set tenantId header for device creation
  if (
    request.url === resources.adminCreateDevice.url &&
    request.method?.toLowerCase() ===
      resources.adminCreateDevice.method.toLowerCase()
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    request.headers.set('tenantId', request?.data?.tenantId);
  }
  return request;
};

class BackendService {
  _axiosInstance: AxiosInstance;
  _axios: AxiosInstance;

  constructor() {
    this._axiosInstance = Axios.create({
      baseURL: AppConfig.API_URL,
    });
    this._axios = setupCache(this._axiosInstance, {
      // debug: console.log,
      headerInterpreter: getHeaderInterpreter,
      generateKey: buildKeyGenerator(
        ({
          baseURL = '',
          url = '',
          method = 'get',
          params,
          data,
          headers,
        }: CacheRequestConfig) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          getBuildGeneratorKey({ baseURL, url, method, params, data, headers }),
      ),
    });
    this._axios.interceptors.request.use(beforeRequestInterceptor);
  }

  getUsers = () => this._axios.request(resources.getUsers);

  getUsersByUserType = (userTypeId: UUID) =>
    this._axios.request<FetchUsersResponse>({
      ...resourceCreators.getUsersByUserType(
        filterParamsString([
          {
            prop: 'userType.id',
            values: [userTypeId],
          },
        ]),
      ),
    });

  getTenantAdminDevicesList = (queryParams: {
    paginationParams: PaginationParams;
    searchKeyword?: string;
    filters?: QueryFilter;
    sortBy?: TableParamsType['sortBy'];
  }) => {
    const {
      paginationParams,
      filters = [],
      searchKeyword = '',
      sortBy,
    } = queryParams;
    const queryString = paginationAndSearchParamsString(
      paginationParams,
      searchKeyword,
    );

    return this._axios.request<FetchAdminDevicesResponse>({
      ...resourceCreators.getTenantAdminDevicesList(
        queryString,
        filterParamsString([...filters], false),
        sortBy?.order
          ? sortByParamString(
              [
                {
                  prop: sortBy.columnKey,
                  order: sortBy.order,
                },
              ],
              false,
            )
          : '',
      ),
    });
  };
  getDevices = (deviceId?: UUID) =>
    this._axios.request<FetchDevicesResponse>({
      ...resourceCreators.getDevices(
        deviceId
          ? filterParamsString(
              [
                {
                  prop: 'manufacturerId',
                  values: [deviceId],
                },
              ],
              false,
            )
          : '',
      ),
    });

  getLocationMountOptions = () => {
    return this._axios.request<FetchDevicesMountOptionsResponse>({
      ...resources.getLocationMountOptions,
    });
  };
  getFwVersionCounters = (currentCustomerId?: UUID) => {
    return currentCustomerId
      ? this._axios.request<FetchFwVersionsCountResponse>({
          ...resourceCreators.getCustomerFwVersionCounters(currentCustomerId),
        })
      : this._axios.request<FetchFwVersionsCountResponse>({
          ...resources.getFwVersionCounters,
        });
  };

  getDeviceById = (id: UUID) =>
    this._axios.request<FetchDevicesResponse>({
      ...resourceCreators.getDeviceById(
        filterParamsString([
          {
            prop: 'manufacturerId',
            values: [id],
          },
        ]),
      ),
    });

  getAdminDevicesByTenantId = (id: UUID) =>
    this._axios.request<FetchDevicesResponse>({
      ...resourceCreators.getAdminDevicesByTenantId(
        filterParamsString([
          {
            prop: 'tenantId',
            values: [id],
          },
        ]),
      ),
    });

  adminGetDevices = (queryParams: {
    paginationParams: PaginationParams;
    searchKeyword?: string;
    filters?: QueryFilter;
    sortBy?: TableParamsType['sortBy'];
  }) => {
    const {
      paginationParams,
      filters = [],
      searchKeyword = '',
      sortBy,
    } = queryParams;
    const queryString = paginationAndSearchParamsString(
      paginationParams,
      searchKeyword,
    );

    return this._axios.request<FetchAdminDevicesResponse>({
      ...resourceCreators.adminGetDevices(
        queryString,
        filterParamsString([...filters], false),
        sortBy?.order
          ? sortByParamString(
              [
                {
                  prop: sortBy.columnKey,
                  order: sortBy.order,
                },
              ],
              false,
            )
          : '',
      ),
    });
  };
  exportCsvAdminDevices = (queryParams: {
    paginationParams: PaginationParams;
    searchKeyword?: string;
    filters?: QueryFilter;
    sortBy?: TableParamsType['sortBy'];
  }) => {
    const {
      paginationParams,
      filters = [],
      searchKeyword = '',
      sortBy,
    } = queryParams;
    const queryString = paginationAndSearchParamsString(
      paginationParams,
      searchKeyword,
    );

    return this._axios.request<FetchAdminDevicesResponse>({
      ...resourceCreators.exportCsvAdminDevices(
        queryString,
        filterParamsString([...filters], false),
        sortBy?.order
          ? sortByParamString(
              [
                {
                  prop: sortBy.columnKey,
                  order: sortBy.order,
                },
              ],
              false,
            )
          : '',
      ),
    });
  };
  exportCsvDevices = (queryParams: {
    paginationParams: PaginationParams;
    searchKeyword?: string;
    filters?: QueryFilter;
    sortBy?: TableParamsType['sortBy'];
  }) => {
    const {
      paginationParams,
      filters = [],
      searchKeyword = '',
      sortBy,
    } = queryParams;
    const queryString = paginationAndSearchParamsString(
      paginationParams,
      searchKeyword,
    );

    return this._axios.request<FetchAdminDevicesResponse>({
      ...resourceCreators.exportCsvDevices(
        queryString,
        filterParamsString([...filters], false),
        sortBy?.order
          ? sortByParamString(
              [
                {
                  prop: sortBy.columnKey,
                  order: sortBy.order,
                },
              ],
              false,
            )
          : '',
      ),
    });
  };

  exportCsvDeviceLocations = (queryParams: {
    paginationParams: PaginationParams;
    searchKeyword: string;
    filters?: QueryFilter;
    sortBy?: TableParamsType['sortBy'];
  }) => {
    const {
      paginationParams,
      filters = [],
      searchKeyword = '',
      sortBy,
    } = queryParams;
    const queryString = paginationAndSearchParamsString(
      paginationParams,
      searchKeyword,
    );

    return this._axios.request<FetchAdminLocationsDevicesResponse>({
      ...resourceCreators.exportCsvDeviceLocations(
        queryString,
        filterParamsString([...filters], false),
        sortBy?.order
          ? sortByParamString(
              [
                {
                  prop: sortBy.columnKey,
                  order: sortBy.order,
                },
              ],
              false,
            )
          : '',
      ),
    });
  };
  exportCsvAdminDeviceLocations = (queryParams: {
    paginationParams: PaginationParams;
    searchKeyword: string;
    filters?: QueryFilter;
    sortBy?: TableParamsType['sortBy'];
  }) => {
    const {
      paginationParams,
      filters = [],
      searchKeyword = '',
      sortBy,
    } = queryParams;
    const queryString = paginationAndSearchParamsString(
      paginationParams,
      searchKeyword,
    );

    return this._axios.request<FetchAdminLocationsDevicesResponse>({
      ...resourceCreators.exportCsvAdminDeviceLocations(
        queryString,
        filterParamsString([...filters], false),
        sortBy?.order
          ? sortByParamString(
              [
                {
                  prop: sortBy.columnKey,
                  order: sortBy.order,
                },
              ],
              false,
            )
          : '',
      ),
    });
  };

  adminGetLocationsDevices = (queryParams: {
    paginationParams: PaginationParams;
    searchKeyword: string;
    filters?: QueryFilter;
    sortBy?: TableParamsType['sortBy'];
  }) => {
    const {
      paginationParams,
      filters = [],
      searchKeyword = '',
      sortBy,
    } = queryParams;
    const queryString = paginationAndSearchParamsString(
      paginationParams,
      searchKeyword,
    );

    return this._axios.request<FetchAdminLocationsDevicesResponse>({
      ...resourceCreators.adminGetLocationsDevices(
        queryString,
        filterParamsString([...filters], false),
        sortBy?.order
          ? sortByParamString(
              [
                {
                  prop: sortBy.columnKey,
                  order: sortBy.order,
                },
              ],
              false,
            )
          : '',
      ),
    });
  };

  getLocationAndDevices = (queryParams: {
    paginationParams: PaginationParams;
    searchKeyword?: string;
    filters?: QueryFilter;
    sortBy?: TableParamsType['sortBy'];
  }) => {
    const {
      paginationParams,
      filters = [],
      searchKeyword = '',
      sortBy,
    } = queryParams;
    const queryString = paginationAndSearchParamsString(
      paginationParams,
      searchKeyword,
    );

    return this._axios.request<FetchAdminDevicesResponse>({
      ...resourceCreators.getLocationAndDevices(
        queryString,
        filterParamsString([...filters], false),
        sortBy?.order
          ? sortByParamString(
              [
                {
                  prop: sortBy.columnKey,
                  order: sortBy.order,
                },
              ],
              false,
            )
          : '',
      ),
    });
  };
  getTenantConnectedDevices = (tenantId: UUID) => {
    return this._axios.request<ConnectedDevices>({
      ...resourceCreators.getTenantConnectedDevices(tenantId.toString()),
    });
  };
  adminGetMonitoringDaysDevices = (deviceIds: UUID[] = []) => {
    return this._axios.request<FetchAdminMonitoringDaysResponse>({
      ...resourceCreators.adminGetMonitoringDaysDevices(deviceIds.toString()),
    });
  };

  adminSearchAllDevices = (
    queryParams: PaginationParams & { searchKeyword: string },
  ) => {
    const { searchKeyword, ...pagination } = queryParams;
    const queryString = pagination?.limit
      ? paginationAndSearchParamsString(pagination, searchKeyword)
      : searchParamString(searchKeyword);

    return this._axios.request<FetchAdminDevicesResponse>({
      ...resourceCreators.adminSearchAllDevices(queryString),
    });
  };

  adminCreateDevice = (data: unknown) =>
    this._axios.request({ ...resources.adminCreateDevice, data });

  updateDevice = (manufacturerId: string, data: unknown) =>
    this._axios.request({
      ...resourceCreators.updateDevice(manufacturerId),
      data,
    });

  adminUpdateDevice = (manufacturerId: string, data: unknown) =>
    this._axios.request({
      ...resourceCreators.adminUpdateDevice(manufacturerId),
      data,
    });

  adminUpdateDeviceTenant = (manufacturerId: string, tenantId: string | null) =>
    this._axios.request({
      ...resourceCreators.adminUpdateDeviceTenant(manufacturerId),
      // @ts-ignore TODO: Check why null can be set here
      headers: { tenantId },
    });

  adminDeleteDevice = (manufacturerId: string) =>
    this._axios.request({
      ...resourceCreators.adminDeleteDevice(manufacturerId),
    });

  getDeviceConnectionInfoByTenantId = (
    tenantId: UUID,
    continuousDisconnectionSeconds: number,
  ) =>
    this._axios.request<FetchDeviceConnectionInfoResponse>(
      resourceCreators.getDeviceConnectionInfoByTenantId(
        tenantId,
        continuousDisconnectionSeconds.toString(),
      ),
    );

  getTenantsDeviceCounters = (tenantIds: UUID[]) =>
    this._axios.request<getTenantDeviceCouuntersResponse>(
      resourceCreators.getTenantsDeviceCounters(tenantIds.toString()),
    );
  getTenantsMonitoringDeviceCounters = (tenantIds: UUID[]) =>
    this._axios.request<getTenantMonitoringDeviceCouuntersResponse>(
      resourceCreators.getTenantsMonitoringDeviceCounters(tenantIds.toString()),
    );

  getTenantsOperators = (operatorsIds: UUID[]) =>
    this._axios.request<OperatorType[]>(
      resourceCreators.filterOperators(
        filterParamsString(
          [
            {
              prop: 'id',
              values: operatorsIds,
            },
          ],
          true,
        ),
      ),
    );

  getTenantsPaginated = (
    queryParams: PaginationParams & {
      search: string;
      groupFilter: boolean;
      sortBy?: TableParamsType['sortBy'];
    },
  ) => {
    const { search, groupFilter = false, sortBy, ...pagination } = queryParams;
    const queryString = paginationAndSearchParamsString(pagination, search);

    return this._axios.request<FetchTenantsResponse>(
      resourceCreators.getTenantsPaginated(
        queryString,
        filterParamsString(
          [
            {
              prop: 'group',
              values: [groupFilter],
            },
          ],
          false,
        ),
        sortBy?.order
          ? sortByParamString(
              [
                {
                  prop: sortBy.columnKey,
                  order: sortBy.order,
                },
              ],
              false,
            )
          : '',
      ),
    );
  };

  exportCsvAdminTenants = (queryParams: {
    paginationParams: PaginationParams;
    searchKeyword?: string;
    filters?: QueryFilter;
    sortBy?: TableParamsType['sortBy'];
  }) => {
    const {
      paginationParams,
      filters = [],
      searchKeyword = '',
      sortBy,
    } = queryParams;
    const queryString = paginationAndSearchParamsString(
      paginationParams,
      searchKeyword,
    );

    return this._axios.request<FetchAdminDevicesResponse>({
      ...resourceCreators.exportCsvAdminTenants(
        queryString,
        filterParamsString([...filters], false),
        sortBy?.order
          ? sortByParamString(
              [
                {
                  prop: sortBy.columnKey,
                  order: sortBy.order,
                },
              ],
              false,
            )
          : '',
      ),
    });
  };

  searchAllTenants = (
    queryParams: PaginationParams & { searchKeyword: string },
  ) => {
    const { searchKeyword, ...pagination } = queryParams;
    const queryString = pagination?.limit
      ? paginationAndSearchParamsString(pagination, searchKeyword)
      : searchParamString(searchKeyword);
    return this._axios.request<FetchTenantsResponse>({
      ...resourceCreators.searchAllTenants(queryString),
    });
  };

  getCustomerTenantsPaginated = (
    paginationParams: {
      page?: number;
      limit?: number;
    },
    customerId: UUID,
    groupFilter?: boolean,
    operatorId?: UUID,
    searchKeyword = '',
    sortBy?: TableParamsType['sortBy'],
    cache = 'true',
  ) => {
    const queryString = paginationAndSearchParamsString(
      paginationParams,
      searchKeyword,
    );
    const hasFilters =
      groupFilter === true || groupFilter === false || !!operatorId;
    const filters = [
      groupFilter === true || groupFilter === false
        ? {
            prop: 'group',
            values: [groupFilter],
          }
        : null,
      operatorId
        ? {
            prop: 'operatorId',
            values: [operatorId],
          }
        : null,
    ].filter(elem => !!elem) as QueryFilter;
    const filter = hasFilters ? filterParamsString(filters, false) : '';

    return this._axios.request<FetchTenantsResponse>(
      resourceCreators.getCustomerTenantsPaginated(
        queryString,
        customerId,
        filter,
        sortBy?.order
          ? sortByParamString(
              [
                {
                  prop: sortBy.columnKey,
                  order: sortBy.order,
                },
              ],
              false,
            )
          : '',
        cache,
      ),
    );
  };

  createTenant = (data: unknown) =>
    this._axios.request({ ...resources.createTenant, data });

  getTenants = () =>
    this._axios.request<FetchTenantsResponse>(resources.getTenants);

  getSubtenants = (parentTenantId: string) =>
    this._axios.request<FetchTenantsResponse>({
      ...resourceCreators.getSubtenants(parentTenantId),
    });

  deleteTenant = (id: string) =>
    this._axios.request({ ...resourceCreators.deleteTenant(id) });

  updateTenant = (id: string, data: unknown) =>
    this._axios.request({ ...resourceCreators.updateTenant(id), data });

  login = (data: unknown) => this._axios.request({ ...resources.login, data }); // TODO: use UMS package to login (when ready + has users)

  getUserDetails = () =>
    this._axios.request<FetchUserDetailsResponse>({
      ...resources.getUserDetails,
    });

  getSpotMeasurements = ({
    patientId,
    startTime,
    endTime,
  }: {
    patientId: string;
    startTime: string;
    endTime: string;
  }) =>
    this._axios.request({
      ...resourceCreators.getSpotMeasurements(patientId),
      params: { startTime, endTime },
    });

  getAllSessions = ({ patientId }: { patientId: string }) =>
    this._axios.request({ ...resourceCreators.getAllSessions(patientId) });

  getSessionGraphPoints = ({
    sessionId,
    metric,
  }: {
    sessionId: string;
    metric: string;
  }) =>
    this._axios.request({
      ...resourceCreators.queryBySession(sessionId),
      params: { metric },
    });

  getDateTimeGraphPoints = ({
    patientId,
    metric,
    startTime,
    endTime,
  }: {
    patientId: string;
    metric: string;
    startTime: string;
    endTime: string;
  }) =>
    this._axios.request<FetchStatisticsDataResponse>({
      ...resourceCreators.queryByDateTime(patientId),
      params: { metric, startTime, endTime },
    });

  getGraphDataByDateTime = ({
    patientId,
    metric,
    startTime,
    endTime,
  }: {
    patientId: string;
    metric: string;
    startTime: string;
    endTime: string;
  }) =>
    this._axios.request<FetchAggregatedContinuousMeasurementsResponse>({
      ...resourceCreators.queryGraphDataByDateTime(patientId),
      params: { metric, startTime, endTime },
    });

  getGraphDataMotionIndexByDateTime = ({
    patientId,
    startTime,
    endTime,
  }: {
    patientId: string;
    startTime: string;
    endTime: string;
  }) =>
    this._axios.request<FetchMotionIndexMeasurementsResponse>({
      ...resourceCreators.getGraphDataMotionIndexByDateTime(patientId),
      params: { startTime, endTime },
    });

  getPhiRegionalAccess = () =>
    this._axios.request<GetPhiRegionalRestrictionResponse>({
      ...resources.getPhiRegionalAccess,
    });

  getPatients = () =>
    this._axios.request<FetchPatientsResponse>({ ...resources.getPatients });

  deletePatient = (id: string) =>
    this._axios.request({ ...resourceCreators.deletePatient(id) });

  createPatient = (data: unknown) =>
    this._axios.request({ ...resources.createPatient, data });

  updatePatient = ({ id, data }: { id: string; data: unknown }) =>
    this._axios.request<Patient>({
      ...resourceCreators.updatePatient(id),
      data,
    });

  updatePatientConsent = ({
    id,
    isConsented,
  }: {
    id: string;
    isConsented: boolean;
  }) =>
    this._axios.request<Patient>({
      ...resourceCreators.updatePatientConsent(
        id,
        consentParamString(isConsented),
      ),
    });

  deviceCredentials = (data: { deviceIds: SerialNumber[] }) =>
    this._axios.request<FetchDeviceCredentialsResponse>({
      ...resources.deviceCredentials,
      data,
    });

  startContinuous = (deviceId: UUID, patientId: UUID, saveRawData: boolean) =>
    this._axios.request<StartSessionResponse>({
      ...resourceCreators.startContinuous(deviceId),
      data: { patientId, saveRawData },
    });

  stopContinuous = (deviceId: UUID) =>
    this._axios.request<void>({ ...resourceCreators.stopContinuous(deviceId) });

  resetContinuous = (deviceId: UUID) =>
    this._axios.request<void>({
      ...resourceCreators.resetContinuous(deviceId),
    });

  startSpot = (deviceId: UUID, patientId: UUID) =>
    this._axios.request({
      ...resourceCreators.startSpot(deviceId),
      data: { patientId },
    });

  getAllUserTypes = (data: unknown) =>
    this._axios.request<FetchUserTypesResponse>({
      ...resources.getAllUserTypes,
      data,
    });

  getAllUsers = (payload?: GetUsersPayload) => {
    const {
      customerId = '',
      page = DEFAULT_PAGINATION.PAGE - 1,
      limit = 10000,
      searchKeyword = '',
    } = payload || {};

    const paginationParams = paginationAndSearchParamsString(
      { page, limit },
      searchKeyword,
      !customerId,
    );

    const filterParams = customerId
      ? filterParamsString([
          {
            prop: 'customerId',
            values: [customerId],
          },
        ])
      : '';

    const queryParams = `${filterParams}${paginationParams}`;

    return this._axios.request<FetchUsersResponse>({
      ...resourceCreators.getAllUsers(queryParams),
    });
  };

  getMfaUsersData = ({
    customerId = '',
    page = DEFAULT_PAGINATION.PAGE - 1,
    limit = 10000,
    searchKeyword = '',
  }) => {
    const paginationParams = paginationAndSearchParamsString(
      { page, limit },
      searchKeyword,
      !customerId,
    );
    const filterParams = customerId
      ? filterParamsString([
          {
            prop: 'customerId',
            values: [customerId],
          },
        ])
      : '';

    const queryParams = `${customerId && filterParams}${paginationParams}`;
    return this._axios.request<FetchUsersResponse>({
      ...resourceCreators.getMfaUsersData(queryParams),
    });
  };

  getInvitedUsers = ({
    customerId = '',
    page = DEFAULT_PAGINATION.PAGE - 1,
    limit = 10000,
    searchKeyword = '',
  }) => {
    const paginationParams = paginationAndSearchParamsString(
      { page, limit },
      searchKeyword,
      !customerId,
    );

    const filterParams = customerId
      ? filterParamsString([
          {
            prop: 'customerId',
            values: [customerId],
          },
        ])
      : '';

    const queryParams = `${customerId && filterParams}${paginationParams}`;

    return this._axios.request<FetchInvitedUsersResponse>({
      ...resourceCreators.getInvitedUsers(queryParams),
    });
  };

  inviteUser = (data: unknown) =>
    this._axios.request({ ...resources.inviteUser, data });

  updateUser = ({ userId, data }: { userId: string; data: unknown }) =>
    this._axios.request({ ...resourceCreators.updateUser(userId), data });

  updateMfaUserInfo = ({ userId, data }: { userId: string; data: unknown }) =>
    this._axios.request({
      ...resourceCreators.updateMfaUserInfo(userId),
      data,
    });

  toggleUserActivation = ({
    userId,
    locked,
  }: {
    userId: UUID;
    locked: boolean;
  }) => {
    const data = { locked: locked };

    return this._axios.request({
      ...resourceCreators.toggleUserActivation(userId),
      data,
    });
  };

  deleteUser = (userId: string) =>
    this._axios.request({ ...resourceCreators.deleteUser(userId) });

  getGroups = (page: string | number = 1, limit: string | number = 20) => {
    const queryString = paginationAndSearchParamsString({
      page: parseInt(`${page}`),
      limit: parseInt(`${limit}`),
    });
    return this._axios.request<FetchGroupListResponse>({
      ...resourceCreators.getGroups(queryString),
    });
  };
  createGroup = (data: { name: string; applicationIds: UUID[] }) =>
    this._axios.request<CreateGroupResponse>({
      ...resources.createGroup,
      data,
    });

  adminSearchAllGroups = (searchKeyword: string) => {
    const queryString = searchParamString(searchKeyword);

    return this._axios.request<FetchGroupListResponse>({
      ...resourceCreators.searchGroups(queryString),
    });
  };

  getUploadUrl = (versionId: string) =>
    this._axios.request({ ...resourceCreators.getUploadUrl(versionId) });

  deleteGroup = (groupId: string) =>
    this._axios.request({ ...resourceCreators.deleteGroup(groupId) });

  getVersionByGroupId = (groupId: string) =>
    this._axios.request({ ...resourceCreators.getVersionByGroupId(groupId) });

  getGroupClients = () =>
    this._axios.request<FetchGroupClientsResponse>({
      ...resourceCreators.getGroupClients(),
    });

  assignDevice = ({
    groupId,
    data,
  }: {
    groupId: UUID;
    data: { clientIds: SerialNumber[]; removeOldGroupIfExists: boolean };
  }) =>
    this._axios.request({ ...resourceCreators.assignDevice(groupId), data });

  editAssignedDevices = ({
    groupId,
    data,
  }: {
    groupId: UUID;
    data: {
      clientIds: SerialNumber[];
      unassignedClientIds: SerialNumber[];
      removeOldGroupIfExists: boolean;
    };
  }) => {
    return this._axios.request({
      ...resourceCreators.editAssignedDevices(groupId),
      data,
    });
  };

  updateGroup = ({ groupId, data }: { groupId: string; data: unknown }) =>
    this._axios.request({ ...resourceCreators.updateGroup(groupId), data });

  createVersion = ({ versionId, data }: { versionId: string; data: unknown }) =>
    this._axios.request({ ...resourceCreators.createVersion(versionId), data });

  updateVersion = ({ versionId, data }: { versionId: string; data: unknown }) =>
    this._axios.request({ ...resourceCreators.updateVersion(versionId), data });

  getDefaultVersion = (appId: string) =>
    this._axios.request<FetchDefaultVersionResponse>({
      ...resourceCreators.getDefaultVersion(appId),
    });

  updateDefaultVersion = ({ appId, data }: { appId: string; data: unknown }) =>
    this._axios.request({
      ...resourceCreators.updateDefaultVersion(appId),
      data,
    });

  getVersionById = (versionId: string) =>
    this._axios.request<FetchVersionResponse>({
      ...resourceCreators.getVersionById(versionId),
    });

  getVersion = (filter?: QueryFilter) =>
    this._axios.request({
      ...resourceCreators.getVersion(filterParamsString(filter || [])),
    });

  uploadFile = ({
    url,
    fileToUpload,
  }: {
    url: string;
    fileToUpload: unknown;
  }) => Axios({ method: 'PUT', url, data: fileToUpload });

  deleteDeviceCertificate = (manufacturerId: string) =>
    this._axios.request({
      ...resourceCreators.deleteDeviceCertificate(manufacturerId),
    });

  getUserConsents = () =>
    this._axios.request<FetchUserConsentsResponse>({
      ...resourceCreators.getUserConsents(),
    });

  getConsent = (consentId: string) =>
    this._axios.request<FetchConsentResponse>({
      ...resourceCreators.getConsent(consentId),
    });

  confirmConsent = (revisionId: string) =>
    this._axios.request<void>({
      ...resourceCreators.confirmConsent(),
      data: { revisionId, localeName: 'eng' },
    });

  getHriData = ({
    patientId,
    startTime,
    stopTime,
  }: {
    patientId: string;
    startTime: string;
    stopTime: string;
  }) =>
    this._axios.request({
      ...resourceCreators.getHriData(patientId),
      params: { startTime, stopTime },
    });

  getHriFile = (url: string) =>
    this._axios.request(resourceCreators.getHriFile(url));

  deleteVersion = (versionId: string) =>
    this._axios.request({ ...resourceCreators.deleteVersion(versionId) });

  getSessionsForDevice = ({
    deviceId,
    startDateTime,
    endDateTime,
  }: {
    deviceId: string;
    startDateTime: string;
    endDateTime: string;
  }) =>
    this._axios.request({
      ...resourceCreators.getSessionsForDevice(deviceId),
      params: { startDateTime, endDateTime },
    });

  getSessionDataToDownload = ({
    sessionId,
    startTime,
    endTime,
  }: {
    sessionId: string;
    startTime: string;
    endTime: string;
  }) =>
    this._axios.request({
      ...resourceCreators.getSessionDataToDownload(sessionId),
      params: { startTime, endTime },
    });

  getTenant = () =>
    this._axios.request<FetchTenantResponse>({ ...resources.getTenant });

  resendInvitation = (invitationId: string) =>
    this._axios.request({ ...resourceCreators.resendInvitation(invitationId) });

  deleteInvitation = (invitationId: string) =>
    this._axios.request({ ...resourceCreators.deleteInvitation(invitationId) });

  getDeviceStatus = (devicesId: string) =>
    this._axios.request({ ...resourceCreators.getDeviceStatus(devicesId) });

  getDevicesStatuses = (devicesIds: string) =>
    this._axios.request<FetchDevicesLatestSessionsResponse>({
      ...resourceCreators.getDevicesStatuses(devicesIds),
    });

  getPatientsLatestSessions = (patientIds: string) =>
    this._axios.request<FetchPatientsLatestSessionsResponse>({
      ...resourceCreators.getPatientsLatestSessions(patientIds),
    });

  getAllLatestSessions = () =>
    this._axios.request<FetchDevicesLatestSessionsResponse>(
      resourceCreators.getAllLatestSessions(),
    );

  getVSAlertThresholds = () =>
    this._axios.request<FetchAlertThresholdsResponse>({
      ...resources.getVSAlertThresholds,
    });

  createAlertThreshold = (data: unknown) =>
    this._axios.request<CreateAlertThresholdResponse>({
      ...resources.createAlertThreshold,
      data,
    });

  updateAlertThreshold = (id: string, data: unknown) =>
    this._axios.request<UpdateAlertThresholdResponse>({
      ...resourceCreators.updateAlertThreshold(id),
      data,
    });

  getBaselineAlertThresholds = () =>
    this._axios.request<FetchBaselineAlertThresholdsResponse>({
      ...resources.getBaselineAlertThresholds,
    });

  createBaselineAlertThreshold = (data: unknown) =>
    this._axios.request<CreateBaselineAlertThresholdResponse>({
      ...resources.createBaselineAlertThreshold,
      data,
    });

  updateActivityAlertSettings = (data: unknown) =>
    this._axios.request<any>({
      ...resources.updateActivityAlertSettings,
      data,
    });

  updateMTMActivityAlertSettings = (data: unknown) =>
    this._axios.request<any>({
      ...resources.updateMTMActivityAlertSettings,
      data,
    });

  updateBaselineAlertThreshold = (id: string, data: unknown) =>
    this._axios.request<UpdateBaselineAlertThresholdResponse>({
      ...resourceCreators.updateBaselineAlertThreshold(id),
      data,
    });

  deleteAlertThreshold = (alertId: string) =>
    this._axios.request<DeleteAlertThresholdResponse>({
      ...resourceCreators.deleteAlertThreshold(alertId),
    });

  clearAlert = (id: string) =>
    this._axios.request({ ...resourceCreators.clearAlert(id) });

  fetchPatientsCurrentAlerts = (patientIds: string[]) =>
    this._axios.request<FetchAlertsListResponse>({
      ...resources.fetchPatientsCurrentAlerts,
      params: { patientIds },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    });

  fetchPatientsHistoricalAlerts = (
    patientIds: string[],
    startDateTime: DateUTC,
    endDateTime: DateUTC,
  ) =>
    this._axios.request<FetchAlertsListResponse>({
      ...resources.fetchPatientsHistoricalAlerts,
      params: { patientIds, startDateTime, endDateTime },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    });

  fetchMTMAlerts = ({ page = 1, limit = 20, searchQuery = '' }) => {
    const paginationParams = paginationAndSearchParamsString(
      { page, limit },
      searchQuery,
    );
    return this._axios.request<FetchMTMAlertsListResponse>({
      ...resourceCreators.fetchMTMAlerts(paginationParams),
      params: {
        ignoreSingleDeviceDisconnection: true,
      },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    });
  };

  suppressAlertType = (alertId: UUID) =>
    this._axios.request<Alert>({
      ...resourceCreators.suppressAlertType(alertId),
    });

  unSuppressAlertType = (alertId: UUID) =>
    this._axios.request<Alert>({
      ...resourceCreators.unSuppressAlertType(alertId),
    });

  clearAllPatientAlerts = (alertId: UUID) =>
    this._axios.request<Alert>({
      ...resourceCreators.clearAllPatientAlerts(alertId),
    });

  getMeasurementSettings = () =>
    this._axios.request<FetchMeasurementSettingsResponse>(
      resources.getMeasurementSettings,
    );

  createMeasurementSettings = (data: { highQuality: boolean }) =>
    this._axios.request<CreateMeasurementSettingsResponse>({
      ...resources.createMeasurementSettings,
      data,
    });

  updateMeasurementSettings = (data: { highQuality: boolean }) =>
    this._axios.request<UpdateMeasurementSettingsResponse>({
      ...resources.updateMeasurementSettings,
      data,
    });

  getRooms = (tenantId?: UUID) =>
    this._axios.request<FetchRoomsResponse>({
      ...resourceCreators.getRooms(tenantId),
    });

  createRoom = (data: unknown) =>
    this._axios.request<Room>({ ...resources.createRoom, data });

  updateRoom = (id: string, data: unknown) =>
    this._axios.request({ ...resourceCreators.updateRoom(id), data });

  deleteRoom = (alertId: string) =>
    this._axios.request({ ...resourceCreators.deleteRoom(alertId) });

  getAlertsSettings = () =>
    this._axios.request<FetchAlertSettingsResponse>(
      resources.getAlertsSettings,
    );
  deleteAlertsSettings = () =>
    this._axios.request(resources.deleteAlertsSettings);
  fetchActivityAlertSettings = () =>
    this._axios.request<ActivityAlertSettingsRoot>(
      resources.fetchActivityAlertSettings,
    );
  fetchMTMActivityAlertSettings = () =>
    this._axios.request<ActivityAlertSettingsRoot>(
      resources.fetchMTMActivityAlertSettings,
    );
  getPatientsAlertsToggleSettings = () =>
    this._axios.request<getPatientsAlertsToggleSettingsResponse>(
      resources.getPatientsAlertsToggleSettings,
    );
  getHistogramData = ({
    patientId,
    startTime,
    endTime,
  }: {
    patientId: string;
    startTime: string;
    endTime: string;
  }) =>
    this._axios.request<FetchMetricHistogramDataResponse>(
      resourceCreators.getHistogramData(patientId, startTime, endTime),
    );

  assignDeviceToBed = ({
    bedId,
    deviceId,
  }: {
    bedId: string;
    deviceId: string;
  }) =>
    this._axios.request(resourceCreators.assignDeviceToBed(bedId, deviceId));

  createBedInRoom = ({
    roomId,
    name,
    tenantId,
  }: {
    roomId: string;
    name: string;
    tenantId?: UUID;
  }) =>
    this._axios.request<{ id: string; name: string }>({
      ...resourceCreators.createBedInRoom(),
      data: tenantId ? { roomId, name, tenantId } : { roomId, name },
    });

  getSubscriberStatus = (
    userId: string,
    targetMedia: string,
    alertType: string,
  ) =>
    this._axios.request<FetchSubscriberStatusResponse>(
      resourceCreators.getSubscriberStatus(userId, targetMedia, alertType),
    );

  updateSubscribers = (userId: UUID, data: UpdateSubscribersPayload) =>
    this._axios.request<UpdateSubscribersResponse>({
      ...resourceCreators.updateSubscribers(userId),
      data,
    });

  getSubscribersList = () =>
    this._axios.request<FetchSubscribersResponse>(resources.getSubscribersList);

  updateSubscribersList = (data: UpdateSubscribersListPayload) =>
    this._axios.request<UpdateSubscribersResponse>({
      ...resources.updateSubscribersList,
      data,
    });

  getBedOccupancyData = ({
    patientId,
    startTime,
    endTime,
  }: {
    patientId: string;
    startTime: string;
    endTime: string;
  }) =>
    this._axios.request<FetchBedOccupancyDataResponse>(
      resourceCreators.getBedOccupancyData(patientId, startTime, endTime),
    );

  getPatientMedicalConditions = ({ patientId }: { patientId: string }) =>
    this._axios.request<getPatientMedicalConditionResponse>(
      resourceCreators.getPatientMedicalConditions(patientId),
    );

  getAlertsLogData = ({
    patientId,
    startDate,
    endDate,
    page = 1,
    limit = 30,
  }: {
    patientId: string;
    startDate: string;
    endDate: string;
    page?: number;
    limit?: number;
  }) => {
    const queryString = paginationAndSearchParamsString({ page, limit });

    return this._axios.request<FetchAlertLogsDataResponse>(
      resourceCreators.getAlertsLogData(
        patientId,
        startDate,
        endDate,
        queryString,
      ),
    );
  };

  getPatientAlertTypesSettings = (patientIds: string) =>
    this._axios.request<FetchPatientAlertTypesResponse>({
      ...resourceCreators.getPatientAlertTypesSettings(patientIds),
    });

  setBedExitAlertStatus = (patientId: string, data: unknown) =>
    this._axios.request<PatchBedExitAlertStatusResponse>({
      ...resourceCreators.setBedExitAlertStatus(patientId),
      data,
    });

  fetchPatientNotes = (patientId: string) =>
    this._axios.request<FetchPatientNotesReponse>({
      ...resourceCreators.fetchPatientNotes(patientId),
    });
  fetchPatientsNoteCounterMetadata = (patientIds: string[]) =>
    this._axios.request<FetchPatientsNoteCounterMetadataResponse>({
      ...resources.fetchPatientsNoteCounterMetadata,
      params: {
        patientIds,
      },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    });
  createNote = (payload: CreateNotePayload) =>
    this._axios.request<CreateNoteResponse>({
      ...resourceCreators.createNote(payload.patientId),
      data: payload,
    });

  fetchAlertWithPersonalDetails = (alertId: UUID) =>
    this._axios.request<FetchAlertWithPersonalDetailsResponse>({
      ...resourceCreators.fetchAlertWithPersonalDetails(alertId),
    });

  fetchMTMAttentionList = (
    startDateTime: DateUTC,
    endDateTime: DateUTC,
    page = 1,
    limit = 20,
    searchQuery = '',
  ) => {
    const paginationParams = paginationAndSearchParamsString(
      { page, limit },
      searchQuery,
    );
    return this._axios.request<FetchMTMAttentionListResponse>({
      ...resourceCreators.fetchMTMAttentionList(paginationParams),
      params: { startDateTime, endDateTime },
    });
  };

  fetchMTMDeviceConnectionInfo = (continuousDisconnectionSeconds: number) =>
    this._axios.request<FetchMTMDeviceConnectionInfoResponse>({
      ...resources.fetchMTMDeviceConnectionInfo,
      params: { continuousDisconnectionSeconds },
    });

  searchOperators = ({
    customerId = '',
    page = DEFAULT_PAGINATION.PAGE - 1,
    limit = DEFAULT_PAGINATION.LIMIT,
    searchKeyword = '',
  }) => {
    const paginationParams = paginationAndSearchParamsString(
      { page, limit },
      searchKeyword,
    );

    return this._axios.request<GetOperatorsResponse>({
      ...resourceCreators.searchOperators(customerId, paginationParams),
    });
  };

  getOperators = () =>
    this._axios.request<GetOperatorsResponse>({
      ...resources.getOperators,
    });

  getCustomerAccess = (customerId: UUID) => {
    return this._axios.request<CreateCustomerAccessPayload>({
      ...resourceCreators.getCustomerAccess(customerId),
    });
  };

  getAllSdkCustomersActivity = () => {
    return this._axios.request<{
      customerActivityList: CustomersSdkActivityResponse[];
    }>({
      ...resources.getAllSdkCustomersActivity,
    });
  };

  searchCustomers = (queryParams?: GetCustomerQueryParams) => {
    const {
      searchKeyword = '',
      customerIds = [],
      sortBy: { columnKey = 'name', order = 'ASC' } = {},
    } = queryParams || {};

    const queryString = searchKeyword ? searchParamString(searchKeyword) : '';
    const sorting = sortByParamString(
      [
        {
          prop: columnKey,
          order,
        },
      ],
      !searchKeyword,
    );
    const customerIdsParam = !customerIds.filter(elem => !!elem)?.length
      ? ''
      : filterParamsString(
          [
            {
              prop: 'id',
              values: customerIds,
            },
          ],
          false,
        );

    return this._axios.request<GetCustomersResponse>({
      ...resourceCreators.searchCustomers(
        queryString,
        sorting,
        customerIdsParam,
      ),
    });
  };

  createCustomer = (payload: CreateCustomerPayload) =>
    this._axios.request<CreateCustomerPayload>({
      ...resources.createCustomer,
      data: payload,
    });

  updateCustomer = (customerId: UUID, customerForm: CreateCustomerPayload) =>
    this._axios.request<CreateCustomerPayload>({
      ...resourceCreators.updateCustomer(customerId),
      data: customerForm,
    });

  updateCustomerAccess = (
    customerId: UUID,
    customerAccessForm: CreateCustomerAccessPayload,
  ) =>
    this._axios.request<CreateCustomerAccessPayload>({
      ...resourceCreators.updateCustomerAccess(customerId),
      data: customerAccessForm,
    });

  deleteCustomer = (customerId: UUID) =>
    this._axios.request({
      ...resourceCreators.deleteCustomer(customerId),
    });

  createOperator = (payload: CreateOperatorPayload) =>
    this._axios.request<OperatorType>({
      ...resources.createOperator,
      data: payload,
    });

  updateOperator = (operatorId: UUID, operatorForm: CreateOperatorPayload) =>
    this._axios.request({
      ...resourceCreators.updateOperator(operatorId),
      data: operatorForm,
    });

  deleteOperator = (operatorId: UUID) =>
    this._axios.request({
      ...resourceCreators.deleteOperator(operatorId),
    });

  getOperatorsDeviceCount = (customerId: UUID) =>
    this._axios.request<GetOperatorsDeviceCountResponse>({
      ...resourceCreators.getOperatorsDeviceCount(customerId),
    });

  getTenantsByOperatorId = (operatorId: UUID) =>
    this._axios.request<GetTenantsByOperatorIdResponse>({
      ...resourceCreators.getTenantsByOperatorId(operatorId),
    });

  getFacilitiesByEmrType = (operatorId: UUID, emrType: string) =>
    this._axios.request<GetFacilitiesResponse>(
      resourceCreators.getFacilitiesByEmrType(operatorId, emrType),
    );

  mapEmrFacilities = (data: MapFacilitiesPayload) =>
    this._axios.request({
      ...resources.mapEmrFacilities,
      data,
    });

  fetchMTMSubtenantsAlertCounters = () =>
    this._axios.request<FetchMTMSubtenantsAlertCounters>({
      ...resources.fetchMTMSubtenantsAlertCounters,
    });

  fetchMTMRooms = (MtmDeviceIds: Array<SerialNumber | null>) =>
    this._axios.request<FetchRoomsResponse>({
      ...resources.fetchMTMRooms,
      params: {
        deviceIds: [...MtmDeviceIds],
      },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    });

  fetchMTMPatientDetails = (patientIds: Array<UUID>) =>
    this._axios.request<FetchPatientsResponse>({
      ...resources.fetchMTMPatients,
      params: {
        ...(patientIds?.length
          ? {
              'filter[0]': {
                prop: 'id',
                values: patientIds,
              },
            }
          : {}),
      },
      paramsSerializer: params =>
        qs.stringify(params, {
          indices: true,
          arrayFormat: 'repeat',
          allowDots: true,
        }),
    });
  getMedicalReportsMainGraphdata = (
    shortDate: string,
    tenantId: UUID,
    patientId: UUID,
  ) =>
    this._axios.request<getMedicalReportsMainGraphDataResponse>({
      ...resourceCreators.getMedicalReportsMainGraphData(
        shortDate,
        tenantId,
        patientId,
      ),
    });

  getMedicalReportsBedOccupancy = (
    shortDate: string,
    tenantId: UUID,
    patientId: UUID,
  ) =>
    this._axios.request<BedOccupancy[]>({
      ...resourceCreators.getMedicalReportsBedOccupancy(
        shortDate,
        tenantId,
        patientId,
      ),
    });
  getMedicalReportsHistogram = (
    shortDate: string,
    tenantId: UUID,
    patientId: UUID,
  ) =>
    this._axios.request<getMedicalReportsHistogramResponse>({
      ...resourceCreators.getMedicalReportsHistogram(
        shortDate,
        tenantId,
        patientId,
      ),
    });
  getMedicalReportsEventsSummary = (shortDate: string, tenantId: UUID) =>
    this._axios.request<getMedicalReportsEventsSummaryResponse>({
      ...resourceCreators.getMedicalReportsEventsSummary(shortDate, tenantId),
    });
  getMedicalReportsDayAbnormalities = (shortDate: string, tenantId: UUID) =>
    this._axios.request<getMedicalReportsAbnormalitiesResponse[]>({
      ...resourceCreators.getMedicalReportsDayAbnormalities(
        shortDate,
        tenantId,
      ),
    });
  getMedicalReportsAbnormalitiesV2 = (shortDate: string, tenantId: UUID) =>
    this._axios.request<getMedicalReportsAbnormalitiesV2Response>({
      ...resourceCreators.getMedicalReportsAbnormalitiesV2(shortDate, tenantId),
    });
  getMedicalReports3DaysAbnormalities = (shortDate: string, tenantId: UUID) =>
    this._axios.request<getMedicalReportsAbnormalitiesResponse[]>({
      ...resourceCreators.getMedicalReports3DaysAbnormalities(
        shortDate,
        tenantId,
      ),
    });

  getMedicalReportsSettings = (tenantId: UUID) =>
    this._axios.request<getMedicalReportsSettingsResponse>({
      ...resourceCreators.getMedicalReportsSettings(tenantId),
    });

  getPieChartData = (shortDate: string, tenantId: UUID) =>
    this._axios.request<getPieChartDataResponse>({
      ...resourceCreators.getPieChartData(shortDate, tenantId),
    });
}

export default new BackendService();
