import { connect } from 'react-redux';

import {
  selectors as tenantDataSelectors,
  actions as tenantDataActions,
} from 'src/redux/data/tenant';

const mapStateToProps = state => ({
  parentTenantList: tenantDataSelectors.getAddTenantsParentList(state),
});

const mapActionCreators = {
  getAddTenantsParentList: tenantDataActions.getAddTenantsParentList,
};

const Connector = connect(mapStateToProps, mapActionCreators);
export default Connector;
