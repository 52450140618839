import { connect, ConnectedProps } from 'react-redux';

import {
  selectors as sdkSelectors,
  actions as customersActions,
} from 'src/redux/data/SDK';
import { selectors as customersSelectors } from 'src/redux/data/customers';
import { RootState } from 'src/redux/store';

const mapStateToProps = (state: RootState) => {
  const customersList = customersSelectors.selectCustomersList(state);
  const sdkCustomersActivity = sdkSelectors.selectSdkCustomersActivity(state);

  const sdkData = sdkCustomersActivity?.map(sdk => {
    const matchingCustomer = customersList?.find(
      customer => sdk.customerId === customer.id,
    );

    return { ...sdk, customerName: matchingCustomer?.name };
  });

  return {
    sdkData: sdkData,
    isLoading: sdkSelectors.isSdkCustomersActivityLoading(state),
  };
};

const mapActionCreators = {
  getAllSdkCustomersActivity: customersActions.getAllSdkCustomersActivity,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
