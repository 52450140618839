import { connect, ConnectedProps } from 'react-redux';
import {
  actions as deviceActions,
  selectors as deviceSelectors,
} from 'src/redux/data/device';
import { RootState } from 'src/redux/store';
import { apiStatusEnum } from 'src/utils/constants';

const mapStateToProps = (state: RootState) => ({
  isLoading:
    deviceSelectors.selectSpecificDeviceDataStatus(state) ===
    apiStatusEnum.LOADING,
  specificDeviceData: deviceSelectors.selectSpecificDeviceData(state),
});

const mapActionCreators = {
  getSpecificDeviceData: deviceActions.getSpecificDeviceData,
};
const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
