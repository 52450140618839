import {
  createSlice,
  createSelector,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from 'src/redux/store';
import { DATA_FETCHING_STATUS, DATA_STATE_KEY } from '../../constants';
import { ResponseInterface } from '../../types';
import { OperatorsInitialState } from './types';
import {
  CreateOperatorPayload,
  OperatorDeviceCountType,
  OperatorType,
} from 'src/routes/Customers/components/OperatorsTab/modules/types';
import { SortableFields, SortOrder, UUID } from 'src/types/utility';
import { DEFAULT_PAGINATION } from 'src/utils/constants';
import { TableParamsType } from 'src/types/table';

export const STATE_KEY = 'operators';

const OperatorInitialData = {
  name: '',
  groupTenantId: '',
  emrMappings: [
    {
      organizationId: '',
      emrType: '',
    },
  ],
};

export const INITIAL_STATE: OperatorsInitialState = {
  operatorsList: { data: null, status: null },
  operatorForm: OperatorInitialData,
  isLoadingForm: null,
  currentOperator: null,
  tableParams: {
    current: DEFAULT_PAGINATION.PAGE - 1,
    pageSize: DEFAULT_PAGINATION.LIMIT,
    sortBy: {
      columnKey: SortOrder.desc,
      order: SortableFields.creationTime,
    },
    total: 0,
    showSizeChanger: false,
  },
  operatorsDeviceCount: { data: null, status: null },
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    setOperatorsList(
      state,
      action: PayloadAction<ResponseInterface<OperatorType[]>>,
    ) {
      state.operatorsList = { ...action.payload };
    },
    setOperatorsStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.operatorsList = { ...state.operatorsList, status: action.payload };
    },
    setTableParams: (state, action: PayloadAction<TableParamsType>) => {
      state.tableParams = { ...state.tableParams, ...action.payload };
    },
    handleChangeForm(state, action: PayloadAction<CreateOperatorPayload>) {
      state.operatorForm = { ...state.operatorForm, ...action.payload };
    },
    setFormStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.isLoadingForm = action.payload;
    },
    setOperatorForm(state, action: PayloadAction<CreateOperatorPayload>) {
      state.operatorForm = action.payload;
    },
    setCurrentOperator(state, action: PayloadAction<OperatorType>) {
      state.currentOperator = action.payload;
    },
    resetFormAndCurrentOperator(state) {
      state.operatorForm = OperatorInitialData;
      state.currentOperator = null;
    },
    setOperatorsDeviceCount(
      state,
      action: PayloadAction<ResponseInterface<OperatorDeviceCountType[]>>,
    ) {
      state.operatorsDeviceCount = { ...action.payload };
    },
    setOperatorsDeviceCountStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.operatorsDeviceCount.status = action.payload;
    },
  },
});

export const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;

export const selectors = {
  isLoadingOperators: createSelector(
    getState,
    state =>
      state.operatorsList.status === DATA_FETCHING_STATUS.LOADING ||
      state.operatorsDeviceCount.status === DATA_FETCHING_STATUS.LOADING,
  ),
  isLoadingForm: createSelector(
    getState,
    state => state.isLoadingForm === DATA_FETCHING_STATUS.LOADING,
  ),
  selectOperatorsList: createSelector(
    getState,
    state => state.operatorsList.data,
  ),
  selectOperatorsListStatus: createSelector(
    getState,
    state => state.operatorsList.status,
  ),
  selectOperatorsDeviceCount: createSelector(
    getState,
    state => state.operatorsDeviceCount.data,
  ),
  selectEditableData: createSelector(getState, state => state.operatorForm),
  selectCurrentOperator: createSelector(
    getState,
    state => state.currentOperator,
  ),
  selectTableParams: createSelector(getState, state => state.tableParams),
};

const extraActions = {
  getOperatorsList: createAction<{
    page?: number;
    limit?: number;
    searchKeyword?: string;
    customerId: UUID;
  }>(`${STATE_KEY}/getOperatorsList`),
  createOperator: createAction<UUID>(`${STATE_KEY}/createOperator`),
  editOperator: createAction<UUID>(`${STATE_KEY}/editOperator`),
  deleteOperatorById: createAction<{
    operatorId: UUID;
    customerId: UUID;
  }>(`${STATE_KEY}/deleteOperatorById`),
  getOperatorsDeviceCount: createAction<UUID>(
    `${STATE_KEY}/getOperatorsDeviceCount`,
  ),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
