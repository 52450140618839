import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import Connector, { PropsFromRedux } from './Connector';
import { StyledModal } from './styled';
import Table from 'src/components/CrudCommonComponents/Table';
import { getTableColumns } from './utils';
import { CreateCustomerPayload } from 'src/routes/Customers/modules/types';

type Props = PropsFromRedux & {
  intl: IntlShape;
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  currentCustomer?: CreateCustomerPayload;
};
const FwVersionCountersModal = ({
  intl,
  isModalVisible,
  setIsModalVisible,
  getFwCounters,
  fwCounters,
  currentCustomer,
  isLoading,
}: Props) => {
  useEffect(() => {
    getFwCounters(currentCustomer?.id);
  }, [getFwCounters, currentCustomer?.id]);

  return (
    <StyledModal
      title={intl.formatMessage(messages.modalTitle)}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      okButtonText={intl.formatMessage(messages.ok)}
      onOk={() => setIsModalVisible(false)}
      withCancelButton={false}
      shouldCloseOnOk
      bodyStyle={{
        height: '400px',
        overflowY: 'auto',
        overflowX: 'auto',
      }}
    >
      <Table
        sticky={true}
        loading={isLoading}
        data={fwCounters}
        columns={getTableColumns(intl, messages)}
      />
    </StyledModal>
  );
};

export const messages = defineMessages({
  modalTitle: {
    defaultMessage: 'FW versions',
  },
  ok: {
    defaultMessage: 'Ok',
  },
  fwColumnHeader: {
    defaultMessage: 'FW Version',
  },
  devicesCountColumnHeader: {
    defaultMessage: '# of devices',
  },
});

export default Connector(injectIntl(FwVersionCountersModal));
