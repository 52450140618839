import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import * as R from 'fp-ts/lib/Record';
import * as Ord from 'fp-ts/lib/Ord';

import { MODAL_STATUS } from 'src/components/Modal/constants';
import { RootState } from 'src/redux/store';
import {
  ConnectedLocationInfo,
  DeviceConnectionState,
  MTMAssignedDevices,
  MTMDeviceConnectioninfo,
  RawDevice,
  TenantConnectionInfo,
} from 'src/types/devices';
import { PageMetaDataType, SerialNumber, UUID } from 'src/types/utility';
import { DATA_FETCHING_STATUS, DATA_STATE_KEY } from '../../constants';
import {
  FetchFwVersionsCountResponse,
  PaginationParams,
} from 'src/services/types';
import { TableParamsType } from 'src/types/table';
import { SingleSelectItem } from 'src/components/general-ui/SelectBoxForm';

export const STATE_KEY = 'device';

type DropdownOptions = {
  [key: string]: SingleSelectItem[];
};

export const INITIAL_STATE: {
  devicesList: {
    data: RawDevice[];
    pageMetadata: PageMetaDataType;
  };
  metadata: {
    connectionInfo: MTMDeviceConnectioninfo;
    sessionInfo: MTMAssignedDevices;
  };
  tenantDeviceConnectionInfo: TenantConnectionInfo;
  status: keyof typeof DATA_FETCHING_STATUS;
  modalStatus: keyof typeof MODAL_STATUS;
  resetCountinousStatus: keyof typeof DATA_FETCHING_STATUS | null;
  locationMountOptions: {
    data: DropdownOptions | null;
    status: keyof typeof DATA_FETCHING_STATUS | null;
  };
  specificDeviceData: {
    data: RawDevice | null;
    status: keyof typeof DATA_FETCHING_STATUS | null;
  };
  fwVersionCounters: {
    data:
      | FetchFwVersionsCountResponse['deviceCountByVersionResponseList']
      | null;
    status: keyof typeof DATA_FETCHING_STATUS | null;
  };
  tenantDeviceLocation: {
    data: ConnectedLocationInfo[] | null;
    status: keyof typeof DATA_FETCHING_STATUS | null;
  };
} = {
  devicesList: {
    data: [],
    pageMetadata: {
      totalResults: 0,
      page: 0,
      limit: 0,
    },
  },
  metadata: {
    connectionInfo: {},
    sessionInfo: {},
  },
  tenantDeviceConnectionInfo: {
    connectedAssigned: 0,
    connectedUnassigned: 0,
    disconnectedAssigned: 0,
    disconnectedUnassigned: 0,
    currentUtilization: 0,
    noConsent: 0,
  },
  status: DATA_FETCHING_STATUS.LOADING,
  modalStatus: MODAL_STATUS.INITIAL,
  resetCountinousStatus: null,
  locationMountOptions: {
    data: null,
    status: null,
  },
  specificDeviceData: {
    data: null,
    status: null,
  },
  fwVersionCounters: {
    data: null,
    status: null,
  },
  tenantDeviceLocation: {
    data: null,
    status: null,
  },
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    setDevicesList(
      state,
      action: PayloadAction<{
        data: RawDevice[];
        metadata: PageMetaDataType;
      }>,
    ) {
      const { data, metadata } = action.payload;
      state.devicesList.data = data;
      state.devicesList.pageMetadata = metadata;
    },
    setStatus(state, action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>) {
      state.status = action.payload;
    },
    setModalStatus(state, action: PayloadAction<keyof typeof MODAL_STATUS>) {
      state.modalStatus = action.payload;
    },
    setDeviceConnectionState(
      state,
      action: PayloadAction<DeviceConnectionState>,
    ) {
      const { deviceId, connectionStatus, timestamp } = action.payload;
      const device = state.devicesList.data.find(
        d => d.manufacturerId === deviceId,
      );

      if (device) {
        // @ts-ignore Todo fix typo later
        device.connectionStatus = {
          ...device.connectionStatus,
          connected: connectionStatus === 'connected',
          lastModifiedTimestamp: timestamp,
        };
      }
    },
    fetchMTMDeviceConnectionInfoSuccess(
      state,
      action: PayloadAction<Record<UUID, TenantConnectionInfo>>,
    ) {
      state.metadata.connectionInfo = { ...action.payload };
    },
    fetchDeviceConnectionInfoSuccess(
      state,
      action: PayloadAction<TenantConnectionInfo>,
    ) {
      state.tenantDeviceConnectionInfo = { ...action.payload };
    },
    resetContinuousStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.resetCountinousStatus = action.payload;
    },
    getLocationMountOptionsSuccess(
      state,
      action: PayloadAction<{
        data: DropdownOptions;
        status: keyof typeof DATA_FETCHING_STATUS;
      }>,
    ) {
      state.locationMountOptions = { ...action.payload };
    },
    getSpecificDeviceDataSuccess(
      state,
      action: PayloadAction<{
        data: RawDevice | null;
      }>,
    ) {
      state.specificDeviceData.data = action.payload.data;
    },
    setLocationMountOptionsStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.locationMountOptions.status = action.payload;
    },
    setSpecificDeviceDataStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.specificDeviceData.status = action.payload;
    },
    getFwVersionCountersSuccess(
      state,
      action: PayloadAction<
        FetchFwVersionsCountResponse['deviceCountByVersionResponseList']
      >,
    ) {
      state.fwVersionCounters.data = action.payload;
    },
    getTenantDeviceLocationSuccess(
      state,
      action: PayloadAction<ConnectedLocationInfo[]>,
    ) {
      state.tenantDeviceLocation.data = action.payload;
    },
    setFwVersionCountersStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.fwVersionCounters.status = action.payload;
    },
    setTenantDeviceLocationStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.tenantDeviceLocation.status = action.payload;
    },
  },
  extraReducers: {},
});

const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;

const getDeviceId = (_state: RootState, deviceId: SerialNumber) => deviceId;

const getTenantId = (_state: RootState, tenantId: UUID) => tenantId;

export const selectors = {
  getDevicesList: createSelector(getState, state => state.devicesList.data),
  getDevicesListMetadata: createSelector(
    getState,
    state => state.devicesList.pageMetadata,
  ),
  isDeviceListLoading: createSelector(
    getState,
    state => state.status === DATA_FETCHING_STATUS.LOADING,
  ),
  getStatus: createSelector(getState, state => state.status),
  getModalStatus: createSelector(getState, state => state.modalStatus),
  selectDevice: createSelector(getState, getDeviceId, (state, deviceId) =>
    state.devicesList.data.find(d => d.manufacturerId === deviceId),
  ),
  getIsDeviceConnected: createSelector(
    getState,
    getDeviceId,
    (state, deviceId) => {
      const device = state.devicesList.data.find(
        d => d.manufacturerId === deviceId,
      );
      return device?.connectionStatus?.connected || false;
    },
  ),
  selectTenantDeviceConnectionInfo: createSelector(getState, state => ({
    totalDevices: state.devicesList.pageMetadata.totalResults,
    connectedAssignedDevices:
      state.tenantDeviceConnectionInfo.connectedAssigned,
    connectedUnassignedDevices:
      state.tenantDeviceConnectionInfo.connectedUnassigned,
    disconnectedAssignedDevices:
      state.tenantDeviceConnectionInfo.disconnectedAssigned,
    disconnectedUnassignedDevices:
      state.tenantDeviceConnectionInfo.disconnectedUnassigned,
    currentUtilization: state.devicesList.data.length
      ? Number(
          (state.tenantDeviceConnectionInfo.connectedAssigned * 100) /
            state.devicesList.data.length,
        ).toFixed(0)
      : 0,
    noConsentPatientsWithAssignedDevices:
      state.tenantDeviceConnectionInfo.noConsent,
  })),

  selectMTMDeviceStatistics: createSelector(getState, state => {
    const { connectionInfo } = state.metadata;

    const mtmDeviceStats = R.reduceWithIndex(Ord.trivial)(
      {
        connected: {
          assigned: 0,
          unassigned: 0,
        },
        disconnected: {
          assigned: 0,
          unassigned: 0,
        },
        noConsent: 0,
      },
      (_tenantId: UUID, acc, info: TenantConnectionInfo) => ({
        connected: {
          assigned: acc.connected.assigned + info.connectedAssigned,
          unassigned: acc.connected.unassigned + info.connectedUnassigned,
        },
        disconnected: {
          assigned: acc.disconnected.assigned + info.disconnectedAssigned,
          unassigned: acc.disconnected.unassigned + info.disconnectedUnassigned,
        },
        noConsent: acc.noConsent + info.noConsent,
      }),
    )(connectionInfo);
    const totalDevices =
      mtmDeviceStats.connected.assigned +
      mtmDeviceStats.connected.unassigned +
      mtmDeviceStats.disconnected.assigned +
      mtmDeviceStats.noConsent +
      mtmDeviceStats.disconnected.unassigned;

    return {
      totalDevices,
      connectedAssignedDevices: mtmDeviceStats.connected.assigned,
      connectedUnassignedDevices: mtmDeviceStats.connected.unassigned,
      disconnectedAssignedDevices: mtmDeviceStats.disconnected.assigned,
      disconnectedUnassignedDevices: mtmDeviceStats.disconnected.unassigned,
      currentUtilization: totalDevices
        ? Number(
            (mtmDeviceStats.connected.assigned * 100) / totalDevices,
          ).toFixed(0)
        : 0,
      noConsent: mtmDeviceStats.noConsent,
    };
  }),
  selectSubtenantDeviceStatistics: createSelector(
    getState,
    getTenantId,
    (state, tenantId) => {
      const tenantConnectionInfo = state.metadata.connectionInfo[tenantId];
      if (!tenantConnectionInfo) {
        return {
          totalDevices: 0,
          connectedAssignedDevices: 0,
          connectedUnassignedDevices: 0,
          disconnectedAssignedDevices: 0,
          disconnectedUnassignedDevices: 0,
          noConsent: 0,
        };
      }
      return {
        totalDevices:
          tenantConnectionInfo.connectedAssigned +
          tenantConnectionInfo.connectedUnassigned +
          tenantConnectionInfo.disconnectedAssigned +
          tenantConnectionInfo.noConsent +
          tenantConnectionInfo.disconnectedUnassigned,
        connectedAssignedDevices: tenantConnectionInfo.connectedAssigned,
        connectedUnassignedDevices: tenantConnectionInfo.connectedUnassigned,
        disconnectedAssignedDevices: tenantConnectionInfo.disconnectedAssigned,
        disconnectedUnassignedDevices:
          tenantConnectionInfo.disconnectedUnassigned,
        noConsent: tenantConnectionInfo.noConsent,
      };
    },
  ),
  getResetContinousStatus: createSelector(
    getState,
    state => state.resetCountinousStatus,
  ),
  selectLocationMountOptions: createSelector(
    getState,
    state => state.locationMountOptions.data,
  ),
  selectLocationMountOptionsStatus: createSelector(
    getState,
    state => state.locationMountOptions.status,
  ),
  selectFwVersionCounters: createSelector(
    getState,
    state => state.fwVersionCounters.data,
  ),
  selectTenantDeviceLocationData: createSelector(
    getState,
    state => state.tenantDeviceLocation.data,
  ),
  selectFwVersionCountersStatus: createSelector(
    getState,
    state => state.fwVersionCounters.status,
  ),
  selectSpecificDeviceDataStatus: createSelector(
    getState,
    state => state.specificDeviceData.status,
  ),
  selectSpecificDeviceData: createSelector(
    getState,
    state => state.specificDeviceData.data,
  ),
};

export type getAdminDevicesListParams = {
  page: number;
  limit: number;
  viewMode?: string;
  searchKeyword?: string;
  filters?: Record<string, string | Date>;
  sortBy?: TableParamsType['sortBy'];
};

const extraActions = {
  getUserDevicesList: createAction<getAdminDevicesListParams>(
    `${STATE_KEY}/getUserDevicesList`,
  ),
  getDevicesList: createAction(`${STATE_KEY}/getDevicesList`),
  getAdminDevicesList: createAction<getAdminDevicesListParams>(
    `${STATE_KEY}/getAdminDevicesList`,
  ),
  searchAllDevicesAdmin: createAction<
    PaginationParams & { searchKeyword: string }
  >(`${STATE_KEY}/searchAllDevicesAdmin`),
  deleteDevice: createAction<{
    manufacturerId: SerialNumber;
    certificateExists: boolean;
  }>(`${STATE_KEY}/deleteDevice`),
  editDevice: createAction<{
    manufacturerId: SerialNumber;
    name: string;
    room: SingleSelectItem & { id: UUID };
    bed: SingleSelectItem;
    tenantId: UUID;
  }>(`${STATE_KEY}/editDevice`),
  createDevice: createAction<{
    manufacturerId: SerialNumber;
    name: string;
    tenantId: UUID;
    room: SingleSelectItem & { id: UUID };
    bed: SingleSelectItem;
  }>(`${STATE_KEY}/createDevice`),
  assignDevice: createAction<{
    manufacturerId: SerialNumber;
    tenantSelect: UUID;
  }>(`${STATE_KEY}/assignDevice`),
  detachDevice: createAction<SerialNumber>(`${STATE_KEY}/detachDevice`),
  fetchMTMDeviceConnectionInfo: createAction<number>(
    `${STATE_KEY}/fetchMTMDeviceConnectionInfo`,
  ),
  fetchDeviceConnectionForCurrentTenant: createAction(
    `${STATE_KEY}/fetchDeviceConnectionForCurrentTenant`,
  ),
  updateDeviceConnectionInfo: createAction(
    `${STATE_KEY}/updateDeviceConnectionInfo`,
  ),
  resetContinuous: createAction<UUID>(`${STATE_KEY}/resetContinuous`),
  getLocationMountOptions: createAction(`${STATE_KEY}/getLocationMountOptions`),
  getSpecificDeviceData: createAction<UUID>(
    `${STATE_KEY}/getSpecificDeviceData`,
  ),
  getTenantLocationInfo: createAction<UUID>(
    `${STATE_KEY}/getTenantLocationInfo`,
  ),
  getFwCounters: createAction<string | undefined>(`${STATE_KEY}/getFwCounters`),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
