export const CUSTOMER_ACCESS_INITIAL_VALUES = {
  customerId: '',
  ipAddresses: [],
  throughput: '',
  enabled: false,
};

export const CUSTOMER_INITIAL_VALUES = {
  name: '',
  contactId: '',
  address: '',
  contactName: '',
  contactEmail: '',
  regionalAccess: [],
};
