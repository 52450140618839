import React from 'react';
import { styled } from 'styled-components';
import { CheckCircleOutlined } from '@ant-design/icons';

import { defineMessages } from 'react-intl';
import { SortOrder } from 'src/types/utility';
import { sortWithEmptyString } from 'src/utils/sorters/utils';

import BackendService from 'src/services/BackendService';
import dayjs from 'dayjs';
import { MEDICAL_REPORT_DATE_FORMAT } from 'src/utils/timeUtils';

import { initFileDownloadUsingAnchorElement } from 'src/utils/fileUtils';
import {
  actionSuccessNotification,
  notifyUserByActionTypeAndCode,
} from 'src/utils//errorHandling/notifications';
import { fileActions } from 'src/utils/constants';

export const StyledCheckmark = styled(CheckCircleOutlined)`
  color: ${props => props.theme.colors.activeUserColor};
`;

export const getTenantAddress = record =>
  [...Object.values(record.address || {}).filter(value => !!value)].join(', ');

export const getTenantColumns = (intl, messages, currentCustomer) => [
  {
    title: intl.formatMessage(messages.name),
    dataIndex: 'name',
    key: 'name',
    filtered: true,
    onFilter: (value, record) =>
      record.name.toLowerCase().includes(value.toLowerCase()),
    sorter: true,
    defaultSortOrder: 'ascend',
  },
  !currentCustomer
    ? {
        ...{
          title: intl.formatMessage(messages.customerLabel),
          dataIndex: 'customerName',
          key: 'customerName',
          sorter: true,
        },
      }
    : null,
  {
    title: intl.formatMessage(messages.operatorLabel),
    dataIndex: 'operatorName',
    key: 'operatorName',
    sorter: (a, b) =>
      currentCustomer
        ? sortWithEmptyString(a.operatorName, b.operatorName)
        : true,
  },
  {
    title: intl.formatMessage(messages.tenantTypeLabel),
    dataIndex: 'type',
    key: 'type',
    render: (_, record) => {
      return record?.group
        ? intl.formatMessage(messages.groupManagerLabel)
        : intl.formatMessage(messages.name);
    },
  },
  {
    title: intl.formatMessage(messages.address),
    dataIndex: 'address',
    key: 'address',

    render: (_, record) => {
      const address = getTenantAddress(record);
      return address ? <div>{address}</div> : null;
    },
    sorter: (a, b) =>
      sortWithEmptyString(getTenantAddress(a), getTenantAddress(b)),
  },
  {
    title: intl.formatMessage(messages.devicesLabel),
    dataIndex: 'devicesNo',
    key: 'devicesNo',
    sorter: (a, b) => a.devicesNo - b.devicesNo,
  },
  {
    title: intl.formatMessage(messages.connectedLabel),
    dataIndex: 'connectedDevicesNo',
    key: 'connectedDevicesNo',
    sorter: (a, b) => a.connectedDevicesNo - b.connectedDevicesNo,
  },
  {
    title: intl.formatMessage(messages.monitoringLabel),
    dataIndex: 'monitoringDevicesNo',
    key: 'monitoringDevicesNo',
    sorter: (a, b) => a.monitoringDevicesNo - b.monitoringDevicesNo,
  },
];

export const getCurrentOrder = sorter => {
  if (sorter.order === 'descend') {
    return SortOrder.desc;
  }
  if (sorter.order === 'ascend') {
    return SortOrder.asc;
  }
  return '';
};
export const getTenantTableactions = (
  intl,
  messages,
  excludedFromDeleteTenants,
  setIsConfirmationModalVisible,
  setActiveActionRow,
  setIsEditModalVisible,
  setModalMode,
) => [
  {
    key: 'delete',
    name: intl.formatMessage(messages.delete),
    isDisabled: ({ record }) => excludedFromDeleteTenants.includes(record.id),
    onClick: (_, { record }) => {
      setIsConfirmationModalVisible(true);
      setActiveActionRow(record);
    },
  },
  {
    key: 'edit',
    name: intl.formatMessage(messages.edit),
    onClick: (_, { record }) => {
      setActiveActionRow(record);
      setIsEditModalVisible(true);
      setModalMode(tenantModalModes.EDIT);
    },
  },
];
export const TENANT_TYPES = {
  GROUP: 'group',
  TENANT: 'parentTenantId',
};
export const getTenantTypeOptions = intl => [
  {
    id: TENANT_TYPES.TENANT,
    label: intl.formatMessage(messages.tenantLabel),
    // value: TENANT_TYPES.TENANT,
    value: false,
  },
  {
    id: TENANT_TYPES.GROUP,
    label: intl.formatMessage(messages.groupManagerLabel),
    // value: TENANT_TYPES.GROUP,
    value: true,
  },
];

const messages = defineMessages({
  tenantLabel: {
    defaultMessage: 'Tenant',
  },
  groupManagerLabel: {
    defaultMessage: 'Multi-Tenant Manager',
  },
});

export const tenantModalModes = {
  ADD: 'ADD',
  EDIT: 'EDIT',
};

export const exportCsvData = (tableParams, setIsCsvLoading) => {
  if (!tableParams) {
    return;
  }
  setIsCsvLoading(true);
  const { searchKeyword = '', sortBy, filters } = tableParams;

  const downloadedFileName = `Tenants_${dayjs().format(MEDICAL_REPORT_DATE_FORMAT)}.csv`;

  BackendService.exportCsvAdminTenants({
    paginationParams: { page: 0, limit: 100000 },
    searchKeyword,
    filters,
    sortBy,
  })
    .then(response => {
      const type = response.headers['content-type'];
      const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });

      initFileDownloadUsingAnchorElement(
        URL.createObjectURL(blob),
        downloadedFileName,
      );

      setIsCsvLoading(false);
      actionSuccessNotification(fileActions.DOWNLOAD, downloadedFileName);
    })
    .catch(err => {
      notifyUserByActionTypeAndCode(
        fileActions.DOWNLOAD,
        downloadedFileName,
        err,
      );
      setIsCsvLoading(false);
      console.log(err);
    });
};
