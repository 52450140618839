import React, { Dispatch, SetStateAction } from 'react';
import { IntlShape } from 'react-intl';
import { ColumnsType } from 'antd/lib/table';

import { operatorsTableMessages, operatorsTableActions } from './constants';
import {
  OperatorDeviceCountType,
  OperatorModalType,
  OperatorType,
} from '../../modules/types';
import { TableActionsConfig } from 'src/types/table';
import { MenuProps } from 'antd';
import { PropsFromRedux } from './Connector';
import { UUID } from 'src/types/utility';

export const items: MenuProps['items'] = [
  { key: '1', label: 'Tenant 1' },
  { key: '2', label: 'Tenant 2' },
];

export const getOperatorsColumns = (
  intl: IntlShape,
  deviceCount: OperatorDeviceCountType[],
): ColumnsType<OperatorType> => [
  {
    title: intl.formatMessage(operatorsTableMessages.operatorName),
    dataIndex: 'name',
    key: 'operatorName',
  },
  {
    title: intl.formatMessage(operatorsTableMessages.organizationId),
    dataIndex: 'organizationId',
    key: 'organizationId',
    render: (_, record) =>
      record?.emrMappings?.map(emr => {
        return (
          <p style={{ margin: 0 }} key={emr?.organizationId}>
            {emr.organizationId}
          </p>
        );
      }),
  },
  {
    title: intl.formatMessage(operatorsTableMessages.emr),
    dataIndex: 'integratedEMR',
    key: 'integratedEMR',
    render: (_, record) =>
      record?.emrMappings?.map(emr => {
        return (
          <p style={{ margin: 0 }} key={emr?.emrType}>
            {emr.emrType}
          </p>
        );
      }),
  },
  {
    title: intl.formatMessage(operatorsTableMessages.integrationType),
    dataIndex: 'integrationType',
    key: 'integrationType',
    render: (_, record) =>
      record?.emrMappings?.map(emr => {
        return (
          <p style={{ margin: 0 }} key={emr.emrType}>
            Full
          </p>
        );
      }),
  },
  {
    title: intl.formatMessage(operatorsTableMessages.numberOfBuidlings),
    dataIndex: 'buildingsCount',
    key: 'buildingsCount',
    render: (_, record) => record.tenantsCount,
  },
  {
    title: intl.formatMessage(operatorsTableMessages.syncedTenantsCount),
    dataIndex: 'syncedTenantsCount',
    key: 'syncedTenantsCount',
  },
  {
    title: intl.formatMessage(operatorsTableMessages.numberOfDevices),
    dataIndex: 'devicesCount',
    key: 'devicesCount',
    render: (_, record) =>
      deviceCount?.find(item => item.operatorId === record.id)?.deviceCount ||
      0,
  },
];

export const getTableActions = (
  intl: IntlShape,
  setModalState: Dispatch<SetStateAction<OperatorModalType>>,
  setOperatorForm: PropsFromRedux['setOperatorForm'],
  setCurrentOperator: PropsFromRedux['setCurrentOperator'],
  deleteOperator: PropsFromRedux['deleteOperator'],
  customerId: UUID,
): TableActionsConfig<OperatorType>[] => [
  {
    key: 'delete',
    name: intl.formatMessage(operatorsTableActions.delete),
    onClick: (_, { record }) => {
      deleteOperator({ operatorId: record.id, customerId: customerId });
      setModalState({ mode: '', visible: false });
    },
  },
  {
    key: 'edit',
    name: intl.formatMessage(operatorsTableActions.edit),
    onClick: (_, { record }) => {
      setCurrentOperator(record);
      setOperatorForm({
        name: record.name,
        emrMappings:
          record?.emrMappings &&
          record?.emrMappings.map(emr => ({
            organizationId: emr.organizationId,
            emrType: emr.emrType,
          })),
      });
      setModalState({ mode: 'EDIT', visible: true });
    },
  },
];
