import {
  LegendItem,
  ScriptableScaleContext,
  Tick,
  TooltipItem,
} from 'chart.js';

import hrAlertAbove from 'src/routes/IndividualDashboard/resources/hr-alert-above-marker.svg';
import hrAlertBelow from 'src/routes/IndividualDashboard/resources/hr-alert-below-marker.svg';
import rrAlertAbove from 'src/routes/IndividualDashboard/resources/rr-alert-above-marker.svg';
import rrAlertBelow from 'src/routes/IndividualDashboard/resources/rr-alert-below-marker.svg';
import bedExitAlert from 'src/routes/IndividualDashboard/resources/bed-exit-alert-marker.svg';
import bedExitFrequency from 'src/routes/IndividualDashboard/resources/bed-exit-frequency-marker.svg';
import positionChange from 'src/routes/IndividualDashboard/resources/position-change.svg';
import { POINTS_DATE_FORMAT } from 'src/routes/IndividualDashboard/modules/dashboard/utils';
import { isHourAtCorrectResolution } from 'src/routes/IndividualDashboard/modules/dashboard/time-processors';
import { toDisplayFormat } from 'src/utils/timeUtils';
import { AlertLog } from 'src/types/alerts';
import { MainGraphDataPoint } from 'src/routes/IndividualDashboard/modules/dashboard/types';
import { createImage, parseDateWithFormat } from './utils';
import {
  AlertsDataSet,
  GraphOptionsProps,
  HorizontalLineData,
  HorizontalLineDataSet,
  SupportedMetrics,
  SupportedPrepositions,
} from './types';
import { isMedicalAlertLog } from 'src/redux/data/alerts/modules/typeGuards';
import {
  ALERT_METRIC_ENUM,
  ALERT_METRIC_PREPOSITION_ENUM,
} from 'src/redux/data/constants';

const mapMetricAndPrepositionToAlertImage = (
  metric: SupportedMetrics,
  preposition: SupportedPrepositions | null,
): string => {
  if (!preposition) {
    switch (metric) {
      case ALERT_METRIC_ENUM.LONG_OUT_OF_BED:
        return bedExitAlert;
      case ALERT_METRIC_ENUM.BED_EXIT_FREQUENCY:
        return bedExitFrequency;
      case ALERT_METRIC_ENUM.POSITION_CHANGE:
        return positionChange;
    }
    return bedExitAlert;
  }

  switch (metric) {
    case ALERT_METRIC_ENUM.BED_EXIT:
      return bedExitAlert;
    case ALERT_METRIC_ENUM.HR:
      return preposition === ALERT_METRIC_PREPOSITION_ENUM.ABOVE
        ? hrAlertAbove
        : hrAlertBelow;
    case ALERT_METRIC_ENUM.RR:
      return preposition === ALERT_METRIC_PREPOSITION_ENUM.ABOVE
        ? rrAlertAbove
        : rrAlertBelow;
    case ALERT_METRIC_ENUM.HR_BASELINE:
      return preposition === ALERT_METRIC_PREPOSITION_ENUM.ABOVE
        ? hrAlertAbove
        : hrAlertBelow;
    case ALERT_METRIC_ENUM.RR_BASELINE:
      return preposition === ALERT_METRIC_PREPOSITION_ENUM.ABOVE
        ? rrAlertAbove
        : rrAlertBelow;
    default:
      return '';
  }
};

export const getHorizontalLineData = (
  threshold: number,
  startTime: string,
  endTime: string,
): HorizontalLineData => [
  {
    x: startTime,
    y: threshold,
  },
  {
    x: endTime,
    y: threshold,
  },
];

export const getHorizontalLineDataset = ({
  threshold,
  startTime,
  endTime,
  label,
  color,
  yAxisID,
  isDashed,
  hidden = false,
}: {
  threshold: number | null;
  startTime: string;
  endTime: string;
  label: string;
  color: string;
  yAxisID: string;
  isDashed?: boolean;
  hidden?: boolean;
}): HorizontalLineDataSet | null =>
  threshold
    ? {
        label,
        data: getHorizontalLineData(threshold, startTime, endTime),
        borderColor: color,
        backgroundColor: color,
        pointRadius: 3,
        yAxisID,
        ...(isDashed ? { borderDash: [5, 5] } : {}),
        hidden,
      }
    : null;

const getAlertMarkerLineData = (alerts: AlertLog[]): MainGraphDataPoint[] =>
  alerts.map(alert => ({
    x: toDisplayFormat(alert.startTime, POINTS_DATE_FORMAT),
    y: 160,
  }));

const getAlertMarkerImages = (alerts: AlertLog[]): HTMLImageElement[] =>
  alerts
    .filter(alert => !!alert.thresholdMetric)
    .filter(isMedicalAlertLog)
    .map(alert =>
      createImage(
        mapMetricAndPrepositionToAlertImage(
          alert.thresholdMetric,
          alert.thresholdPreposition,
        ),
      ),
    );

export const getAlertMarkerDataset = (alerts: AlertLog[]): AlertsDataSet => ({
  yAxisID: 'yLeft',
  pointStyle: getAlertMarkerImages(alerts),
  data: getAlertMarkerLineData(alerts),
});

// for 24h time interval X axis label resolution should be 1 hour
// for 72h time interval X axis label resolution should be 3 hours
// for 7 days time interval X axis label resolution should be 6 hours + day marks
// for custom date interval X axis label resolution should be 24h
export const getGraphOptions = ({
  startTime,
  endTime,
  stepSize,
  unit,
  thresholds,
  messages,
  intl,
  isExtraLarge,
}: GraphOptionsProps) => ({
  responsive: true,
  maintainAspectRatio: false,

  interaction: {
    mode: 'point',
  },
  stacked: true,
  layout: {
    padding: {
      bottom: isExtraLarge ? -15 : -20,
    },
  },
  plugins: {
    legend: {
      position: 'bottom',
      align: 'start',
      labels: {
        filter: function (legendItem: LegendItem) {
          return legendItem.datasetIndex !== 0;
        },
        usePointStyle: true,
        pointStyle: 'rectRounded',
        font: {
          size: isExtraLarge ? 12 : 6,
        },
      },
    },
    tooltip: {
      filter: function (tooltipItem: TooltipItem<'line'>) {
        return tooltipItem.datasetIndex !== 0;
      },
      callbacks: {
        label: ({ dataset, formattedValue }: TooltipItem<'bar'>) => {
          const label = dataset.label ?? '';
          if (
            // @ts-ignore Fix later
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            label === intl.formatMessage(messages.motionIndexLegend)
          ) {
            return `${label}: ${Number(parseFloat(formattedValue) / 3.8).toFixed(2)}`;
          }
          if (
            // @ts-ignore Fix later
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            label === intl.formatMessage(messages.postureChangeLegend)
          ) {
            return label;
          }
          return `${label}: ${formattedValue}`;
        },
      },
    },
  },
  scales: {
    x: {
      type: 'time',
      min: startTime,
      max: endTime,
      grid: {
        display: false,
      },
      time: {
        parser: POINTS_DATE_FORMAT,
        displayFormats: {
          hour: 'H:mm',
        },
        ...(stepSize === 1 ? { stepSize } : {}),
        unit,
      },
      ticks: {
        font: {
          weight: (item: ScriptableScaleContext) => {
            if (item.tick?.label?.includes(':')) {
              return 400;
            } else return 700;
          },
          size: isExtraLarge ? 10 : 6,
        },
        callback: function (
          tickValue: number | string,
          index: number,
          ticks: Tick[],
        ): string | number | null | undefined {
          if (stepSize === 3) {
            const date = parseDateWithFormat(tickValue, 'H:mm');
            if (!isHourAtCorrectResolution(date, 3)) {
              return null;
            }

            if (date.hour() === 0) {
              const date = parseDateWithFormat(ticks[index]?.value || '');
              return date.format('MMM DD');
            }

            return tickValue;
          }

          if (stepSize === 6) {
            const date = parseDateWithFormat(tickValue, 'H:mm');
            if (!isHourAtCorrectResolution(date, 6)) {
              return null;
            }

            if (date.hour() === 0) {
              const date = parseDateWithFormat(ticks[index]?.value || '');
              return date.format('MMM DD');
            }

            return tickValue;
          }

          return tickValue;
        },
      },
    },
    yLeft: {
      type: 'linear',
      position: 'left',
      grid: {
        display: true,
        drawBorder: false,
        drawOnChartArea: true,
        drawTicks: true,
      },
      beginAtZero: true,
      min: 0,
      max: 170, // Set to 170 instead of 160 in order to add top offset to display full alert images
      ticks: {
        stepSize: 1, // Set to 1 in order to catch every unit inside the callback
        includeBounds: false,
        autoSkip: false,
        callback: function (
          tickValue: number,
        ): string | number | null | undefined {
          // Added extra conditions to hide tick line when dataset is hidden
          if (
            tickValue % 40 !== 0 &&
            tickValue === thresholds.hr.above &&
            // @ts-ignore Fix later
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            this.chart._metasets.find(
              // @ts-ignore Fix later
              metaset =>
                // @ts-ignore Fix later
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                metaset.label === intl.formatMessage(messages.hrThresholdAbove),
            )?.hidden
          ) {
            return null;
          }

          if (
            tickValue % 40 !== 0 &&
            tickValue === thresholds.hr.below &&
            // @ts-ignore Fix later
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            this.chart._metasets.find(
              // @ts-ignore Fix later
              metaset =>
                // @ts-ignore Fix later
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                metaset.label === intl.formatMessage(messages.hrThresholdBelow),
            )?.hidden
          ) {
            return null;
          }

          return tickValue % 40 === 0 ||
            tickValue === thresholds.hr.above ||
            tickValue === thresholds.hr.below
            ? tickValue
            : null;
        },
        font: {
          size: isExtraLarge ? 10 : 6,
        },
      },
    },
    yRight: {
      type: 'linear',
      position: 'right',
      grid: {
        display: true,
        drawBorder: false,
        drawOnChartArea: true,
        drawTicks: true,
      },
      beginAtZero: true,
      min: 0,
      max: 42.5, // Set to 42.5 instead of 40 in order to add top offset to display full alert images
      ticks: {
        stepSize: 1, // Set to 1 in order to catch every unit inside the callback
        autoSkip: false,
        includeBounds: false,
        major: {
          enabled: true,
        },
        callback: function (
          tickValue: number,
        ): string | number | null | undefined {
          // Added extra conditions to hide tick line when dataset is hidden
          if (
            tickValue % 10 !== 0 &&
            tickValue === thresholds.rr.above &&
            // @ts-ignore Fix later
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            this.chart._metasets.find(
              // @ts-ignore Fix later
              metaset =>
                // @ts-ignore Fix later
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                metaset.label === intl.formatMessage(messages.rrThresholdAbove),
            )?.hidden
          ) {
            return null;
          }

          if (
            tickValue % 10 !== 0 &&
            tickValue === thresholds.rr.below &&
            // @ts-ignore Fix later
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            this.chart._metasets.find(
              // @ts-ignore Fix later
              metaset =>
                // @ts-ignore Fix later
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                metaset.label === intl.formatMessage(messages.rrThresholdBelow),
            )?.hidden
          ) {
            return null;
          }

          return tickValue % 10 === 0 ||
            tickValue === thresholds.rr.above ||
            tickValue === thresholds.rr.below
            ? tickValue
            : null;
        },
        font: {
          size: isExtraLarge ? 10 : 6,
        },
      },
    },
  },
});
