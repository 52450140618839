import { styled } from 'styled-components';

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
`;
export const DeviceHeader = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: ${props => props.theme.colors.successText};
`;
export const StyledModalBody = styled.div`
  position: relative;
`;
