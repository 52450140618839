import React from 'react';
import MedicalReportsWrapper from 'src/routes/MedicalReports/components/MedicalReportsWrapper';
import MedicalReports from 'src/routes/MedicalReports';

const MedicalReportsV1Wrapper = (): JSX.Element => (
  <MedicalReportsWrapper>
    <MedicalReports />
  </MedicalReportsWrapper>
);

export default MedicalReportsV1Wrapper;
