import React from 'react';
import { UUID } from 'src/types/utility';
import dayjs from 'dayjs';
import { MONTH_DAY_YEAR_FORMAT } from 'src/utils/timeUtils';
import { TenantsHistoryContainer, EachRow } from './styled';

const TenantsHistoryCell = ({
  tenantsHistory,
}: {
  tenantsHistory: {
    id: UUID;
    attachedTime: string;
    tenantName: string;
  }[];
}) => {
  return (
    !!tenantsHistory?.length &&
    tenantsHistory.map(({ tenantName = '-', attachedTime, id }) => {
      const historyDate = attachedTime
        ? dayjs(attachedTime).format(MONTH_DAY_YEAR_FORMAT)
        : '-';
      return (
        <TenantsHistoryContainer key={id}>
          <EachRow>{`${historyDate} ${tenantName}`} </EachRow>
        </TenantsHistoryContainer>
      );
    })
  );
};

export default TenantsHistoryCell;
