import { all, call, put, takeLatest } from 'typed-redux-saga/macro';

import { actions } from './slice';
import { actions as customerActions } from 'src/redux/data/customers';
import { DATA_FETCHING_STATUS } from '../../constants';
import BackendService from 'src/services/BackendService';
import { notifyUserByActionTypeAndCode } from 'src/utils/errorHandling/notifications';

function* getAllSdkCustomersActivity(
  action: ReturnType<typeof actions.getAllSdkCustomersActivity>,
) {
  try {
    yield* put(
      actions.setSdkCustomersActivityStatus(DATA_FETCHING_STATUS.LOADING),
    );
    const { data } = yield* call(BackendService.getAllSdkCustomersActivity);

    yield* put(customerActions.searchCustomers());

    yield* put(
      actions.setSdkCustomersActivity({
        data: data?.customerActivityList,
        status: DATA_FETCHING_STATUS.SUCCESS,
      }),
    );
  } catch (e) {
    console.error('error in getAllSdkCustomersActivity: ', e);
    notifyUserByActionTypeAndCode(
      action.type,
      'Could not get Customers SDK activity',
      e,
    );
    yield* put(
      actions.setSdkCustomersActivity({
        data: [],
        status: DATA_FETCHING_STATUS.ERROR,
      }),
    );
  }
}

export default function* watchCusomterActions() {
  yield* all([
    takeLatest(actions.getAllSdkCustomersActivity, getAllSdkCustomersActivity),
  ]);
}
