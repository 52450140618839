import { MeasurementsState, SpotMeasurementsState } from './measurements';
import { DateUTC, SerialNumber, UUID } from './utility';

export type DeviceConnectionState = {
  deviceId: SerialNumber;
  connectionStatus: 'connected' | 'disconnected';
  timestamp: number;
  currentTime: DateUTC;
};

export type DeviceCredentials = {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
  expiration: DateUTC;
};

export type RawDevice = {
  name: string;
  manufacturerId: SerialNumber;
  deviceId?: SerialNumber;
  tenantId: UUID;
  certificateExists: boolean;
  connectionStatus: {
    connected: boolean;
    lastModifiedTimestamp: number;
    sessionId: UUID | null;
    ipAddress: string | null;
  };
  inf?: {
    deviceId: SerialNumber;
    json: string;
    idx: number;
    patientId: string;
    sessionId: string;
    hwVersion: string;
    fwVersion: string;
    versionId: string;
    applicationId: string;
    serialNumber: SerialNumber;
    state: string;
    wirelessHWVersion: string;
    wirelessVersionId: string;
    ssid: string;
    ipAddress: string;
    macAddress: string;
    id: UUID;
    tenantId: UUID;
    creationTime: DateUTC;
    lastModifiedTime: DateUTC;
  };
  customerId?: string | null;
  operatorId?: string | null;
  operatorName?: string | null;
  customerName?: string | null;
  mountingType?: string | null;
  wiringType?: string | null;
  electricalOutletReplacement?: boolean | null;
  tenantName?: string | null;
  bedName?: string | null;
  roomName?: string | null;
  roomId?: string | null;
  bedId?: string | null;
};

export type RawLocationDevice = {
  bedId: UUID;
  bedName: string;
  roomName: string;
  roomId: UUID;
  tenantName: string;
  tenantId: UUID;
  operatorName: string;
  operatorId: UUID;
  customerName: string;
  customerId: UUID;
  mountingType: null;
  wiringType: null;
  electricalOutletReplacement: boolean;
  notes: string;
  deviceId: UUID;
};

export type ConnectedLocationInfo = Pick<
  RawLocationDevice,
  'bedId' | 'bedName' | 'roomName' | 'roomId' | 'deviceId'
> & { isConnected: boolean };

export type ConnectedDevices = {
  connectedDevices: Array<{
    manufacturerId: '214232400027';
    patientAssignmentStatus: 'ASSIGNED';
  }>;
};

export type DeviceIdWithRoomAndBed = {
  deviceId: SerialNumber;
  roomName?: string;
  bedName?: string;
};

export type DeviceWithRoomAndBed = RawDevice & {
  roomName?: string;
  bedName?: string;
};

export enum DeviceMonitorStatus {
  INACTIVE = 'INACTIVE',
  STARTING = 'STARTING',
  ACTIVE = 'ACTIVE',
  STOPPING = 'STOPPING',
  NON_CONSENTED_RUNNING = 'NON_CONSENTED_RUNNING',
}

export enum DevicePatientAssignment {
  ASSIGNED = 'ASSIGNED',
  UNASSIGNED = 'UNASSIGNED',
}

export type MonitoredDevice = {
  patientId: UUID | null;
  sessionId: UUID | null;
  time: {
    startTime: DateUTC;
    currentTime: DateUTC;
  } | null;
  status: DeviceMonitorStatus;
  continuousData: MeasurementsState;
  spotData: Record<UUID, SpotMeasurementsState>;
  hriData: Record<string, never>;
};

export type DeviceDisconnectionOverview = {
  manufacturerId: SerialNumber;
  lastConnectedAt: DateUTC | null;
  patientAssignmentStatus: DevicePatientAssignment;
};

export type TenantConnectionInfo = {
  connectedAssigned: number;
  connectedUnassigned: number;
  disconnectedAssigned: number;
  disconnectedUnassigned: number;
  currentUtilization: number;
  noConsent: number;
};
export type MTMDeviceConnectioninfo = Record<UUID, TenantConnectionInfo>;

export type TenantAssignedDevices = {
  sessionsByDeviceId: Record<SerialNumber, UUID>;
};
export type MTMAssignedDevices = Record<UUID, TenantAssignedDevices>;

export interface LocationMountOptionsType {
  mountingType: string[];
  wiringType: string[];
  electricalOutletReplacement: boolean[];
}
