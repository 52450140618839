import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BedOccupancyComponent from './BedOccupancyComponent';
const BedOccupancy = ({
  patient,
  getBedOccupancyData,
  hoursInBedData,
  numberOfBedExitAlertsData,
  clearBedOccupancyGraph,
  isLoading,
}) => {
  const patientId = patient?.id;
  useEffect(() => {
    if (patientId) {
      getBedOccupancyData({ patientId });
    }
    return () => {
      clearBedOccupancyGraph();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient.id]);
  return (
    <BedOccupancyComponent
      isLoading={isLoading}
      patientId={patientId}
      hoursInBedData={hoursInBedData}
      numberOfBedExitAlertsData={numberOfBedExitAlertsData}
    />
  );
};
BedOccupancy.propTypes = {
  getBedOccupancyData: PropTypes.func.isRequired,
  patient: PropTypes.object,
  hoursInBedData: PropTypes.array.isRequired,
  numberOfBedExitAlertsData: PropTypes.array.isRequired,
  clearBedOccupancyGraph: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

BedOccupancy.defaultProps = {
  patient: {},
};

export default BedOccupancy;
