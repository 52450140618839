import { all, call, put, select, takeLatest } from 'typed-redux-saga/macro';

import { actions } from './slice';
import { DATA_FETCHING_STATUS } from '../../constants';
import BackendService from 'src/services/BackendService';
import { notifyUserByActionTypeAndCode } from 'src/utils/errorHandling/notifications';
import { getState as getOperatorsState } from 'src/redux/data/operators/modules/slice';

function* getFacilitiesList(action: ReturnType<typeof actions.getFacilities>) {
  const { currentOperator } = yield* select(getOperatorsState);

  const operatorId = currentOperator?.id || '';
  const emrMappings =
    (currentOperator?.emrMappings &&
      currentOperator?.emrMappings[0]?.emrType) ||
    '';

  try {
    yield* put(actions.setFacilitiesStatus(DATA_FETCHING_STATUS.LOADING));

    const { data } = yield* call(
      BackendService.getFacilitiesByEmrType,
      operatorId,
      emrMappings,
    );

    yield* put(
      actions.setFacilitiesList({
        data: data.pccFacilities,
        status: DATA_FETCHING_STATUS.SUCCESS,
      }),
    );
  } catch (e) {
    console.error('error in fetchFacilities: ', e);
    yield* put(
      actions.setFacilitiesList({
        data: null,
        status: DATA_FETCHING_STATUS.ERROR,
      }),
    );
    notifyUserByActionTypeAndCode(action.type, e, e);
  }
}

function* getOperatorTenants() {
  const { currentOperator } = yield* select(getOperatorsState);

  const operatorId = currentOperator?.id || '';

  try {
    yield* put(actions.setOperatorTenantsStatus(DATA_FETCHING_STATUS.LOADING));
    const {
      data: { tenantNarrowResponseV2s },
    } = yield* call(BackendService.getTenantsByOperatorId, operatorId);

    yield* put(
      actions.setOperatorTenantsList({
        //@ts-ignore testing
        data: tenantNarrowResponseV2s,
        status: DATA_FETCHING_STATUS.SUCCESS,
      }),
    );
  } catch (e) {
    console.error('error in fetchOperatorTenants: ', e);
    yield* put(
      actions.setOperatorTenantsList({
        data: null,
        status: DATA_FETCHING_STATUS.ERROR,
      }),
    );
    notifyUserByActionTypeAndCode();
  }
}

function* mapFacilities(action: ReturnType<typeof actions.mapFacilities>) {
  const data = action.payload;

  try {
    yield* put(actions.setFacilityFormStatus(DATA_FETCHING_STATUS.LOADING));
    //@ts-ignore: TODO: add type when flow is tested
    yield* call(BackendService.mapEmrFacilities(data));
    yield* put(actions.setFacilityFormStatus(DATA_FETCHING_STATUS.SUCCESS));
  } catch (e) {
    console.error('error in mappingFacilities: ', e);
    yield* put(
      actions.setOperatorTenantsList({
        //@ts-ignore testing
        data: null,
        status: DATA_FETCHING_STATUS.ERROR,
      }),
    );
    notifyUserByActionTypeAndCode();
  }
}

export default function* watchEmrMappingsActions() {
  yield* all([
    takeLatest(actions.getFacilities, getFacilitiesList),
    takeLatest(actions.getOperatorTenants, getOperatorTenants),
    takeLatest(actions.mapFacilities, mapFacilities),
  ]);
}
