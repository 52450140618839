import React, { useEffect } from 'react';
import Connector, { PropsFromRedux } from './Connector';
import MedicalConditions from './index';
import { API_STATUS } from 'src/utils/api-constants';

const MedicalConditionsContainer = ({
  getPatientMedicalConditions,
  patient,
  medicalConditions,
  medicalConditionsStatus,
}: PropsFromRedux): JSX.Element => {
  useEffect(() => {
    getPatientMedicalConditions({ patientId: patient?.id });
  }, [getPatientMedicalConditions, patient?.id]);

  return (
    <MedicalConditions
      isLoading={medicalConditionsStatus === API_STATUS.LOADING}
      medicalConditions={medicalConditions}
    />
  );
};

export default Connector(MedicalConditionsContainer);
