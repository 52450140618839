import { ListUserType, PendingUser, User } from 'src/types/users';
import {
  USER_STATUS,
  userFormFields,
} from 'src/components/UserCrudComponents/constants';
import {
  expandArrayItemsToHaveFullName,
  convertAllEmptyToUndefined,
  convertAllEmptyToNull,
} from 'src/utils/parser';
import { ActiveUserWithStatus, PendingUserWithStatus } from './types';

export const parseFormDataToInviteUserRequest = (
  formData: Record<string, unknown>,
) => {
  const formDataToRequestStructure = {
    email: formData[userFormFields.EMAIL],
    firstName: formData[userFormFields.FIRST_NAME],
    lastName: formData[userFormFields.LAST_NAME],
    userTypeId: formData[userFormFields.USER_TYPE],
    phone: formData[userFormFields.PHONE],
    invitationType: 'EMAIL',
    customerId: formData[userFormFields.CUSTOMER_ID],
    tenantId: formData[userFormFields.TENANT_ID],
    selectedSubGroups: formData[userFormFields.SDK_USER_PERMISSIONS],
  };
  return convertAllEmptyToUndefined(formDataToRequestStructure);
};

export const parseFormDataToUpdateUserRequest = (
  formData: Record<string, unknown>,
) => {
  const formDataToRequestStructure = {
    phone: formData[userFormFields.PHONE],
    email: formData[userFormFields.EMAIL],
    firstName: formData[userFormFields.FIRST_NAME],
    lastName: formData[userFormFields.LAST_NAME],
    customerId: formData[userFormFields.CUSTOMER_ID],
    tenantId: formData[userFormFields.TENANT_ID],
    selectedSubGroups: formData[userFormFields.SDK_USER_PERMISSIONS],
  };
  return convertAllEmptyToUndefined(formDataToRequestStructure);
};
export const parseFormDataToUpdateUserMfaRequest = (
  formData: Record<string, unknown>,
) => {
  const formDataToRequestStructure = {
    mfa: formData[userFormFields.MFA],
    mfaPhone: formData[userFormFields.MFA_PHONE],
  };
  return convertAllEmptyToNull(formDataToRequestStructure);
};

// FIXME: This is a workaround until we understand how we want to get only the users and user types needed
const notSupportedUserTypeIds = [
  '392234c3-792b-4e5d-b2b3-7dfe25b25288',
  '3bacf400-ad4b-4707-b32f-2aac5a802645',
  '764baf07-27fc-4bdf-84eb-99be5f3433da',
  'c2b00614-c5e5-4a42-b96b-bbae057055a5',
];

export const parseUserTypesResponse = (responseData: ListUserType[]) =>
  responseData?.filter(
    userTypeObj => !notSupportedUserTypeIds.includes(userTypeObj?.id),
  );

export const parseAllUsersResponse = (
  responseData: User[],
): ActiveUserWithStatus[] => {
  const supportedUsers = responseData.filter(
    userObj => !notSupportedUserTypeIds.includes(userObj?.userType?.id),
  );
  const usersArray = expandArrayItemsToHaveFullName(
    supportedUsers,
    userFormFields.FIRST_NAME,
    userFormFields.LAST_NAME,
  );

  return usersArray.map(user => ({
    ...user,
    status: user?.locked ? USER_STATUS.INACTIVE : USER_STATUS.ACTIVE,
  }));
};

export const parseAllPendingUsersResponse = (
  responseData: PendingUser[],
): PendingUserWithStatus[] => {
  const supportedUsers = responseData.filter(
    userObj => !notSupportedUserTypeIds.includes(userObj?.userType?.id),
  );
  const usersArray = expandArrayItemsToHaveFullName(
    supportedUsers,
    userFormFields.FIRST_NAME,
    userFormFields.LAST_NAME,
  );

  return usersArray.map(user => ({ ...user, status: USER_STATUS.PENDING }));
};

export const parseFormDataToUpdateUserPhoneRequest = (
  formData: Record<string, unknown>,
) => {
  const formDataToRequestStructure = {
    phone: formData[userFormFields.PHONE],
  };
  return convertAllEmptyToNull(formDataToRequestStructure);
};
