import { styled } from 'styled-components';

import { Typography } from 'antd';

const { Paragraph } = Typography;

export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.9fr 1.2fr 0.4fr 1fr;
  align-items: start;
  background: #fbfbfb;
  box-shadow: 0px 0px 3px rgba(78, 83, 96, 0.25);
  border-radius: 4px;
  padding: 0.5rem;
  color: #252525;
  gap: 0.2rem;
`;

export const StyledTenantName = styled(Paragraph)`
  font-style: normal;
  font-weight: 700;
  font-size: 0.6rem;
  margin-bottom: 0 !important;
`;

export const RoomDetails = styled.div`
  & .ant-typography {
    font-style: normal;
    font-weight: 500;
    font-size: 0.65rem;
    width: 3.5rem;
    margin: 0;
  }
`;

export const BoldItem = styled.span`
  font-weight: 800;
`;

export const ItemLabel = styled.span`
  font-weight: 400;
  padding-right: 0.2rem;
`;

export const PatientName = styled.div`
  & .ant-typography {
    font-family: ${props => props.theme.fonts.secondary};
    font-style: normal;
    font-weight: 400;
    font-size: 0.55rem;
    color: #767881;
    text-decoration-line: underline;
    cursor: pointer;
    width: 3.5rem;
    margin: 0;
  }
  &.has-consent {
    cursor: pointer;
  }
`;

export const DisconnectionAlertInfo = styled.div`
  grid-column: span 2;
  font-style: normal;
  font-weight: 700;
  font-size: 0.65rem;
`;

export const AlertNotesWrapper = styled.div`
  display: flex;
  grid-column: span 4;
  justify-content: end;
`;
