import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import history from 'src/utils/history';
import { appRoutes } from 'src/routes/Root/modules/routesUtils';
import neteeraLogo from './resources/neteera-logo.svg';
import {
  StyledContent,
  StyledHeader,
  StyledLayout,
  StyledLogo,
  StyledMedicalReportInfo,
} from './components/styled';
import UserMenu from './components/UserMenu';
import NavigationMenu from './components/NavigationMenu';
import Connector, { PropsFromRedux } from './Connector';
import APP_CONFIG from 'src/config/AppConfig';
import { hasGMPermissions } from 'src/utils/permissions';
import SoundSettings from '../SoundSettings';
import PhiRestrictionBadge from '../PhiRestrictionBadge';
import {
  ACCESS_DENIED_PHI_PROTECTION,
  getCustomerMedicalReportLogo,
  MEDICAL_REPORTS_VERSION,
} from 'src/components/AppLayout/utils';

interface Props extends PropsFromRedux {
  children: React.ReactNode;
  intl: IntlShape;
}

const AppLayout = ({
  children,
  loggedInUserName,
  loggedInUserType,
  userTenantName,
  userTenantTimezone,
  medicalReportsSettings,
  isMedicalReportAccessLoading,
  logout,
  onIdle,
  errorSettings,
}: Props): JSX.Element => {
  const isMedicalReportAccessPhiRestricted =
    errorSettings === ACCESS_DENIED_PHI_PROTECTION;
  const isMedicalReportRoute = window.location.pathname.includes(
    appRoutes.MEDICAL_REPORTS,
  );
  const medicalReportVersion = window.location.pathname.includes(
    MEDICAL_REPORTS_VERSION.V1,
  )
    ? MEDICAL_REPORTS_VERSION.V1
    : MEDICAL_REPORTS_VERSION.V2;
  const handleOnIdle = (event?: Event) => {
    if (
      hasGMPermissions() ||
      history.location.pathname.includes(appRoutes.NURSES_STATION)
    ) {
      return;
    }

    // eslint-disable-next-line no-console
    console.log('user is idle', event);
    logout();
    onIdle(true);
  };

  useIdleTimer({
    timeout: APP_CONFIG.SESSION_TIMEOUT_SEC * 1000,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <StyledLayout data-cy="application-content">
      <StyledHeader data-cy="application-header">
        {!isMedicalReportAccessLoading &&
          !isMedicalReportAccessPhiRestricted && (
            <StyledLogo
              src={neteeraLogo}
              alt="Neteera"
              data-cy="neteera-logo"
            />
          )}
        {isMedicalReportRoute &&
          !isMedicalReportAccessLoading &&
          !isMedicalReportAccessPhiRestricted &&
          medicalReportsSettings?.customerId && (
            <StyledLogo
              src={getCustomerMedicalReportLogo(
                medicalReportsSettings.customerId,
              )}
              alt="Neteera"
              data-cy="neteera-logo"
            />
          )}
        {!isMedicalReportAccessLoading &&
          !isMedicalReportAccessPhiRestricted && (
            <NavigationMenu
              className={isMedicalReportRoute ? 'medical-report-nav' : ''}
              loggedInUserType={loggedInUserType}
            />
          )}
        {isMedicalReportRoute &&
          !isMedicalReportAccessLoading &&
          !isMedicalReportAccessPhiRestricted && (
            <StyledMedicalReportInfo>
              <FormattedMessage
                {...messages.reportVersion}
                values={{ version: medicalReportVersion }}
              />
            </StyledMedicalReportInfo>
          )}

        {!isMedicalReportRoute && (
          <>
            <PhiRestrictionBadge loggedInUserType={loggedInUserType} />
            <SoundSettings />
          </>
        )}
        <UserMenu
          className={'user-menu'}
          loggedInUserName={loggedInUserName}
          userTenantName={userTenantName}
          userTenantTimezone={userTenantTimezone}
          logout={logout}
        />
      </StyledHeader>
      <StyledContent>{children}</StyledContent>
    </StyledLayout>
  );
};

const messages = defineMessages({
  reportVersion: {
    defaultMessage: '| Report Version Number {version}',
  },
});

export default Connector(injectIntl(AppLayout));
