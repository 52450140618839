import { RawDevice, RawLocationDevice } from 'src/types/devices';
import { AxiosResponse } from 'axios';
import {
  FetchAdminDevicesResponse,
  FetchAdminLocationsDevicesResponse,
  FetchAdminMonitoringDaysResponse,
} from 'src/services/types';
import { createIntl, defineMessages } from 'react-intl';
import { SingleSelectItem } from 'src/components/general-ui/SelectBoxForm';
import { CustomerType } from 'src/routes/Customers/modules/types';
import { hasAllPermissions } from 'src/utils/permissions';
import permissions from 'src/permissions';

type LocationMountOptionsObject = {
  [key: string]: (string | boolean)[];
};
export type DropdownOptions = Record<string, SingleSelectItem[]>;

export const extractDataFromLocations = (deviceLocation: RawLocationDevice) => {
  const {
    deviceId = '-',
    customerId = '-',
    roomId = '-',
    bedId = '-',
    operatorId = '-',
    operatorName = '-',
    customerName = '-',
    mountingType = '-',
    wiringType = '-',
    electricalOutletReplacement = false,
    tenantName = '-',
    bedName = '-',
    roomName = '-',
    notes = '-',
  } = deviceLocation;
  return {
    deviceId,
    customerId,
    operatorId,
    operatorName,
    customerName,
    mountingType,
    wiringType,
    electricalOutletReplacement,
    tenantName,
    bedName,
    roomName,
    roomId,
    notes,
    bedId,
  };
};

export const getLocationDataForDevices = (
  devicesResponse: AxiosResponse<FetchAdminDevicesResponse>,
  deviceLocationsResponse:
    | AxiosResponse<FetchAdminLocationsDevicesResponse>
    | null
    | undefined,
  monitoringDaysResponse:
    | AxiosResponse<FetchAdminMonitoringDaysResponse>
    | null
    | undefined,
  customerNames: CustomerType[] | null | undefined,
) => {
  return devicesResponse?.data?.data.reduce((acc: RawDevice[], device) => {
    const deviceId = device.manufacturerId;
    const customerId = device.customerId;
    const currentCustomer = customerNames?.find(
      customer => customer.id === customerId,
    );
    if (!deviceId) {
      return [...acc, { ...device, customerName: currentCustomer?.name }];
    }
    const deviceMonitoringDays =
      monitoringDaysResponse?.data?.monitoredDays[deviceId];

    const newDevice = {
      ...device,
      monitoringDays: deviceMonitoringDays
        ? deviceMonitoringDays.monitoredDaysCount
        : 0,
      customerName: currentCustomer?.name,
    };

    if (!deviceLocationsResponse) {
      return [...acc, newDevice];
    }
    const deviceLocation = deviceLocationsResponse?.data?.data.find(
      elem => elem.deviceId === deviceId,
    );

    if (!deviceLocation) {
      return [...acc, newDevice];
    }
    const locationInfo = extractDataFromLocations(deviceLocation);
    return [
      ...acc,
      {
        ...newDevice,
        ...locationInfo,
      },
    ];
  }, []);
};

export const getDevicesForLocations = (
  locationsResponse: AxiosResponse<FetchAdminLocationsDevicesResponse>,
  devicesResponse: AxiosResponse<FetchAdminDevicesResponse> | undefined,
  monitoringDaysResponse:
    | AxiosResponse<FetchAdminMonitoringDaysResponse>
    | undefined,
) => {
  return locationsResponse?.data?.data?.reduce(
    (acc: RawDevice[], deviceLocation) => {
      const deviceId = deviceLocation.deviceId;
      const locationInfo = extractDataFromLocations(deviceLocation);
      if (!deviceId) {
        return [
          ...acc,
          {
            ...locationInfo,
            name: '',
            manufacturerId: '',
            tenantId: '',
            certificateExists: false,
            connectionStatus: {
              connected: false,
              lastModifiedTimestamp: 0,
              sessionId: null,
              ipAddress: null,
            },
          },
        ];
      }
      const deviceData = devicesResponse?.data?.data.find(
        elem => elem.manufacturerId === deviceId,
      );
      const deviceMonitoringDays =
        monitoringDaysResponse?.data?.monitoredDays[deviceId];
      if (!deviceData) {
        return [
          ...acc,
          {
            ...locationInfo,
            name: '',
            manufacturerId: '',
            tenantId: '',
            certificateExists: false,
            connectionStatus: {
              connected: false,
              lastModifiedTimestamp: 0,
              sessionId: null,
              ipAddress: null,
            },
            monitoringDays: deviceMonitoringDays
              ? deviceMonitoringDays.monitoredDaysCount
              : 0,
          },
        ];
      }
      return [
        ...acc,
        {
          ...deviceData,
          ...locationInfo,
          monitoringDays: deviceMonitoringDays
            ? deviceMonitoringDays.monitoredDaysCount
            : 0,
        },
      ];
    },
    [],
  );
};

export const parseMountOptions = (
  mountOptions: LocationMountOptionsObject,
): DropdownOptions => {
  const intl = createIntl({ locale: 'en', messages: {} });
  const options: DropdownOptions = {};

  Object.entries(mountOptions).forEach(([key, values]) => {
    options[key] = values.map((value: string | boolean) => ({
      label:
        typeof value === 'boolean'
          ? value
            ? intl.formatMessage(messages.yes)
            : intl.formatMessage(messages.no)
          : value,
      value,
    }));
  });

  return options;
};

const messages = defineMessages({
  yes: {
    defaultMessage: 'Yes',
  },
  no: {
    defaultMessage: 'No',
  },
});

export const isSysAdmin = () =>
  hasAllPermissions(
    permissions.VIEW_DEVICES_ADD_BUTTON,
    permissions.VIEW_DEVICES_TENANT_ACTIONS,
    permissions.VIEW_DEVICES_TENANT_COLUMN,
    permissions.VIEW_DEVICES_EDIT_DEVICE_ACTION,
    permissions.VIEW_DEVICES_DELETE_DEVICE_ACTION,
  );
