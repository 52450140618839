import {
  createSlice,
  createSelector,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import { Room } from 'src/types/rooms';
import { UUID } from 'src/types/utility';
import { DATA_STATE_KEY, DATA_FETCHING_STATUS } from '../../constants';
import { mergeArraysWithUniqueIds } from '../../dataUtils';
import {
  AssignBedPayload,
  CreateBedPayload,
  CreateRoomPayload,
  EditRoomPayload,
} from './types';

export const STATE_KEY = 'rooms';

export const INITIAL_STATE: { roomsList: Room[]; status: string } = {
  roomsList: [],
  status: DATA_FETCHING_STATUS.INITIAL,
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    onRoomListLoading(state) {
      state.status = DATA_FETCHING_STATUS.LOADING;
    },
    onLoadRoomListSuccess(state, action: PayloadAction<Room[]>) {
      state.roomsList = mergeArraysWithUniqueIds(
        state.roomsList,
        action.payload,
      );
      state.status = DATA_FETCHING_STATUS.SUCCESS;
    },
    onLoadRoomListFail(state) {
      state.status = DATA_FETCHING_STATUS.ERROR;
    },
  },
  extraReducers: {},
});

const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;

export const selectors = {
  getRoomList: createSelector(getState, state => state.roomsList),
  getStatus: createSelector(getState, state => state.status),
  getIsLoading: createSelector(
    getState,
    state => state.status === DATA_FETCHING_STATUS.LOADING,
  ),
};

const extraActions = {
  onLoadRoomsList: createAction<{ tenantId?: UUID }>(
    `${STATE_KEY}/onLoadRoomsList`,
  ),
  onCreateRoom: createAction<CreateRoomPayload>(`${STATE_KEY}/onCreateRoom`),
  onEditRoom: createAction<EditRoomPayload>(`${STATE_KEY}/onEditRoom`),
  onDeleteRoom: createAction<UUID>(`${STATE_KEY}/onDeleteRoom`),
  onCreateBed: createAction<CreateBedPayload>(`${STATE_KEY}/onCreateBed`),
  onAssignBed: createAction<AssignBedPayload>(`${STATE_KEY}/onAssignBed`),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
