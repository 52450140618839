import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';

import { PageWrapper, SubTitle } from 'src/components/styled';
import Table from 'src/components/CrudCommonComponents/Table';
import ConfirmationModal from 'src/components/Modal/ConfirmationModal';
import TenantModal from './TenantModal';
import { DEFAULT_PAGINATION } from 'src/utils/constants';
import {
  getCurrentOrder,
  getTenantColumns,
  getTenantTableactions,
  getTenantTypeOptions,
  tenantModalModes,
  exportCsvData,
} from './utils';
import { MODAL_STATUS } from 'src/components/Modal/constants';
import { deepEqual } from 'src/utils/comparators';
import Connector from 'src/routes/Tenants';
import { StyledTenantsTypeSelector } from 'src/routes/Tenants/components/styled';
import { useCalculateTableHeight } from 'src/utils/hooks/useCalculateTableHeight';
import { ExportCsvButtonCustom } from 'src/components/CrudCommonComponents/styled';
import { prepareFiltersForQueryParamsObj } from 'src/services/utils';

const TenantsPage = ({
  tenantsList,
  getPaginatedTenants,
  getOperators,
  operatorsList,
  getCustomers,
  customersList,
  tenantsTotalCount,
  deleteTenant,
  createTenant,
  editTenant,
  excludedFromDeleteTenants,
  isLoading,
  modalStatus,
  setModalStatus,
  horizontalLayout = false,
  currentCustomer = null,
  getCustomerPaginatedTenants,
  intl,
}) => {
  const [isModalVisible, setisModalVisible] = useState(false);
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const [modalMode, setModalMode] = useState(null);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const [activeActionRow, setActiveActionRow] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableParams, setTableParams] = useState({
    current: DEFAULT_PAGINATION.PAGE,
    pageSize: 2 * DEFAULT_PAGINATION.LIMIT,
    showSizeChanger: false,
    sortBy: {
      columnKey: 'name',
      order: 'ASC',
    },
  });
  const [tableGroupFilter, setTableGroupFilter] = useState(false);
  const columns = getTenantColumns(intl, messages, currentCustomer);
  const serverSortingColumns = currentCustomer
    ? ['name']
    : ['name', 'customerName', 'operatorName'];
  const tableHeight = useCalculateTableHeight(currentCustomer);

  const actions = getTenantTableactions(
    intl,
    messages,
    excludedFromDeleteTenants,
    setIsConfirmationModalVisible,
    setActiveActionRow,
    setisModalVisible,
    setModalMode,
  );

  const handleSearch = searchKeyword => {
    setTableParams(prevState => ({
      ...prevState,
      searchKeyword,
      current: 1,
    }));
  };

  // Tenants sys admin
  useEffect(() => {
    if (currentCustomer?.id) {
      return;
    }
    getPaginatedTenants({
      page: tableParams.current - 1,
      limit: tableParams.pageSize,
      groupFilter: tableGroupFilter,
      search: tableParams.searchKeyword,
      sortBy: tableParams.sortBy,
    });
  }, [
    tableParams,
    getCustomers,
    getPaginatedTenants,
    currentCustomer?.id,
    tableGroupFilter,
  ]);

  // Customer tenant screen
  useEffect(() => {
    if (!currentCustomer?.id) {
      return;
    }
    getCustomerPaginatedTenants({
      page: tableParams.current - 1,
      limit: tableParams.pageSize,
      groupFilter: tableGroupFilter,
      customerID: currentCustomer?.id,
      searchKeyword: tableParams.searchKeyword,
      cache: 'true',
      sortBy: tableParams.sortBy,
    });
  }, [
    tableParams,
    tableGroupFilter,
    getCustomerPaginatedTenants,
    currentCustomer?.id,
  ]);

  useEffect(() => {
    if (!isLoading) {
      setTableData(
        tenantsList.map(tenantData => ({
          key: tenantData.id,
          ...tenantData,
        })),
      );
    }
  }, [tenantsList, isLoading]);

  const handleTableChange = (pagination, filters, sorter) => {
    let sortBy = null;
    if (serverSortingColumns.includes(sorter?.columnKey)) {
      sortBy = {
        columnKey: sorter.columnKey,
        order: getCurrentOrder(sorter),
      };
    }
    setTableParams(prevState => ({
      ...prevState,
      current: pagination?.current ?? prevState.current,
      sortBy,
    }));
  };

  useEffect(() => {
    if (modalStatus !== MODAL_STATUS.SUCCESS) {
      return;
    }
    if (currentCustomer?.id) {
      getCustomerPaginatedTenants({
        page: tableParams.current - 1,
        limit: tableParams.pageSize,
        groupFilter: tableGroupFilter,
        customerID: currentCustomer?.id,
        search: tableParams.searchKeyword,
        cache: 'true',
        sortBy: tableParams.sortBy,
      });
      return;
    }

    getPaginatedTenants({
      groupFilter: tableGroupFilter,
      page: tableParams.current - 1,
      limit: tableParams.pageSize,
      search: tableParams.searchKeyword,
      sortBy: tableParams.sortBy,
    });
  }, [
    tableGroupFilter,
    currentCustomer?.id,
    modalStatus,
    getPaginatedTenants,
    tableParams,
  ]);

  return (
    <PageWrapper>
      <SubTitle>
        <FormattedMessage
          {...messages.tenantsCounter}
          values={{ tenantsCount: tenantsTotalCount }}
        />
      </SubTitle>
      <Table
        sticky={true}
        scroll={{ y: tableHeight }}
        horizontalLayout={horizontalLayout}
        withCsvExport={true}
        customExportButton={
          <ExportCsvButtonCustom
            isLoading={isCsvLoading}
            disabled={isCsvLoading}
            onClick={() =>
              exportCsvData(
                {
                  ...tableParams,
                  filters: prepareFiltersForQueryParamsObj({
                    customerId: currentCustomer ? currentCustomer?.id : null,
                    group: tableGroupFilter,
                  }),
                },
                setIsCsvLoading,
              )
            }
          >
            {intl.formatMessage(messages.exportCsvButtonLabel)}
          </ExportCsvButtonCustom>
        }
        loading={isLoading}
        data={tableData}
        pagination={
          tenantsTotalCount > tableParams?.pageSize
            ? { ...tableParams, total: tenantsTotalCount }
            : false
        }
        onChange={handleTableChange}
        columns={columns.filter(elem => !!elem)}
        actions={actions}
        actionsColumnIndex={8}
        withSearch
        searchFunction={handleSearch}
        searchPlaceholder={intl.formatMessage(messages.searchPlaceholder)}
        withAddButton
        addButtonText={intl.formatMessage(messages.addButtonText)}
        addButtonOnClick={() => {
          setActiveActionRow({});
          setisModalVisible(true);
          setModalMode(tenantModalModes.ADD);
        }}
        customHeaderComponents={
          <StyledTenantsTypeSelector
            onChange={value => setTableGroupFilter(value)}
            defaultValue={getTenantTypeOptions(intl)[0]}
            options={getTenantTypeOptions(intl)}
          />
        }
      />
      <ConfirmationModal
        isModalVisible={isConfirmationModalVisible}
        setIsModalVisible={value => setIsConfirmationModalVisible(value)}
        onOk={() => {
          deleteTenant({
            tenantId: activeActionRow.id,
            page: tableParams.current - 1,
            limit: tableParams.pageSize,
            groupFilter: tableGroupFilter,
            customerID: currentCustomer?.id,
            searchKeyword: tableParams.searchKeyword,
          });
        }}
      />
      {isModalVisible && (
        <TenantModal
          currentCustomer={currentCustomer}
          operatorsList={operatorsList}
          customersList={customersList}
          getOperators={getOperators}
          getCustomers={getCustomers}
          isModalVisible={isModalVisible}
          setIsModalVisible={value => {
            setisModalVisible(value);
          }}
          onOk={tenantData => {
            if (modalMode === tenantModalModes.ADD) {
              createTenant(tenantData);
              return;
            }
            editTenant({
              id: activeActionRow.id,
              data: tenantData,
            });
          }}
          mode={modalMode}
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
          tenantListRow={activeActionRow}
        />
      )}
    </PageWrapper>
  );
};

const messages = defineMessages({
  tenantsCounter: {
    defaultMessage:
      '{tenantsCount } {tenantsCount, select, 1 {tenant} other {tenants}}',
  },
  tenants: {
    defaultMessage: 'Tenants',
  },
  searchPlaceholder: {
    defaultMessage: 'Search by name',
  },
  loggedInToTenantAlert: {
    defaultMessage:
      'You are trying to delete the tenant you are logged in to. This operation is not allowed.',
  },
  name: {
    defaultMessage: 'Tenant',
  },
  enableCPX: {
    defaultMessage: 'CPX Enabled',
  },
  address: {
    defaultMessage: 'Address',
  },
  delete: {
    defaultMessage: 'Delete',
  },
  edit: {
    defaultMessage: 'Edit',
  },
  contactName: {
    defaultMessage: 'Contact name',
  },
  contactEmail: {
    defaultMessage: 'Contact Email',
  },
  contactPhone: {
    defaultMessage: 'Contact phone',
  },
  addButtonText: {
    defaultMessage: '+ Add tenant',
  },
  operatorLabel: {
    defaultMessage: 'Operator',
  },
  customerLabel: {
    defaultMessage: 'Customer',
  },
  tenantTypeLabel: {
    defaultMessage: 'Type',
  },
  groupManagerLabel: {
    defaultMessage: 'Multi-Tenant Manager',
  },
  devicesLabel: {
    defaultMessage: 'Devices',
  },
  connectedLabel: {
    defaultMessage: 'Connected',
  },
  monitoringLabel: {
    defaultMessage: 'Monitoring',
  },
  exportCsvButtonLabel: {
    id: 'Tenants.Component.exportCsvButtonLabel',
    defaultMessage: 'Export CSV',
  },
});

TenantsPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  tenantsList: PropTypes.array.isRequired,
  getOperators: PropTypes.func,
  getCustomers: PropTypes.func,
  operatorsList: PropTypes.array,
  customersList: PropTypes.array,
  getPaginatedTenants: PropTypes.func.isRequired,
  tenantsTotalCount: PropTypes.number,
  deleteTenant: PropTypes.func.isRequired,
  createTenant: PropTypes.func.isRequired,
  editTenant: PropTypes.func.isRequired,
  excludedFromDeleteTenants: PropTypes.arrayOf(PropTypes.string).isRequired,
  intl: PropTypes.object.isRequired,
  modalStatus: PropTypes.string.isRequired,
  setModalStatus: PropTypes.func.isRequired,
  horizontalLayout: PropTypes.bool,
  currentCustomer: PropTypes.object,
  getCustomerPaginatedTenants: PropTypes.func,
};
export default Connector(
  injectIntl(
    React.memo(TenantsPage, (oldProps, newProps) =>
      deepEqual(oldProps, newProps),
    ),
  ),
);
