import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { PageWrapper, SubTitle } from 'src/components/styled';
import Table from 'src/components/CrudCommonComponents/Table';
import ConfirmationModal from 'src/components/Modal/ConfirmationModal';
import { hasAllPermissions, hasAnyPermissions } from 'src/utils/permissions';
import DeviceModal, { deviceModalModes } from './AddEditDeviceForm/DeviceModal';
import AssignDeviceModal from './AssignDeviceModal';
import DeviceInfoNodal from './DeviceInfoModal/DeviceInfoModal';
import { DEFAULT_PAGINATION, healthProfessional } from 'src/utils/constants';
import { deepEqual } from 'src/utils/comparators';
import {
  exportAdminCsvData,
  exportCsvData,
  getActionPermissionsMap,
  getDevicesColumns,
  getSortingColumns,
} from './utils';
import { messages } from './constants';
import {
  DevicesFilterOptions,
  getEnabledFields,
} from '../components/AdvancedSearch/constants';
import Connector from '../containers/container';
import { Button } from 'antd';

import AdvancedSearchModal from './AdvancedSearch';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { useSelector } from 'react-redux';
import { getCurrentOrder } from 'src/routes/Tenants/components/utils';
import FwVersionCountersModal from 'src/routes/Devices/components/FwVersionCountersModal/FwVersionCountersModal';
import {
  StyledTableContainer,
  AdvancedFilterSelect,
} from 'src/routes/Devices/components/styled';
import { debounce } from 'lodash';
import { useDeviceSearch } from 'src/routes/Devices/hooks/useDeviceSearch';
import permissions from 'src/permissions';
import { useCalculateTableHeight } from 'src/utils/hooks/useCalculateTableHeight';
import { ExportCsvButtonCustom } from 'src/components/CrudCommonComponents/styled';

const DevicesPage = ({
  devicesList,
  getUserDevicesList,
  onLoadDevicesListPage,
  detachDevice,
  assignDevice,
  deleteDevice,
  createDevice,
  editDevice,
  resetContinuous,
  tenantOptions,
  isLoading,
  intl,
  modalStatus,
  setModalStatus,
  loggedInUserType,
  devicesTotalCount,
  roomsList,
  horizontalLayout,
  currentCustomer = null,
}) => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] =
    useState(false);
  const [isDetachConfirmationVisible, setIsDetachConfirmationVisible] =
    useState(false);
  const [isAssignDeviceModalVisible, setIsAssignDeviceModalVisible] =
    useState(false);
  const [isDeviceInfoModalVisible, setisDeviceInfoModalVisible] =
    useState(false);
  const [isResetConfirmationVisible, setIsResetConfirmationVisible] =
    useState(false);

  const [isAdvancedSearchModalVisible, setIsAdvancedSearchModalVisible] =
    useState(false);
  const [isFwVersionCountersVisible, setIsFwVersionCountersVisible] =
    useState(false);
  const [activeActionRow, setActiveActionRow] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableParams, setTableParams] = useState(null);
  const history = useHistory();
  const activeRowManufacturerId =
    tableParams?.viewMode === DevicesFilterOptions[0].value
      ? activeActionRow.manufacturerId
      : activeActionRow.deviceId;
  const userType = loggedInUserType?.name;
  const isSysAdminUser = hasAllPermissions(
    permissions.VIEW_DEVICES_ADD_BUTTON,
    permissions.VIEW_DEVICES_TENANT_ACTIONS,
    permissions.VIEW_DEVICES_TENANT_COLUMN,
    permissions.VIEW_DEVICES_EDIT_DEVICE_ACTION,
    permissions.VIEW_DEVICES_DELETE_DEVICE_ACTION,
  );
  const timezone = useSelector(state =>
    loggedInUserSelectors.getUserTenantTimezone(state),
  );
  const devicesListByUserMap = {
    [healthProfessional]: devicesList.filter(
      device => device.certificateExists,
    ),
  };
  const devicesListHandler = {
    get(devicesListMap, userTypeProperty) {
      return devicesListMap[userTypeProperty] || devicesList;
    },
  };
  const proxyDevicesList = new Proxy(devicesListByUserMap, devicesListHandler);
  const serverSortingColumns = getSortingColumns(
    tableParams?.viewMode === DevicesFilterOptions[0].value,
  );
  const totalDevices = searchKeyword?.length
    ? tableData?.length
    : devicesTotalCount;

  const actions = [];
  const ACTION_PERMISSION_MAP = getActionPermissionsMap(
    intl,
    history,
    setActiveActionRow,
    setIsDeleteConfirmationVisible,
    setIsEditModalVisible,
    setIsDetachConfirmationVisible,
    setIsAssignDeviceModalVisible,
    setisDeviceInfoModalVisible,
    setIsResetConfirmationVisible,
  );
  const tableHeight = useCalculateTableHeight(currentCustomer, 345, 490);

  Object.keys(ACTION_PERMISSION_MAP).forEach(permission => {
    const hasPermission = hasAnyPermissions(permission);
    if (hasPermission) {
      actions.push(...ACTION_PERMISSION_MAP[permission]);
    }
  });

  const tableColumns = getDevicesColumns(
    intl,
    userType,
    timezone,
    serverSortingColumns,
    actions,
    tableParams?.viewMode === DevicesFilterOptions[0].value,
    currentCustomer,
  )?.filter(elem => !!elem);

  useEffect(() => {
    setTableParams({
      current: DEFAULT_PAGINATION.PAGE,
      pageSize: DEFAULT_PAGINATION.LIMIT,
      filters: { customerId: currentCustomer?.id },
      viewMode: DevicesFilterOptions[0].value,
    });
  }, [currentCustomer]);

  useEffect(() => {
    if (!tableParams) {
      return;
    }
    onLoadDevicesListPage({
      page: tableParams.current - 1,
      limit: tableParams.pageSize,
      filters: tableParams.filters,
      viewMode: tableParams.viewMode,
      sortBy: tableParams.sortBy,
    });
  }, [tableParams]);

  useDeviceSearch(
    searchKeyword,
    isLoading,
    setTableData,
    proxyDevicesList,
    userType,
    tableColumns,
    tableParams,
  );
  const handleTableChange = (pagination, filters, sorter) => {
    if (!tableParams) {
      return;
    }
    let sortBy = null;
    if (serverSortingColumns.includes(sorter?.columnKey)) {
      sortBy = {
        columnKey: sorter.columnKey,
        order: getCurrentOrder(sorter),
      };
    }

    setTableParams(prevState => ({
      ...prevState,
      current: pagination.current,
      sortBy,
    }));
  };
  return (
    <PageWrapper>
      <SubTitle>
        <FormattedMessage
          {...messages.devicesCounter}
          values={{ devicesCount: totalDevices }}
        />
      </SubTitle>
      <StyledTableContainer>
        <Table
          scroll={{ y: tableHeight }}
          withCsvExport={true}
          customExportButton={
            <ExportCsvButtonCustom
              isLoading={isCsvLoading}
              disabled={isCsvLoading}
              onClick={() =>
                isSysAdminUser
                  ? exportAdminCsvData(tableParams, setIsCsvLoading)
                  : exportCsvData(tableParams, setIsCsvLoading)
              }
            >
              {intl.formatMessage(messages.exportCsvButtonLabel)}
            </ExportCsvButtonCustom>
          }
          horizontalLayout={horizontalLayout}
          loading={isLoading}
          data={tableData}
          rowKey="key"
          columns={tableColumns}
          withSearch
          searchFunction={debounce(
            searchKeyword => setSearchKeyword(searchKeyword),
            300,
          )}
          pagination={
            totalDevices > tableParams?.pageSize
              ? {
                  ...tableParams,
                  total: totalDevices,
                  showSizeChanger: false,
                }
              : false
          }
          onChange={handleTableChange}
          searchPlaceholder={intl.formatMessage(messages.searchPlaceholder)}
          // Remove add button for now since BE is not ready yet
          withAddButton={hasAnyPermissions(permissions.VIEW_DEVICES_ADD_BUTTON)}
          addButtonOnClick={() => {
            setIsAddModalVisible(true);
          }}
          customHeaderComponents={
            <>
              <AdvancedFilterSelect
                defaultValue={DevicesFilterOptions[0].value}
                onChange={viewMode =>
                  setTableParams(prevState => ({
                    ...prevState,
                    current: 1,
                    filters: {
                      mountOnly:
                        viewMode === DevicesFilterOptions[2].value
                          ? true
                          : undefined,
                    },
                    sortBy: null,
                    viewMode,
                  }))
                }
                options={DevicesFilterOptions}
              />
              <Button
                onClick={() =>
                  setIsAdvancedSearchModalVisible(!isAdvancedSearchModalVisible)
                }
              >
                {intl.formatMessage(messages.advancedSearchTitleBtn, {
                  totalFieldsCount: tableParams?.filters
                    ? Object.keys(tableParams.filters)
                        ?.filter(filterKey => !!tableParams.filters[filterKey])
                        ?.filter(val => val !== DevicesFilterOptions[2].value)
                        ?.length
                    : 0,
                })}
              </Button>
              <Button
                onClick={() =>
                  setIsFwVersionCountersVisible(!isFwVersionCountersVisible)
                }
              >
                {intl.formatMessage(messages.fwVersionCountersTitleBtn)}
              </Button>
            </>
          }
        />
      </StyledTableContainer>
      {isFwVersionCountersVisible && (
        <FwVersionCountersModal
          currentCustomer={currentCustomer}
          setIsModalVisible={setIsFwVersionCountersVisible}
          isModalVisible={isFwVersionCountersVisible}
        />
      )}
      {isAdvancedSearchModalVisible && (
        <AdvancedSearchModal
          isModalVisible={isAdvancedSearchModalVisible}
          setIsModalVisible={value => setIsAdvancedSearchModalVisible(value)}
          enabledFields={
            getEnabledFields(!!currentCustomer)[tableParams?.viewMode]
          }
          onOk={formData => {
            setTableParams(prevState => ({
              ...prevState,
              filters: {
                ...prevState?.filters,
                ...formData,
              },
              current: 1,
            }));
            setIsAdvancedSearchModalVisible(false);
          }}
          isAllDevicesView={
            tableParams?.viewMode === DevicesFilterOptions[0].value
          }
          initialValues={{ ...tableParams?.filters }}
        />
      )}
      {isAddModalVisible && (
        <DeviceModal
          isModalVisible={isAddModalVisible}
          setIsModalVisible={value => {
            setIsAddModalVisible(value);
          }}
          onOk={createDeviceData => {
            createDevice(createDeviceData);
          }}
          mode={deviceModalModes.ADD}
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
        />
      )}
      {isEditModalVisible && (
        <DeviceModal
          isModalVisible={isEditModalVisible}
          setIsModalVisible={value => {
            setIsEditModalVisible(value);
          }}
          onOk={editDeviceData => {
            editDevice({
              ...editDeviceData,
              manufacturerId: activeRowManufacturerId,
            });
          }}
          mode={deviceModalModes.EDIT}
          deviceListRow={activeActionRow}
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
          roomsList={roomsList}
        />
      )}
      <ConfirmationModal
        isModalVisible={isDeleteConfirmationVisible}
        setIsModalVisible={value => {
          setIsDeleteConfirmationVisible(value);
        }}
        onOk={() => {
          deleteDevice({
            manufacturerId: activeRowManufacturerId,
            certificateExists: activeActionRow.certificateExists,
          });
        }}
        message={intl.formatMessage(messages.deleteConfirmation)}
      />
      <ConfirmationModal
        isModalVisible={isDetachConfirmationVisible}
        setIsModalVisible={value => {
          setIsDetachConfirmationVisible(value);
        }}
        onOk={() => {
          detachDevice(activeRowManufacturerId);
        }}
        message={intl.formatMessage(messages.detachConfirmation)}
      />
      <AssignDeviceModal
        isModalVisible={isAssignDeviceModalVisible}
        setIsModalVisible={value => {
          setIsAssignDeviceModalVisible(value);
        }}
        onAssign={assignDeviceData => {
          setIsAssignDeviceModalVisible(false);
          assignDevice({
            ...assignDeviceData,
            manufacturerId: activeRowManufacturerId,
          });
        }}
        tenantOptions={tenantOptions}
      />
      <DeviceInfoNodal
        isModalVisible={isDeviceInfoModalVisible}
        setIsModalVisible={value => {
          setisDeviceInfoModalVisible(value);
        }}
        onClick={() => {
          setisDeviceInfoModalVisible(false);
        }}
        device={devicesList.find(
          device => device.manufacturerId === activeRowManufacturerId,
        )}
      />
      <ConfirmationModal
        isModalVisible={isResetConfirmationVisible}
        setIsModalVisible={value => {
          setIsResetConfirmationVisible(value);
        }}
        onOk={() => {
          resetContinuous(activeRowManufacturerId);
        }}
        message={intl.formatMessage(messages.resetConfirmation)}
      />
    </PageWrapper>
  );
};

DevicesPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  horizontalLayout: PropTypes.bool.isRequired,
  devicesList: PropTypes.array.isRequired,
  devicesTotalCount: PropTypes.number.isRequired,
  tenantOptions: PropTypes.array.isRequired,
  getUserDevicesList: PropTypes.func.isRequired,
  detachDevice: PropTypes.func.isRequired,
  assignDevice: PropTypes.func.isRequired,
  createDevice: PropTypes.func.isRequired,
  deleteDevice: PropTypes.func.isRequired,
  editDevice: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  modalStatus: PropTypes.string.isRequired,
  setModalStatus: PropTypes.func.isRequired,
  loggedInUserType: PropTypes.object.isRequired,
  devicesStatus: PropTypes.string.isRequired,
  onLoadDevicesListPage: PropTypes.func.isRequired,
  roomsList: PropTypes.array.isRequired,
  searchAllDevicesAdmin: PropTypes.func,
  // setSearchedDevices: PropTypes.func,
  setSearchKeyword: PropTypes.func,
  searchKeyword: PropTypes.array,
  resetContinuous: PropTypes.func,
  currentCustomer: PropTypes.object,
};

export default Connector(
  injectIntl(
    React.memo(DevicesPage, (oldProps, newProps) =>
      deepEqual(oldProps, newProps),
    ),
  ),
);
