import { getIntl } from '../../languageUtils';
import { deviceMessages } from './deviceMessages';
import { customerMessages } from './customerMessages';
import { fileMessages } from './fileMessages';
import { tenantMessages } from './tenantMessages';
import { userMessages } from './userMessages';
import { patientMessages } from './patientMessages';
import { patientHistoryMessages } from './patientHistoryMessages';
import { monitorMessages } from './monitorMessages';
import { authMessages } from './authMessages';
import { appMessages } from './appMessages';
import { versionMessages } from './versionMessages';
import { groupMessages } from './groupMessages';
import { managmentMessages } from './managmentMessages';
import { settingsMessages } from './settingsMessages';
import { alertsMessages } from './alertsMessages';
import { SMSEmailAlertsMessages } from './SMSEmailAlertsMessages';

export const messages = {
  ...deviceMessages,
  ...fileMessages,
  ...tenantMessages,
  ...userMessages,
  ...patientMessages,
  ...patientHistoryMessages,
  ...monitorMessages,
  ...authMessages,
  ...appMessages,
  ...versionMessages,
  ...groupMessages,
  ...managmentMessages,
  ...settingsMessages,
  ...alertsMessages,
  ...SMSEmailAlertsMessages,
  ...customerMessages,
};

export const intl = getIntl(messages);
