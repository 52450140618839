import {
  createSlice,
  createSelector,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from 'src/redux/store';
import { selectors as tenantSelectors } from 'src/redux/data/tenant';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { selectors as groupManagerSelectors } from 'src/redux/data/groupManager';
import { selectors as usersSelector } from 'src/redux/data/user';
import { UUID } from 'src/types/utility';
import { MODAL_STATUS } from 'src/components/Modal/constants';
import { API_STATUS } from 'src/utils/api-constants';
import { GM_DATA_KEYS } from 'src/redux/data/groupManager/modules/constants';
import { UserWithStatus } from 'src/redux/data/user/modules/types';
import {
  UserActivitySubscription,
  UserMedicalSubscription,
  UserTechnicalSubscription,
} from 'src/types/subscribers';
import { SubTenantType, UserActionPayload, UserTableItem } from './types';

export const STATE_KEY = 'groupManagerSettings';

export const INITIAL_STATE: {
  selectedTenantId: UUID | null;
  status: API_STATUS;
  modalStatus: MODAL_STATUS;
} = {
  selectedTenantId: null,
  status: API_STATUS.LOADING,
  modalStatus: MODAL_STATUS.INITIAL,
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    setSelectedTenantId: (state, action: PayloadAction<UUID>) => {
      state.selectedTenantId = action.payload;
    },
    setStatus(state, action: PayloadAction<API_STATUS>) {
      state.status = action.payload;
    },
    setModalStatus(state, action: PayloadAction<MODAL_STATUS>) {
      state.modalStatus = action.payload;
    },
  },
});

const extraActions = {
  gmSettingsPageUnmounted: createAction(`${STATE_KEY}/gmSettingsPageUnmounted`),
  gmSettingsInviteUser: createAction<{
    formData: { name: string; email: string; userType: UUID; tenantId: UUID };
    selectedTenantId: UUID;
  }>(`${STATE_KEY}/gmSettingsInviteUser`),
  resendUserInvitation: createAction<UserActionPayload<UserTableItem>>(
    `${STATE_KEY}/resentUserInvitation`,
  ),
  updateTenantSubscribers: createAction<
    UserActionPayload<{
      usersAssignedToEmail: {
        MEDICAL: UserMedicalSubscription[];
        TECHNICAL: UserTechnicalSubscription[];
        ACTIVITY: UserActivitySubscription[];
      };
      usersAssignedToSMS: {
        MEDICAL: UserMedicalSubscription[];
        TECHNICAL: UserTechnicalSubscription[];
        ACTIVITY: UserActivitySubscription[];
      };
    }>
  >(`${STATE_KEY}/updateTenantSubscribers`),
  updateUserByTenantId: createAction<{
    formData: { name: string; email: string; userType?: UUID; tenantId: UUID };
    userId: UUID;
    selectedTenantId: UUID;
  }>(`${STATE_KEY}/updateUserByTenantId`),
  updateUserPhoneNumberByTenantId: createAction<{
    formData: { phone: string | null };
    userId: UUID;
    selectedTenantId: UUID;
  }>(`${STATE_KEY}/updateUserPhoneNumberByTenantId`),
  deleteUserByTenantId: createAction<UserActionPayload<UserTableItem>>(
    `${STATE_KEY}/deleteUserByTenantId`,
  ),
  deleteInvitationByTenantId: createAction<UserActionPayload<UserTableItem>>(
    `${STATE_KEY}/deleteInvitationByTenantId`,
  ),
};

const getState = (state: RootState) => state[STATE_KEY] || INITIAL_STATE;

const getTenantId = (state: RootState) => state[STATE_KEY].selectedTenantId;

export const selectors = {
  getSelectedTenantId: createSelector(
    getState,
    state => state.selectedTenantId,
  ),
  getStatus: createSelector(getState, state => state.status),
  getModalStatus: createSelector(getState, state => state.modalStatus),
};

export const extraSelectors = {
  selectSubtenants: createSelector(
    (state: RootState) => state,
    tenantSelectors.getTenantsList,
    (state, subtenants): SubTenantType[] => {
      const loggedInTenantId = loggedInUserSelectors.getUserTenantId(state);
      const loggedInTenantName = loggedInUserSelectors.getUserTenantName(state);

      return [
        {
          id: loggedInTenantId || '',
          name: loggedInTenantName,
          loggedInGm: true,
        },
        ...subtenants,
      ];
    },
  ),
  selectUsersTableData: createSelector(
    usersSelector.getUsersList,
    usersSelector.getInvitedUsersList,
    (state: RootState) =>
      groupManagerSelectors.selectDataTenantDict(state, GM_DATA_KEYS.USERS),
    getTenantId,
    loggedInUserSelectors.getCurrentTenantId,
    (
      userList,
      invitedList,
      usersTenantDict,
      selectedTenantId,
      gmId,
    ): UserWithStatus[] =>
      [...userList, ...invitedList].filter(user =>
        selectedTenantId === gmId
          ? user.tenantId === gmId
          : usersTenantDict[user.id] === selectedTenantId,
      ),
  ),
};

export const actions = { ...slice.actions, ...extraActions };
const { reducer } = slice;
export default reducer;
