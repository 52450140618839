import { styled } from 'styled-components';
import { Select } from 'antd';

export const StyledTableContainer = styled.div`
  .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover {
    background: #fafafa;
  }
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const AdvancedFilterSelect = styled(Select)`
  min-width: 7rem;
`;
