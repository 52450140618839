import React from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';

import PageSummaryPanel, {
  totalDevicesIcon,
  connectedAssignedDevicesIcon,
  connectedUnassignedDevicesIcon,
  disconnectedAssignedDevicesIcon,
  disconnectedUnassignedDevicesIcon,
  lastDayAlertsIcon,
  disconnectedAssignedDevicesRedIcon,
  disconnectedUnassignedDevicesRedIcon,
  lastDayAlertsRedIcon,
  currentUtilizationIconNS,
  noConsentIconNS,
} from 'src/components/PageSummaryPanel';
import Connector, { PropsFromRedux } from './Connector';

type Props = PropsFromRedux & {
  intl: IntlShape;
};

const messages = defineMessages({
  totalDevices: {
    defaultMessage: 'Total Devices',
  },
  connectedAssignedDevices: {
    defaultMessage: 'Connected & Assigned',
  },
  connectedUnassignedDevices: {
    defaultMessage: 'Connected & Unassigned',
  },
  disconnectedAssignedDevices: {
    defaultMessage: 'Disconnected & Assigned',
  },
  disconnectedUnassignedDevices: {
    defaultMessage: 'Disconnected & Unassigned',
  },
  noConsentPatients: {
    defaultMessage: 'No Consent',
  },
  currentUtilization: {
    defaultMessage: 'Current Utilization',
  },
  lastDayAlerts: {
    defaultMessage: 'Notifications',
  },
  totalDevicesTooltip: {
    defaultMessage: 'Total devices assigned to current tenant',
  },
  connectedAssignedDevicesTooltip: {
    defaultMessage:
      'Total devices that are assigned to patients and currently monitoring',
  },
  connectedUnassignedDevicesTooltip: {
    defaultMessage:
      'Total devices that are available and ready for use. Currently not assigned and not monitoring',
  },
  disconnectedAssignedDevicesTooltip: {
    defaultMessage:
      'Total devices that got disconnected while undergoing a monitoring session',
  },
  disconnectedUnassignedDevicesTooltip: {
    defaultMessage:
      'Total devices that got disconnected while not monitoring any patients',
  },
  noConsentPatientsTooltip: {
    defaultMessage:
      'Total number of devices that are assigned to patients with no consent, and therefore not monitoring',
  },
  currentUtilizationTooltip: {
    defaultMessage:
      'Total devices that are assigned to patients and monitored in the previous 24 hours, out of total number of devices, not including devices assigned to patients without consent',
  },
  lastDayAlertsTooltip: {
    defaultMessage: 'Total current active notifications',
  },
});

const getStatisticsItems = ({
  totalDevices,
  connectedAssignedDevices,
  connectedUnassignedDevices,
  disconnectedAssignedDevices,
  disconnectedUnassignedDevices,
  noConsentPatientsWithAssignedDevices = 0,
  currentUtilization,
  lastDayAlertEventsCount,
  intl,
}: Props) => [
  {
    key: 'total-devices',
    order: 1,
    icon: totalDevicesIcon,
    title: intl.formatMessage(messages.totalDevices),
    tooltip: intl.formatMessage(messages.totalDevicesTooltip),
    value: totalDevices.toString(),
  },
  {
    key: 'connected-assigned-devices',
    order: 2,
    icon: connectedAssignedDevicesIcon,
    title: intl.formatMessage(messages.connectedAssignedDevices),
    tooltip: intl.formatMessage(messages.connectedAssignedDevicesTooltip),
    value: connectedAssignedDevices.toString(),
  },
  {
    key: 'connected-unassigned-devices',
    order: 3,
    icon: connectedUnassignedDevicesIcon,
    title: intl.formatMessage(messages.connectedUnassignedDevices),
    tooltip: intl.formatMessage(messages.connectedUnassignedDevicesTooltip),
    value: connectedUnassignedDevices.toString(),
  },
  {
    key: 'disconnected-assigned-devices',
    order: 4,
    icon:
      disconnectedAssignedDevices > 0
        ? disconnectedAssignedDevicesRedIcon
        : disconnectedAssignedDevicesIcon,
    title: intl.formatMessage(messages.disconnectedAssignedDevices),
    tooltip: intl.formatMessage(messages.disconnectedAssignedDevicesTooltip),
    value: disconnectedAssignedDevices.toString(),
  },
  {
    key: 'disconnected-unassigned-devices',
    order: 5,
    icon:
      disconnectedUnassignedDevices > 0
        ? disconnectedUnassignedDevicesRedIcon
        : disconnectedUnassignedDevicesIcon,
    title: intl.formatMessage(messages.disconnectedUnassignedDevices),
    tooltip: intl.formatMessage(messages.disconnectedUnassignedDevicesTooltip),
    value: disconnectedUnassignedDevices.toString(),
  },
  {
    key: 'no-consent-patients-with-assigned-devices',
    order: 6,
    icon: noConsentIconNS,
    title: intl.formatMessage(messages.noConsentPatients),
    tooltip: intl.formatMessage(messages.noConsentPatientsTooltip),
    value: noConsentPatientsWithAssignedDevices.toString(),
  },
  {
    key: 'current-utilization',
    order: 7,
    icon: currentUtilizationIconNS,
    title: intl.formatMessage(messages.currentUtilization),
    tooltip: intl.formatMessage(messages.currentUtilizationTooltip),
    value: `${currentUtilization}%`,
  },
  {
    key: 'last-day-alerts',
    order: 8,
    icon:
      lastDayAlertEventsCount > 0 ? lastDayAlertsRedIcon : lastDayAlertsIcon,
    title: intl.formatMessage(messages.lastDayAlerts),
    tooltip: intl.formatMessage(messages.lastDayAlertsTooltip),
    value: lastDayAlertEventsCount.toString(),
  },
];

const SummaryPanel = (props: Props): JSX.Element => {
  return <PageSummaryPanel statisticsItems={getStatisticsItems(props)} />;
};

export default Connector(injectIntl(SummaryPanel));
