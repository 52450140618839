/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from 'src/utils/history';
import {
  reducer as languageMenuReducer,
  STATE_KEY as languageMenuStateKey,
} from 'src/components/LanguageMenu';
// import appReducer, { STATE_KEY as appStateKey } from '../modules/app/app.slice';
import monitorReducer, {
  STATE_KEY as monitorStateKey,
} from 'src/routes/Monitor/modules/slice';
import {
  reducer as nurseStationReducer,
  STATE_KEY as nurseStationStateKey,
} from 'src/routes/NursesStation';
import {
  reducer as gmDashboardReducer,
  STATE_KEY as gmDashboardStateKey,
} from 'src/routes/GMDashboard';
import { statistics, idb } from 'src/routes/IndividualDashboard';
import {
  reducer as dataReducer,
  STATE_KEY as dataStateKey,
  logoutActionType,
} from '../data';
import patientHistoryReducer, {
  STATE_KEY as patientHistoryStateKey,
} from '../../routes/Patients/HistoryModal/modules/slice';
import {
  reducer as alertSidebarReducer,
  STATE_KEY as alertSidebarStateKey,
} from 'src/components/Sidebars/AlertSidebar';
import {
  reducer as alertSidebarGMReducer,
  STATE_KEY as alertSidebarGMStateKey,
} from 'src/components/Sidebars/AlertSidebarGroupManager';
import {
  reducer as settingsReducer,
  STATE_KEY as settingsStateKey,
} from 'src/routes/Settings';
import {
  reducer as gmSettingsReducer,
  STATE_KEY as gmSettingsStateKey,
} from 'src/routes/GMSettings';
import {
  reducer as medicalReportsReducer,
  STATE_KEY as medicalReportsStateKey,
} from 'src/routes/MedicalReports';

const staticReducers = {
  [languageMenuStateKey]: languageMenuReducer,
  router: connectRouter(history),
  [dataStateKey]: dataReducer,

  // Layout
  [alertSidebarStateKey]: alertSidebarReducer,
  [alertSidebarGMStateKey]: alertSidebarGMReducer,

  // Routes:
  [monitorStateKey]: monitorReducer,
  [patientHistoryStateKey]: patientHistoryReducer,
  [statistics.STATE_KEY]: statistics.reducer,
  [idb.STATE_KEY]: idb.reducer,
  [nurseStationStateKey]: nurseStationReducer,
  [gmDashboardStateKey]: gmDashboardReducer,
  [gmSettingsStateKey]: gmSettingsReducer,
  [settingsStateKey]: settingsReducer,
  [medicalReportsStateKey]: medicalReportsReducer,
};

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const createdAppReducer = combineReducers({
    ...staticReducers,
    ...injectedReducers,
  });

  // rootReducer in charge of resetting the store on logout.
  // see https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store

  // @ts-ignore TODO: Rewrite this
  const rootReducer = (state, action) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (action.type === logoutActionType) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      state = { data: { app: { ...state.data.app } } };
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return createdAppReducer(state, action);
  };

  return rootReducer;
}
