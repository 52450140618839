import { convertUtcToLocaleDateTime } from 'src/redux/data/parser';
import { DATE_TIME_FORMAT, displayInTimezone } from '../timeUtils';

export const splitDataPointsListToMultipleLists = (
  dataPointsList,
  roundDigits,
) => {
  const minPoints = [];
  const avgPoints = [];
  const maxPoints = [];
  const minMaxPoints = [];
  for (const dataPoint of dataPointsList) {
    const convertedTime = convertUtcToLocaleDateTime(dataPoint?.time);
    // the check if the value is less than 0, then set 0 in Y, is currently the operator's requirement. It should be deleted once the requirement is changed
    minMaxPoints.push({
      x: convertedTime,
      y: [
        dataPoint?.minimum < 0 ? 0 : Math.round(dataPoint?.minimum),
        dataPoint?.maximum < 0 ? 0 : Math.round(dataPoint?.maximum),
      ],
    });
    maxPoints.push({
      x: convertedTime,
      y: dataPoint?.average < 0 ? 0 : dataPoint?.maximum?.toFixed(roundDigits),
      sessionIds: dataPoint.sessionIds,
    });
    avgPoints.push({
      x: convertedTime,
      y: dataPoint?.average < 0 ? 0 : dataPoint?.average?.toFixed(roundDigits),
      sessionIds: dataPoint.sessionIds,
    });
    minPoints.push({
      x: convertedTime,
      y: dataPoint?.minimum < 0 ? 0 : dataPoint?.minimum?.toFixed(roundDigits),
      sessionIds: dataPoint.sessionIds,
    });
  }

  return { maxPoints, avgPoints, minPoints, minMaxPoints };
};

export const splitDataPointsListToMultipleListsWithTimezone = (
  dataPointsList,
  timezone,
  roundDigits,
) => {
  const minPoints = [];
  const avgPoints = [];
  const maxPoints = [];
  const minMaxPoints = [];
  for (const dataPoint of dataPointsList) {
    const convertedTime = displayInTimezone(
      dataPoint?.time,
      timezone,
      DATE_TIME_FORMAT,
    );
    // the check if the value is less than 0, then set 0 in Y, is currently the operator's requirement. It should be deleted once the requirement is changed
    minMaxPoints.push({
      x: convertedTime,
      y: [
        dataPoint?.minimum < 0 ? 0 : Math.round(dataPoint?.minimum),
        dataPoint?.maximum < 0 ? 0 : Math.round(dataPoint?.maximum),
      ],
    });
    maxPoints.push({
      x: convertedTime,
      y: dataPoint?.average < 0 ? 0 : dataPoint?.maximum?.toFixed(roundDigits),
      sessionIds: dataPoint.sessionIds,
    });
    avgPoints.push({
      x: convertedTime,
      y: dataPoint?.average < 0 ? 0 : dataPoint?.average?.toFixed(roundDigits),
      sessionIds: dataPoint.sessionIds,
    });
    minPoints.push({
      x: convertedTime,
      y: dataPoint?.minimum < 0 ? 0 : dataPoint?.minimum?.toFixed(roundDigits),
      sessionIds: dataPoint.sessionIds,
    });
  }

  return { maxPoints, avgPoints, minPoints, minMaxPoints };
};

const convertTimeXToNumber = points =>
  points?.map((p, index) => ({ x: index + 1, y: +p.y }));

export const calculateTrendLine = points => {
  const dataPoints = convertTimeXToNumber(points);
  let xSum = 0;
  let ySum = 0;
  let xySum = 0;
  let xSquare = 0;
  const dpsLength = dataPoints.length;
  for (let i = 0; i < dpsLength; i++) {
    xySum += dataPoints[i].x * dataPoints[i].y;
  }
  const a = xySum * dpsLength;

  for (let i = 0; i < dpsLength; i++) {
    xSum += dataPoints[i].x;
    ySum += dataPoints[i].y;
  }
  const b = xSum * ySum;

  for (let i = 0; i < dpsLength; i++) {
    xSquare += Math.pow(dataPoints[i].x, 2);
  }
  const c = dpsLength * xSquare;

  const d = Math.pow(xSum, 2);
  const slope = (a - b) / (c - d);
  const e = slope * xSum;
  const yIntercept = (ySum - e) / dpsLength;

  const startPoint = getTrendLinePoint(dataPoints[0]?.x, slope, yIntercept);
  const endPoint = getTrendLinePoint(
    dataPoints[dpsLength - 1]?.x,
    slope,
    yIntercept,
  );
  return [
    { x: points[0]?.x, y: startPoint?.y },
    { x: points[dpsLength - 1]?.x, y: endPoint?.y },
  ];
};

const getTrendLinePoint = (x, slope, intercept) => ({
  x,
  y: slope * x + intercept,
});
