import React, { Dispatch, SetStateAction } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import {
  PlusOutlined,
  MergeCellsOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import { styled } from 'styled-components';

import SelectTenantBox from 'src/routes/Customers/components/OperatorsTab/components/SelectTenantBox';
import ActionButton from 'src/components/general-ui/Buttons/ActionButton';
import { UUID } from 'src/types/utility';
import {
  OperatorTenantType,
  FacilityMappingStrategyState,
} from 'src/routes/Customers/components/OperatorsTab/modules/types';
import { ListTenant } from 'src/types/tenants';
import { DATA_STRATEGY } from 'src/utils/constants';

type Props = {
  id: UUID;
  mappingForm: FacilityMappingStrategyState;
  operatorTenantsList: OperatorTenantType[];
  mtmTenantsList: ListTenant[];
  setMapFacilitiesForm: Dispatch<SetStateAction<FacilityMappingStrategyState>>;
};

const ActionsButtonsWrap = styled.div`
  width: 216px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .delete-strategy-button {
    color: ${props => props.theme.colors.veryDarkGrey};
  }

  .select-tenant-box {
    width: 170px;
  }

  .strategy-button {
    &:first-child {
      margin-right: 0.5rem;
    }
  }
`;

const ActionButtons = ({
  id,
  mappingForm,
  operatorTenantsList,
  mtmTenantsList,
  setMapFacilitiesForm,
}: Props) => {
  if (mappingForm[id]?.strategy === DATA_STRATEGY.MAP) {
    return (
      <ActionsButtonsWrap>
        <SelectTenantBox
          options={operatorTenantsList}
          onChange={(tenantId: UUID) => {
            setMapFacilitiesForm(prevState => ({
              ...prevState,
              [id]: {
                ...prevState[id],
                tenantId: tenantId,
              },
            }));
          }}
          style={{
            width: '10rem',
          }}
        />
        <Button
          ghost
          className="delete-strategy-button"
          onClick={() => {
            setMapFacilitiesForm(prevState => ({
              ...prevState,
              [id]: {
                ...prevState[id],
                strategy: DATA_STRATEGY.SKIP,
              },
            }));
          }}
        >
          <CloseOutlined />
        </Button>
      </ActionsButtonsWrap>
    );
  } else if (mappingForm[id]?.strategy === DATA_STRATEGY.CREATE) {
    return (
      <ActionsButtonsWrap>
        <SelectTenantBox
          options={mtmTenantsList}
          onChange={tenantId => {
            setMapFacilitiesForm(prevState => ({
              ...prevState,
              [id]: {
                ...prevState[id],
                parentId: tenantId,
              },
            }));
          }}
        />
        <Button
          ghost
          className="delete-strategy-button"
          onClick={() => {
            setMapFacilitiesForm(prevState => ({
              ...prevState,
              [id]: {
                ...prevState[id],
                strategy: DATA_STRATEGY.SKIP,
              },
            }));
          }}
        >
          <CloseOutlined />
        </Button>
      </ActionsButtonsWrap>
    );
  } else if (mappingForm[id]?.strategy === DATA_STRATEGY.MAPPED) {
    return (
      <ActionsButtonsWrap>
        <FormattedMessage {...messages.facilityExists} />
      </ActionsButtonsWrap>
    );
  }

  return (
    <ActionsButtonsWrap>
      <ActionButton
        className="strategy-button"
        onClick={() => {
          setMapFacilitiesForm(prevState => ({
            ...prevState,
            [id]: {
              ...prevState[id],
              strategy: DATA_STRATEGY.MAP,
            },
          }));
        }}
      >
        <FormattedMessage {...messages.mapTenant} /> <MergeCellsOutlined />
      </ActionButton>
      <ActionButton
        className="strategy-button"
        onClick={() => {
          setMapFacilitiesForm(prevState => ({
            ...prevState,
            [id]: {
              ...prevState[id],
              strategy: DATA_STRATEGY.CREATE,
            },
          }));
        }}
      >
        <FormattedMessage {...messages.create} /> <PlusOutlined />
      </ActionButton>
    </ActionsButtonsWrap>
  );
};

const messages = defineMessages({
  mapTenant: {
    defaultMessage: 'Map Tenant',
  },
  create: {
    defaultMessage: 'Create',
  },
  facilityExists: {
    defaultMessage: 'Facility exists in healthgate',
  },
});

export default React.memo(ActionButtons);
