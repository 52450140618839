import { defineMessages } from 'react-intl';

export const tenantMessages = defineMessages({
  // error
  createTenantError: {
    defaultMessage: 'Could not create tenant',
  },
  createTenantMissingAdmin: {
    defaultMessage: 'The group tenant must have an admin!',
  },
  editTenantError: {
    defaultMessage: 'Could not edit tenant',
  },
  deleteTenantError: {
    defaultMessage: 'Could not delete tenant',
  },
  devicesAttachedToTenantError: {
    defaultMessage: 'Some devices might still be attached to this tenant',
  },
  couldNotFetchDevicesError: {
    defaultMessage:
      'Could not validate that no devices attached to this tenant. Try again later',
  },

  // success
});
