import { deviceActionToErrorNotificationMessagesMap } from './deviceActionsToNotificationMessagesMapper';
import {
  fileActionToSuccessNotificationMessagesMap,
  fileActionToErrorNotificationMessagesMap,
} from './fileActionsToNotificationMessagesMapper';
import {
  tenantActionToSuccessNotificationMessagesMap,
  tenantActionToErrorNotificationMessagesMap,
} from './tenantActionsToNotificationMessagesMapper';
import {
  userActionToSuccessNotificationMessagesMap,
  userActionToErrorNotificationMessagesMap,
  gmSettingsToErrorNotificationMessagesMap,
} from './userActionsToNotificationMessagesMapper';
import {
  patientActionToSuccessNotificationMessagesMap,
  patientActionToErrorNotificationMessagesMap,
} from './patientActionsToNotificationMessagesMapper';
import {
  monitorActionToSuccessNotificationMessagesMap,
  monitorActionToWarningNotificationMessagesMap,
  monitorActionToErrorNotificationMessagesMap,
} from './monitorActionsToNotificationMessagesMapper';
import {
  authActionToSuccessNotificationMessagesMap,
  authActionToErrorNotificationMessagesMap,
} from './authActionsToNotificationMessagesMapper';
import {
  appActionToSuccessNotificationMessagesMap,
  appActionToErrorNotificationMessagesMap,
} from './appActionsToNotificationMessagesMapper';
import {
  patientHistoryActionToSuccessNotificationMessagesMap,
  patientHistoryActionToErrorNotificationMessagesMap,
} from './patientHistoryActionsToNotificationMessagesMapper';
import {
  groupActionsToErrorNotificationMessagesMap,
  groupActionsToSuccessNotificationMessagesMap,
} from './groupActionsToNotificationMessagesMapper';
import {
  versionActionsToErrorNotificationMessagesMap,
  versionActionsToSuccessNotificationMessagesMap,
} from './versionActionsToNotificationMessagesMapper';
import { managmentActionToErrorNotificationMessagesMap } from './managmentActionsToNotificationMessagesMapper';
import {
  settingsActionsToErrorNotificationMessagesMap,
  settingsActionsToSuccessNotificationMessagesMap,
} from './settingsActionsToNotificationMessagesMapper';
import {
  alertsActionsToErrorNotificationMessagesMap,
  alertsActionsToSuccessNotificationMessagesMap,
} from './alertsActionsToNotificationMessagesMapper';
import {
  SMSEmailAlertsActionsToSuccessNotificationMessagesMap,
  SMSEmailAlertsActionsErrorNotificationMessagesMap,
} from './SMSEmailAlertsActionsToNotificationMessagesMapper';
import {
  customersErrorNotificationMessagesMap,
  customersSuccessNotificationMessagesMap,
} from 'src/utils/errorHandling/mappers/customersNotificationMessagesMapper';

export const actionToSuccessNotificationMessagesMap = {
  ...fileActionToSuccessNotificationMessagesMap,
  ...tenantActionToSuccessNotificationMessagesMap,
  ...userActionToSuccessNotificationMessagesMap,
  ...patientActionToSuccessNotificationMessagesMap,
  ...monitorActionToSuccessNotificationMessagesMap,
  ...authActionToSuccessNotificationMessagesMap,
  ...appActionToSuccessNotificationMessagesMap,
  ...patientHistoryActionToSuccessNotificationMessagesMap,
  ...versionActionsToSuccessNotificationMessagesMap,
  ...groupActionsToSuccessNotificationMessagesMap,
  ...settingsActionsToSuccessNotificationMessagesMap,
  ...alertsActionsToSuccessNotificationMessagesMap,
  ...SMSEmailAlertsActionsToSuccessNotificationMessagesMap,
  ...customersSuccessNotificationMessagesMap,
};

export const actionToErrorNotificationMessagesMap = {
  ...deviceActionToErrorNotificationMessagesMap,
  ...fileActionToErrorNotificationMessagesMap,
  ...tenantActionToErrorNotificationMessagesMap,
  ...userActionToErrorNotificationMessagesMap,
  ...gmSettingsToErrorNotificationMessagesMap,
  ...patientActionToErrorNotificationMessagesMap,
  ...monitorActionToErrorNotificationMessagesMap,
  ...authActionToErrorNotificationMessagesMap,
  ...appActionToErrorNotificationMessagesMap,
  ...patientHistoryActionToErrorNotificationMessagesMap,
  ...groupActionsToErrorNotificationMessagesMap,
  ...versionActionsToErrorNotificationMessagesMap,
  ...managmentActionToErrorNotificationMessagesMap,
  ...settingsActionsToErrorNotificationMessagesMap,
  ...alertsActionsToErrorNotificationMessagesMap,
  ...SMSEmailAlertsActionsErrorNotificationMessagesMap,
  ...customersErrorNotificationMessagesMap,
};

export const actionWarningNotificationMessagesMap = {
  ...monitorActionToWarningNotificationMessagesMap,
};
