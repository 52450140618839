import Search from 'antd/es/input/Search';
import { styled } from 'styled-components';

export const FacilityMappingWrap = styled.div`
  height: 490px;
  overflow: auto;
`;

export const FacilityTenantFieldsRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const FacilitySearchBox = styled(Search)`
  width: 250px;
  margin-bottom: 0.5rem;
`;

export const FacilityLabel = styled.p`
  width: 15rem;
  font-size: 0.7rem;
  font-weight: 600;
  margin-bottom: 0;
`;
