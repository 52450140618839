import { connect } from 'react-redux';
import {
  actions as deviceDataActions,
  selectors as deviceDataSelectors,
} from 'src/redux/data/device';
import { selectors as sessionsSelectors } from 'src/redux/data/sessions';
import { selectors as tenantDataSelectors } from 'src/redux/data/tenant';
import { selectors as groupDataSelectors } from 'src/redux/data/groups';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';
import permissions from 'src/permissions';
import { hasAnyPermissions } from 'src/utils/permissions';
import { SessionStatus } from 'src/types/sessions';
import dataActions from '../../../redux/data/dataActions';
import { actions } from '../modules/slice';
import { selectors as roomSelectors } from 'src/redux/data/rooms';

const devicesStatus = state => deviceDataSelectors.getStatus(state);

const isDataLoading = state =>
  devicesStatus(state) === DATA_FETCHING_STATUS.LOADING ||
  (hasAnyPermissions(permissions.VIEW_DEVICES_TENANT_COLUMN) &&
    tenantDataSelectors.getStatus(state) === DATA_FETCHING_STATUS.LOADING);

const findAssignedPatientId = (devicesList, selectedDeviceId) => {
  const correspondingItem = devicesList.find(
    d => d.deviceId === selectedDeviceId,
  );
  if (
    correspondingItem &&
    correspondingItem.patientId &&
    correspondingItem.status === SessionStatus.RUNNING
  ) {
    return correspondingItem.patientId;
  } else return null;
};

const getTenantHistoryWithTenantName = (tenantHistory, tenantsList) => {
  if (
    !tenantHistory ||
    !tenantHistory.length ||
    !tenantsList ||
    !tenantsList.length
  ) {
    return tenantHistory;
  }
  return tenantHistory.map(tenantHistory => ({
    ...tenantHistory,
    tenantName: tenantsList.find(tenant => tenant.id === tenantHistory.tenantId)
      ?.name,
  }));
};

const getDevicesList = state => {
  const devicesList = deviceDataSelectors.getDevicesList(state);
  const devicesWithStatusesList = sessionsSelectors.selectSessions(state);
  const tenantsList = tenantDataSelectors.getTenantsList(state);
  const groupClientList = groupDataSelectors.getGroupClients(state);
  const roomsList = roomSelectors.getRoomList(state);
  let devicesDisplayList = devicesList;

  if (roomsList.length > 0) {
    devicesDisplayList = devicesDisplayList.map(device => ({
      ...device,
      assignedPatientId: findAssignedPatientId(
        devicesWithStatusesList,
        device.manufacturerId,
      ),
      roomBed: roomsList
        .map(room => {
          const bed = room.beds.find(
            bed => bed.deviceId === device.manufacturerId,
          );
          if (bed) {
            return {
              deviceId: bed.deviceId,
              bed: { id: bed.id, name: bed.name },
              room: { id: room.id, name: room.name },
            };
          }
          return undefined;
        })
        .find(rb => rb !== undefined),
    }));
  }
  if (tenantsList.length > 0) {
    devicesDisplayList = devicesDisplayList.map(device => ({
      ...device,
      tenantName: tenantsList.find(tenant => tenant.id === device.tenantId)
        ?.name,
      tenantsHistory: getTenantHistoryWithTenantName(
        device.tenantsHistory,
        tenantsList,
      ),
    }));
  }
  if (groupClientList.length > 0) {
    devicesDisplayList = devicesDisplayList.map(device => ({
      ...device,
      groupName: groupClientList.find(
        client => client.id === device.manufacturerId,
      )?.groupName,
      groupId: groupClientList.find(
        client => client.id === device.manufacturerId,
      )?.groupId,
    }));
  }
  return devicesDisplayList;
};

const getTenantOptions = state => {
  const tenantsList = tenantDataSelectors.getTenantsList(state);

  return tenantsList
    .filter(tenant => !tenant.group)
    .map(tenant => ({ label: tenant.name, value: tenant.id }));
};

const getRoomList = state => {
  const roomsList = roomSelectors.getRoomList(state);
  roomsList.forEach(room => room.beds.map(b => ({ room, ...b })));
  return roomsList.map(room => ({
    id: room.id,
    label: room.name,
    value: room.id,
    beds: room?.beds.map(bed => ({
      label: bed.name,
      value: bed.id,
      deviceId: bed.deviceId,
    })),
  }));
};

const mapStateToProps = (state, ownProps) => ({
  devicesList: getDevicesList(state, ownProps.searchKeyword),
  tenantOptions: getTenantOptions(state),
  isLoading: isDataLoading(state),
  devicesTotalCount:
    deviceDataSelectors.getDevicesListMetadata(state).totalResults,
  modalStatus: deviceDataSelectors.getModalStatus(state),
  loggedInUserType: loggedInUserSelectors.getLoggedInUserType(state),
  devicesStatus: devicesStatus(state),
  roomsList: getRoomList(state),
});

const mapActionCreators = {
  ...deviceDataActions,
  ...dataActions,
  ...actions,
};

const Connector = connect(mapStateToProps, mapActionCreators);
export default Connector;
