import { combineReducers } from 'redux';
import { fork, all } from 'typed-redux-saga/macro';

import { DATA_STATE_KEY } from './constants';
import {
  loggedInUserStateKey,
  reducer as loggedInUserReducer,
  saga as loggedInUserSaga,
} from './loggedInUser';
import { appStateKey, reducer as appReducer, saga as appSaga } from './app';
import {
  authStateKey,
  reducer as authReducer,
  saga as authSaga,
  actions as authActions,
} from './auth';
import { userStateKey, reducer as userReducer, saga as userSaga } from './user';
import {
  tenantStateKey,
  reducer as tenantReducer,
  saga as tenantSaga,
  utilityTenantListSaga as tenantsListUtilitySaga,
} from './tenant';
import {
  deviceStateKey,
  reducer as deviceReducer,
  saga as deviceSaga,
} from './device';
import {
  patientStateKey,
  reducer as patientReducer,
  saga as patientSaga,
} from './patient';
import {
  groupsStateKey,
  reducer as groupsReducer,
  saga as groupsSaga,
} from './groups';
import {
  versionStateKey,
  reducer as versionReducer,
  saga as versionSaga,
} from './version';
import {
  managmentStateKey,
  reducer as managmentReducer,
  saga as managmentSaga,
} from './managment';
import {
  sessionsStateKey,
  reducer as sessionsReducer,
  saga as sessionsSaga,
} from './sessions';
import {
  monitorStateKey,
  reducer as monitorReducer,
  saga as monitorSaga,
} from './monitor';
import {
  alertsStateKey,
  reducer as alertReducer,
  saga as alertSaga,
  soundSaga as alertSoundSaga,
} from './alerts';
import {
  measurementSettingsStateKey,
  reducer as measurementSettingsReducer,
  saga as measurementSettingsSaga,
} from './settings';
import {
  roomsStateKey,
  reducer as roomsReducer,
  saga as roomsSaga,
} from './rooms';
import {
  SMSEmailAlertsStateKey,
  reducer as SMSEmailAlertsReducer,
  saga as SMSEmailAlertsSaga,
} from './SMSEmailAlerts';
import {
  subscribersStateKey,
  reducer as subscribersReducer,
  saga as subscribersSaga,
} from './subscribers';
import {
  groupManagerStateKey,
  reducer as groupManagerReducer,
  saga as groupManagerSaga,
  utilitySaga as groupManagerUtilitySaga,
  utilityAttentionListSaga as groupManagerAttentionListUtilitySaga,
} from './groupManager';
import {
  notesStateKey,
  reducer as notesReducer,
  saga as notesSaga,
} from './notes';

import {
  customersStateKey,
  reducer as customersReducer,
  saga as customersSaga,
} from './customers';
import {
  operatorsStateKey,
  reducer as operatorsReducer,
  saga as operatorsSaga,
} from './operators';

import {
  emrMappingsStateKey,
  reducer as emrMappingsReducer,
  saga as emrMappingsSaga,
} from './emrMappings';

import { sdkStateKey, reducer as sdkReducer, saga as sdkSaga } from './SDK';

export const STATE_KEY = DATA_STATE_KEY;

export const logoutActionType = authActions.logout.type;

export const reducer = combineReducers({
  [loggedInUserStateKey]: loggedInUserReducer,
  [appStateKey]: appReducer,
  [sessionsStateKey]: sessionsReducer,
  [authStateKey]: authReducer,
  [alertsStateKey]: alertReducer,
  [subscribersStateKey]: subscribersReducer,
  [tenantStateKey]: tenantReducer,
  [deviceStateKey]: deviceReducer,
  [patientStateKey]: patientReducer,
  [userStateKey]: userReducer,
  [groupsStateKey]: groupsReducer,
  [versionStateKey]: versionReducer,
  [managmentStateKey]: managmentReducer,
  [monitorStateKey]: monitorReducer,
  [measurementSettingsStateKey]: measurementSettingsReducer,
  [roomsStateKey]: roomsReducer,
  [SMSEmailAlertsStateKey]: SMSEmailAlertsReducer,
  [notesStateKey]: notesReducer,
  [groupManagerStateKey]: groupManagerReducer,
  [customersStateKey]: customersReducer,
  [operatorsStateKey]: operatorsReducer,
  [emrMappingsStateKey]: emrMappingsReducer,
  [sdkStateKey]: sdkReducer,
});

export const sagas = function* root() {
  yield* all([
    fork(appSaga),
    fork(loggedInUserSaga),
    fork(tenantSaga),
    fork(tenantsListUtilitySaga),
    fork(sessionsSaga),
    fork(authSaga),
    fork(deviceSaga),
    fork(patientSaga),
    fork(userSaga),
    fork(groupsSaga),
    fork(versionSaga),
    fork(managmentSaga),
    fork(monitorSaga),
    fork(alertSaga),
    fork(alertSoundSaga),
    fork(subscribersSaga),
    fork(measurementSettingsSaga),
    fork(roomsSaga),
    fork(SMSEmailAlertsSaga),
    fork(notesSaga),
    fork(groupManagerUtilitySaga),
    fork(groupManagerAttentionListUtilitySaga),
    fork(groupManagerSaga),
    fork(customersSaga),
    fork(operatorsSaga),
    fork(emrMappingsSaga),
    fork(sdkSaga),
  ]);
};
