import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { getOperatorsColumns } from './utils';
import { StyledTable } from './styled';
import { OperatorModalType, OperatorType } from '../../modules/types';
import Connector, { PropsFromRedux } from './Connector';
import { DEFAULT_PAGINATION, MODAL_MODES } from 'src/utils/constants';
import { TableProps } from 'antd';
import { operatorsTableActions } from './constants';
import { UUID } from 'src/types/utility';

type Props = PropsFromRedux & {
  intl: IntlShape;
  isLoading: boolean;
  setModalState: Dispatch<SetStateAction<OperatorModalType>>;
  customerId: UUID;
  horizontalLayout: boolean;
};

const OperatorsTable = ({
  intl,
  operatorsList,
  isLoading,
  setModalState,
  // setOperatorForm,
  // setCurrentOperator,
  // deleteOperator,
  getOperators,
  tableParams,
  setTableParams,
  horizontalLayout,
  customerId,
  getOperatorsDeviceCount,
  deviceCount,
}: Props) => {
  const { pageSize, total = 0 } = tableParams;

  const columns = getOperatorsColumns(intl, deviceCount);
  // const actions = getTableActions(
  //   intl,
  //   setModalState,
  //   setOperatorForm,
  //   setCurrentOperator,
  //   deleteOperator,
  //   customerId,
  // );

  useEffect(() => {
    getOperators({
      page: DEFAULT_PAGINATION.PAGE - 1,
      limit: DEFAULT_PAGINATION.LIMIT,
      customerId: customerId,
    });
    getOperatorsDeviceCount(customerId);
  }, [getOperators, getOperatorsDeviceCount, customerId]);

  const handleTableChange: TableProps<OperatorType>['onChange'] = (
    pagination,
    _filters,
    sorter,
    _extra,
  ) => {
    getOperators({
      page: pagination.current ?? 1,
      limit: pagination.pageSize ?? DEFAULT_PAGINATION.LIMIT,
      customerId: customerId,
    });
    setTableParams({
      current: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? DEFAULT_PAGINATION.LIMIT,
      sortBy: {
        columnKey: sorter as string,
        order: sorter as string,
      },
    });
  };

  const handleSearch = (keyword: string) => {
    getOperators({
      page: DEFAULT_PAGINATION.PAGE - 1,
      limit: DEFAULT_PAGINATION.LIMIT,
      searchKeyword: keyword,
      customerId: customerId,
    });
    setTableParams({
      ...tableParams,
      current: 1,
    });
  };

  return (
    <StyledTable
      data={operatorsList || []}
      columns={columns}
      // actions={actions}
      loading={isLoading}
      horizontalLayout={horizontalLayout}
      withSearch
      searchFunction={handleSearch}
      // withAddButton
      addButtonText={
        <FormattedMessage {...operatorsTableActions.addOperator} />
      }
      addButtonOnClick={() =>
        setModalState({ mode: MODAL_MODES.ADD, visible: true })
      }
      pagination={
        total > pageSize && {
          ...tableParams,
          total: total,
        }
      }
      onChange={handleTableChange}
    />
  );
};

export default Connector(injectIntl(OperatorsTable));
