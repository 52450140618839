import { styled } from 'styled-components';

import Table from 'src/components/CrudCommonComponents/Table';
import { SpacedRow } from 'src/components/styled';
import ActionButton from 'src/components/general-ui/Buttons/ActionButton';

export const StyledTable = styled(Table)`
  .ant-table-cell {
    min-width: 4rem !important;
  }
`;

export const StyledSpacedRow = styled(SpacedRow)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: 50%;
  position: relative;
`;

export const StyledActionButton = styled(ActionButton)`
  margin-top: 0.3rem;
`;
