import React from 'react';
import MedicalReportsWrapper from 'src/routes/MedicalReports/components/MedicalReportsWrapper';
import MedicalReportV2 from 'src/routes/MedicalReports/MedicalReportV2';

const MedicalReportsV2Wrapper = (): JSX.Element => (
  <MedicalReportsWrapper>
    <MedicalReportV2 />
  </MedicalReportsWrapper>
);

export default MedicalReportsV2Wrapper;
