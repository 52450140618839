import { styled } from 'styled-components';
import { Tabs } from 'antd';
import { media } from 'src/utils/mediaQueryUtils';

export const StyledTabs = styled(Tabs)`
  display: block;
  height: 100%;
  color: ${props => props.theme.colors.individualDashboardLightText};
  .ant-tabs-tabpane {
    height: 100%;
  }

  .ant-tabs-nav {
    margin: 0;
    background-color: ${props =>
      props.theme.colors.individualDashboardBackground};
  }
  .ant-tabs-tab {
    font-size: 0.6rem;
    padding: 0.3rem 0;
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${props =>
      props.theme.colors.individualDashboardTabsText} !important;
  }
  .ant-tabs-ink-bar {
    border-bottom: 2px solid
      ${props => props.theme.colors.individualDashboardActiveBorder} !important;
  }
`;

export const BedOcuppancyBoxWrapper = styled.div`
  border: ${props =>
    `1px solid ${props.theme.colors.individualDashboardHeaderBorder}`};
  border-radius: 8px;
  padding-top: 4px;
  height: 38%;
  background-color: ${props =>
    props.theme.colors.measurementCardBackgroundColor};
  .ant-tabs-nav-list {
    ${media.small`
      padding: 0px;
      .ant-tabs-ink-bar {
        left: 0.7rem;
      }
    `}
  }
  .ant-tabs-content-holder {
    height: 100%;
    .ant-tabs-content {
      height: 100%;
    }
  }
`;
