import { createIntl, createIntlCache, defineMessages } from 'react-intl';
import locales from '../translations/locales';

export const supportedLocales = Object.freeze({
  en_US: 'en_US',
  he_IL: 'he_IL',
});

export const supportedLanguages = Object.freeze({
  en: 'en',
  he: 'he',
});

const localeToLanguageMapper = {
  [supportedLocales.en_US]: supportedLanguages.en,
  [supportedLocales.he_IL]: supportedLanguages.he,
};

const languageToLocaleMapper = {
  [supportedLanguages.en]: supportedLocales.en_US,
  [supportedLanguages.he]: supportedLocales.he_IL,
};

const languageMessages = {
  [supportedLanguages.en]: locales.en,
  [supportedLanguages.he]: locales.he,
};

export const DEFAULT_LOCALE = supportedLocales.en_US;

const findSupportedLocale = (localeToFind: keyof typeof supportedLocales) =>
  Object.values(supportedLocales).find(
    locale =>
      localeToFind && locale.toUpperCase() === localeToFind.toUpperCase(),
  );

// getFormattedLocale:
// input - locale (any locale in any syntax)
// output - if the input is one of the supported locales --> return the supported locale in the right syntax (xx_XX).
//          else --> return the default locale.
export const getFormattedLocale = (locale: keyof typeof supportedLocales) =>
  findSupportedLocale(locale) || DEFAULT_LOCALE;

export const getLanguageByLocale = (locale: keyof typeof supportedLocales) => {
  const formattedLocale = getFormattedLocale(locale);
  return localeToLanguageMapper[formattedLocale];
};

export const getLocaleByLanguage = (
  lang: keyof typeof languageToLocaleMapper,
) => languageToLocaleMapper[lang] || DEFAULT_LOCALE;

export const getLanguageMessagesByLanguage = (
  lang: keyof typeof languageMessages,
) => languageMessages[lang];

export const isSupportedLocale = (locale: keyof typeof supportedLocales) =>
  !!supportedLocales[locale];

export const DEFAULT_LANGUAGE = getLanguageByLocale(supportedLocales.en_US);

export const getSupportedLocalesRegularExp = () =>
  Object.values(supportedLocales).reduce(
    // @ts-ignore TODO: Fix when enough info about logic
    (acc, currentLocale) => `${acc}|${currentLocale}`,
  );

export const getLocaleFromUrlPath = (pathname: string) => {
  const localeRegexpPattern = new RegExp('([a-z]{2}_[A-Z]{2})', 'gi');
  const localePatternExecResult = localeRegexpPattern.exec(pathname);

  return (localePatternExecResult && localePatternExecResult[0]) ?? '';
};

export const getUrlPathWithoutLocale = (locale: string, url: string) => {
  const regexpSuffixPattern = new RegExp(`/${locale}(.*)`, 'gmi');
  const pathSuffixExecResult = regexpSuffixPattern.exec(url);
  return pathSuffixExecResult && pathSuffixExecResult[1]; // full path without the locale
};

export const getIntl = (messages: ReturnType<typeof defineMessages>) =>
  createIntl(
    {
      locale:
        getLocaleFromUrlPath(window.location.pathname)?.split('_')[0] || 'en',
      // @ts-ignore TODO: Fix when enough info about logic
      messages,
    },
    createIntlCache(),
  );
