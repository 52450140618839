import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import {
  actions as idbActions,
  selectors as idbSelectors,
} from 'src/routes/IndividualDashboard/modules/dashboard/slice';

const mapStateToProps = (state: RootState) => ({
  patient: idbSelectors.getPatientDetails(state),
  medicalConditions: idbSelectors.getPatientMedicalConditions(state),
  medicalConditionsStatus:
    idbSelectors.getPatientMedicalConditionsStatus(state),
});

const mapActionCreators = {
  getPatientMedicalConditions: idbActions.getPatientMedicalConditions,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
