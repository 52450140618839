import { styled } from 'styled-components';
import { Checkbox } from 'antd';

export const EachColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  .ant-form-item {
    width: 300px;
  }
`;

export const FormSection = styled.div`
  display: flex;
`;
export const EachRow = styled.div`
  display: flex;
  align-self: flex-end;
  .timezone-offset {
    width: 100px;
  }
  .timezone-id {
    width: 270px;
  }
`;

export const PermissionsCheckboxWrap = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;

  label {
    margin: 1rem 0;
  }
`;
