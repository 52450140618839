import React from 'react';
import DeviceWrapper from 'src/routes/Devices/components/DeviceWrapper';
import { DevicesTableContainer } from 'src/routes/Customers/components/DevicesTab/styled';
import { PropsFromRedux } from 'src/routes/Customers/components/TenantsTab/Connector';
import { CreateCustomerPayload } from 'src/routes/Customers/modules/types';
type Props = PropsFromRedux & {
  currentCustomer: CreateCustomerPayload;
};
const DevicesTab = ({ currentCustomer }: Props) => {
  return (
    <DevicesTableContainer>
      <DeviceWrapper
        currentCustomer={currentCustomer}
        horizontalLayout={true}
      />
      ;
    </DevicesTableContainer>
  );
};

export default DevicesTab;
