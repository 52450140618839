import React, { useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import Connector, { PropsFromRedux } from './Connector';
import OperatorsTable from './components/OperatorsTable';
import OperatorStepWizard from './components/OperatorWizard';

import ActionButton from 'src/components/general-ui/Buttons/ActionButton';
import { OperatorModalType } from './modules/types';
import { MODAL_MODES } from 'src/utils/constants';
import { CustomerType } from '../../modules/types';
import { Wrapper, OperatorModal } from './styled';

type Props = PropsFromRedux & { currentCustomer: CustomerType };

const OperatorsPage = ({
  createOperator,
  editOperator,
  operatorForm,
  resetFormAndCurrentOperator,
  currentCustomer,
}: Props): JSX.Element => {
  const [modalState, setModalState] = useState<OperatorModalType>({
    mode: '',
    visible: false,
  });

  const handleSave = () => {
    setModalState(prevState => ({ ...prevState, mode: '', visible: false }));
    if (modalState.mode === MODAL_MODES.ADD) {
      createOperator(currentCustomer?.id);
      return;
    }
    editOperator(currentCustomer?.id);
  };

  return (
    <Wrapper>
      <OperatorsTable
        horizontalLayout={true}
        setModalState={setModalState}
        customerId={currentCustomer?.id}
      />
      <OperatorModal
        destroyOnClose
        className="operator-modal"
        title={<FormattedMessage {...messages.manageOperator} />}
        centered
        open={modalState.visible}
        onOk={handleSave}
        onCancel={() => {
          resetFormAndCurrentOperator();
          setModalState(prevState => ({
            ...prevState,
            mode: '',
            visible: false,
          }));
        }}
        footer={[
          <ActionButton
            className="modal-cancel-btn"
            key="cancel"
            onClick={() => {
              resetFormAndCurrentOperator();
              setModalState(prevState => ({
                ...prevState,
                mode: '',
                visible: false,
              }));
            }}
          >
            <FormattedMessage {...messages.cancel} />
          </ActionButton>,
          <ActionButton
            key="submit"
            onClick={handleSave}
            disabled={!operatorForm.name}
          >
            {modalState.mode && (
              <FormattedMessage {...modesMessages[modalState.mode]} />
            )}
          </ActionButton>,
        ]}
      >
        <OperatorStepWizard
          customerId={currentCustomer?.id}
          modalMode={modalState.mode}
        />
      </OperatorModal>
    </Wrapper>
  );
};

const modesMessages = defineMessages({
  ADD: {
    defaultMessage: 'Add Operator',
  },
  EDIT: {
    defaultMessage: 'Edit Operator',
  },
});

const messages = defineMessages({
  operators: {
    defaultMessage: 'Operators',
  },
  manageOperator: {
    defaultMessage: 'Manage Operator',
  },

  save: {
    defaultMessage: 'Save',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
});

export default Connector(OperatorsPage);
