import { all, put, takeLatest } from 'typed-redux-saga/macro';

import { actions as deviceActions } from 'src/redux/data/device';
import { actions as patientActions } from 'src/redux/data/patient';
import { actions as roomActions } from 'src/redux/data/rooms';
import { actions as alertActions } from 'src/redux/data/alerts';
import { actions } from './slice';

function* onPageMount() {
  yield* all([
    put(deviceActions.getDevicesList()),
    put(deviceActions.fetchDeviceConnectionForCurrentTenant()),
    put(patientActions.fetchAllPatients()),
    put(roomActions.onLoadRoomsList({})),
    put(alertActions.getThresholdsList()),
    put(alertActions.getBaselineThresholdsList()),
    put(alertActions.getPatientsBedActivityAlertStatus([])),
    put(alertActions.getPatientsAlertsToggleSettings()),
  ]);
}

export default function* watchNursesStationActions() {
  yield* all([takeLatest(actions.nurseStationPageMounted, onPageMount)]);
}
