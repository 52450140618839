import { styled } from 'styled-components';
import Modal from 'src/components/Modal';

export const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    background-color: ${props => props.theme.colors.simpleModalBackground};
  }
  .ant-modal-body {
    padding: 0 3rem;
    overflow: auto;
    .ant-form-item-explain-error {
      font-size: 0.7rem;
      min-height: 1rem;
    }
  }
`;

export const FormWrapper = styled.div`
  .ant-form-item {
    .ant-form-item-row {
      justify-content: space-between;
    }
    .ant-form-item-control {
      flex: none;
      width: 8rem;
      .ant-form-item-control-input {
        width: 8rem;
      }
    }
  }
`;
