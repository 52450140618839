import React, { lazy } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import Connector, { PropsFromRedux } from './Connector';
import { DetailedInfoContainer, MainTitle, Wrapper } from './styled';
import EventsSummaryBars from './components/EventsSummaryBars';
import { Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { linkParamsType } from 'src/routes/MedicalReports/types';
import { deepEqual } from 'src/utils/comparators';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { MEDICAL_REPORT_DATE_FORMAT } from 'src/utils/timeUtils';
import { removeDuplicatesFromArrayOfObjects } from 'src/utils/dataUtils';
import FooterComponent from 'src/routes/MedicalReports/components/FooterComponent';
import RenderOnViewportEntry from 'src/routes/MedicalReports/components/RenderOnViewportEntry';

const HeaderComponent = lazy(() => import('./components/HeaderComponent'));
const LastDayAbnormalityTable = lazy(
  () => import('./components/LastDayAbnormalityTable'),
);
const Last3DaysAbnormalityTable = lazy(
  () => import('./components/Last3DaysAbnormalityTable'),
);
const IdbComponents = lazy(() => import('./components/IdbComponents'));
const DevicesPieChart = lazy(() => import('./components/DevicesPieChart'));

dayjs.extend(customParseFormat);

type Props = PropsFromRedux & { intl: IntlShape };
const MedicalReportPage = ({ intl, patientsData }: Props): JSX.Element => {
  const { tenantId, date } = useParams<linkParamsType>();
  const reportDate = dayjs(date, MEDICAL_REPORT_DATE_FORMAT);
  const abnormality72hEndDate = reportDate.subtract(1, 'day').format('MM/DD');
  const abnormality72hStartDate = reportDate.subtract(3, 'day').format('MM/DD');

  return (
    <Wrapper>
      <RenderOnViewportEntry style={{ minHeight: '90px' }}>
        <HeaderComponent />
      </RenderOnViewportEntry>
      <RenderOnViewportEntry style={{ minHeight: '760px' }}>
        <EventsSummaryBars
          tenantId={tenantId}
          date={date}
          title={intl.formatMessage(messages.title)}
        />
      </RenderOnViewportEntry>
      <RenderOnViewportEntry>
        <LastDayAbnormalityTable
          tenantId={tenantId}
          date={date}
          title={intl.formatMessage(messages.abnormalityTable24HoursTitle, {
            date: reportDate.format('MM/DD'),
          })}
          locale={{
            emptyText: intl.formatMessage(messages.noLastDayAbnormalities),
          }}
        />
      </RenderOnViewportEntry>
      <RenderOnViewportEntry>
        <Last3DaysAbnormalityTable
          tenantId={tenantId}
          date={date}
          locale={{
            emptyText: intl.formatMessage(messages.no3dAbnormalities),
          }}
          title={intl.formatMessage(messages.abnormalityTable3DaysTitle, {
            startDate: abnormality72hStartDate,
            endDate: abnormality72hEndDate,
          })}
        />
      </RenderOnViewportEntry>
      {!!patientsData?.length && (
        <DetailedInfoContainer>
          <MainTitle>
            <FormattedMessage {...messages.detailedInformationTitle} />
          </MainTitle>
          <Typography>
            <FormattedMessage {...messages.detailedInformationSubTitle} />
          </Typography>
          <Typography>
            <FormattedMessage {...messages.detailedInformationSecondSubTitle} />
          </Typography>
        </DetailedInfoContainer>
      )}
      {removeDuplicatesFromArrayOfObjects(patientsData, ['patientId'])
        .filter(patient => !!patient.isConsented)
        .map(patient => (
          <RenderOnViewportEntry
            key={patient.patientId}
            style={{ minHeight: '630px' }}
          >
            <IdbComponents
              tenantId={tenantId}
              date={date}
              key={patient.patientId}
              {...patient}
            />
          </RenderOnViewportEntry>
        ))}
      <RenderOnViewportEntry style={{ minHeight: '1770px' }}>
        <DevicesPieChart
          title={intl.formatMessage(messages.operationalSummaryTitle)}
          tenantId={tenantId}
          date={date}
          showDevicesConsent
        />
      </RenderOnViewportEntry>
      <FooterComponent />
    </Wrapper>
  );
};

const messages = defineMessages({
  title: {
    defaultMessage: 'Summary',
  },
  abnormalityTable24HoursTitle: {
    defaultMessage: 'Abnormality Indications over prior 24 hours ({date})',
  },
  noLastDayAbnormalities: {
    defaultMessage: 'No abnormalities detected in the past 24 hours.',
  },
  no3dAbnormalities: {
    defaultMessage: 'No abnormalities detected in the past 72 hours.',
  },
  abnormalityTable3DaysTitle: {
    defaultMessage:
      'Abnormality Indications over prior 72 hours ({startDate} -{endDate})',
  },
  detailedInformationTitle: {
    defaultMessage: 'Detailed Information',
  },
  detailedInformationSubTitle: {
    defaultMessage:
      '7-day overview for patients with recorded medical events over prior 24 hours',
  },
  detailedInformationSecondSubTitle: {
    defaultMessage: 'Click on patient name to access individual dashboard',
  },
  operationalSummaryTitle: {
    defaultMessage: 'Operational Summary',
  },
  customerNameTitle: {
    defaultMessage: 'Customer Name: {data}',
  },
  tenantNameTitle: {
    defaultMessage: 'Tenant Name: {data}',
  },
  generationDateTitle: {
    defaultMessage: 'Generation date: {data}',
  },
});

export default Connector(
  injectIntl(
    React.memo(MedicalReportPage, (oldProps, newProps) =>
      deepEqual(oldProps, newProps),
    ),
  ),
);
