import { hasAllPermissions } from 'src/utils/permissions';
import permissions from 'src/permissions';

export const devicesFields = {
  CUSTOMER_ID: 'customerId',
  CUSTOMER_NAME: 'customerName',
  OPERATOR_NAME: 'operatorName',
  TENANT_NAME: 'tenantName',
  TENANT_ID: 'tenantId',
  BED_ID: 'bedId',
  ROOM_ID: 'roomId',
  OPERATOR_ID: 'operatorId',
  MOUNT_TYPE: 'mountingType',
  WIRING_TYPE: 'wiringType',
  ELECTRICAL_OUTLET_REPLACEMENT: 'electricalOutletReplacement',
  MANUFACTURER_ID: 'manufacturerId',
  NOTES: 'notes',
  DEVICE_ID: 'deviceId',
};

export const isSysAdmin = () =>
  hasAllPermissions(
    permissions.VIEW_DEVICES_ADD_BUTTON,
    permissions.VIEW_DEVICES_TENANT_ACTIONS,
    permissions.VIEW_DEVICES_TENANT_COLUMN,
    permissions.VIEW_DEVICES_EDIT_DEVICE_ACTION,
    permissions.VIEW_DEVICES_DELETE_DEVICE_ACTION,
  );
