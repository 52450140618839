import {
  extractBooleanEnvVar,
  extractNumberEnvVar,
  extractStringEnvVar,
} from './utils';

const APP_CONFIG = {
  ENV: extractStringEnvVar('REACT_APP_ENV'),
  ENV_NAME: extractStringEnvVar('REACT_APP_ENV_NAME'),
  API_URL: extractStringEnvVar('REACT_APP_API_URL'),
  // GOOGLE_ANALYTICS_TRACKER_ID: extractStringEnvVar(
  //   'REACT_APP_GOOGLE_ANALYTICS_TRACKER_ID',
  // ),
  APPLICATION_ID: extractStringEnvVar('REACT_APP_APPLICATION_ID'),
  APPLICATION_NAME: extractStringEnvVar('REACT_APP_APPLICATION_NAME'),
  MANUALS_BUCKET_LINK: extractStringEnvVar('REACT_APP_MANUALS_BUCKET'),
  HRI_ENABLED: extractBooleanEnvVar('REACT_APP_HRI_ENABLED'),
  SAVE_RAW_DATA_DEFAULT: extractBooleanEnvVar(
    'REACT_APP_SAVE_RAW_DATA_DEFAULT',
  ),
  RAW_DATA_TOGGLE_ENABLED: extractBooleanEnvVar(
    'REACT_APP_RAW_DATA_TOGGLE_ENABLED',
  ),
  DEFAULT_SETTINGS_ID: extractStringEnvVar('REACT_APP_DEFAULT_SETTINGS_ID'),
  ALERT_SOUND_REPEAT_TIMES: extractNumberEnvVar(
    'REACT_APP_ALERT_SOUND_REPEAT_TIMES',
  ),
  SESSION_TIMEOUT_SEC: extractNumberEnvVar('REACT_APP_SESSION_TIMEOUT_SEC'),
};

export default APP_CONFIG;

// import ProdConfig from './ProdConfig';
// import DevConfig from './DevConfig';

// const appConfig = window.BIOT_MED_APP_CONFIG || {};

// const APP_CONFIG =
// 	    appConfig ? appConfig : process.env.NODE_ENV === 'development' ?
//             DevConfig : ProdConfig;

// const APP_CONFIG = process.env.NODE_ENV === 'development' ? DevConfig : ProdConfig;

// export const mergeAppConfig = newConf => {
// 	APP_CONFIG = {
// 		...APP_CONFIG,
// 		newConf,
// 	};
// };
