import reducer, {
  actions,
  selectors,
  STATE_KEY,
} from 'src/redux/data/operators/modules/slice';
import saga from 'src/redux/data/operators/modules/saga';

import Component from './Component';

export { saga, actions, reducer, selectors, STATE_KEY };
export default Component;
