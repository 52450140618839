import { createSlice, createSelector, createAction } from '@reduxjs/toolkit';
import { MODAL_STATUS } from 'src/components/Modal/constants';
import { DATA_STATE_KEY, DATA_FETCHING_STATUS } from '../../constants';
import { DEVICE_TYPE } from 'src/routes/FirmwareManager/Versions/constants';

export const STATE_KEY = 'version';

export const INITIAL_STATE = {
  versionsList: { data: null, status: null },
  latestVersions: { data: null, latestVersionsStatus: null },
  versionsByDeviceType_130H: { data: null, status: null },
  versionsByDeviceType_130H_Plus: { data: null, status: null },
  modalStatus: MODAL_STATUS.INITIAL,
};

/* eslint-disable no-param-reassign */
const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    setLatestVersionsStatus(state, action) {
      state.latestVersions.status = action.payload;
    },
    setLatestVersionsSuccess(state, action) {
      state.latestVersions.data = action.payload;
      state.latestVersions.status = DATA_FETCHING_STATUS.SUCCESS;
    },

    setVersionsByDeviceStatus(state, action) {
      if (action.payload.deviceType === DEVICE_TYPE.DEVICE_130H) {
        state.versionsByDeviceType_130H.status = action.payload;
        return;
      }
      state.versionsByDeviceType_130H_Plus.status = action.payload;
    },
    setVersionsByDeviceSuccess(state, action) {
      if (action.payload.deviceType === DEVICE_TYPE.DEVICE_130H) {
        state.versionsByDeviceType_130H.data = action.payload.data;
        state.versionsByDeviceType_130H.status = DATA_FETCHING_STATUS.SUCCESS;
        return;
      }
      state.versionsByDeviceType_130H_Plus.data = action.payload.data;
      state.versionsByDeviceType_130H_Plus.status =
        DATA_FETCHING_STATUS.SUCCESS;
    },

    setVersionListStatus(state, action) {
      state.versionsList.status = action.payload;
    },
    onLoadVersionListSuccess(state, action) {
      state.versionsList.data = action.payload;
      state.versionsList.status = DATA_FETCHING_STATUS.SUCCESS;
    },

    setModalStatus(state, action) {
      state.modalStatus = action.payload;
    },
  },
  extraReducers: {},
});
/* eslint-enable no-param-reassign */

const getState = state => state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;

export const selectors = {
  getVersionsList: createSelector(getState, state => state.versionsList.data),
  getLatestVersions: createSelector(
    getState,
    state => state.latestVersions.data,
  ),
  getVersionsByDeviceType_130H: createSelector(
    getState,
    state => state.versionsByDeviceType_130H.data,
  ),
  getVersionsByDeviceType_130H_Plus: createSelector(
    getState,
    state => state.versionsByDeviceType_130H_Plus.data,
  ),
  isLoadingLatestVersion: createSelector(
    getState,
    state => state.latestVersions.status === DATA_FETCHING_STATUS.LOADING,
  ),
  isLoadingVersionsByDeviceType: createSelector(
    getState,
    state =>
      state.versionsByDeviceType_130H.status ||
      state.versionsByDeviceType_130H_Plus.status ===
        DATA_FETCHING_STATUS.LOADING,
  ),
  getValidVersionsList: createSelector(getState, state =>
    state.versionsList.data.filter(version => version.valid),
  ),
  getStatus: createSelector(getState, state => state.status),
  getModalStatus: createSelector(getState, state => state.modalStatus),
  getIsLoading: createSelector(
    getState,
    state => state.versionsList.status === DATA_FETCHING_STATUS.LOADING,
  ),
};

const extraActions = {
  onLoadVersionList: createAction(`${STATE_KEY}/onLoadVersionList`),
  onLoadLatestVersions: createAction(`${STATE_KEY}/onLoadLatestVersions`),
  onLoadVersionsByDeviceType: createAction(
    `${STATE_KEY}/onLoadVersionsByDeviceType`,
  ),
  onUploadVersion: createAction(`${STATE_KEY}/onUploadVersion`),
  onUpdateVersion: createAction(`${STATE_KEY}/onUpdateVersion`),
  deleteVersion: createAction(`${STATE_KEY}/deleteVersion`),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
