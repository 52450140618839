import { styled } from 'styled-components';

export const WizardWrapper = styled.div`
  min-height: 500px;

  .ant-steps.operator-wizard-steps {
    padding: 2rem 0;
    .ant-steps-item-icon {
      background-color: #87b7c5;
      border-color: #87b7c5;
    }
    .ant-steps-item-wait .ant-steps-item-icon {
      background-color: rgba(0, 0, 0, 0.06);
      border-color: transparent;
    }
    .ant-steps-item-finish {
      .ant-steps-item-icon {
        background-color: rgba(135, 183, 197, 0.5);
        border-color: rgba(135, 183, 197, 0.5);
        color: #fff;
      }
      .ant-steps-item-content > .ant-steps-item-title::after {
        background-color: #87b7c5;
      }
    }
  }
`;

export const StepsContent = styled.div`
  min-height: 400px;
  margin-bottom: 1rem;
`;

export const StepsAction = styled.div`
  width: 8rem;
  display: flex;
  justify-content: space-between;
`;
