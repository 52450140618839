import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';

import UsersTable from 'src/routes/Users';
import { CustomerType } from '../../modules/types';
import { UsersUnderCustomersWrapper } from './styled';

type Props = {
  intl: IntlShape;
  currentCustomer: CustomerType;
  horizontalLayout: boolean;
};

const UsersTab = ({ currentCustomer }: Props) => {
  return (
    <UsersUnderCustomersWrapper>
      <UsersTable currentCustomer={currentCustomer} />
    </UsersUnderCustomersWrapper>
  );
};

export default injectIntl(UsersTab);
