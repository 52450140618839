import React from 'react';
import dayjs from 'dayjs';

import permissions from 'src/permissions';
import { messages } from './constants';
import { redirectToPatientIDB } from 'src/utils/patientHelpers';
import { hasAnyPermissions } from 'src/utils/permissions';
import {
  convertToTimezone,
  MEDICAL_REPORT_DATE_FORMAT,
  US_12H_DATE_AND_TIME_FORMAT,
} from 'src/utils/timeUtils';
import TenantsHistoryCell from 'src/routes/Devices/components/TenantsHistoryCell/TenantsHistoryCell';
import BurgerActions from './BurgerActions/BurgerActions';
import BackendService from 'src/services/BackendService';
import { prepareFiltersForQueryParamsObj } from 'src/services/utils';

import { initFileDownloadUsingAnchorElement } from 'src/utils/fileUtils';
import {
  actionSuccessNotification,
  notifyUserByActionTypeAndCode,
} from 'src/utils/errorHandling/notifications';
import { fileActions } from 'src/utils/constants';

export const getActionPermissionsMap = (
  intl,
  history,
  setActiveActionRow,
  setIsDeleteConfirmationVisible,
  setIsEditModalVisible,
  setIsDetachConfirmationVisible,
  setIsAssignDeviceModalVisible,
  setisDeviceInfoModalVisible,
  setIsResetConfirmationVisible,
) => ({
  [permissions.VIEW_DEVICES_DELETE_DEVICE_ACTION]: [
    {
      key: 'delete device',
      name: intl.formatMessage(messages.deleteDevice),
      isDisabled: ({ record }) => record?.tenantName,
      onClick: (_, { record }) => {
        setActiveActionRow(record);
        setIsDeleteConfirmationVisible(true);
      },
    },
    {
      key: 'reset device',
      name: intl.formatMessage(messages.resetDevice),
      isDisabled: ({ record }) => !record?.connectionStatus?.connected,
      onClick: (_, { record }) => {
        setActiveActionRow(record);
        setIsResetConfirmationVisible(true);
      },
    },
  ],
  [permissions.VIEW_DEVICES_EDIT_DEVICE_ACTION]: [
    {
      key: 'edit device',
      name: intl.formatMessage(messages.editDevice),
      // disabled for now since BE is not ready yet
      // isDisabled: () => true,
      onClick: (_, { record }) => {
        setActiveActionRow(record);
        setIsEditModalVisible(true);
      },
    },
  ],
  [permissions.VIEW_DEVICES_TENANT_ACTIONS]: [
    {
      key: 'detach',
      name: intl.formatMessage(messages.detachTenant),
      isDisabled: ({ record }) => !record?.tenantName,
      onClick: (_, { record }) => {
        setActiveActionRow(record);
        setIsDetachConfirmationVisible(true);
      },
    },
    {
      key: 'assign',
      name: intl.formatMessage(messages.assignTenant),
      isDisabled: ({ record }) => record?.tenantName,
      onClick: (_, { record }) => {
        setActiveActionRow(record);
        setIsAssignDeviceModalVisible(true);
      },
    },
  ],
  [permissions.VIEW_DEVICES_MONITOR_ACTION]: [
    {
      key: 'dashboard',
      name: intl.formatMessage(messages.dashboard),
      onClick: (_, { record }) =>
        redirectToPatientIDB(record?.assignedPatientId),
    },
  ],
  [permissions.VIEW_DEVICE_INFO_ACTION]: [
    {
      key: 'info',
      name: intl.formatMessage(messages.info),
      onClick: (_, { record }) => {
        setActiveActionRow(record);
        setisDeviceInfoModalVisible(true);
      },
    },
  ],
});

export const getDevicesColumns = (
  intl,
  userType,
  timezone,
  serverSortingColumns,
  actions,
  isAllDevicesView,
  currentCustomer,
) => {
  return [
    isAllDevicesView
      ? {
          title: intl.formatMessage(messages.manufacturerId),
          dataIndex: 'manufacturerId',
          key: 'manufacturerId',
          fixed: 'left',
          sorter: serverSortingColumns.includes('manufacturerId'),
        }
      : {
          title: intl.formatMessage(messages.manufacturerId),
          dataIndex: 'deviceId',
          key: 'deviceId',
          fixed: 'left',
          sorter: serverSortingColumns.includes('deviceId'),
        },
    !currentCustomer
      ? {
          title: intl.formatMessage(messages.customer),
          dataIndex: 'customerName',
          key: 'customerName',
          sorter: serverSortingColumns.includes('customerName'),
          render: (_, { customerName = '-' }) => `${customerName}`,
        }
      : null,
    {
      title: intl.formatMessage(messages.operator),
      dataIndex: 'operatorName',
      key: 'operatorName',
      sorter: serverSortingColumns.includes('operatorName'),
      render: (_, { operatorName = '-' }) => `${operatorName}`,
    },
    hasAnyPermissions(permissions.VIEW_DEVICES_TENANT_COLUMN)
      ? {
          title: intl.formatMessage(messages.tenant),
          dataIndex: 'tenantName',
          key: 'tenantName',
          sorter: serverSortingColumns.includes('tenantName'),
        }
      : null,
    {
      title: intl.formatMessage(messages.location),
      dataIndex: 'roomName',
      key: 'roomName',
      render: (_, { roomName = '-', bedName = '-' }) =>
        `${roomName} ${bedName}`,
      sorter: serverSortingColumns.includes('roomName'),
    },
    {
      title: intl.formatMessage(messages.connectivityStatus),
      dataIndex: 'connected',
      key: 'connected',
      render: (_, record) =>
        record?.connectionStatus?.connected ? 'Connected' : 'Disconnected',
      sorter: serverSortingColumns.includes('connected'),
    },
    {
      title: intl.formatMessage(messages.lastConnectionTime),
      dataIndex: 'lastModifiedTimestamp',
      key: 'lastModifiedTimestamp',
      render: (_, record) =>
        record?.connectionStatus?.lastModifiedTimestamp
          ? convertToTimezone(
              record?.connectionStatus?.lastModifiedTimestamp,
              timezone,
            ).format(US_12H_DATE_AND_TIME_FORMAT)
          : '-',
      sorter: serverSortingColumns.includes('lastModifiedTimestamp'),
    },
    {
      title: intl.formatMessage(messages.monitoringDays),
      dataIndex: 'monitoringDays',
      key: 'monitoringDays',
      render: (_, { monitoringDays = 0 }) => monitoringDays,
      sorter: serverSortingColumns.includes('monitoringDays'),
    },
    {
      title: intl.formatMessage(messages.activationDate),
      dataIndex: 'activationDate',
      key: 'activationDate',
      render: (_, { activationDate }) =>
        activationDate ? dayjs(activationDate).format('YYYY-MM-DD') : '-',
      sorter: serverSortingColumns.includes('activationDate'),
    },
    {
      title: intl.formatMessage(messages.mountingType),
      dataIndex: 'mountingType',
      key: 'mountingType',
      sorter: serverSortingColumns.includes('mountingType'),
    },
    {
      title: intl.formatMessage(messages.wiringType),
      dataIndex: 'wiringType',
      key: 'wiringType',
      sorter: serverSortingColumns.includes('wiringType'),
    },
    {
      title: intl.formatMessage(messages.electricalOutletReplacement),
      dataIndex: 'electricalOutletReplacement',
      key: 'electricalOutletReplacement',
      render: (_, { electricalOutletReplacement }) =>
        electricalOutletReplacement ? 'Yes' : 'No',
      sorter: serverSortingColumns.includes('electricalOutletReplacement'),
    },
    {
      title: intl.formatMessage(messages.tenantHistory),
      dataIndex: 'tenantsHistory',
      key: 'tenantsHistory',
      sorter: serverSortingColumns.includes('tenantsHistory'),
      render: (_, { tenantsHistory }) => (
        <>
          <TenantsHistoryCell tenantsHistory={tenantsHistory} />
        </>
      ),
    },
    {
      title: intl.formatMessage(messages.notes),
      dataIndex: 'notes',
      key: 'notes',
      sorter: serverSortingColumns.includes('notes'),
    },
    {
      title: 'Action',
      key: 'operation',
      render: (text, record, index) => (
        <BurgerActions
          index={index}
          text={text}
          actions={actions}
          record={record}
        />
      ),
    },
  ];
};

export const getSortingColumns = isAllDevicesView =>
  isAllDevicesView
    ? ['manufacturerId', 'lastModifiedTimestamp', 'connected']
    : [
        'tenantName',
        'roomName',
        'operatorName',
        'customerName',
        'mountingType',
        'wiringType',
        'electricalOutletReplacement',
      ];

const onCsvDataExportSuccess = (
  response,
  downloadedFileName,
  setIsCsvLoading,
) => {
  const type = response.headers['content-type'];
  const blob = new Blob([response.data], {
    type: type,
    encoding: 'UTF-8',
  });

  initFileDownloadUsingAnchorElement(
    URL.createObjectURL(blob),
    downloadedFileName,
  );

  setIsCsvLoading(false);
  actionSuccessNotification(fileActions.DOWNLOAD, downloadedFileName);
};
const onCsvDataExportError = (err, downloadedFileName, setIsCsvLoading) => {
  notifyUserByActionTypeAndCode(fileActions.DOWNLOAD, downloadedFileName, err);
  setIsCsvLoading(false);
  console.log(err);
};

export const exportAdminCsvData = (tableParams, setIsCsvLoading) => {
  if (!tableParams) {
    return;
  }
  setIsCsvLoading(true);
  const { searchKeyword = '', filters, sortBy, viewMode } = tableParams;

  const downloadedFileName = `System Admin_${viewMode === 'allDevices' ? 'Devices' : 'Locations'}_${dayjs().format(MEDICAL_REPORT_DATE_FORMAT)}.csv`;

  if (viewMode === 'allDevices') {
    BackendService.exportCsvAdminDevices({
      paginationParams: { page: 0, limit: 100000 },
      searchKeyword,
      filters: prepareFiltersForQueryParamsObj(filters, [
        'activationDate',
        'etcDate',
      ]),
      sortBy,
    })
      .then(response =>
        onCsvDataExportSuccess(response, downloadedFileName, setIsCsvLoading),
      )
      .catch(err =>
        onCsvDataExportError(err, downloadedFileName, setIsCsvLoading),
      );
    return;
  }
  BackendService.exportCsvAdminDeviceLocations({
    paginationParams: { page: 0, limit: 100000 },
    searchKeyword,
    filters: prepareFiltersForQueryParamsObj(filters),
    sortBy,
  })
    .then(response =>
      onCsvDataExportSuccess(response, downloadedFileName, setIsCsvLoading),
    )
    .catch(err =>
      onCsvDataExportError(err, downloadedFileName, setIsCsvLoading),
    );
};
export const exportCsvData = (tableParams, setIsCsvLoading) => {
  if (!tableParams) {
    return;
  }
  setIsCsvLoading(true);
  const { searchKeyword = '', filters, sortBy, viewMode } = tableParams;

  const downloadedFileName = `Tenant Admin_${viewMode === 'allDevices' ? 'Devices' : 'Locations'}_${dayjs().format(MEDICAL_REPORT_DATE_FORMAT)}.csv`;

  if (viewMode === 'allDevices') {
    BackendService.exportCsvDevices({
      paginationParams: { page: 0, limit: 100000 },
      searchKeyword,
      filters: prepareFiltersForQueryParamsObj(filters, [
        'activationDate',
        'etcDate',
      ]),
      sortBy,
    })
      .then(response =>
        onCsvDataExportSuccess(response, downloadedFileName, setIsCsvLoading),
      )
      .catch(err =>
        onCsvDataExportError(err, downloadedFileName, setIsCsvLoading),
      );
    return;
  }
  BackendService.exportCsvDeviceLocations({
    paginationParams: { page: 0, limit: 100000 },
    searchKeyword,
    filters: prepareFiltersForQueryParamsObj(filters),
    sortBy,
  })
    .then(response =>
      onCsvDataExportSuccess(response, downloadedFileName, setIsCsvLoading),
    )
    .catch(err =>
      onCsvDataExportError(err, downloadedFileName, setIsCsvLoading),
    );
};
