import React, { useEffect, Dispatch, SetStateAction } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { DatePicker, Form, Input } from 'antd';
import {
  devicesAdvancedSearchFields,
  advancedSearchMessages,
  devicesAdvancedSearchLabels,
} from '../../components/AdvancedSearch/constants';
import { ModalWrapper, FormWrapper } from './styled';
import { extractSelectOptions } from 'src/components/CrudCommonComponents/utils';
import SelectBoxForm from 'src/components/general-ui/SelectBoxForm';
import { UUID } from 'src/types/utility';
import Connector, { PropsFromRedux } from './Connector';
import { hasSAPermissions } from 'src/utils/permissions';
import { CreateCustomerPayload } from 'src/routes/Customers/modules/types';
import { Dayjs } from 'dayjs';

type Props = PropsFromRedux & {
  intl: IntlShape;
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  currentCustomer?: CreateCustomerPayload | null;
  enabledFields: Record<string, boolean>;
  initialValues: Record<string, string | boolean | Dayjs> | null;
  onOk: (values: Record<string, string | boolean | Dayjs>) => void;
  isAllDevicesView?: boolean;
};

const AdvancedSearchModal = ({
  intl,
  isModalVisible,
  setIsModalVisible,
  getCustomers,
  customersList,
  getTenantsByCustomer,
  tenantsByCustomerList,
  getLocationMountOptions,
  locationMountOptions,
  enabledFields,
  initialValues,
  onOk,
  isAllDevicesView = true,
  ...rest
}: Props) => {
  const [form] = Form.useForm();
  const formCustomerId = form.getFieldValue(
    devicesAdvancedSearchFields.CUSTOMER_ID,
  ) as UUID;
  const formCustomerIdIstouched = form.isFieldTouched(
    devicesAdvancedSearchFields.CUSTOMER_ID,
  );

  useEffect(() => {
    if (isModalVisible) {
      form.resetFields();
      // TODO: here to add reset table params in order to clear stored initial values
    }
  }, [isModalVisible, form]);

  useEffect(() => {
    if (!locationMountOptions) {
      getLocationMountOptions();
    }

    if (!formCustomerId) {
      return;
    }
    getTenantsByCustomer({
      customerID: formCustomerId,
    });
  }, [
    getTenantsByCustomer,
    formCustomerId,
    getLocationMountOptions,
    locationMountOptions,
  ]);

  useEffect(() => {
    if (
      enabledFields[devicesAdvancedSearchFields.CUSTOMER_ID] &&
      hasSAPermissions()
    ) {
      getCustomers();
    }
  }, [getCustomers]);

  useEffect(() => {
    if (!formCustomerIdIstouched) {
      return;
    }
    form.setFieldValue(devicesAdvancedSearchFields.TENANT_ID, undefined);
  }, [formCustomerId]);

  const resetFieldsToUndefined = () => {
    Object.keys(enabledFields).map(field =>
      form.setFieldValue(field, undefined),
    );
  };

  const deviceIdFieldName = isAllDevicesView
    ? devicesAdvancedSearchFields.MANUFACTURER_ID
    : devicesAdvancedSearchFields.DEVICE_ID;

  return (
    <ModalWrapper
      title={intl.formatMessage(advancedSearchMessages.formTitle)}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      okButtonText={intl.formatMessage(advancedSearchMessages.applyBtn)}
      withCancelButton={false}
      withResetButton={true}
      resetForm={resetFieldsToUndefined}
      onOk={form.submit}
      destroyOnClose
      onClose={form.resetFields}
      {...rest}
    >
      {form ? (
        <Form
          form={form}
          onFinish={(values: Record<string, string | boolean | Dayjs>) => {
            onOk(values);
          }}
          scrollToFirstError
          layout="horizontal"
        >
          {() => (
            <>
              <FormWrapper>
                <Form.Item
                  name={devicesAdvancedSearchFields.CUSTOMER_ID}
                  label={intl.formatMessage(
                    devicesAdvancedSearchLabels.customerName,
                  )}
                  initialValue={
                    initialValues?.[devicesAdvancedSearchFields.CUSTOMER_ID]
                  }
                >
                  <SelectBoxForm
                    options={extractSelectOptions(
                      customersList || [],
                      'name',
                      'id',
                    )}
                    placeholder={intl.formatMessage(
                      advancedSearchMessages.selectPlaceholder,
                    )}
                    disabled={
                      !enabledFields[devicesAdvancedSearchFields.CUSTOMER_ID]
                    }
                  />
                </Form.Item>
                <Form.Item
                  name={devicesAdvancedSearchFields.TENANT_ID}
                  label={intl.formatMessage(
                    devicesAdvancedSearchLabels.tenantName,
                  )}
                  initialValue={
                    initialValues?.[devicesAdvancedSearchFields.TENANT_ID]
                  }
                  hidden={!hasSAPermissions()}
                >
                  <SelectBoxForm
                    options={extractSelectOptions(
                      tenantsByCustomerList,
                      'name',
                      'id',
                    )}
                    placeholder={intl.formatMessage(
                      advancedSearchMessages.selectPlaceholder,
                    )}
                    disabled={
                      !form.getFieldValue(
                        devicesAdvancedSearchFields.CUSTOMER_ID,
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  name={devicesAdvancedSearchFields.ROOM_NAME}
                  label={intl.formatMessage(
                    devicesAdvancedSearchLabels.roomName,
                  )}
                  initialValue={
                    initialValues?.[devicesAdvancedSearchFields.ROOM_NAME]
                  }
                >
                  <Input
                    placeholder={intl.formatMessage(
                      advancedSearchMessages.typePlaceholder,
                    )}
                    disabled={
                      !enabledFields[devicesAdvancedSearchFields.ROOM_NAME]
                    }
                  />
                </Form.Item>
                <Form.Item
                  name={devicesAdvancedSearchFields.BED_NAME}
                  label={intl.formatMessage(
                    devicesAdvancedSearchLabels.bedName,
                  )}
                  initialValue={
                    initialValues?.[devicesAdvancedSearchFields.BED_NAME]
                  }
                >
                  <Input
                    placeholder={intl.formatMessage(
                      advancedSearchMessages.typePlaceholder,
                    )}
                    disabled={
                      !enabledFields[devicesAdvancedSearchFields.BED_NAME]
                    }
                  />
                </Form.Item>
                <Form.Item
                  name={devicesAdvancedSearchFields.MOUNT_TYPE}
                  label={intl.formatMessage(
                    devicesAdvancedSearchLabels.mountType,
                  )}
                  initialValue={
                    initialValues?.[devicesAdvancedSearchFields.MOUNT_TYPE]
                  }
                >
                  <SelectBoxForm
                    options={locationMountOptions?.mountingType || []}
                    placeholder={intl.formatMessage(
                      advancedSearchMessages.selectPlaceholder,
                    )}
                    disabled={
                      !enabledFields[devicesAdvancedSearchFields.MOUNT_TYPE]
                    }
                  />
                </Form.Item>
                <Form.Item
                  name={devicesAdvancedSearchFields.WIRING_TYPE}
                  label={intl.formatMessage(
                    devicesAdvancedSearchLabels.wiringType,
                  )}
                  initialValue={
                    initialValues?.[devicesAdvancedSearchFields.WIRING_TYPE]
                  }
                >
                  <SelectBoxForm
                    options={locationMountOptions?.wiringType || []}
                    placeholder={intl.formatMessage(
                      advancedSearchMessages.selectPlaceholder,
                    )}
                    disabled={
                      !enabledFields[devicesAdvancedSearchFields.WIRING_TYPE]
                    }
                  />
                </Form.Item>
                <Form.Item
                  name={
                    devicesAdvancedSearchFields.ELECTRICAL_OUTLET_REPLACEMENT
                  }
                  label={intl.formatMessage(
                    devicesAdvancedSearchLabels.electricalOutletReplacement,
                  )}
                  initialValue={
                    initialValues?.[
                      devicesAdvancedSearchFields.ELECTRICAL_OUTLET_REPLACEMENT
                    ]
                  }
                >
                  <SelectBoxForm
                    options={
                      locationMountOptions?.electricalOutletReplacement || []
                    }
                    placeholder={intl.formatMessage(
                      advancedSearchMessages.selectPlaceholder,
                    )}
                    disabled={
                      !enabledFields[
                        devicesAdvancedSearchFields
                          .ELECTRICAL_OUTLET_REPLACEMENT
                      ]
                    }
                  />
                </Form.Item>
                <Form.Item
                  name={deviceIdFieldName}
                  label={intl.formatMessage(
                    devicesAdvancedSearchLabels.deviceSN,
                  )}
                  initialValue={initialValues?.[deviceIdFieldName]}
                >
                  <Input
                    placeholder={intl.formatMessage(
                      advancedSearchMessages.typePlaceholder,
                    )}
                    disabled={!enabledFields[deviceIdFieldName]}
                  />
                </Form.Item>
                <Form.Item
                  name={devicesAdvancedSearchFields.LAST_CONNECTION_DATE}
                  label={intl.formatMessage(
                    devicesAdvancedSearchLabels.lastConnectionDate,
                  )}
                  initialValue={
                    initialValues?.[
                      devicesAdvancedSearchFields.LAST_CONNECTION_DATE
                    ]
                  }
                >
                  <DatePicker
                    disabled={
                      !enabledFields[
                        devicesAdvancedSearchFields.LAST_CONNECTION_DATE
                      ]
                    }
                  />
                </Form.Item>
                <Form.Item
                  name={devicesAdvancedSearchFields.ACTIVATION_DATE}
                  label={intl.formatMessage(
                    devicesAdvancedSearchLabels.activationDate,
                  )}
                  initialValue={
                    initialValues?.[devicesAdvancedSearchFields.ACTIVATION_DATE]
                  }
                >
                  <DatePicker
                    disabled={
                      !enabledFields[
                        devicesAdvancedSearchFields.ACTIVATION_DATE
                      ]
                    }
                  />
                </Form.Item>
              </FormWrapper>
            </>
          )}
        </Form>
      ) : null}
    </ModalWrapper>
  );
};

export default Connector(injectIntl(AdvancedSearchModal));
