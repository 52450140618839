import { defineMessages } from 'react-intl';
import { styled } from 'styled-components';

export const StyledStatusColumn = styled.div`
  color: ${props =>
    props.status === USER_STATUS.ACTIVE
      ? props.theme.colors.activeUserColor
      : props.theme.colors.pendingUserColor};
`;

export const USER_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  PENDING: 'Pending',
};

export const USERS_TABLE_ACTIONS_KEYS = {
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESEND_INVITE: 'RESEND_INVITE',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};
export const usersActionKeysArray = [
  USERS_TABLE_ACTIONS_KEYS.DELETE,
  USERS_TABLE_ACTIONS_KEYS.EDIT,
  USERS_TABLE_ACTIONS_KEYS.RESEND_INVITE,
  USERS_TABLE_ACTIONS_KEYS.RESET_PASSWORD,
  USERS_TABLE_ACTIONS_KEYS.ACTIVE,
  USERS_TABLE_ACTIONS_KEYS.INACTIVE,
];

export const userFormModes = {
  ADD: 'ADD',
  EDIT: 'EDIT',
};

export const userFormFields = Object.freeze({
  CUSTOMER_ID: 'customerId',
  TENANT_ID: 'tenantId',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  USER_TYPE: 'userType',
  PHONE: 'phone',
  MFA_PHONE: 'mfaPhone',
  MFA: 'mfa',
  SDK_USER_PERMISSIONS: 'selectedSubGroups',
});

export const subTitles = defineMessages({
  general: {
    defaultMessage: 'General',
  },
  userDetails: {
    defaultMessage: 'User Details',
  },
});

export const userFormFieldsLabelsMessages = defineMessages({
  firstName: {
    defaultMessage: 'First Name',
  },
  lastName: {
    defaultMessage: 'Last Name',
  },
  email: {
    defaultMessage: 'Email',
  },
  phoneNumber: {
    defaultMessage: 'Phone Number',
  },
  mfaPhoneNumber: {
    defaultMessage: 'MFA Phone Number',
  },
  enableMfa: {
    defaultMessage: 'Enable MFA',
  },
  userType: {
    defaultMessage: 'User Type',
  },
  customer: {
    defaultMessage: 'Customer',
  },
  tenant: {
    defaultMessage: 'Tenant',
  },
  permissions: {
    defaultMessage: 'Permissions',
  },
});

export const userFormFieldsPlaceholdersMessages = defineMessages({
  firstName: {
    defaultMessage: 'First Name',
  },
  lastName: {
    defaultMessage: 'Last Name',
  },
  email: {
    defaultMessage: 'Email',
  },
  phoneNumber: {
    defaultMessage: 'Phone Number',
  },
  mfaPhoneNumber: {
    defaultMessage: 'MFA Phone Number',
  },
  userType: {
    defaultMessage: 'User Type',
  },
  selectOption: {
    defaultMessage: 'Select Option',
  },
});

export const userTableActionsMessages = defineMessages({
  invite: {
    defaultMessage: 'Invite',
  },
  edit: {
    defaultMessage: 'Edit',
  },
  delete: {
    defaultMessage: 'Delete',
  },
  deleteConfirmation: {
    defaultMessage: 'Are you sure you want to delete?',
  },
  resetPassword: {
    defaultMessage: 'Reset Password',
  },
  resetPasswordDisabledText: {
    defaultMessage: 'Cannot reset password for inactivated user.',
  },
  resendInvite: {
    defaultMessage: 'Resend invite',
  },
  tenant: {
    defaultMessage: 'Tenant',
  },
  activate: {
    defaultMessage: 'Activate',
  },
  activateConfirmation: {
    defaultMessage: 'Are you sure you want to activate this user?',
  },
  inactivate: {
    defaultMessage: 'Inactivate',
  },
  inactivateConfirmation: {
    defaultMessage: 'Are you sure you want to inactivate this user?',
  },
});

/* eslint-disable no-template-curly-in-string */
export const userFormValidateMessages = {
  required: '${label} is required!',
  pattern: {
    mismatch: '${label} is not a valid ${label}!',
  },
  whitespace: '${label} must not be empty',
  requiredPermission: 'At least one permission',
}; /* eslint-enable no-template-curly-in-string */

export const userPageColumnsMessages = defineMessages({
  userType: {
    defaultMessage: 'User Type',
  },
  customer: {
    defaultMessage: 'Customer',
  },
  username: {
    defaultMessage: 'Username',
  },
  permissions: {
    defaultMessage: 'Permissions',
  },
  status: {
    defaultMessage: 'Status',
  },
});

export const systemAdmin = 'System admin';

export const MFA_OPTIONS_VALUES = {
  TRUE: 'true',
  FALSE: 'false',
};
export const MFA_OPTIONS_LABELS = {
  ENABLE: 'Enable',
  DISABLE: 'Disable',
};

export const MFA_OPTIONS = [
  {
    id: '0',
    label: MFA_OPTIONS_LABELS.ENABLE,
    value: MFA_OPTIONS_VALUES.TRUE,
  },
  {
    id: '1',
    label: MFA_OPTIONS_LABELS.DISABLE,
    value: MFA_OPTIONS_VALUES.FALSE,
  },
];
