import {
  createSlice,
  createSelector,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';

import { MODAL_STATUS } from 'src/components/Modal/constants';
import { RootState } from 'src/redux/store';
import { ListTenant } from 'src/types/tenants';
import {
  MetaDataType,
  PageMetaDataType,
  SortableFields,
  SortOrder,
  UUID,
} from 'src/types/utility';
import { DATA_STATE_KEY, DATA_FETCHING_STATUS } from '../../constants';
import {
  FetchMTMSubtenantsAlertCounters,
  PaginationParams,
  tenantDeviceCounters,
  tenantMonitoringDeviceCounters,
} from 'src/services/types';
import { OperatorType } from 'src/routes/Customers/components/OperatorsTab/modules/types';
import { CustomerType } from 'src/routes/Customers/modules/types';
import { TableParamsType } from 'src/types/table';

export const STATE_KEY = 'tenant';

export const INITIAL_STATE: {
  tenantsList: ListTenant[];
  tenantsAlertCounters: {
    data: FetchMTMSubtenantsAlertCounters;
    status: keyof typeof DATA_FETCHING_STATUS | null;
  };
  tenantsDeviceCounters: {
    data: tenantDeviceCounters;
    status: keyof typeof DATA_FETCHING_STATUS | null;
  };
  tenantsMonitoringDeviceCounters: {
    data: tenantMonitoringDeviceCounters;
    status: keyof typeof DATA_FETCHING_STATUS | null;
  };
  tenantsOperators: {
    data: OperatorType[];
    status: keyof typeof DATA_FETCHING_STATUS | null;
  };
  tenantsCustomers: {
    data: CustomerType[];
    status: keyof typeof DATA_FETCHING_STATUS | null;
  };
  paginatedTenants: {
    data: ListTenant[];
    pageMetadata: PageMetaDataType;
  };
  addTenantsParentList: {
    data: ListTenant[];
    status: keyof typeof DATA_FETCHING_STATUS | null;
  };
  tenantsListMetadata: MetaDataType;
  status: keyof typeof DATA_FETCHING_STATUS;
  modalStatus: keyof typeof MODAL_STATUS;
} = {
  tenantsList: [],
  tenantsAlertCounters: {
    data: {
      tenants: {},
      totals: {
        currentAlertsCount: 0,
        attentionListAlertsCount: 0,
        onStatusAlertsCount: 0,
      },
    },
    status: null,
  },
  tenantsDeviceCounters: {
    data: {},
    status: null,
  },
  tenantsOperators: {
    data: [],
    status: null,
  },
  tenantsCustomers: {
    data: [],
    status: null,
  },
  tenantsMonitoringDeviceCounters: {
    data: {},
    status: null,
  },
  paginatedTenants: {
    data: [],
    pageMetadata: {
      totalResults: 0,
      page: 0,
      limit: 0,
    },
  },
  addTenantsParentList: {
    data: [],
    status: null,
  },
  tenantsListMetadata: {
    page: {
      totalResults: 0,
      page: 0,
      limit: 0,
    },
    sort: [
      {
        order: SortOrder.desc,
        prop: SortableFields.creationTime,
      },
    ],
  },
  status: DATA_FETCHING_STATUS.LOADING,
  modalStatus: MODAL_STATUS.INITIAL,
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    setTenantsList(state, action: PayloadAction<ListTenant[]>) {
      state.tenantsList = action.payload;
    },
    setTenantsListMetadata(state, action: PayloadAction<MetaDataType>) {
      state.tenantsListMetadata = { ...action.payload };
    },
    setTenantsAlertCounters(
      state,
      action: {
        payload: FetchMTMSubtenantsAlertCounters;
      },
    ) {
      state.tenantsAlertCounters.data = action.payload;
    },
    setPaginatedTenants(
      state,
      action: PayloadAction<{
        data: ListTenant[];
        pageMetadata: PageMetaDataType;
      }>,
    ) {
      state.paginatedTenants = { ...action.payload };
    },
    setAddTenantsParentList(
      state,
      action: PayloadAction<{
        data: ListTenant[];
        status: keyof typeof DATA_FETCHING_STATUS;
      }>,
    ) {
      state.addTenantsParentList = { ...action.payload };
    },
    setAddTenantsParentListStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.addTenantsParentList.status = action.payload;
    },
    setStatus(state, action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>) {
      state.status = action.payload;
    },
    setTenantsAlertCountersStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.tenantsAlertCounters.status = action.payload;
    },
    setTenantsDeviceCountersStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.tenantsDeviceCounters.status = action.payload;
    },
    setTenantsMonitoringDeviceCountersStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.tenantsMonitoringDeviceCounters.status = action.payload;
    },
    setTenantsOperatorsStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.tenantsOperators.status = action.payload;
    },
    setTenantsCustomersStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.tenantsCustomers.status = action.payload;
    },
    setModalStatus(state, action: PayloadAction<keyof typeof MODAL_STATUS>) {
      state.modalStatus = action.payload;
    },
    setCustomerDeviceCounters(
      state,
      action: PayloadAction<tenantDeviceCounters>,
    ) {
      state.tenantsDeviceCounters.data = { ...action.payload };
    },
    setTenantsOperators(state, action: PayloadAction<OperatorType[]>) {
      state.tenantsOperators.data = { ...action.payload };
    },
    setTenantsCustomers(state, action: PayloadAction<CustomerType[]>) {
      state.tenantsCustomers.data = action.payload;
    },
    setTenantsMonitoringDeviceCounters(
      state,
      action: PayloadAction<tenantMonitoringDeviceCounters>,
    ) {
      state.tenantsMonitoringDeviceCounters.data = { ...action.payload };
    },
  },
  extraReducers: {},
});

const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;

const getTenantId = (_state: RootState, tenantId: UUID) => tenantId;

export const selectors = {
  getTenantsList: createSelector(getState, state => state.tenantsList),
  getMtmTenants: createSelector(getState, state =>
    state.tenantsList.filter(tenant => tenant.group),
  ),
  getTenantsAlertCounters: createSelector(
    getState,
    state => state.tenantsAlertCounters.data,
  ),
  selectTenantsDeviceCounters: createSelector(
    getState,
    state => state.tenantsDeviceCounters.data,
  ),
  selectTenantsMonitoringDeviceCounters: createSelector(
    getState,
    state => state.tenantsMonitoringDeviceCounters.data,
  ),
  getTenantsListMetadata: createSelector(
    getState,
    state => state.tenantsListMetadata,
  ),
  getPaginatedTenants: createSelector(
    getState,
    state => state.paginatedTenants,
  ),
  getAddTenantsParentList: createSelector(
    getState,
    state => state.addTenantsParentList.data,
  ),
  getAddTenantsParentListStatus: createSelector(
    getState,
    state => state.addTenantsParentList.status,
  ),
  getTenant: createSelector(getState, getTenantId, (state, tenantId) =>
    state.tenantsList.find(t => t.id === tenantId),
  ),
  getStatus: createSelector(getState, state => state.status),
  selectTenantsDeviceStatus: createSelector(
    getState,
    state => state.tenantsDeviceCounters.status,
  ),
  selectTenantsOperatorsData: createSelector(
    getState,
    state => state.tenantsOperators.data,
  ),
  selectTenantsCustomersData: createSelector(
    getState,
    state => state.tenantsCustomers.data,
  ),
  selectTenantsCustomersStatus: createSelector(
    getState,
    state => state.tenantsCustomers.status,
  ),
  selectTenantsOperatorsStatus: createSelector(
    getState,
    state => state.tenantsOperators.status,
  ),
  selectTenantsMonitoringDeviceStatus: createSelector(
    getState,
    state => state.tenantsMonitoringDeviceCounters.status,
  ),
  getModalStatus: createSelector(getState, state => state.modalStatus),
};

const extraActions = {
  getTenantsList: createAction(`${STATE_KEY}/getTenantsList`),
  getPaginatedTenants: createAction<
    PaginationParams & {
      search: string;
      groupFilter: boolean;
      sortBy?: TableParamsType['sortBy'];
    }
  >(`${STATE_KEY}/getPaginatedTenants`),
  getCustomerPaginatedTenants: createAction<{
    page?: number;
    limit?: number;
    customerID: UUID;
    groupFilter: boolean;
    searchKeyword?: string;
    cache?: string;
    operatorID?: string;
    sortBy?: TableParamsType['sortBy'];
  }>(`${STATE_KEY}/getCustomerPaginatedTenants`),
  getAddTenantsParentList: createAction<{
    customerID: UUID;
    operatorID?: UUID;
    groupFilter?: boolean;
  }>(`${STATE_KEY}/getAddTenantsParentList`),
  getSubtenantsList: createAction<UUID>(`${STATE_KEY}/getSubtenantsList`),
  getSubtenantsAlertCounters: createAction(
    `${STATE_KEY}/getSubtenantsAlertCounters`,
  ),
  deleteTenant: createAction<{
    tenantId: UUID;
    page: number;
    limit: number;
    customerID?: UUID;
    groupFilter: boolean;
    searchKeyword: string;
  }>(`${STATE_KEY}/deleteTenant`),
  createTenant: createAction(`${STATE_KEY}/createTenant`),
  editTenant: createAction(`${STATE_KEY}/editTenant`),
  getTenantsDeviceCounters: createAction<UUID[]>(
    `${STATE_KEY}/getTenantsDeviceCounters`,
  ),
  getTenantsMonitoringDeviceCounters: createAction<UUID[]>(
    `${STATE_KEY}/getTenantsMonitoringDeviceCounters`,
  ),
  getOperatorsByOperatorsId: createAction<UUID[]>(
    `${STATE_KEY}/getOperatorsByOperatorsId`,
  ),
  getCustomersByIds: createAction<UUID[]>(`${STATE_KEY}/getCustomersByIds`),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
