import { styled } from 'styled-components';
import { Modal } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: 'Inter';
  padding-top: 1.2rem;
`;

export const OperatorModal = styled(Modal)`
  min-width: 45rem;
  .ant-modal-footer {
    position: absolute;
    bottom: 3%;
    right: 5%;
    .modal-cancel-btn {
      margin-right: 5px;
    }
  }
`;
