import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import { injectIntl, defineMessages } from 'react-intl';

import ActionButton from 'src/components/general-ui/Buttons/ActionButton';
import CancelButton from 'src/components/general-ui/Buttons/CancelButton';
import StyledButton from 'src//components/general-ui/Buttons/TransparentButton';
import { MODAL_STATUS } from './constants';
import { ModalTitle, ModalStyled } from './styled';
import Spacer from '../Spacer';

const OkButton = styled(ActionButton)`
  min-width: 3.3rem;
  font-size: 0.7rem;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ModalWrapper = props => {
  const {
    intl,
    children,
    title,
    isModalVisible,
    setIsModalVisible,
    okButtonText,
    cancelButtonText,
    onOk,
    shouldCloseOnOk,
    shouldCloseOnCancel,
    onCancel,
    withCloseButton,
    onClose,
    okButtonProps,
    cancelButtonProps,
    modalStatus,
    setModalStatus,
    withCancelButton,
    withResetButton,
    resetForm,
    ...restProps
  } = props;

  const [isOkLoading, setOkLoading] = useState(false);

  const closeModal = useCallback(() => {
    setIsModalVisible(false);
    setModalStatus(MODAL_STATUS.INITIAL);
    onClose && onClose();
  }, [onClose, setModalStatus, setIsModalVisible]);

  const handleOk = () => {
    onOk();
    if (shouldCloseOnOk) {
      closeModal();
    }
  };

  const handleCancel = () => {
    if (!shouldCloseOnCancel) {
      onCancel && onCancel();
      return;
    }
    closeModal();
    onCancel && onCancel();
  };

  const handleReset = () => {
    resetForm();
  };

  useEffect(() => {
    if (modalStatus === MODAL_STATUS.SUBMITTING) {
      setOkLoading(true);
    } else {
      setOkLoading(false);
    }

    if (modalStatus === MODAL_STATUS.SUCCESS) {
      closeModal();
    }
  }, [modalStatus, closeModal]);

  return (
    <ModalStyled
      title={<ModalTitle>{title}</ModalTitle>}
      open={isModalVisible}
      footer={
        <ButtonsWrapper>
          {withCancelButton && (
            <>
              <CancelButton onClick={handleCancel} {...cancelButtonProps}>
                {cancelButtonText}
              </CancelButton>
              <Spacer flex={'0.02'} />
            </>
          )}
          {withResetButton && (
            <>
              <StyledButton onClick={handleReset}>
                {intl.formatMessage(messages.resetButtonText)}
              </StyledButton>
            </>
          )}
          {okButtonText && (
            <OkButton
              onClick={handleOk}
              isLoading={isOkLoading}
              {...okButtonProps}
            >
              {okButtonText}
            </OkButton>
          )}
        </ButtonsWrapper>
      }
      onCancel={handleCancel}
      closable={withCloseButton}
      {...restProps}
    >
      {children}
    </ModalStyled>
  );
};

ModalWrapper.propTypes = {
  intl: PropTypes.intl,
  title: PropTypes.string,
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func,
  withCloseButton: PropTypes.bool,
  okButtonText: PropTypes.string,
  okButtonProps: PropTypes.object,
  cancelButtonProps: PropTypes.object,
  cancelButtonText: PropTypes.string,
  onOk: PropTypes.func,
  shouldCloseOnOk: PropTypes.bool,
  shouldCloseOnCancel: PropTypes.bool,
  onCancel: PropTypes.func,
  children: PropTypes.object,
  maskClosable: PropTypes.bool,
  onClose: PropTypes.func,
  modalStatus: PropTypes.string,
  setModalStatus: PropTypes.func,
  withCancelButton: PropTypes.bool,
  withResetButton: PropTypes.bool,
  resetForm: PropTypes.func,
};

ModalWrapper.defaultProps = {
  title: null,
  onOk: null,
  onCancel: null,
  onClose: null,
  children: null,
  okButtonText: 'Ok',
  cancelButtonText: 'Cancel',
  withCloseButton: true,
  maskClosable: false,
  shouldCloseOnOk: false,
  shouldCloseOnCancel: true,
  setIsModalVisible: null,
  okButtonProps: {},
  cancelButtonProps: {},
  modalStatus: MODAL_STATUS.INITIAL,
  setModalStatus: () => {},
  withCancelButton: true,
};

export const messages = defineMessages({
  resetButtonText: {
    defaultMessage: 'Reset',
  },
});

export default injectIntl(ModalWrapper);
