import { ListUserType } from 'src/types/users';
import { USER_TYPES } from 'src/utils/constants';

export const getSdkUserTypeId = (userTypes: ListUserType[]) =>
  userTypes && userTypes.find(user => user.name === USER_TYPES.SDK)?.id;

export const getMTMUserTypeId = (userTypes: ListUserType[]) =>
  userTypes &&
  userTypes.find(user => user.name === USER_TYPES.MULTI_TENANT_MANAGER)?.id;

export const getMTVUserTypeId = (userTypes: ListUserType[]) =>
  userTypes &&
  userTypes.find(user => user.name === USER_TYPES.MULTI_TENANT_VIEWER)?.id;

export const getFilteredUserTypes = (userTypes: ListUserType[]) =>
  userTypes.filter(
    userType =>
      ![USER_TYPES.SYSTEM_ADMIN, USER_TYPES.DATA_ADMIN].includes(userType.name),
  );
