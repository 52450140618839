import React from 'react';

import SelectBox from 'src/components/general-ui/SelectBox';
import { UUID } from 'src/types/utility';
import Connector, { PropsFromRedux } from '../Connector';
import { OperatorTenantType } from 'src/routes/Customers/components/OperatorsTab/modules/types';
import { ListTenant } from 'src/types/tenants';

type OptionsType = { value: UUID; label: string }[];

type Props = PropsFromRedux & {
  options: OperatorTenantType[] | ListTenant[];
  onChange: (tenantId: UUID) => void;
  placeholder?: string;
  style?: React.CSSProperties;
};

const SelectTenantBox = ({
  options,
  onChange,
  ...rest
}: Props): JSX.Element => {
  const onSelectTenant = (tenantId: UUID) => {
    onChange(tenantId);
  };

  const optionList = options.map(tenant => ({
    value: tenant?.id,
    label: tenant?.name,
  })) as OptionsType;

  return (
    <SelectBox
      options={optionList}
      handleChange={(id: UUID) => {
        onSelectTenant(id);
      }}
      {...rest}
    />
  );
};

export default Connector(SelectTenantBox);
