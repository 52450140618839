import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from 'src/redux/store';
import { UUID } from 'src/types/utility';
import { MODAL_STATUS } from 'src/components/Modal/constants';
import { API_STATUS } from 'src/utils/api-constants';
import { AlertLog, AlertThreshold, MedicalAlert } from 'src/types/alerts';
import {
  GraphTimeData,
  MainGraphDataPoint,
} from 'src/routes/IndividualDashboard/modules/dashboard/types';
import {
  ContinousMotionAggregation,
  ContinousPositionCount,
  ContinuousAggregatedMeasurement,
} from 'src/types/individual-dashboard';
import { convertToTimezone } from 'src/utils/timeUtils';
import {
  formatIntervalWithResolution,
  getTimeAxisResolution,
} from 'src/routes/IndividualDashboard/modules/dashboard/time-processors';
import { POINTS_DATE_FORMAT } from 'src/routes/IndividualDashboard/modules/dashboard/utils';
import {
  computeIntervalAverage,
  countDataPoints,
  mapRawContinuousDataToGraphData,
  mapRawMotionDataToGraphData,
  mapRawPositionDataToGraphData,
} from 'src/routes/IndividualDashboard/modules/dashboard/data-processors';
import { mergeArraysWithUniqueIds } from 'src/redux/data/dataUtils';
import { HistogramGraphData } from 'src/routes/IndividualDashboard/modules/dashboard/histogram-processor';
import { linkParamsType } from 'src/routes/MedicalReports/types';
import {
  getMedicalReportsAbnormalitiesResponse,
  getMedicalReportsAbnormalitiesV2Response,
  getMedicalReportsSettingsResponse,
  getPieChartDataResponse,
} from 'src/services/types';
import { MainGraphMetadata } from 'src/routes/MedicalReports/modules/types';

export const STATE_KEY = 'medicalReports';

export const INITIAL_STATE: {
  selectedTenantId: UUID | null;
  status: API_STATUS;
  thresholdStatus: API_STATUS;
  modalStatus: MODAL_STATUS;
  bedOccupancyStatus: Record<UUID, API_STATUS>;
  mainGraphStatus: Record<UUID, API_STATUS>;
  metricHistogramStatus: Record<UUID, API_STATUS>;
  mainGraph: Record<
    UUID,
    {
      metadata: MainGraphMetadata;
      data: {
        hrContinuousData: MainGraphDataPoint[];
        rrContinuousData: MainGraphDataPoint[];
        motionContinuousData: MainGraphDataPoint[];
        positionContinuousData: MainGraphDataPoint[];
      };
    }
  >;
  alertsLog: Record<
    UUID,
    {
      apiStatus: API_STATUS;
      data: AlertLog[];
    }
  >;
  alerts: Record<
    UUID,
    {
      apiStatus: API_STATUS;
      data: MedicalAlert[];
    }
  >;
  bedOccupancy: Record<
    UUID,
    {
      apiStatus: API_STATUS;
      hoursInBedData: GraphTimeData[];
      numberOfBedExitAlertsData: GraphTimeData[];
    }
  >;
  metricHistogram: Record<
    UUID,
    {
      apiStatus: API_STATUS;
      data: HistogramGraphData | Record<string, never>;
    }
  >;
  thresholds: Record<UUID, AlertThreshold[]>;
  eventsSummary: {
    data: {
      abnormality: Array<number>;
      admissions: Array<number>;
      dischargeTransfer: Array<number>;
    };
    apiStatus: API_STATUS;
  };
  pieChartSummary: {
    data: getPieChartDataResponse;
    apiStatus: API_STATUS;
  };
  loadLastDayAbnormalities: {
    data: getMedicalReportsAbnormalitiesResponse[];
    apiStatus: API_STATUS;
  };
  loadAbnormalitiesV2: {
    data: getMedicalReportsAbnormalitiesV2Response['data']['data'];
    params: getMedicalReportsAbnormalitiesV2Response['data']['params'];
    apiStatus: API_STATUS;
  };
  loadLast3DaysAbnormalities: {
    data: getMedicalReportsAbnormalitiesResponse[];
    apiStatus: API_STATUS;
  };
  settings: {
    data: getMedicalReportsSettingsResponse;
    apiStatus: API_STATUS;
    error: string | null | undefined;
  };
} = {
  selectedTenantId: null,
  status: API_STATUS.INITIAL,
  thresholdStatus: API_STATUS.INITIAL,
  modalStatus: MODAL_STATUS.INITIAL,
  alertsLog: {},
  alerts: {},
  bedOccupancy: {},
  mainGraph: {},
  thresholds: {},
  metricHistogram: {},
  eventsSummary: {
    data: {
      abnormality: [],
      admissions: [],
      dischargeTransfer: [],
    },
    apiStatus: API_STATUS.INITIAL,
  },
  pieChartSummary: {
    data: {
      monitoringDays: {
        zeroToFive: 0,
        sixToTen: 0,
        elevenToFifteen: 0,
        sixteenPlus: 0,
      },
      devices: {
        connectedAssigned: 0,
        connectedUnassigned: 0,
        disconnectedAssigned: 0,
        disconnectedUnassigned: 0,
        nonConsented: 0,
      },
    },
    apiStatus: API_STATUS.INITIAL,
  },
  loadLastDayAbnormalities: {
    data: [],
    apiStatus: API_STATUS.INITIAL,
  },
  loadAbnormalitiesV2: {
    data: [],
    params: {
      hrThreshold: '',
      rrThreshold: '',
      hrBaselineDeviation: '',
      rrBaselineDeviation: '',
    },
    apiStatus: API_STATUS.INITIAL,
  },
  loadLast3DaysAbnormalities: {
    data: [],
    apiStatus: API_STATUS.INITIAL,
  },
  settings: {
    data: {
      customerName: '',
      tenantName: '',
      customerId: '',
    },
    apiStatus: API_STATUS.INITIAL,
    error: '',
  },
  bedOccupancyStatus: {},
  mainGraphStatus: {},
  metricHistogramStatus: {},
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    setSelectedTenantId: (state, action: PayloadAction<UUID>) => {
      state.selectedTenantId = action.payload;
    },
    setStatus(state, action: PayloadAction<API_STATUS>) {
      state.status = action.payload;
    },
    setModalStatus(state, action: PayloadAction<MODAL_STATUS>) {
      state.modalStatus = action.payload;
    },
    getPatientMetricDataSuccess: (
      state,
      action: PayloadAction<{
        hrData: ContinuousAggregatedMeasurement[];
        rrData: ContinuousAggregatedMeasurement[];
        motionData?: ContinousMotionAggregation[];
        postureData?: ContinousPositionCount[];
        startTime: string;
        endTime: string;
        timezone: string;
        patientId: string;
      }>,
    ) => {
      const {
        hrData,
        rrData,
        motionData,
        postureData,
        startTime,
        endTime,
        timezone,
        patientId,
      } = action.payload;
      const correctedStartTime = convertToTimezone(startTime, timezone);
      const correctedEndTime = convertToTimezone(endTime, timezone);

      const { startTime: binnedStartTime, endTime: binnedEndTime } =
        formatIntervalWithResolution(correctedStartTime, correctedEndTime);
      const { stepSize, unit } = getTimeAxisResolution(
        correctedStartTime,
        correctedEndTime,
      );

      state.mainGraph[patientId] = {
        metadata: {
          startTime: binnedStartTime.format(POINTS_DATE_FORMAT),
          endTime: binnedEndTime.format(POINTS_DATE_FORMAT),
          stepSize,
          unit,
          dataPointsCount: countDataPoints(hrData, rrData),
          hrContinuousBaseline: computeIntervalAverage(hrData),
          rrContinuousBaseline: computeIntervalAverage(rrData),
          isLoading: false,
        },
        data: {
          hrContinuousData: mapRawContinuousDataToGraphData(hrData, timezone),
          rrContinuousData: mapRawContinuousDataToGraphData(rrData, timezone),
          motionContinuousData: mapRawMotionDataToGraphData(
            motionData,
            timezone,
          ),
          positionContinuousData: mapRawPositionDataToGraphData(
            postureData,
            timezone,
          ),
        },
      };
    },
    setAlertsLogData: (
      state,
      action: PayloadAction<{
        patientId: UUID;
        alerts: AlertLog[];
      }>,
    ) => {
      const { alerts, patientId } = action.payload;
      state.alertsLog[patientId] = {
        data: alerts,
        apiStatus: API_STATUS.OK,
      };
    },
    setAlertsData: (
      state,
      action: PayloadAction<{
        patientId: UUID;
        alerts: MedicalAlert[];
      }>,
    ) => {
      const { alerts, patientId } = action.payload;
      state.alerts[patientId] = {
        data: alerts,
        apiStatus: API_STATUS.OK,
      };
    },
    loadBedOccupancyDataSuccess: (
      state,
      action: PayloadAction<{
        patientId: UUID;
        hoursInBed: GraphTimeData[];
        numberOfAlerts: GraphTimeData[];
      }>,
    ) => {
      const { patientId, hoursInBed, numberOfAlerts } = action.payload;

      state.bedOccupancy[patientId] = {
        ...state.bedOccupancy[patientId],
        hoursInBedData: hoursInBed,
        numberOfBedExitAlertsData: numberOfAlerts,
        apiStatus: API_STATUS.OK,
      };
    },
    getThresholdsListSuccess: (
      state,
      action: PayloadAction<{ patientId: UUID; thresholds: AlertThreshold[] }>,
    ) => {
      const { patientId, thresholds } = action.payload;
      state.thresholds[patientId] = mergeArraysWithUniqueIds(
        state.thresholds[patientId] ?? [],
        thresholds,
      );
      state.thresholdStatus = API_STATUS.OK;
    },
    getHistogramDataSuccess: (
      state,
      action: PayloadAction<{
        patientId: UUID;
        histogramData: HistogramGraphData;
      }>,
    ) => {
      const { patientId, histogramData } = action.payload;
      state.metricHistogram[patientId] = {
        data: histogramData,
        apiStatus: API_STATUS.OK,
      };
    },
    getEventsSummaryDataSuccess: (
      state,
      action: PayloadAction<{
        eventsSummary: {
          abnormality: Array<number>;
          admissions: Array<number>;
          dischargeTransfer: Array<number>;
        };
      }>,
    ) => {
      const { eventsSummary } = action.payload;
      state.eventsSummary.data = { ...eventsSummary };
      state.eventsSummary.apiStatus = API_STATUS.OK;
    },
    getPieChartDataSuccess: (
      state,
      action: PayloadAction<getPieChartDataResponse>,
    ) => {
      const data = action.payload;
      state.pieChartSummary.data = data;
      state.pieChartSummary.apiStatus = API_STATUS.OK;
    },
    getLastDayAbnormalitiesDataSuccess: (
      state,
      action: PayloadAction<getMedicalReportsAbnormalitiesResponse[]>,
    ) => {
      state.loadLastDayAbnormalities.data = [...action.payload];
      state.loadLastDayAbnormalities.apiStatus = API_STATUS.OK;
    },
    getAbnormalitiesV2DataSuccess: (
      state,
      action: PayloadAction<getMedicalReportsAbnormalitiesV2Response>,
    ) => {
      const { data } = action.payload;
      const { data: summaryData, params } = data;
      state.loadAbnormalitiesV2.data = summaryData;
      state.loadAbnormalitiesV2.params = params;
      state.loadAbnormalitiesV2.apiStatus = API_STATUS.OK;
    },
    getLast3DaysAbnormalitiesDataSuccess: (
      state,
      action: PayloadAction<getMedicalReportsAbnormalitiesResponse[]>,
    ) => {
      state.loadLast3DaysAbnormalities.data = [...action.payload];
      state.loadLast3DaysAbnormalities.apiStatus = API_STATUS.OK;
    },
    setLast3DaysAbnormalitiesStatus: (
      state,
      action: PayloadAction<API_STATUS>,
    ) => {
      state.loadLast3DaysAbnormalities.apiStatus = action.payload;
    },
    setLastDayAbnormalitiesStatus: (
      state,
      action: PayloadAction<API_STATUS>,
    ) => {
      state.loadLastDayAbnormalities.apiStatus = action.payload;
    },
    setAbnormalitiesV2Status: (state, action: PayloadAction<API_STATUS>) => {
      state.loadAbnormalitiesV2.apiStatus = action.payload;
    },
    getMedicalReportsSettingsDataSuccess: (
      state,
      action: PayloadAction<getMedicalReportsSettingsResponse>,
    ) => {
      state.settings.data = action.payload;
      state.settings.apiStatus = API_STATUS.OK;
    },
    setBedOccupancyStatus: (
      state,
      action: PayloadAction<{ patientId: UUID; status: API_STATUS }>,
    ) => {
      const { patientId, status } = action.payload;
      state.bedOccupancyStatus[patientId] = status;
    },
    setMainGraphStatus: (
      state,
      action: PayloadAction<{ patientId: UUID; status: API_STATUS }>,
    ) => {
      const { patientId, status } = action.payload;
      state.mainGraphStatus[patientId] = status;
    },
    setMetricHistogramStatus: (
      state,
      action: PayloadAction<{ patientId: UUID; status: API_STATUS }>,
    ) => {
      const { patientId, status } = action.payload;
      state.metricHistogramStatus[patientId] = status;
    },
    setEventsSummaryStatus: (state, action: PayloadAction<API_STATUS>) => {
      state.eventsSummary.apiStatus = action.payload;
    },
    setPieChartSummaryStatus: (state, action: PayloadAction<API_STATUS>) => {
      state.pieChartSummary.apiStatus = action.payload;
    },
    setSettingsStatus: (state, action: PayloadAction<API_STATUS>) => {
      state.settings.apiStatus = action.payload;
    },
    setSettingsError: (
      state,
      action: PayloadAction<string | undefined | null>,
    ) => {
      state.settings.error = action.payload;
    },
  },
});

const extraActions = {
  getReportSettings: createAction<linkParamsType>(
    `${STATE_KEY}/getReportSettings`,
  ),
  loadIDBData: createAction<linkParamsType & { patientId: UUID }>(
    `${STATE_KEY}/loadIDBData`,
  ),
  loadSummaryEventsData: createAction<linkParamsType>(
    `${STATE_KEY}/loadSummaryEventsData`,
  ),
  loadDevicesPieChartData: createAction<linkParamsType>(
    `${STATE_KEY}/loadDevicesPieChartData`,
  ),
  loadLastDayAbnormalities: createAction<linkParamsType>(
    `${STATE_KEY}/loadLastDayAbnormalities`,
  ),
  loadAbnormalitiesV2: createAction<linkParamsType>(
    `${STATE_KEY}/loadAbnormalitiesV2`,
  ),
  loadLast3DaysAbnormalities: createAction<linkParamsType>(
    `${STATE_KEY}/loadLast3DaysAbnormalities`,
  ),
};

const getState = (state: RootState) => state[STATE_KEY] || INITIAL_STATE;
const getPatientId = (_state: RootState, patientId: UUID) => patientId;

export const selectors = {
  getSelectedTenantId: createSelector(
    getState,
    state => state.selectedTenantId,
  ),
  getStatus: createSelector(getState, state => state.status),
  getModalStatus: createSelector(getState, state => state.modalStatus),
  getEventsSummaryData: createSelector(
    getState,
    state => state.eventsSummary.data,
  ),
  getPieChartSummaryData: createSelector(
    getState,
    state => state.pieChartSummary.data,
  ),
  getLastDayAbnormalitiesData: createSelector(
    getState,
    state => state.loadLastDayAbnormalities.data,
  ),
  getAbnormalitiesV2: createSelector(
    getState,
    state => state.loadAbnormalitiesV2,
  ),
  getLast3DaysAbnormalitiesData: createSelector(
    getState,
    state => state.loadLast3DaysAbnormalities.data,
  ),
  getLast3DaysAbnormalitiesStatus: createSelector(
    getState,
    state => state.loadLast3DaysAbnormalities.apiStatus,
  ),
  getLastDayAbnormalitiesStatus: createSelector(
    getState,
    state => state.loadLastDayAbnormalities.apiStatus,
  ),
  getAbnormalitiesV2Status: createSelector(
    getState,
    state => state.loadAbnormalitiesV2.apiStatus,
  ),
  getMedicalReportsSettings: createSelector(
    getState,
    state => state.settings.data,
  ),
  getHoursInBedData: createSelector(
    getState,
    getPatientId,
    (state, patientId) => state?.bedOccupancy[patientId]?.hoursInBedData,
  ),
  getNumberOfBedExitAlertsData: createSelector(
    getState,
    getPatientId,
    (state, patientId) =>
      state?.bedOccupancy[patientId]?.numberOfBedExitAlertsData,
  ),
  selectIsBedOccupancyDataLoading: createSelector(
    getState,
    getPatientId,
    (state, patientId) => state?.bedOccupancy[patientId]?.apiStatus,
  ),
  selectMainGraphMetricData: createSelector(
    getState,
    getPatientId,
    (state, patientId) => state?.mainGraph[patientId]?.data,
  ),
  selectMainGraphMetadata: createSelector(
    getState,
    getPatientId,
    (state, patientId) =>
      (state?.mainGraph[patientId]?.metadata ?? {}) as MainGraphMetadata,
  ),
  selectThresholds: createSelector(
    getState,
    getPatientId,
    (state, patientId) => state?.thresholds[patientId],
  ),
  selectAlertsLog: createSelector(
    getState,
    getPatientId,
    (state, patientId) => state?.alertsLog[patientId]?.data ?? [],
  ),
  selectAlerts: createSelector(
    getState,
    getPatientId,
    (state, patientId) => state?.alerts[patientId]?.data ?? [],
  ),
  selectHistogramData: createSelector(
    getState,
    getPatientId,
    (state, patientId) => state.metricHistogram[patientId]?.data ?? {},
  ),
  isHistogramDataLoading: createSelector(
    getState,
    getPatientId,
    (state, patientId) =>
      state.metricHistogram[patientId]?.apiStatus === API_STATUS.LOADING,
  ),
  getBedOccupancyStatus: createSelector(
    getState,
    getPatientId,
    (state, patientId) => state.bedOccupancyStatus[patientId],
  ),
  getMainGraphStatus: createSelector(
    getState,
    getPatientId,
    (state, patientId) => state.mainGraphStatus[patientId],
  ),
  getMetricHistogramStatus: createSelector(
    getState,
    getPatientId,
    (state, patientId) => state.metricHistogramStatus[patientId],
  ),
  getEventsSummaryStatus: createSelector(
    getState,
    state => state.eventsSummary.apiStatus,
  ),
  getSettingsStatus: createSelector(
    getState,
    state => state.settings.apiStatus,
  ),
  getSettingsError: createSelector(getState, state => state.settings.error),
  getPieChartSummaryStatus: createSelector(
    getState,
    state => state.pieChartSummary.apiStatus,
  ),
};

export const actions = { ...slice.actions, ...extraActions };
const { reducer } = slice;
export default reducer;
