import { styled } from 'styled-components';
import Modal from 'src/components/Modal';

export const MedicalConditionsWrapper = styled.div`
  border: ${props =>
    `1px solid ${props.theme.colors.individualDashboardHeaderBorder}`};
  border-radius: 8px;
  background-color: ${props =>
    props.theme.colors.measurementCardBackgroundColor};
  color: #000;
  font-size: 12px;
  padding: 0.5rem;
  position: relative;
  &.emptyBox {
    min-height: 17%;
  }
  .loader {
    top: 0;
  }
`;
export const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const BoldItem = styled.span`
  font-weight: 600;
  display: inline-block;
`;
export const ShortDesc = styled.div`
  display: flex;
`;
export const MedicalConditionContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
export const MedicalConditionCard = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #000;
  &:last-child {
    padding-bottom: 0.5rem;
    border-bottom: none;
  }
`;
export const Title = styled.div`
  display: flex;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
`;
export const ExtraInfo = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
`;
export const StyledModal = styled(Modal)`
  display: flex;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  .ant-modal-content {
    width: 100%;
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`;
export const StyledPrimaryBtn = styled.div<{ isDisabled?: boolean }>`
  padding: 0.3rem 1rem;
  background: #385b75;
  border-radius: 0.9rem;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  white-space: nowrap;

  ${props => (props.isDisabled ? 'cursor: not-allowed' : '')}
`;
export const LastUpdateContainer = styled.div`
  text-align: right;
`;
