import React, { useEffect, useState } from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import { List } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import Connector, { PropsFromRedux } from './Connector';
import {
  FacilityMappingStrategyState,
  // FacilityType,
} from 'src/routes/Customers/components/OperatorsTab/modules/types';
import ActionButtons from './components/ActionButtons';
import {
  FacilityLabel,
  FacilityMappingWrap,
  FacilitySearchBox,
  FacilityTenantFieldsRow,
} from './style';

type Props = PropsFromRedux & { intl: IntlShape };

const FacilitiesFieldMapping = ({
  intl,
  getOperatorTenants,
  getFacilities,
  operatorTenantsList,
  mtmTenantsList,
  facilitiesList,
  facilityInitialDict,
  isLoadingOperatorTenants,
  isLoadingFacilities,
  getTenants,
}: Props) => {
  const [mapFacilitiesForm, setMapFacilitiesForm] =
    useState<FacilityMappingStrategyState>({});
  // const [searchResults, setSearchResults] = useState<FacilityType[] | null>(
  //   null,
  // );

  useEffect(() => {
    getOperatorTenants();
    getFacilities();
    // getTenants();
  }, [getOperatorTenants, getFacilities, getTenants]);

  useEffect(() => {
    setMapFacilitiesForm(facilityInitialDict);
  }, [facilityInitialDict]);

  const facilitiesListData = facilitiesList.map(facility => {
    return (
      <FacilityTenantFieldsRow key={facility.id}>
        <FacilityLabel>{facility.facilityName}</FacilityLabel>
        <ArrowRightOutlined />
        <ActionButtons
          id={facility.id}
          mappingForm={mapFacilitiesForm}
          setMapFacilitiesForm={setMapFacilitiesForm}
          operatorTenantsList={operatorTenantsList}
          mtmTenantsList={mtmTenantsList}
        />
      </FacilityTenantFieldsRow>
    );
  });

  // const onSearch = (value: string) => {
  //   const searchValue = value.toLowerCase();

  //   const facilitySearchResults = facilitiesList.filter(facility => {
  //     const facilityNameSmall = facility.facilityName.toLowerCase();
  //     return facilityNameSmall.includes(searchValue);
  //   });

  //   setSearchResults(facilitySearchResults);
  // };

  return (
    <FacilityMappingWrap>
      <FacilitySearchBox
        placeholder={intl.formatMessage(messages.searchFacility)}
        // onSearch={onSearch}
      />
      <List
        dataSource={facilitiesListData}
        renderItem={item => <List.Item>{item}</List.Item>}
        size="large"
        loading={isLoadingOperatorTenants || isLoadingFacilities}
        bordered
      />
    </FacilityMappingWrap>
  );
};

const messages = defineMessages({
  searchFacility: {
    defaultMessage: 'Search facility',
  },
});

export default Connector(injectIntl(FacilitiesFieldMapping));
