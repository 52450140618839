import { connect, ConnectedProps } from 'react-redux';

import { actions as patientActions } from 'src/redux/data/patient';

const mapActionCreators = {
  updatePatientConsent: patientActions.updatePatientConsent,
};

const Connector = connect(null, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
