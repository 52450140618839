import { useEffect } from 'react';
import { getTableCellValue } from 'src/components/CrudCommonComponents/utils';
import dayjs from 'dayjs';
import { MONTH_DAY_YEAR_FORMAT } from 'src/utils/timeUtils';

export const useDeviceSearch = (
  searchKeyword,
  isLoading,
  setTableData,
  proxyDevicesList,
  userType,
  tableColumns,
  tableParams,
) => {
  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!searchKeyword || !searchKeyword?.length) {
      setTableData(
        proxyDevicesList[userType].map(deviceData => ({
          key: `${deviceData.manufacturerId}-${Math.floor(Math.random() * 100000 + 1)}`,
          ...deviceData,
        })),
      );
      return;
    }
    const searchingColumns = tableColumns
      .filter(elem => elem.key !== 'actions')
      .map(column => column.key);

    setTableData(
      proxyDevicesList[userType]
        .filter(device =>
          searchingColumns.some(key => {
            let columnData;
            const currentColumn = tableColumns.find(
              elem => elem.dataIndex === key,
            );
            columnData = currentColumn?.render
              ? getTableCellValue(currentColumn.render(null, device))
              : device[key];
            if (key === 'tenantsHistory') {
              columnData = '';
              const tenantsHistoryData = device[key];
              tenantsHistoryData?.map(({ tenantName = '-', attachedTime }) => {
                const historyDate = attachedTime
                  ? dayjs(attachedTime).format(MONTH_DAY_YEAR_FORMAT)
                  : '-';
                columnData += `${historyDate} ${tenantName}`;
              });
            }

            return columnData
              ?.toString()
              .toLowerCase()
              .includes(searchKeyword.toString().toLowerCase());
          }),
        )
        .map(deviceData => ({
          key: `${deviceData.manufacturerId}-${Math.floor(Math.random() * 100000 + 1)}`,
          ...deviceData,
        })),
    );
  }, [
    isLoading,
    searchKeyword?.length,
    userType,
    JSON.stringify(tableColumns),
    JSON.stringify(proxyDevicesList),
    tableParams?.viewMode,
  ]);
};
