import styled from 'styled-components';
import { Searchbar } from 'src/components/styled';
import ActionButton from 'src/components/general-ui/Buttons/ActionButton';

export const StyledCustomersListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  gap: 0.5rem;
  height: calc(100vh - 134px);
`;

export const StyledCustomersListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  min-height: calc(100% - 63px - 0.5rem);
`;
export const StyledCustomersListItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 0.1rem;
`;

export const StyledCustomersListItem = styled.div<{
  isActive: boolean;
}>`
  line-height: 41px;
  font-size: 13px;
  cursor: pointer;
  background-color: ${props =>
    props.isActive
      ? props.theme.colors.individualDashboardTimeButtonBackgroundActive
      : props.theme.colors.defaultBackgroundColor};
  padding: 0 1rem;
  //margin-left: -1rem;
  border: 1px solid #dce0e3;
`;
export const StyledSearchbar = styled(Searchbar)`
  padding-left: 1rem;
`;
export const StyledActionButton = styled(ActionButton)`
  margin-left: 1rem;
`;
