import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { mapCurrentAlerts } from 'src/redux/data/alerts/modules/utils';
import {
  actions as alertActions,
  selectors as alertSelectors,
} from 'src/redux/data/alerts';
import { ALERT_METRIC_ENUM } from 'src/redux/data/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as userSelectors } from 'src/redux/data/loggedInUser';
import { RootState } from 'src/redux/store';
import {
  Alert,
  GenericThresholdMetrics,
  OccupancyAlert,
} from 'src/types/alerts';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { getAlertLogsUpdated } from 'src/utils/alertHelpers';

export const useCalculateTableHeight = (
  currentCustomer,
  customSubHeaderTop = 330,
  customerSubHeaderTop = 475,
) => {
  const ref = useRef(null);
  const [tableHeight, setTableHeight] = useState(null);
  const getTableHeight = () => {
    const node = ref.current;
    const { top } = node ? node.getBoundingClientRect() : { top: 0 };
    const subHeaderTop = currentCustomer?.id
      ? customerSubHeaderTop
      : customSubHeaderTop;

    setTableHeight(window.innerHeight - top - subHeaderTop);
  };

  useEffect(() => {
    window.addEventListener('resize', getTableHeight);
  }, []);

  useLayoutEffect(() => {
    getTableHeight();
  }, [ref]);

  return tableHeight;
};
