import {
  all,
  takeLatest,
  call,
  put,
  select,
  delay,
} from 'typed-redux-saga/macro';

import BackendService from 'src/services/BackendService';
import { DEFAULT_PAGINATION, tenantFormFields } from 'src/utils/constants';
import { notifyUserByActionTypeAndCode } from 'src/utils/errorHandling/notifications';
import { tenantErrorCodes } from 'src/utils/errorHandling/errorCodes';
import { MODAL_STATUS } from 'src/components/Modal/constants';
import { actions as deviceActions } from 'src/routes/Devices/modules/slice';
import { actions } from './slice';
import { DATA_FETCHING_STATUS } from '../../constants';
import { getUserDevicesList } from '../../device/modules/saga';
import { selectors as deviceSelectors } from '../../device/modules/slice';
import dataActions from '../../dataActions';
import { UUID } from 'src/types/utility';
import { hasAllPermissions, hasGMPermissions } from 'src/utils/permissions';
import permissions from 'src/permissions';

function* getTenantsList() {
  try {
    if (hasAllPermissions(permissions.VIEW_DEVICES_TENANT_COLUMN)) {
      yield* put(actions.setStatus(DATA_FETCHING_STATUS.LOADING));
      const tenantsResponse = yield* call(BackendService.getTenants);
      yield* put(actions.setTenantsList(tenantsResponse.data.data));
      yield* put(actions.setStatus(DATA_FETCHING_STATUS.SUCCESS));
    }
  } catch (e) {
    console.error('error in fetchTenantsList: ', e);
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.ERROR));
  }
}

function* getPaginatedTenants(
  action: ReturnType<typeof actions.getPaginatedTenants>,
) {
  try {
    if (hasAllPermissions(permissions.VIEW_DEVICES_TENANT_COLUMN)) {
      yield* put(actions.setStatus(DATA_FETCHING_STATUS.LOADING));
      yield* delay(500);
      const tenantsResponse = yield* call(
        BackendService.getTenantsPaginated,
        action.payload,
      );
      yield* put(
        actions.setPaginatedTenants({
          data: tenantsResponse.data.data,
          pageMetadata: tenantsResponse.data.metadata.page,
        }),
      );
      yield* put(actions.setStatus(DATA_FETCHING_STATUS.SUCCESS));
    }
  } catch (e) {
    console.error('error in fetchTenantsList: ', e);
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.ERROR));
  }
}

function* getCustomerPaginatedTenants(
  action: ReturnType<typeof actions.getCustomerPaginatedTenants>,
) {
  try {
    if (hasAllPermissions(permissions.VIEW_DEVICES_TENANT_COLUMN)) {
      const {
        customerID,
        operatorID,
        groupFilter = false,
        searchKeyword = '',
        cache = 'true',
        sortBy,
        ...pagination
      } = action.payload;
      yield* put(actions.setStatus(DATA_FETCHING_STATUS.LOADING));
      yield* delay(500);
      const tenantsResponse = yield* call(
        BackendService.getCustomerTenantsPaginated,
        pagination,
        customerID,
        groupFilter,
        operatorID,
        searchKeyword,
        sortBy,
        cache,
      );
      yield* put(
        actions.setPaginatedTenants({
          data: tenantsResponse.data.data,
          pageMetadata: tenantsResponse.data.metadata.page,
        }),
      );
      yield* put(actions.setStatus(DATA_FETCHING_STATUS.SUCCESS));
    }
  } catch (e) {
    console.error('error in fetchTenantsList: ', e);
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.ERROR));
  }
}

function* getSubtenantsList(
  action: ReturnType<typeof actions.getSubtenantsList>,
) {
  try {
    if (hasGMPermissions()) {
      yield* put(actions.setStatus(DATA_FETCHING_STATUS.LOADING));
      const subtenantsResponse = yield* call(
        BackendService.getSubtenants,
        action.payload,
      );
      yield* put(actions.setTenantsList(subtenantsResponse.data.data));
      yield* put(
        actions.setTenantsListMetadata(subtenantsResponse.data.metadata),
      );
      yield* put(actions.setStatus(DATA_FETCHING_STATUS.SUCCESS));
    }
  } catch (e) {
    console.error('error in fetchTenantsList: ', e);
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.ERROR));
  }
}
function* getSubtenantsAlertCounters() {
  try {
    if (hasGMPermissions()) {
      yield* put(
        actions.setTenantsAlertCountersStatus(DATA_FETCHING_STATUS.LOADING),
      );
      const subtenantsAlertCountersResponse = yield* call(
        BackendService.fetchMTMSubtenantsAlertCounters,
      );
      yield* put(
        actions.setTenantsAlertCounters(subtenantsAlertCountersResponse?.data),
      );
      yield* put(
        actions.setTenantsAlertCountersStatus(DATA_FETCHING_STATUS.SUCCESS),
      );
    }
  } catch (e) {
    console.error('error in fetchTenantsList: ', e);
    yield* put(
      actions.setTenantsAlertCountersStatus(DATA_FETCHING_STATUS.ERROR),
    );
  }
}

function* deleteTenant(action: ReturnType<typeof actions.deleteTenant>) {
  const { tenantId, customerID, page, limit, groupFilter, searchKeyword } =
    action.payload;

  const getDevicesParamsAction = {
    payload: {
      page: DEFAULT_PAGINATION.PAGE - 1,
      limit: DEFAULT_PAGINATION.LIMIT,
      filters: {
        tenantId,
      },
    },
    type: '',
  };

  try {
    yield* call(getUserDevicesList, getDevicesParamsAction);
    const deviceListStatus = yield* select(deviceSelectors.getStatus);
    const devicesList = yield* select(deviceSelectors.getDevicesList);
    const amountOfAttachedDevicesToCurrentTenant = devicesList?.filter(
      device => device.tenantId === tenantId,
    ).length;

    if (deviceListStatus !== DATA_FETCHING_STATUS.SUCCESS) {
      notifyUserByActionTypeAndCode(
        action.type,
        tenantId,
        tenantErrorCodes.COULD_NOT_GET_DEVICE_DATA,
      );
    } else if (amountOfAttachedDevicesToCurrentTenant === 0) {
      yield* call(BackendService.deleteTenant, tenantId);
      if (!customerID) {
        yield* put(
          actions.getPaginatedTenants({
            page,
            limit,
            search: searchKeyword,
            groupFilter,
          }),
        );
      }
      if (customerID) {
        yield* put(
          actions.getCustomerPaginatedTenants({
            page,
            limit,
            groupFilter,
            customerID,
            searchKeyword,
            cache: 'false',
          }),
        );
      }
    } else {
      notifyUserByActionTypeAndCode(
        action.type,
        tenantId,
        tenantErrorCodes.DEVICES_ATTACHED_TO_TENANT_ERROR,
      );
    }
  } catch (error) {
    console.error('error in deleteTenant: ', error);
    notifyUserByActionTypeAndCode(action.type, tenantId, error);
  }
}

function* createTenant(action: ReturnType<typeof actions.createTenant>) {
  // TODO: Update this to be typesafe
  const formData = action.payload as unknown as Record<string, unknown>;
  const hasAdminData =
    formData[tenantFormFields.EMAIL] ||
    formData[tenantFormFields.FIRST_NAME] ||
    formData[tenantFormFields.LAST_NAME] ||
    formData[tenantFormFields.PHONE];
  const createTenantRequest = {
    name: formData[tenantFormFields.NAME],
    isEnableCPX: formData[tenantFormFields.ENABLE_CPX],
    timeZoneOffset: formData[tenantFormFields.TIMEZONE_UTCOFFSET_STR],
    timeZoneId: formData[tenantFormFields.TIMEZONE_ID],
    group: formData[tenantFormFields.GROUP],
    parentTenantId: formData[tenantFormFields.PARENT_TENANT_ID],
    operatorId: formData[tenantFormFields.OPERATOR_ID],
    customerId: formData[tenantFormFields.CUSTOMER_ID],
    address: {
      countryCode: formData[tenantFormFields.COUNTRY_CODE],
      city: formData[tenantFormFields.CITY],
      // zipCode: formData[tenantFormFields.ZIP_CODE],
      address1: formData[tenantFormFields.ADDRESS1],
      // address2: formData[tenantFormFields.ADDRESS2],
      region: formData[tenantFormFields.REGION],
      state: formData[tenantFormFields.STATE],
    },
    admin: hasAdminData
      ? {
          email: formData[tenantFormFields.EMAIL],
          firstName: formData[tenantFormFields.FIRST_NAME],
          lastName: formData[tenantFormFields.LAST_NAME],
          phone: formData[tenantFormFields.PHONE],
        }
      : null,
  };

  try {
    yield* put(actions.setModalStatus(MODAL_STATUS.SUBMITTING));
    yield* call(BackendService.createTenant, createTenantRequest);
    yield* put(actions.setModalStatus(MODAL_STATUS.SUCCESS));
  } catch (error) {
    console.error('error in createTenant: ', error);
    yield* put(actions.setModalStatus(MODAL_STATUS.ERROR));
    notifyUserByActionTypeAndCode(
      action.type,
      createTenantRequest?.name,
      error,
    );
  }
}

function* editTenant(action: ReturnType<typeof actions.editTenant>) {
  // TODO: Update this to be typesafe
  const { id, data: formData } = action.payload as unknown as {
    id: UUID;
    data: Record<string, unknown>;
  };
  const editTenantRequest = {
    name: formData[tenantFormFields.NAME],
    isEnableCPX: formData[tenantFormFields.ENABLE_CPX],
    timeZoneOffset: formData[tenantFormFields.TIMEZONE_UTCOFFSET_STR],
    timeZoneId: formData[tenantFormFields.TIMEZONE_ID],
    group: formData[tenantFormFields.GROUP],
    parentTenantId: formData[tenantFormFields.PARENT_TENANT_ID],
    operatorId: formData[tenantFormFields.OPERATOR_ID],
    address: {
      countryCode: formData[tenantFormFields.COUNTRY_CODE],
      state: formData[tenantFormFields.STATE],
      city: formData[tenantFormFields.CITY],
      // zipCode: formData[tenantFormFields.ZIP_CODE],
      address1: formData[tenantFormFields.ADDRESS1],
      // address2: formData[tenantFormFields.ADDRESS2],
      region: formData[tenantFormFields.REGION],
    },
  };
  try {
    yield* put(actions.setModalStatus(MODAL_STATUS.SUBMITTING));
    yield* call(BackendService.updateTenant, id, editTenantRequest);
    yield* put(actions.setModalStatus(MODAL_STATUS.SUCCESS));
  } catch (error) {
    console.error('error in editTenant: ', error);
    yield* put(actions.setModalStatus(MODAL_STATUS.ERROR));
    notifyUserByActionTypeAndCode(action.type, editTenantRequest?.name, error);
  }
}

function* getAddTenantsParentList(
  action: ReturnType<typeof actions.getAddTenantsParentList>,
) {
  try {
    if (hasAllPermissions(permissions.VIEW_DEVICES_TENANT_COLUMN)) {
      const {
        customerID,
        groupFilter,
        operatorID = undefined,
      } = action.payload;

      yield* put(
        actions.setAddTenantsParentListStatus(DATA_FETCHING_STATUS.LOADING),
      );
      yield* delay(500);
      const tenantsResponse = yield* call(
        BackendService.getCustomerTenantsPaginated,
        { limit: 1000000, page: 0 },
        customerID,
        groupFilter,
        operatorID,
      );
      yield* put(
        actions.setAddTenantsParentList({
          data: tenantsResponse.data.data,
          status: DATA_FETCHING_STATUS.SUCCESS,
        }),
      );
    }
  } catch (e) {
    console.error('error in fetchTenantsList: ', e);
    yield* put(
      actions.setAddTenantsParentListStatus(DATA_FETCHING_STATUS.ERROR),
    );
  }
}

function* getTenantsDeviceCounters(
  action: ReturnType<typeof actions.getTenantsDeviceCounters>,
) {
  try {
    yield* put(
      actions.setTenantsDeviceCountersStatus(DATA_FETCHING_STATUS.LOADING),
    );
    const { data } = yield* call(
      BackendService.getTenantsDeviceCounters,
      action.payload,
    );

    yield* put(actions.setCustomerDeviceCounters(data.deviceCountByTenant));
    yield* put(
      actions.setTenantsDeviceCountersStatus(DATA_FETCHING_STATUS.SUCCESS),
    );
  } catch (error) {
    yield* put(actions.setCustomerDeviceCounters({}));
    yield* put(
      actions.setTenantsDeviceCountersStatus(DATA_FETCHING_STATUS.ERROR),
    );
    console.error('error in getTenantsDeviceCounters: ', error);
  }
}

function* getTenantsMonitoringDeviceCounters(
  action: ReturnType<typeof actions.getTenantsMonitoringDeviceCounters>,
) {
  try {
    yield* put(
      actions.setTenantsMonitoringDeviceCountersStatus(
        DATA_FETCHING_STATUS.LOADING,
      ),
    );
    const { data } = yield* call(
      BackendService.getTenantsMonitoringDeviceCounters,
      action.payload,
    );
    yield* put(
      actions.setTenantsMonitoringDeviceCounters(
        data.monitoredDeviceCountByTenant,
      ),
    );
    yield* put(
      actions.setTenantsMonitoringDeviceCountersStatus(
        DATA_FETCHING_STATUS.SUCCESS,
      ),
    );
  } catch (error) {
    yield* put(actions.setTenantsMonitoringDeviceCounters({}));
    yield* put(
      actions.setTenantsMonitoringDeviceCountersStatus(
        DATA_FETCHING_STATUS.ERROR,
      ),
    );
    console.error('error in getTenantsDeviceCounters: ', error);
  }
}
function* getOperatorsByOperatorsId(
  action: ReturnType<typeof actions.getOperatorsByOperatorsId>,
) {
  try {
    yield* put(actions.setTenantsOperatorsStatus(DATA_FETCHING_STATUS.LOADING));
    const { data } = yield* call(
      BackendService.getTenantsOperators,
      action.payload,
    );
    yield* put(actions.setTenantsOperators(data));
    yield* put(actions.setTenantsOperatorsStatus(DATA_FETCHING_STATUS.SUCCESS));
  } catch (error) {
    yield* put(actions.setTenantsOperators([]));
    yield* put(actions.setTenantsOperatorsStatus(DATA_FETCHING_STATUS.ERROR));
    notifyUserByActionTypeAndCode(action.type, null, error);
    console.error('error in getTenantsDeviceCounters: ', error);
  }
}
function* getCustomersByIds(
  action: ReturnType<typeof actions.getCustomersByIds>,
) {
  try {
    yield* put(actions.setTenantsCustomersStatus(DATA_FETCHING_STATUS.LOADING));
    const { data: searchingResults } = yield* call(
      BackendService.searchCustomers,
      {
        customerIds: action.payload,
      },
    );
    yield* put(actions.setTenantsCustomers(searchingResults?.data));
    yield* put(actions.setTenantsCustomersStatus(DATA_FETCHING_STATUS.SUCCESS));
  } catch (error) {
    yield* put(actions.setTenantsCustomers([]));
    yield* put(actions.setTenantsCustomersStatus(DATA_FETCHING_STATUS.ERROR));
    notifyUserByActionTypeAndCode(action.type, null, error);
    console.error('error in getTenantsDeviceCounters: ', error);
  }
}

export default function* watchTenantActions() {
  yield* all([
    takeLatest(actions.getTenantsList, getTenantsList),
    takeLatest(actions.getSubtenantsList, getSubtenantsList),
    takeLatest(actions.getSubtenantsAlertCounters, getSubtenantsAlertCounters),
    takeLatest(actions.deleteTenant, deleteTenant),
    takeLatest(actions.createTenant, createTenant),
    takeLatest(actions.editTenant, editTenant),
    takeLatest(actions.getPaginatedTenants, getPaginatedTenants),
    takeLatest(
      actions.getCustomerPaginatedTenants,
      getCustomerPaginatedTenants,
    ),
    takeLatest(dataActions.onLoadGroupTenants, getTenantsList),
    takeLatest(deviceActions.onLoadDevicesListPage, getTenantsList),
    takeLatest(actions.getAddTenantsParentList, getAddTenantsParentList),
    takeLatest(actions.getTenantsDeviceCounters, getTenantsDeviceCounters),
    takeLatest(
      actions.getTenantsMonitoringDeviceCounters,
      getTenantsMonitoringDeviceCounters,
    ),
    takeLatest(actions.getOperatorsByOperatorsId, getOperatorsByOperatorsId),
    takeLatest(actions.getCustomersByIds, getCustomersByIds),
  ]);
}
