import React, { useEffect } from 'react';
import Connector, { PropsFromRedux } from './Connector';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';
import { useParams } from 'react-router-dom';
import { linkParamsType } from 'src/routes/MedicalReports/types';
import {
  PhiRestricitonWrapper,
  WrapperContainer,
} from 'src/routes/MedicalReports/components/MedicalReportsWrapper/styled';
import LoadingOverlay from 'src/components/general-ui/LoadingOverlay';
import { Button, Result } from 'antd';
import { ACCESS_DENIED_PHI_PROTECTION } from 'src/components/AppLayout/utils';

type Props = PropsFromRedux & {
  intl: IntlShape;
  children: React.ReactNode;
};

const MedicalReportsWrapper = ({
  intl,
  children,
  isLoading,
  getReportSettings,
  errorSettings,
  logout,
}: Props): JSX.Element => {
  const { tenantId, date } = useParams<linkParamsType>();

  useEffect(() => {
    if (!tenantId || !date) {
      return;
    }
    getReportSettings({ tenantId, date });
  }, [getReportSettings, tenantId, date]);
  if (isLoading) {
    return (
      <WrapperContainer>
        <LoadingOverlay
          loaderText={intl.formatMessage(messages.checkingAccess)}
        />
      </WrapperContainer>
    );
  }
  if (errorSettings === ACCESS_DENIED_PHI_PROTECTION) {
    return (
      <PhiRestricitonWrapper>
        <Result
          status="404"
          title={intl.formatMessage(messages.accessDeniedPhiRestriction)}
          extra={
            <Button onClick={() => logout()}>
              <FormattedMessage {...messages.logoutLabel} />
            </Button>
          }
        />
      </PhiRestricitonWrapper>
    );
  }
  return <>{children}</>;
};

const messages = defineMessages({
  checkingAccess: {
    defaultMessage: 'Checking access, please wait',
  },
  accessDeniedPhiRestriction: {
    defaultMessage: 'Access denied due to PHI regional protection',
  },
  logoutLabel: {
    defaultMessage: 'Logout',
  },
});

export default Connector(injectIntl(MedicalReportsWrapper));
