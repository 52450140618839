import { connect } from 'react-redux';

import { selectors as userDataSelectors } from 'src/redux/data/user';
import { USER_TYPES } from 'src/utils/constants';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { selectors as customersSelectors } from 'src/redux/data/customers';
import {
  selectors as tenantSelectors,
  actions as tenantActions,
} from 'src/redux/data/tenant';
import { extractSelectOptions } from '../CrudCommonComponents/utils';

const mapStateToProps = state => {
  const selectedUserTypes = userDataSelectors.getUserTypes(state);
  const sdkPermissions = selectedUserTypes?.find(
    user => user.name === USER_TYPES.SDK,
  )?.subGroups;

  const allowedUserTypes = [USER_TYPES.TECHNICIAN, USER_TYPES.FACTORY_WORKER];
  const loggedInUserType = loggedInUserSelectors.getLoggedInUserType(state);
  if (loggedInUserType.name !== USER_TYPES.SYSTEM_ADMIN) {
    allowedUserTypes.push(USER_TYPES.SDK);
  }

  return {
    userTypes: selectedUserTypes.filter(
      userType => ![...allowedUserTypes].includes(userType.name),
    ),
    sdkPermissionsSelectOptions: extractSelectOptions(
      sdkPermissions,
      'name',
      'id',
    ),
    customersList: customersSelectors.selectCustomersList(state),
    tenantsByCustomerList: tenantSelectors.getAddTenantsParentList(state),
    loggedInUserCustomerId:
      loggedInUserSelectors.getLoggedInUserCustomerId(state),
    loggedInUserTenantId: loggedInUserSelectors.getCurrentTenantId(state),
  };
};

const mapActionCreators = {
  getTenantsByCustomer: tenantActions.getAddTenantsParentList,
};
const Connector = connect(mapStateToProps, mapActionCreators);
export default Connector;
