import React, { useState } from 'react';
import { Steps } from 'antd';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';

import { StepsContent, StepsAction, WizardWrapper } from './style';
import BorderedButton from 'src/components/general-ui/Buttons/BorderedButton';
import { wizardMessages } from '../OperatorsTable/constants';
import { getWizardSteps } from './stepsConfig';
import Connector, { PropsFromRedux } from './Connector';
import { MODAL_MODES } from 'src/utils/constants';
import { UUID } from 'src/types/utility';

export type Props = PropsFromRedux & {
  intl: IntlShape;
  modalMode: string;
  customerId: UUID;
};

const { Step } = Steps;

const OperatorStepWizard = ({
  intl,
  modalMode,
  isLoadingForm,
  createOperator,
  operatorForm,
  customerId,
}: Props): JSX.Element => {
  const [currentStep, setCurrentStep] = useState<number>(0);

  const steps = getWizardSteps(intl, modalMode);

  const CurrentStepContent = steps[currentStep]?.content;

  const onNext = () => {
    if (modalMode === MODAL_MODES.ADD) {
      createOperator(customerId);
      return;
    }
    setCurrentStep(currentStep => currentStep + 1);
  };

  const organizationId = operatorForm?.emrMappings?.find(
    emr => emr.organizationId,
  );

  const operatorName = operatorForm.name;

  const disabledNextBtn =
    isLoadingForm ||
    steps.length === currentStep ||
    !organizationId ||
    !operatorName;

  return (
    <WizardWrapper>
      <Steps current={currentStep} className="operator-wizard-steps">
        {steps.map(item => (
          <Step key={item.key} title={item.title} />
        ))}
      </Steps>
      <StepsContent>
        {CurrentStepContent && <CurrentStepContent customerId={customerId} />}
      </StepsContent>
      <StepsAction>
        <BorderedButton
          disabled={currentStep === 0}
          onClick={() => setCurrentStep(currentStep => currentStep - 1)}
        >
          <FormattedMessage {...wizardMessages.previous} />
        </BorderedButton>
        <BorderedButton
          disabled={disabledNextBtn}
          loading={isLoadingForm}
          onClick={onNext}
        >
          <FormattedMessage {...wizardMessages.next} />
        </BorderedButton>
      </StepsAction>
    </WizardWrapper>
  );
};

export default Connector(injectIntl(OperatorStepWizard));
