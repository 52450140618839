import { actions as tenantDataActions } from 'src/redux/data/tenant/modules/slice';
import { intl, messages } from '../messages';
import { tenantErrorCodes } from '../errorCodes';

export const tenantActionToErrorNotificationMessagesMap = {
  [tenantDataActions.deleteTenant.type]: (_, error) => {
    let notificationMessages = {
      message: intl.formatMessage(messages.deleteTenantError),
      description: intl.formatMessage(messages.deleteTenantError),
    };

    if (error === tenantErrorCodes.DEVICES_ATTACHED_TO_TENANT_ERROR) {
      notificationMessages = {
        message: intl.formatMessage(messages.deleteTenantError),
        description: intl.formatMessage(messages.devicesAttachedToTenantError),
      };
    } else if (error === tenantErrorCodes.COULD_NOT_GET_DEVICE_DATA) {
      notificationMessages = {
        message: intl.formatMessage(messages.deleteTenantError),
        description: intl.formatMessage(messages.couldNotFetchDevicesError),
      };
    }

    return notificationMessages;
  },
  [tenantDataActions.createTenant.type]: (tenantName, error) => {
    let notificationMessages = {
      message: intl.formatMessage(messages.createTenantError),
      description: `${intl.formatMessage(
        messages.createTenantError,
      )} ${tenantName}`,
    };
    if (error === tenantErrorCodes.MISSING_TENANT_ADMIN) {
      return {
        message: `${intl.formatMessage(messages.createTenantError)} ${tenantName}`,
        description: intl.formatMessage(messages.createTenantMissingAdmin),
      };
    }

    return notificationMessages;
  },
  [tenantDataActions.editTenant.type]: (tenantName, _) => ({
    message: intl.formatMessage(messages.editTenantError),
    description: `${intl.formatMessage(
      messages.editTenantError,
    )} ${tenantName}`,
  }),
};

export const tenantActionToSuccessNotificationMessagesMap = {};
