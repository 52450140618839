import { connect, ConnectedProps } from 'react-redux';

import {
  selectors as operatorsSelectors,
  actions as operatorsActions,
} from 'src/redux/data/operators';
import {
  selectors as tenantSelectors,
  actions as tenantActions,
} from 'src/redux/data/tenant';
import { RootState } from 'src/redux/store';

const mapStateToProps = (state: RootState) => {
  return {
    editableData: operatorsSelectors.selectEditableData(state),
    mtmTenants: tenantSelectors.getPaginatedTenants(state).data,
  };
};

const mapActionCreators = {
  handleChangeForm: operatorsActions.handleChangeForm,
  getCustomerPaginatedTenants: tenantActions.getCustomerPaginatedTenants,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
