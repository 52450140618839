import React, { useEffect, useState } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';
import Connector, { PropsFromRedux } from './Connector';
import { SubTitle } from 'src/components/styled';
import ActionButton from 'src/components/general-ui/Buttons/ActionButton';
import {
  CreateCustomerPayload,
  CustomerModalType,
  CustomerTypeWithAccessData,
} from './modules/types';
import CustomersList from './components/CustomersList';
import { CustomerModal, RightWrapper, StyledTabs, Wrapper } from './styled';
import { CUSTOMER_TABS } from 'src/routes/Customers/modules/tabsConfig';
import CustomerForm from 'src/routes/Customers/components/CustomerForm';
import { FormInstance } from 'antd/es/form/hooks/useForm';
import { CUSTOMER_INITIAL_VALUES } from 'src/redux/data/customers/modules/constants';

type Props = PropsFromRedux & { intl: IntlShape };

const CustomersPage = ({
  customersList,
  isLoading,
  createCustomer,
  searchCustomers,
  intl,
}: Props): JSX.Element => {
  const [form, setForm] =
    useState<FormInstance<CustomerTypeWithAccessData> | null>(null);
  const [isFormValid, setIsFormValid] = useState(false);

  const [modalState, setModalState] = useState<CustomerModalType>({
    mode: '',
    visible: false,
  });
  const [currentCustomer, setCurrentCustomer] = useState<CreateCustomerPayload>(
    CUSTOMER_INITIAL_VALUES,
  );

  useEffect(() => {
    if (!customersList || !customersList.length || !customersList[0]) {
      return;
    }
    const currentCustomerData = customersList?.find(
      elem => elem.id === currentCustomer?.id,
    );
    setCurrentCustomer(
      currentCustomerData?.id ? currentCustomerData : customersList[0],
    );
  }, [customersList, currentCustomer]);

  useEffect(() => {
    searchCustomers();
  }, [searchCustomers]);

  const handleSave = () => {
    if (!form) {
      return;
    }
    setModalState(prevState => ({ ...prevState, mode: '', visible: false }));
    createCustomer(form.getFieldsValue());
    form.resetFields();
  };

  const onCustomerChange = (customer: CreateCustomerPayload) => {
    setCurrentCustomer(customer);
  };
  const onCustomerSearch = (searchKeyword: string) => {
    setCurrentCustomer(CUSTOMER_INITIAL_VALUES);
    searchCustomers({ searchKeyword });
  };
  return (
    <Wrapper>
      <CustomersList
        isLoading={isLoading}
        searchCustomers={onCustomerSearch}
        onAdd={setModalState}
        customersList={customersList ?? []}
        currentCustomer={{
          ...currentCustomer,
        }}
        onCustomerClick={onCustomerChange}
      />
      <RightWrapper>
        <SubTitle>{currentCustomer?.name}</SubTitle>
        {!!customersList?.length && (
          <StyledTabs
            destroyInactiveTabPane
            items={CUSTOMER_TABS.map(
              ({ key, labelMessage, component: Component }) => ({
                key: key,
                label: <span>{intl.formatMessage(labelMessage)}</span>,
                children: (
                  <Component
                    isAddMode={false}
                    currentCustomer={currentCustomer}
                  />
                ),
              }),
            )}
          />
        )}
        {modalState.visible && (
          <CustomerModal
            destroyOnClose
            className="customer-modal"
            title={
              modalState.mode && (
                <FormattedMessage {...modesMessages[modalState.mode]} />
              )
            }
            centered
            open={modalState.visible}
            onOk={handleSave}
            onCancel={() => {
              if (!form) {
                return;
              }
              setModalState(prevState => ({
                ...prevState,
                mode: '',
                visible: false,
              }));
              form.resetFields();
            }}
            footer={[
              <ActionButton
                className="modal-cancel-btn"
                key="cancel"
                onClick={() => {
                  setModalState(prevState => ({
                    ...prevState,
                    mode: '',
                    visible: false,
                  }));
                }}
              >
                <FormattedMessage {...messages.cancel} />
              </ActionButton>,
              <ActionButton
                key="submit"
                onClick={handleSave}
                disabled={!isFormValid}
              >
                {modalState.mode && (
                  <FormattedMessage {...modesMessages[modalState.mode]} />
                )}
              </ActionButton>,
            ]}
          >
            <CustomerForm
              onFormMount={(form: FormInstance<CustomerTypeWithAccessData>) =>
                setForm(form)
              }
              setIsFormValid={setIsFormValid}
            />
          </CustomerModal>
        )}
      </RightWrapper>
    </Wrapper>
  );
};

const modesMessages = defineMessages({
  ADD: {
    defaultMessage: 'Add Customer',
  },
  EDIT: {
    defaultMessage: 'Edit Customer',
  },
});

const messages = defineMessages({
  customers: {
    defaultMessage: 'Customers',
  },
  save: {
    defaultMessage: 'Save',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
});

export default Connector(injectIntl(CustomersPage));
