import React, { useEffect, useState } from 'react';

import Connector, { PropsFromRedux } from './Connector';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';

import { getSdkColumns } from './utils';
import { StyledSdkTable, Wrapper } from './styled';

type Props = PropsFromRedux & { intl: IntlShape };

const SdkPage = ({
  intl,
  sdkData,
  getAllSdkCustomersActivity,
  isLoading,
}: Props): JSX.Element => {
  const columns = getSdkColumns(intl);

  useEffect(() => {
    getAllSdkCustomersActivity();
  }, [getAllSdkCustomersActivity]);

  const [heigth, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Wrapper>
      <StyledSdkTable
        scroll={{ y: heigth - 250 }}
        data={sdkData}
        columns={columns}
        loading={isLoading}
        withSearch
        searchPlaceholder={intl.formatMessage(messages.searchSdk)}
      />
    </Wrapper>
  );
};

const messages = defineMessages({
  searchSdk: {
    defaultMessage: 'Search',
  },
});

export default Connector(injectIntl(SdkPage));
