import { all, takeLatest, call, put } from 'typed-redux-saga/macro';

import BackendService from 'src/services/BackendService';
import { notifyUserByActionTypeAndCode } from 'src/utils/errorHandling/notifications';
import { DATA_FETCHING_STATUS } from '../../constants';
import dataActions from '../../dataActions';
import { actions } from './slice';
import {
  parseFormDataToCreatePatientRequest,
  parseFormDataToUpdatePatientRequest,
} from './parser';

export function* fetchPatientsList() {
  try {
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.LOADING));
    const { data } = yield* call(BackendService.getPatients);
    yield* put(actions.fetchAllPatientsSuccess(data.data));
  } catch (e) {
    console.error('error in fetchPatientsList: ', e);
    yield* put(actions.fetchAllPatientsFail());
  }
}

function* deletePatient(action: ReturnType<typeof actions.deletePatient>) {
  const id = action.payload;
  try {
    yield* call(BackendService.deletePatient, id);
    yield* call(fetchPatientsList);
  } catch (error) {
    console.error('error in deletePatient: ', error);
    notifyUserByActionTypeAndCode(action.type, id, error);
  }
}

function* createPatient(action: ReturnType<typeof actions.createPatient>) {
  const formData = action.payload;
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const request = parseFormDataToCreatePatientRequest(formData);
    yield* call(BackendService.createPatient, request);
    yield* call(fetchPatientsList);
  } catch (error) {
    console.error('error in createPatient: ', error);
    notifyUserByActionTypeAndCode(action.type, formData?.patientId, error);
  }
}

function* updatePatient(action: ReturnType<typeof actions.updatePatient>) {
  const { id, data } = action.payload;
  try {
    const request = parseFormDataToUpdatePatientRequest(id, data);
    const { data: patientDetails } = yield* call(
      BackendService.updatePatient,
      request,
    );
    yield* put(actions.updatePatientData(patientDetails));
  } catch (error) {
    console.error('error in updatePatient: ', error);
    notifyUserByActionTypeAndCode(action.type, data?.patientId ?? id, error);
  }
}
function* updatePatientConsent(
  action: ReturnType<typeof actions.updatePatientConsent>,
) {
  const { id } = action.payload;
  try {
    const { data: patientDetails } = yield* call(
      BackendService.updatePatientConsent,
      action.payload,
    );
    yield* put(
      actions.blockPatientConsent({
        id,
        isConsentBlocked: true,
      }),
    );
    yield* put(actions.updatePatientData(patientDetails));
  } catch (error) {
    console.error('error in updatePatientConsent: ', error);
    notifyUserByActionTypeAndCode(action.type, id, error);
  }
}

export default function* watchPatientActions() {
  yield* all([
    takeLatest(actions.fetchAllPatients, fetchPatientsList),
    takeLatest(actions.deletePatient, deletePatient),
    takeLatest(actions.createPatient, createPatient),
    takeLatest(actions.updatePatient, updatePatient),
    takeLatest(actions.updatePatientConsent, updatePatientConsent),
    takeLatest(dataActions.onLoadStatistics, fetchPatientsList),
    takeLatest(dataActions.onLoadNursesStationPage, fetchPatientsList),
  ]);
}
