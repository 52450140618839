import React from 'react';
import { Popover, Button } from 'antd';
import ActionsRow from 'src/components/CrudCommonComponents/ActionsRow';

type Props = {
  actions: Record<string, string>[];
  record: Record<string, string>;
  index: number;
  text: string;
};

const BurgerActions = ({ index, text, actions, record }: Props) => {
  return (
    <Popover
      content={
        <ActionsRow
          actions={actions}
          record={record}
          rowProps={{ text, record, index }}
          verticalLayout
        />
      }
      trigger="click"
      placement="bottom"
    >
      <Button>...</Button>
    </Popover>
  );
};

export default BurgerActions;
