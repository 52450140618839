import { connect, ConnectedProps } from 'react-redux';

import {
  selectors as customersSelectors,
  actions as customersActions,
} from 'src/redux/data/customers';
import { RootState } from 'src/redux/store';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';

const mapStateToProps = (state: RootState) => ({
  customersList: customersSelectors.selectCustomersList(state),
  isLoading:
    customersSelectors.selectCustomersListStatus(state) ===
    DATA_FETCHING_STATUS.LOADING,
  customerForm: customersSelectors.selectEditableData(state),
});

const mapActionCreators = {
  createCustomer: customersActions.createCustomer,
  searchCustomers: customersActions.searchCustomers,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
