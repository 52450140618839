import styled from 'styled-components';
import TransparentButton from 'src/components/general-ui/Buttons/TransparentButton';
import ActionButton from 'src/components/general-ui/Buttons/ActionButton';
import CancelButton from 'src/components/general-ui/Buttons/CancelButton';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StyledCustomerForm = styled.div`
  display: flex;
  .add-customer-form {
    display: flex;
    width: auto;
    gap: 5rem;
    margin-top: 1.5rem;
    .point-of-contact-name {
      margin-bottom: 45px;
    }
  }
`;
export const StyledButton = styled(TransparentButton)`
  color: ${props => props.theme.colors.continuousGraphRrContinuousColor};
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-self: flex-start;
  margin-left: auto;
  &:disabled {
    color: ${props => props.theme.colors.actionButtonDisabledColor};
  }
`;
export const StyledPrimaryBtn = styled(ActionButton)`
  display: flex;
  align-self: self-end;
  height: auto;
`;
export const StyledCancelBtn = styled(CancelButton)`
  display: flex;
  align-self: self-end;
  height: auto;
`;
export const StyledButtonsContainer = styled.div`
  display: flex;
  align-self: flex-end;
  gap: 1rem;
`;
