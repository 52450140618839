import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form, Input } from 'antd';
import {
  MFA_OPTIONS,
  MFA_OPTIONS_VALUES,
  userFormFields,
  userFormFieldsLabelsMessages as labelMessages,
  userFormFieldsPlaceholdersMessages as placeholderMessages,
  userFormModes,
  userFormValidateMessages,
  subTitles,
} from './constants';
import {
  defaultFormFieldsRulesArray as defaultRulesArray,
  emailPattern,
  NETEERA_CUSTOMER_ID,
  phonePattern,
  USER_TYPES,
} from 'src/utils/constants';
import { extractSelectOptions } from 'src/components/CrudCommonComponents/utils';
import RadioGroup from 'src/components/general-ui/RadioGroup';
import { EachColumn, FormSection, PermissionsCheckboxWrap } from './styled';
import { SubTitle } from 'src/components/styled';
import SelectBoxForm from '../general-ui/SelectBoxForm';
import {
  getSdkUserTypeId,
  getFilteredUserTypes,
  getMTMUserTypeId,
  getMTVUserTypeId,
} from './utils';
import { hasSAPermissions } from 'src/utils/permissions';

const UserForm = ({
  form,
  onSubmit,
  userTypes,
  user,
  mode,
  intl,
  fields,
  customersList,
  getTenantsByCustomer,
  tenantsByCustomerList,
  sdkPermissionsSelectOptions,
  currentCustomer,
  loggedInUserCustomerId,
  loggedInUserTenantId,
}) => {
  const SDK_USER_ID = getSdkUserTypeId(userTypes);
  const MTM_USER_ID = getMTMUserTypeId(userTypes);
  const MTV_USER_ID = getMTVUserTypeId(userTypes);
  const filteredlUserTypes = getFilteredUserTypes(userTypes);
  const formCustomerId = form.getFieldValue(userFormFields.CUSTOMER_ID);
  const formCustomerIdIstouched = form.isFieldTouched(
    userFormFields.CUSTOMER_ID,
  );
  const formUserType = form.getFieldValue(userFormFields.USER_TYPE);

  useEffect(() => {
    if (!formCustomerId) {
      return;
    }
    getTenantsByCustomer({
      customerID: formCustomerId,
    });
  }, [getTenantsByCustomer, formCustomerId]);

  useEffect(() => {
    if (formUserType === SDK_USER_ID) {
      return;
    }
    form.setFieldValue(userFormFields.SDK_USER_PERMISSIONS, ['', '', '']);

    () => form.resetFields();
  }, [formUserType, SDK_USER_ID]);

  useEffect(() => {
    if (
      !formCustomerIdIstouched ||
      (formUserType && ![SDK_USER_ID, MTM_USER_ID].includes(formUserType))
    ) {
      return;
    }
    form.setFieldValue(userFormFields.TENANT_ID, undefined);
  }, [formCustomerId, formUserType]);

  const getExternalUserTypeOptions = () =>
    fields?.externalUserTypes
      ? fields?.externalUserTypes
          .filter(userType => !!userType)
          .map(userType => ({
            id: userType.id,
            label: userType.name,
            value: userType.id,
          }))
      : null;

  const onMfaEnableChange = isMfaEnabled => {
    form.setFieldsValue({
      [userFormFields.MFA]: isMfaEnabled,
    });
  };

  return form ? (
    <Form
      form={form}
      layout="vertical"
      onFinish={values => {
        if (formUserType !== SDK_USER_ID) {
          delete values[userFormFields.SDK_USER_PERMISSIONS];
        }
        onSubmit(values);
      }}
      scrollToFirstError
      validateMessages={userFormValidateMessages}
    >
      {() => (
        <>
          <SubTitle>{intl.formatMessage(subTitles.general)}</SubTitle>
          <FormSection>
            <EachColumn>
              <Form.Item
                name={userFormFields.CUSTOMER_ID}
                label={intl.formatMessage(labelMessages.customer)}
                rules={hasSAPermissions() && defaultRulesArray}
                initialValue={
                  hasSAPermissions() || mode === userFormModes.EDIT
                    ? user?.customerId || currentCustomer?.id
                    : loggedInUserCustomerId
                }
                hidden={!hasSAPermissions()}
              >
                <SelectBoxForm
                  width="100%"
                  options={
                    currentCustomer?.id
                      ? [
                          {
                            value: currentCustomer.id,
                            label: currentCustomer.name,
                          },
                        ]
                      : extractSelectOptions(customersList, 'name', 'id')
                  }
                  placeholder={intl.formatMessage(
                    placeholderMessages.selectOption,
                  )}
                  disabled={currentCustomer?.id}
                />
              </Form.Item>
              {fields?.userType && (
                <Form.Item
                  name={userFormFields.USER_TYPE}
                  label={intl.formatMessage(labelMessages.userType)}
                  initialValue={user?.userTypeId}
                  rules={defaultRulesArray}
                  dependencies={[
                    userFormFields.CUSTOMER_ID,
                    userFormFields.SDK_USER_PERMISSIONS,
                  ]}
                >
                  <SelectBoxForm
                    id={`${mode}-${userFormFields.USER_TYPE}`}
                    placeholder={intl.formatMessage(
                      placeholderMessages.userType,
                    )}
                    options={extractSelectOptions(
                      formCustomerId === NETEERA_CUSTOMER_ID
                        ? userTypes
                        : filteredlUserTypes,
                      'name',
                      'id',
                    )}
                    disabled={mode === userFormModes.EDIT}
                    allowClear
                  />
                </Form.Item>
              )}
              {fields?.externalUserTypes && (
                <Form.Item
                  name={userFormFields.USER_TYPE}
                  label={intl.formatMessage(labelMessages.userType)}
                  initialValue={user?.userType}
                  rules={defaultRulesArray}
                >
                  <SelectBoxForm
                    id={`${mode}-${userFormFields.USER_TYPE}`}
                    placeholder={intl.formatMessage(
                      placeholderMessages.userType,
                    )}
                    options={getExternalUserTypeOptions()}
                    disabled={mode === userFormModes.EDIT}
                    allowClear
                  />
                </Form.Item>
              )}
              <Form.Item
                name={userFormFields.TENANT_ID}
                label={intl.formatMessage(labelMessages.tenant)}
                initialValue={
                  hasSAPermissions() ? user?.tenantId : loggedInUserTenantId
                }
                dependencies={[userFormFields.USER_TYPE]}
                rules={SDK_USER_ID !== formUserType && defaultRulesArray}
                hidden={!hasSAPermissions()}
              >
                <SelectBoxForm
                  width="100%"
                  options={extractSelectOptions(
                    [MTV_USER_ID, MTM_USER_ID].includes(formUserType)
                      ? tenantsByCustomerList.filter(tenant => tenant.group)
                      : tenantsByCustomerList,
                    'name',
                    'id',
                  )}
                  placeholder={intl.formatMessage(
                    placeholderMessages.selectOption,
                  )}
                  disabled={
                    hasSAPermissions() &&
                    (SDK_USER_ID === formUserType || !formCustomerId)
                  }
                />
              </Form.Item>
            </EachColumn>
            <EachColumn>
              {hasSAPermissions() && (
                <Form.Item
                  name={userFormFields.SDK_USER_PERMISSIONS}
                  label={intl.formatMessage(labelMessages.permissions)}
                  dependencies={[userFormFields.USER_TYPE]}
                  initialValue={
                    user?.userType === USER_TYPES.SDK
                      ? user?.groups?.map(item => item.id)
                      : undefined
                  }
                  messageVariables={{
                    label: userFormValidateMessages.requiredPermission,
                  }}
                  rules={SDK_USER_ID === formUserType && [defaultRulesArray[0]]}
                >
                  <PermissionsCheckboxWrap
                    options={sdkPermissionsSelectOptions}
                    disabled={formUserType !== SDK_USER_ID}
                  />
                </Form.Item>
              )}
            </EachColumn>
          </FormSection>
          <SubTitle>{intl.formatMessage(subTitles.userDetails)}</SubTitle>
          <FormSection>
            <EachColumn>
              <Form.Item
                name={userFormFields.FIRST_NAME}
                label={intl.formatMessage(labelMessages.firstName)}
                initialValue={user?.firstName}
                rules={defaultRulesArray}
              >
                <Input
                  placeholder={intl.formatMessage(
                    placeholderMessages.firstName,
                  )}
                  id={`${mode}-${userFormFields.FIRST_NAME}`}
                />
              </Form.Item>
              <Form.Item
                name={userFormFields.EMAIL}
                label={intl.formatMessage(labelMessages.email)}
                initialValue={user?.email}
                rules={[{ required: true, pattern: emailPattern }]}
              >
                <Input
                  placeholder={intl.formatMessage(placeholderMessages.email)}
                  disabled={mode === userFormModes.EDIT}
                  id={`${mode}-${userFormFields.EMAIL}`}
                />
              </Form.Item>
            </EachColumn>
            <EachColumn>
              <Form.Item
                name={userFormFields.LAST_NAME}
                label={intl.formatMessage(labelMessages.lastName)}
                initialValue={user?.lastName}
                rules={defaultRulesArray}
              >
                <Input
                  placeholder={intl.formatMessage(placeholderMessages.lastName)}
                  id={`${mode}-${userFormFields.LAST_NAME}`}
                />
              </Form.Item>
              <Form.Item
                name={userFormFields.PHONE}
                label={intl.formatMessage(labelMessages.phoneNumber)}
                initialValue={user?.phone}
                rules={[{ pattern: phonePattern }]}
              >
                <Input
                  placeholder={intl.formatMessage(
                    placeholderMessages.phoneNumber,
                  )}
                  disabled={!!fields?.phone?.disabled}
                  id={`${mode}-${userFormFields.PHONE}`}
                />
              </Form.Item>
              {fields?.mfaPhone && (
                <>
                  <Form.Item
                    name={userFormFields.MFA_PHONE}
                    label={intl.formatMessage(labelMessages.mfaPhoneNumber)}
                    initialValue={user?.mfaPhone}
                    rules={[{ pattern: phonePattern }]}
                  >
                    <Input
                      placeholder={intl.formatMessage(
                        placeholderMessages.mfaPhoneNumber,
                      )}
                      id={`${mode}-${userFormFields.MFA_PHONE}`}
                    />
                  </Form.Item>
                  <Form.Item
                    initialValue={user?.mfa}
                    name={userFormFields.MFA}
                    label={intl.formatMessage(labelMessages.enableMfa)}
                  >
                    <RadioGroup
                      onSelect={onMfaEnableChange}
                      selectedOption={
                        user?.mfa
                          ? MFA_OPTIONS_VALUES.TRUE
                          : MFA_OPTIONS_VALUES.FALSE
                      }
                      optionsList={MFA_OPTIONS}
                    />
                  </Form.Item>
                </>
              )}
            </EachColumn>
          </FormSection>
        </>
      )}
    </Form>
  ) : null;
};
UserForm.propTypes = {
  form: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.object,
  mode: PropTypes.oneOf(Object.values(userFormModes)).isRequired,
  userTypes: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  fields: PropTypes.object,
  customersList: PropTypes.array,
  tenantsByCustomerList: PropTypes.array,
  sdkPermissionsSelectOptions: PropTypes.array,
  currentCustomer: PropTypes.object,
  getTenantsByCustomer: PropTypes.func,
  loggedInUserCustomerId: PropTypes.string,
  loggedInUserTenantId: PropTypes.string,
};

UserForm.defaultProps = {
  user: {},
};

export default injectIntl(UserForm);
