import { all, takeLatest, call, put } from 'typed-redux-saga/macro';

import {
  actionSuccessNotification,
  notifyUserByActionTypeAndCode,
} from 'src/utils/errorHandling/notifications';
import { MODAL_STATUS } from 'src/components/Modal/constants';
import UmsLogicSdk from 'src/libs/ums-js-logic';
import BackendService from 'src/services/BackendService';
import dataActions from 'src/redux/data/dataActions';
import {
  parseFormDataToUpdateUserRequest,
  parseFormDataToInviteUserRequest,
  parseFormDataToUpdateUserPhoneRequest,
  parseFormDataToUpdateUserMfaRequest,
} from './parser';
import { actions } from './slice';
import { DEFAULT_PAGINATION } from 'src/utils/constants';
import { DATA_FETCHING_STATUS } from '../../constants';
import { USER_STATUS } from 'src/components/UserCrudComponents/constants';
import { mergeOrganizationAndMfaUsers } from '../../dataUtils';

export function* fetchUserTypes() {
  try {
    const response = yield* call(BackendService.getAllUserTypes, {});
    yield* put(actions.fetchUserTypesSuccess(response.data));
  } catch (e) {
    console.error('error in fetchUserTypes: ', e);
    yield* put(actions.fetchUserTypesFail());
  }
}

export function* fetchUsersAndInvitedUsersList(
  action?: ReturnType<typeof actions.fetchAllUsers>,
) {
  const customerId = action?.payload || '';

  const queryParams = {
    customerId,
    page: DEFAULT_PAGINATION.PAGE - 1,
    limit: 10000,
  };

  if (customerId) {
    yield* put(actions.setResetUsersState());
  }

  try {
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.LOADING));
    yield* call(fetchUserTypes);

    const usersResponse = yield* call(BackendService.getAllUsers, queryParams);

    const usersResponseWithMfaInfo = yield* call(
      BackendService.getMfaUsersData,
      queryParams,
    );

    const invitedUsersResponse = yield* call(
      BackendService.getInvitedUsers,
      queryParams,
    );

    yield* put(
      actions.fetchAllUsersSuccess({
        users: mergeOrganizationAndMfaUsers(
          usersResponse.data,
          usersResponseWithMfaInfo.data.data,
        ),
        invitedUsers: invitedUsersResponse.data,
      }),
    );
  } catch (e) {
    console.error('error in fetchUsersAndInvitedUsersList: ', e);
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.ERROR));
  }
}

export function* fetchExistentUsers() {
  try {
    const usersResponse = yield* call(BackendService.getAllUsers);
    yield* put(
      actions.fetchExistentUsersSuccess({
        users: usersResponse.data,
      }),
    );
  } catch (e) {
    console.error('error in fetchExistentUsers: ', e);
    yield* put(actions.fetchExistentUsersFail());
  }
}

function* inviteUser(action: ReturnType<typeof actions.inviteUser>) {
  const { formData, customerId } = action.payload;

  const customerIdForm = customerId;

  try {
    yield* put(actions.setModalStatus(MODAL_STATUS.SUBMITTING));
    const request = parseFormDataToInviteUserRequest(formData);
    yield* call(BackendService.inviteUser, request);

    actionSuccessNotification(action.type, formData);

    yield* put(actions.fetchAllUsers(customerIdForm));
    yield* put(actions.setModalStatus(MODAL_STATUS.SUCCESS));
  } catch (error) {
    console.error('error in inviteUser: ', error);
    yield* put(actions.setModalStatus(MODAL_STATUS.ERROR));

    notifyUserByActionTypeAndCode(
      action.type,
      { firstName: formData?.firstName, lastName: formData?.lastName },
      error,
    );
  }
}

// TODO: check if it can be deleted, when logged in as a MTM user, there is used
function* deleteUser(action: ReturnType<typeof actions.deleteUser>) {
  const userId = action.payload;
  try {
    yield* call(BackendService.deleteUser, userId);
    yield* put(actions.deleteUserSuccess(userId));
  } catch (error) {
    console.error('error in deleteUser: ', error);
    notifyUserByActionTypeAndCode(action.type, userId, error);
  }
}

function* updateUser(action: ReturnType<typeof actions.updateUser>) {
  const { userId, data, userStatus, customerId } = action.payload;
  try {
    yield* put(actions.setModalStatus(MODAL_STATUS.SUBMITTING));

    const request = { userId, data: parseFormDataToUpdateUserRequest(data) };

    yield* call(BackendService.updateUser, request);
    if (userStatus === USER_STATUS.ACTIVE) {
      yield* put(actions.updateMfaUserInfo({ userId, data }));
    }

    yield* put(actions.fetchAllUsers(customerId));
    yield* put(actions.setModalStatus(MODAL_STATUS.SUCCESS));
  } catch (error) {
    console.error('error in updateUser: ', error);
    yield* put(actions.setModalStatus(MODAL_STATUS.ERROR));

    notifyUserByActionTypeAndCode(
      action.type,
      { firstName: data?.firstName, lastName: data?.lastName },
      error,
    );
  }
}

function* updateUserPhoneNumber(
  action: ReturnType<typeof actions.updateUserPhone>,
) {
  const { userId, data } = action.payload;

  try {
    const request = {
      userId,
      data: parseFormDataToUpdateUserPhoneRequest(data),
    };

    yield* call(BackendService.updateUser, request);
    yield* put(actions.fetchAllUsers());

    actionSuccessNotification(action.type, null);
  } catch (error) {
    console.error('error in updateUserPhoneNumber: ', error);
    notifyUserByActionTypeAndCode(action.type, {}, error);
  }
}

function* updateMfaUserInfo(action: ReturnType<typeof actions.updateUser>) {
  const { userId, data } = action.payload;
  try {
    yield* put(actions.setModalStatus(MODAL_STATUS.SUBMITTING));
    const request = { userId, data: parseFormDataToUpdateUserMfaRequest(data) };
    yield* call(BackendService.updateMfaUserInfo, request);
  } catch (error) {
    console.error('error in updateMfaUserInfo: ', error);
    yield* put(actions.setModalStatus(MODAL_STATUS.ERROR));
    notifyUserByActionTypeAndCode(action.type, error, error);
  }
}

function* sendMessageForResetPassword(
  action: ReturnType<typeof actions.sendMessageForResetPassword>,
) {
  const { email } = action.payload;
  try {
    yield* call([UmsLogicSdk, UmsLogicSdk.forgotPassword], { username: email });

    actionSuccessNotification(action.type);
  } catch (error) {
    console.error('Error in send message for reset password: ', error);
    notifyUserByActionTypeAndCode(action.type, action.payload, error);
  }
}

function* resendInvitation(
  action: ReturnType<typeof actions.resendInvitation>,
) {
  const record = action.payload;
  try {
    yield* call(BackendService.resendInvitation, record.id);
    actionSuccessNotification(action.type, record);
  } catch (error) {
    notifyUserByActionTypeAndCode(action.type, record, error);
  }
}

function* deleteInvitation(
  action: ReturnType<typeof actions.deleteInvitation>,
) {
  const userId = action.payload;
  try {
    yield* call(BackendService.deleteInvitation, userId);
    yield* put(actions.deleteInvitationSuccess(userId));
  } catch (error) {
    notifyUserByActionTypeAndCode(action.type, userId, error);
  }
}

function* toggleUserActivation(
  action: ReturnType<typeof actions.toggleUserActivation>,
) {
  const { userId, customerId } = action.payload;
  try {
    yield* call(BackendService.toggleUserActivation, action.payload);

    yield* put(actions.fetchAllUsers(customerId));
  } catch (error) {
    console.error('error in toggleUserActivation: ', error);
    notifyUserByActionTypeAndCode(action.type, userId, error);
  }
}

export default function* watchUserActions() {
  yield* all([
    takeLatest(actions.fetchUserTypes, fetchUserTypes),
    takeLatest(actions.fetchAllUsers, fetchUsersAndInvitedUsersList),
    takeLatest(actions.fetchExistentUsers, fetchExistentUsers),
    takeLatest(actions.inviteUser, inviteUser),
    takeLatest(actions.deleteUser, deleteUser),
    takeLatest(actions.updateUser, updateUser),
    takeLatest(actions.updateMfaUserInfo, updateMfaUserInfo),
    takeLatest(
      actions.sendMessageForResetPassword,
      sendMessageForResetPassword,
    ),
    takeLatest(actions.resendInvitation, resendInvitation),
    takeLatest(actions.deleteInvitation, deleteInvitation),
    takeLatest(actions.updateUserPhone, updateUserPhoneNumber),
    takeLatest(dataActions.onLoadSMSEmailAlert, fetchUsersAndInvitedUsersList),
    takeLatest(actions.toggleUserActivation, toggleUserActivation),
  ]);
}
