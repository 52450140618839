import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import Modal from 'src/components/Modal';
import Connector from './Connector';
import SelectBoxForm from 'src/components/general-ui/SelectBoxForm';
import { extractSelectOptions } from 'src/components/CrudCommonComponents/utils';
import { devicesFields, isSysAdmin } from './utils';
import { userFormModes } from 'src/components/UserCrudComponents/constants';
import ConfirmationModal from 'src/components/Modal/ConfirmationModal';
import { deepEqual } from 'src/utils/comparators';
import { MODAL_STATUS } from 'src/components/Modal/constants';
import {
  defaultFormFieldsRulesArray as defaultRulesArray,
  getMandatoryFormFieldsWithoutSpaceRules,
} from 'src/utils/constants';
import { hasAllPermissions } from 'src/utils/permissions';
import permissions from 'src/permissions';
import { DeviceHeader, StyledHeader, StyledModalBody } from './styled';
import Spacer from 'src/components/Spacer';
import DeviceFieldSelect from 'src/components/DeviceFieldSelect';
import {
  NEW_BED_ID,
  NEW_ROOM_ID,
} from 'src/redux/data/rooms/modules/constants';
import { useLocationFields } from 'src/routes/Devices/components/AddEditDeviceForm/useLocationFields';
import { selectors as roomSelectors } from 'src/redux/data/rooms';
import { selectors as operatorsSelectors } from 'src/redux/data/operators';
import { API_STATUS } from 'src/utils/api-constants';
import { selectors as customersSelectors } from 'src/redux/data/customers';
import { selectors as tenantDataSelectors } from 'src/redux/data/tenant';
import { selectors as deviceSelectors } from 'src/redux/data/device';
import LoadingOverlay from 'src/components/general-ui/LoadingOverlay';
export const deviceModalModes = {
  ADD: 'ADD',
  EDIT: 'EDIT',
};

const DeviceModal = ({
  isModalVisible,
  setIsModalVisible,
  onOk,
  mode,
  modalStatus,
  setModalStatus,
  intl,
  deviceListRow,
  getCustomers,
  getOperators,
  getTenantsByCustomerOrOperator,
  getLocationMountOptions,
  customersList,
  operatorsList,
  tenantsByCustomerList,
  locationMountOptions,
  getRoomsList,
  roomsList,
  areRoomsListLoading,
  areOperatorsLoading,
  areCustomersLoading,
  areTenantsByCustomerListLoading,
  areLocationMountOptionsLoading,
}) => {
  const [form] = Form.useForm();
  const [isDiscardConfirmationVisible, setIsDiscardConfirmationVisible] =
    useState(false);
  const operatorsOptionList = operatorsList?.map(operator => ({
    value: operator.id,
    label: operator.name,
  }));

  useEffect(() => {
    isSysAdmin() && getCustomers();
    getLocationMountOptions();
  }, []);

  const selectedRoomId = form.getFieldValue(devicesFields.ROOM_ID);
  const selectedBedId = form.getFieldValue(devicesFields.BED_ID);
  const isDataLoading = isSysAdmin()
    ? areRoomsListLoading ||
      areOperatorsLoading ||
      areCustomersLoading ||
      areTenantsByCustomerListLoading ||
      areLocationMountOptionsLoading
    : areRoomsListLoading;
  const {
    addRoom,
    setRoomInput,
    addBed,
    setBedInput,
    roomOptions,
    newRoom,
    bedOptions,
    newBed,
    isReAssignDeviceConfirmationVisible,
    setIsReAssignDeviceConfirmationVisible,
  } = useLocationFields(
    form,
    roomsList,
    deviceListRow,
    selectedRoomId,
    selectedBedId,
  );
  const canEditLocation = () =>
    hasAllPermissions(permissions.VIEW_ROOMS_DEVICE_MODAL);

  // When customer field changes
  useEffect(() => {
    if (!form.getFieldValue(devicesFields.CUSTOMER_ID) || !isSysAdmin()) {
      return;
    }
    getOperators({
      customerId: form.getFieldValue(devicesFields.CUSTOMER_ID),
      limit: 1000000,
    });
    getTenantsByCustomerOrOperator({
      customerID: form.getFieldValue(devicesFields.CUSTOMER_ID),
    });
  }, [
    getOperators,
    getTenantsByCustomerOrOperator,
    form.getFieldValue(devicesFields.CUSTOMER_ID),
  ]);

  // When operator field changes
  useEffect(() => {
    if (!form.getFieldValue(devicesFields.OPERATOR_ID) || !!isSysAdmin()) {
      return;
    }
    getTenantsByCustomerOrOperator({
      customerID: form.getFieldValue(devicesFields.CUSTOMER_ID),
      operatorID: form.getFieldValue(devicesFields.OPERATOR_ID),
    });
  }, [
    getTenantsByCustomerOrOperator,
    form.getFieldValue(devicesFields.OPERATOR_ID),
  ]);
  // When tenant field changes
  useEffect(() => {
    if (!form.getFieldValue(devicesFields.TENANT_ID)) {
      return;
    }
    getRoomsList({
      tenantId: form.getFieldValue(devicesFields.TENANT_ID),
    });
  }, [getRoomsList, form.getFieldValue(devicesFields.TENANT_ID)]);

  const modeToPropsMap = {
    [deviceModalModes.ADD]: {
      title: intl.formatMessage(messages.addDevice),
      okButtonText: intl.formatMessage(messages.add),
      initialValues: {},
    },
    [deviceModalModes.EDIT]: {
      title: intl.formatMessage(messages.editDevice),
      okButtonText: intl.formatMessage(messages.apply),
      initialValues: deviceListRow,
    },
  };
  const initialValues = modeToPropsMap[mode].initialValues;
  const onFinish = values => {
    const room = roomOptions.find(r => r.value === selectedRoomId);
    const bed = bedOptions.find(b => b.value === selectedBedId);
    onOk({ ...values, room, bed });
    /*setIsModalVisible(false);
    setModalStatus(MODAL_STATUS.INITIAL);*/
  };

  const initialValuesHasBeenChanged = () => {
    const formValues = { ...form.getFieldsValue() };
    const initialFormFields = {};
    formValues.bedId =
      typeof formValues.bedId == 'string' || formValues.bedId instanceof String
        ? formValues.bedId
        : formValues['bedId']?.bedId;
    Object.keys(formValues)?.forEach(key => {
      initialFormFields[key] = initialValues[key];
    });
    return !deepEqual(initialFormFields, formValues);
  };

  return (
    <>
      <Modal
        title={modeToPropsMap[mode].title}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        okButtonText={modeToPropsMap[mode].okButtonText}
        onOk={() => {
          form.submit();
        }}
        modalStatus={modalStatus}
        shouldCloseOnCancel={false}
        onCancel={() => {
          setIsDiscardConfirmationVisible(initialValuesHasBeenChanged());
          if (initialValuesHasBeenChanged()) {
            return;
          }
          setIsModalVisible(false);
          setModalStatus(MODAL_STATUS.INITIAL);
        }}
        setModalStatus={setModalStatus}
      >
        <StyledModalBody>
          {isDataLoading && <LoadingOverlay />}
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            validateMessages={deviceFormValidateMessages}
          >
            {() => (
              <>
                <Form.Item
                  name={devicesFields.MANUFACTURER_ID}
                  label={intl.formatMessage(messages.manufacturerIdLabel)}
                  initialValue={initialValues?.[devicesFields.MANUFACTURER_ID]}
                  rules={getMandatoryFormFieldsWithoutSpaceRules(
                    intl.formatMessage(messages.manufacturerIdError),
                  )}
                >
                  <Input
                    disabled={mode !== deviceModalModes.ADD}
                    autoComplete="off"
                  />
                </Form.Item>
                <Form.Item
                  name={devicesFields.CUSTOMER_ID}
                  label={intl.formatMessage(messages.customerName)}
                  initialValue={initialValues?.[devicesFields.CUSTOMER_ID]}
                  rules={defaultRulesArray}
                >
                  <SelectBoxForm
                    disabled={mode === userFormModes.EDIT}
                    options={
                      isSysAdmin()
                        ? extractSelectOptions(
                            customersList || [],
                            'name',
                            'id',
                          )
                        : [
                            {
                              label:
                                initialValues?.[devicesFields.CUSTOMER_NAME],
                              value: initialValues?.[devicesFields.CUSTOMER_ID],
                            },
                          ]
                    }
                    placeholder={intl.formatMessage(messages.selectPlaceholder)}
                  />
                </Form.Item>
                <Form.Item
                  name={devicesFields.OPERATOR_ID}
                  label={intl.formatMessage(messages.operator)}
                  initialValue={initialValues?.[devicesFields.OPERATOR_ID]}
                  dependencies={[devicesFields.CUSTOMER_ID]}
                >
                  <SelectBoxForm
                    disabled={
                      !form.getFieldValue(devicesFields.CUSTOMER_ID) ||
                      mode === userFormModes.EDIT
                    }
                    id={`${mode}-${devicesFields.OPERATOR_ID}`}
                    placeholder={intl.formatMessage(messages.selectPlaceholder)}
                    options={
                      isSysAdmin()
                        ? operatorsOptionList
                        : [
                            {
                              label:
                                initialValues?.[devicesFields.OPERATOR_NAME],
                              value: initialValues?.[devicesFields.OPERATOR_ID],
                            },
                          ]
                    }
                    allowClear
                  />
                </Form.Item>
                <Form.Item
                  name={devicesFields.TENANT_ID}
                  label={intl.formatMessage(messages.tenantName)}
                  initialValue={initialValues?.[devicesFields.TENANT_ID]}
                  rules={defaultRulesArray}
                >
                  <SelectBoxForm
                    options={
                      isSysAdmin()
                        ? extractSelectOptions(
                            tenantsByCustomerList,
                            'name',
                            'id',
                          )
                        : [
                            {
                              label: initialValues?.[devicesFields.TENANT_NAME],
                              value: initialValues?.[devicesFields.TENANT_ID],
                            },
                          ]
                    }
                    placeholder={intl.formatMessage(messages.selectPlaceholder)}
                    disabled={
                      !form.getFieldValue(devicesFields.CUSTOMER_ID) ||
                      mode === userFormModes.EDIT
                    }
                  />
                </Form.Item>
                {canEditLocation() ? (
                  <>
                    <Form.Item
                      name={devicesFields.ROOM_ID}
                      label={intl.formatMessage(messages.roomLabel)}
                      dependencies={[devicesFields.TENANT_ID]}
                      initialValue={initialValues?.[devicesFields.ROOM_ID]}
                    >
                      <DeviceFieldSelect
                        disabled={!form.getFieldValue(devicesFields.TENANT_ID)}
                        addNew={addRoom}
                        options={roomOptions}
                        title={intl.formatMessage(messages.addRoom)}
                        newValue={newRoom}
                        onChangeInput={setRoomInput}
                      />
                    </Form.Item>
                    <Form.Item
                      validateTrigger={'onSubmit'}
                      name={devicesFields.BED_ID}
                      label={intl.formatMessage(messages.bedLabel)}
                      dependencies={[devicesFields.ROOM_ID]}
                      rules={[
                        {
                          required: !!form.getFieldValue(devicesFields.ROOM_ID),
                          message: intl.formatMessage(messages.bedIdError),
                        },
                        {
                          validator(rule, value) {
                            if (
                              !value &&
                              !!form.getFieldValue(devicesFields.ROOM_ID)
                            ) {
                              return Promise.reject();
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <DeviceFieldSelect
                        disabled={!form.getFieldValue(devicesFields.ROOM_ID)}
                        addNew={addBed}
                        options={bedOptions}
                        title={intl.formatMessage(messages.addBed)}
                        newValue={newBed}
                        onChangeInput={setBedInput}
                      />
                    </Form.Item>
                  </>
                ) : (
                  <></>
                )}
                <Form.Item
                  name={devicesFields.MOUNT_TYPE}
                  label={intl.formatMessage(messages.mountType)}
                  initialValue={initialValues?.[devicesFields.MOUNT_TYPE]}
                >
                  <SelectBoxForm
                    disabled={canEditLocation() && !selectedBedId}
                    options={locationMountOptions?.mountingType || []}
                    placeholder={intl.formatMessage(messages.selectPlaceholder)}
                  />
                </Form.Item>
                <Form.Item
                  name={devicesFields.WIRING_TYPE}
                  label={intl.formatMessage(messages.wiringType)}
                  initialValue={initialValues?.[devicesFields.WIRING_TYPE]}
                >
                  <SelectBoxForm
                    disabled={canEditLocation() && !selectedBedId}
                    options={locationMountOptions?.wiringType || []}
                    placeholder={intl.formatMessage(messages.selectPlaceholder)}
                  />
                </Form.Item>
                <Form.Item
                  name={devicesFields.ELECTRICAL_OUTLET_REPLACEMENT}
                  label={intl.formatMessage(
                    messages.electricalOutletReplacement,
                  )}
                  initialValue={
                    initialValues?.[devicesFields.ELECTRICAL_OUTLET_REPLACEMENT]
                  }
                >
                  <SelectBoxForm
                    disabled={canEditLocation() && !selectedBedId}
                    options={
                      locationMountOptions?.electricalOutletReplacement || []
                    }
                    placeholder={intl.formatMessage(messages.selectPlaceholder)}
                  />
                </Form.Item>
                <Form.Item
                  name={devicesFields.NOTES}
                  label={intl.formatMessage(messages.note)}
                  initialValue={initialValues?.[devicesFields.NOTES]}
                >
                  <Input
                    disabled={canEditLocation() && !selectedBedId}
                    autoComplete="off"
                  />
                </Form.Item>
              </>
            )}
          </Form>
        </StyledModalBody>
      </Modal>
      <ConfirmationModal
        isModalVisible={isDiscardConfirmationVisible}
        setIsModalVisible={value => {
          setIsDiscardConfirmationVisible(value);
        }}
        onOk={() => {
          setIsModalVisible(false);
          setModalStatus(MODAL_STATUS.INITIAL);
        }}
        message={intl.formatMessage(messages.discardChanges)}
      />
      <ConfirmationModal
        isModalVisible={isReAssignDeviceConfirmationVisible}
        setIsModalVisible={value => {
          setIsReAssignDeviceConfirmationVisible(value);
        }}
        onOk={() => {}}
        onCancel={() => {
          form.setFieldValue(devicesFields.BED_ID, undefined);
        }}
        message={intl.formatMessage(messages.locationAlreadyConnected)}
      />
    </>
  );
};

const messages = defineMessages({
  addDevice: {
    defaultMessage: 'Add Device',
  },
  add: {
    defaultMessage: 'Add',
  },
  editDevice: {
    defaultMessage: 'Edit Device',
  },
  apply: {
    defaultMessage: 'Apply',
  },
  nameFieldLabel: {
    defaultMessage: 'Name',
  },
  selectPlaceholder: {
    defaultMessage: 'Select',
  },
  manufacturerIdLabel: {
    defaultMessage: 'Manufacturer Id',
  },
  note: {
    defaultMessage: 'Note',
  },
  customerName: {
    defaultMessage: 'Customer Name',
  },
  operator: {
    defaultMessage: 'Operator Name',
  },
  tenantName: {
    defaultMessage: 'Tenant Name',
  },
  location: {
    defaultMessage: 'Location',
  },
  mountType: {
    defaultMessage: 'Mounting Type',
  },
  wiringType: {
    defaultMessage: 'Wiring Type',
  },
  electricalOutletReplacement: {
    defaultMessage: 'Electrical Outlet Replacement',
  },
  manufacturerIdError: {
    defaultMessage: 'No spaces allowed',
  },
  bedIdError: {
    defaultMessage: 'Bed is mandatory',
  },
  discardChanges: {
    defaultMessage:
      'There are some unsaved changes. Are you sure you want to discard them?',
  },
  locationAlreadyConnected: {
    defaultMessage:
      'You are about to override an existing location. Are you sure you want to proceed?',
  },
  addRoom: {
    defaultMessage: 'Add new Room',
  },
  addBed: {
    defaultMessage: 'Add new Bed',
  },
  roomLabel: {
    defaultMessage: 'Room',
  },
  bedLabel: {
    defaultMessage: 'Bed',
  },
});

/* eslint-disable no-template-curly-in-string */
export const deviceFormValidateMessages = {
  required: '${label} is required!',
};
/* eslint-enable no-template-curly-in-string */

DeviceModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  modalStatus: PropTypes.string.isRequired,
  setModalStatus: PropTypes.func.isRequired,
  getCustomers: PropTypes.func.isRequired,
  getOperators: PropTypes.func.isRequired,
  getTenantsByCustomerOrOperator: PropTypes.func.isRequired,
  getLocationMountOptions: PropTypes.func.isRequired,
  deviceListRow: PropTypes.object,
  customersList: PropTypes.array,
  operatorsList: PropTypes.array,
  tenantsByCustomerList: PropTypes.array,
  locationMountOptions: PropTypes.object,
  getRoomsList: PropTypes.func.isRequired,
  roomsList: PropTypes.array,
  areRoomsListLoading: PropTypes.bool,
  areOperatorsLoading: PropTypes.bool,
  areCustomersLoading: PropTypes.bool,
  areTenantsByCustomerListLoading: PropTypes.bool,
  areLocationMountOptionsLoading: PropTypes.bool,
};

DeviceModal.defaultProps = {
  deviceListRow: {},
  roomsList: [],
};

export default Connector(
  injectIntl(
    React.memo(DeviceModal, (oldProps, newProps) =>
      deepEqual(oldProps, newProps),
    ),
  ),
);
