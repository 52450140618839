import { hasAllPermissions } from 'src/utils/permissions';
import permissions from 'src/permissions';

export const isSysAdmin = () =>
  hasAllPermissions(
    permissions.VIEW_DEVICES_ADD_BUTTON,
    permissions.VIEW_DEVICES_TENANT_ACTIONS,
    permissions.VIEW_DEVICES_TENANT_COLUMN,
    permissions.VIEW_DEVICES_EDIT_DEVICE_ACTION,
    permissions.VIEW_DEVICES_DELETE_DEVICE_ACTION,
  );
