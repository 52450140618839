import { styled } from 'styled-components';
import { Form, Select } from 'antd';
import Modal from 'src/components/Modal';

export const EachColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  .ant-form-item {
    width: 300px;
  }
`;
export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;
export const StyledModal = styled(Modal)`
  .ant-modal-body {
    height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;
export const FormSection = styled.div`
  display: flex;
`;
export const EachRow = styled.div`
  display: flex;
  align-self: flex-end;
  .timezone-offset {
    width: 100px;
  }
  .timezone-id {
    width: 270px;
  }
`;

export const StyledTenantsTypeSelector = styled(Select)`
  min-width: 200px;
`;
