import React, { useState } from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import { PatientMedicalCondition } from 'src/routes/IndividualDashboard/modules/dashboard/types';
import MedicalConditionsContent from './MedicalConditionsContent';
import { MedicalConditionsWrapper, StyledModal } from './styled';
import { Dayjs } from 'dayjs';
import LoadingOverlay from 'src/components/general-ui/LoadingOverlay';

type Props = {
  medicalConditions: {
    conditions: PatientMedicalCondition[];
    lastUpdated: Dayjs | null;
  };
  intl: IntlShape;
  isLoading: boolean;
};

const MedicalConditions = ({
  medicalConditions,
  intl,
  isLoading,
}: Props): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { conditions, lastUpdated } = medicalConditions;
  return (
    <>
      <MedicalConditionsWrapper
        className={!conditions.length || isLoading ? 'emptyBox' : undefined}
      >
        {isLoading ? (
          <LoadingOverlay className="loader" />
        ) : (
          <MedicalConditionsContent
            medicalConditions={conditions.slice(0, 2)}
            lastUpdated={lastUpdated}
            medicalConditionsLength={conditions.length}
            onExpandHandler={() => setIsModalVisible(true)}
            showExpandButton={conditions.length > 1}
          />
        )}
      </MedicalConditionsWrapper>
      <StyledModal
        title={intl.formatMessage(messages.modalTitle)}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        okButtonText={intl.formatMessage(messages.ok)}
        onOk={() => setIsModalVisible(false)}
        withCancelButton={false}
        shouldCloseOnOk
        bodyStyle={{
          height: '400px',
          overflowY: 'hidden',
          overflowX: 'hidden',
        }}
      >
        <MedicalConditionsContent
          medicalConditions={conditions}
          lastUpdated={lastUpdated}
        />
      </StyledModal>
    </>
  );
};

const messages = defineMessages({
  modalTitle: {
    defaultMessage: 'Medical Conditions',
  },
  expand: {
    defaultMessage: 'Expand',
  },
  code: {
    defaultMessage: 'Code:',
  },
  ok: {
    defaultMessage: 'Ok',
  },
});

export default injectIntl(MedicalConditions);
