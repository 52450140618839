import React from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';
import { Line } from 'react-chartjs-2';

import theme from 'src/themes/theme';
import LoadingOverlay from 'src/components/general-ui/LoadingOverlay';
import { useWindowSize } from 'src/utils/hooks/useWindowSize';
import { BREAKPOINT_SIZES } from 'src/utils/mediaQueryUtils';
import { truthy } from 'src/utils/fpUtils';
import { deepEqual } from 'src/utils/comparators';
import {
  getHorizontalLineDataset,
  getGraphOptions,
  getAlertMarkerDataset,
} from './graphUtils';
import { GraphWrapper, YAxisTitlesWrapper } from './styled';
import { IProps } from './Connector';
import { GraphDataProps } from './types';
import { getMotionBackgroundColor, motionBarThickness } from './utils';
import dayjs from 'dayjs';

const mapGraphData = ({
  startTime,
  endTime,
  isLoading,
  hrContinuousData,
  rrContinuousData,
  hrThresholdLines,
  rrThresholdLines,
  hrBaseline,
  rrBaseline,
  alerts,
  motionContinuousData,
  positionContinuousData,
  intl,
  hiddenMotionIndex = false,
}: GraphDataProps) => {
  const selectionDaysDifferences = dayjs(endTime)?.diff(
    dayjs(startTime),
    'days',
  );
  return {
    datasets: [
      alerts,
      ...hrThresholdLines,
      {
        label: intl.formatMessage(messages.hrContinuousLegend),
        data: hrContinuousData,
        borderColor: theme.colors.continuousGraphHrContinuousColor,
        backgroundColor: theme.colors.continuousGraphHrContinuousColor,
        yAxisID: 'yLeft',
      },
      hrBaseline,
      ...rrThresholdLines,
      {
        label: intl.formatMessage(messages.rrContinuousLegend),
        data: rrContinuousData,
        borderColor: theme.colors.continuousGraphRrContinuousColor,
        backgroundColor: theme.colors.continuousGraphRrContinuousColor,
        yAxisID: 'yRight',
      },
      rrBaseline,
      selectionDaysDifferences <= 7
        ? {
            type: 'bar',
            label: intl.formatMessage(messages.motionIndexLegend),
            data: motionContinuousData,
            backgroundColor: motionContinuousData.map(({ y }) =>
              getMotionBackgroundColor(y / 4),
            ),
            order: 1,
            yAxisID: 'yRight',
            barThickness: motionBarThickness(selectionDaysDifferences),
            hidden: hiddenMotionIndex,
          }
        : null,
      dayjs(endTime)?.diff(dayjs(startTime), 'hours') <= 24
        ? {
            type: 'bar',
            label: intl.formatMessage(messages.postureChangeLegend),
            data: positionContinuousData,
            backgroundColor: '#818181',
            order: 1,
            yAxisID: 'yRight',
            hidden: true,
            barThickness: 1,
          }
        : null,
    ]
      .filter(truthy)
      .map(x => ({ ...x, hidden: x.hidden ?? isLoading })),
  };
};

type Props = IProps & {
  intl: IntlShape;
  hiddenLines?: Record<string, boolean>;
};

const GraphComponent = ({
  metadata: {
    isLoading,
    startTime,
    endTime,
    stepSize,
    unit,
    hrContinuousBaseline,
    rrContinuousBaseline,
  },
  data: {
    hrContinuousData,
    rrContinuousData,
    motionContinuousData,
    positionContinuousData,
  },
  thresholds,
  alerts,
  intl,
  hiddenLines = {},
}: Props): JSX.Element => {
  const [width] = useWindowSize();
  const hrThresholdLines = [
    getHorizontalLineDataset({
      threshold: thresholds.hr.above,
      startTime,
      endTime,
      label: intl.formatMessage(messages.hrThresholdAbove),
      color: '#FFC048',
      yAxisID: 'yLeft',
      isDashed: true,
      hidden: hiddenLines.HrThresholds,
    }),
    getHorizontalLineDataset({
      threshold: thresholds.hr.below,
      startTime,
      endTime,
      label: intl.formatMessage(messages.hrThresholdBelow),
      color: '#FFC048',
      yAxisID: 'yLeft',
      isDashed: true,
      hidden: hiddenLines.HrThresholds,
    }),
  ];
  const rrThresholdLines = [
    getHorizontalLineDataset({
      threshold: thresholds.rr.above,
      startTime,
      endTime,
      label: intl.formatMessage(messages.rrThresholdAbove),
      color: '#63B7C3',
      yAxisID: 'yRight',
      isDashed: true,
      hidden: hiddenLines.RrThresholds,
    }),
    getHorizontalLineDataset({
      threshold: thresholds.rr.below,
      startTime,
      endTime,
      label: intl.formatMessage(messages.rrThresholdBelow),
      color: '#63B7C3',
      yAxisID: 'yRight',
      isDashed: true,
      hidden: hiddenLines.RrThresholds,
    }),
  ];

  const hrBaseline = getHorizontalLineDataset({
    threshold: hrContinuousBaseline,
    startTime,
    endTime,
    label: intl.formatMessage(messages.hrContinuousBaseline),
    color: '#F08838',
    yAxisID: 'yLeft',
    isDashed: false,
    hidden: hiddenLines.hrBaseline,
  });
  const rrBaseline = getHorizontalLineDataset({
    threshold: rrContinuousBaseline,
    startTime,
    endTime,
    label: intl.formatMessage(messages.rrContinuousBaseline),
    color: '#59DDAA',
    yAxisID: 'yRight',
    isDashed: false,
    hidden: hiddenLines.rrBaseline,
  });
  return (
    <GraphWrapper data-cy={`periodic-graph-loading=${isLoading.toString()}`}>
      {isLoading && <LoadingOverlay />}
      <YAxisTitlesWrapper>
        <span>
          <FormattedMessage {...messages.bpm} />
        </span>
        <span>
          <FormattedMessage {...messages.brpm} />
        </span>
      </YAxisTitlesWrapper>
      <Line
        type="line"
        options={getGraphOptions({
          startTime,
          endTime,
          stepSize,
          unit,
          thresholds,
          messages,
          intl,
          isExtraLarge: width > BREAKPOINT_SIZES.XL,
        })}
        data={mapGraphData({
          startTime,
          endTime,
          isLoading,
          hrContinuousData,
          rrContinuousData,
          hrThresholdLines,
          rrThresholdLines,
          hrBaseline,
          rrBaseline,
          alerts: getAlertMarkerDataset(alerts),
          motionContinuousData,
          positionContinuousData,
          intl,
          hiddenMotionIndex: hiddenLines.motionIndex,
        })}
      />
    </GraphWrapper>
  );
};

const messages = defineMessages({
  bpm: {
    defaultMessage: '[BPM]',
  },
  brpm: {
    defaultMessage: '[BrPM]',
  },
  hrContinuousLegend: {
    defaultMessage: 'HR Continuous',
  },
  rrContinuousLegend: {
    defaultMessage: 'RR Continuous',
  },
  hrContinuousBaseline: {
    defaultMessage: 'HR Baseline',
  },
  rrContinuousBaseline: {
    defaultMessage: 'RR Baseline',
  },
  hrThresholdAbove: {
    defaultMessage: 'HR Max Threshold',
  },
  hrThresholdBelow: {
    defaultMessage: 'HR Min Threshold',
  },
  rrThresholdAbove: {
    defaultMessage: 'RR Max Threshold',
  },
  rrThresholdBelow: {
    defaultMessage: 'RR Min Threshold',
  },
  motionIndexLegend: {
    defaultMessage: 'Motion Index',
  },
  postureChangeLegend: {
    defaultMessage: 'Position Change',
  },
});

export default injectIntl(
  React.memo(GraphComponent, (oldProps, newProps) =>
    deepEqual(oldProps, newProps),
  ),
);
