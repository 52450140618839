import styled from 'styled-components';
import Modal from 'src/components/Modal';

export const StyledModal = styled(Modal)`
  display: flex;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  .ant-modal-content {
    width: 100%;
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: 0;
  }
`;
