import { call, put, select, take } from 'typed-redux-saga/macro';
import { Action } from '@reduxjs/toolkit';
import { actions, selectors } from 'src/redux/data/tenant';
import { filterDuplicates } from 'src/utils/dataUtils';
import { UUID } from 'src/types/utility';

function* onTenantsListLoaded() {
  const paginatedTenantsList = yield* select(selectors.getPaginatedTenants);
  const paginatedTenantsIds = filterDuplicates(
    paginatedTenantsList.data.reduce((acc: UUID[], tenant) => {
      return [...acc, tenant.id || ''];
    }, []),
  ).filter(elem => !!elem);
  const paginatedOperatorsIds = filterDuplicates(
    paginatedTenantsList.data.reduce((acc: UUID[], tenant) => {
      return [...acc, tenant.operatorId || ''];
    }, []),
  ).filter(elem => !!elem);
  if (!paginatedTenantsIds || !paginatedTenantsIds.length) {
    return;
  }
  yield* put(actions.getTenantsDeviceCounters(paginatedTenantsIds));
  yield* put(actions.getTenantsMonitoringDeviceCounters(paginatedTenantsIds));
  yield* put(actions.getOperatorsByOperatorsId(paginatedOperatorsIds));
}

export default function* watchTenantDataActions() {
  while (true) {
    yield* take((action: Action) => {
      return action.type === actions.setPaginatedTenants.type;
    });
    yield* call(onTenantsListLoaded);
  }
}
