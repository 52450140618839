import React, { useEffect } from 'react';

import Connector, { PropsFromRedux } from './Connector';
import { Line } from 'react-chartjs-2';
import { linkParamsType } from 'src/routes/MedicalReports/types';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import { intl } from 'src/utils/errorHandling/messages';
import { ComponentWrapper, MainTitle } from 'src/routes/MedicalReports/styled';
import { useTheme } from 'styled-components';
import LoadingOverlay from 'src/components/general-ui/LoadingOverlay';

type Props = PropsFromRedux &
  linkParamsType & { intl: IntlShape; title: string };

const EventsSummaryBars = ({
  loadSummaryEventsData,
  eventsSummaryData,
  tenantId,
  date,
  title,
  isLoading,
}: Props): JSX.Element => {
  const theme = useTheme();

  useEffect(() => {
    if (!date || !tenantId) {
      return;
    }
    loadSummaryEventsData({
      date,
      tenantId,
    });
  }, [date, loadSummaryEventsData, tenantId]);

  return (
    <ComponentWrapper>
      {isLoading && <LoadingOverlay />}
      <MainTitle>{title}</MainTitle>
      <Line
        type="line"
        data={{
          labels: [
            intl.formatMessage(messages.oneDayLabel),
            intl.formatMessage(messages.oneWeekLabel),
            intl.formatMessage(messages.oneMonthLabel),
            intl.formatMessage(messages.dailyAverageLabel),
          ],
          datasets: [
            {
              type: 'bar',
              label: intl.formatMessage(messages.admissionsLabel),
              data: eventsSummaryData?.admissions,
              backgroundColor: theme.colors.summaryAdmissionColor,
              order: 1,
              barPercentage: 0.7,
              categoryPercentage: 0.5,
            },
            {
              type: 'bar',
              label: intl.formatMessage(messages.dischargeLabel),
              data: eventsSummaryData?.dischargeTransfer,
              backgroundColor: theme.colors.dischargeTransferColor,
              order: 2,
              barPercentage: 0.7,
              categoryPercentage: 0.5,
            },
            {
              type: 'bar',
              label: intl.formatMessage(messages.abnormalityLabel),
              data: eventsSummaryData?.abnormality,
              backgroundColor: theme.colors.summaryAbnormalityColor,
              order: 3,
              barPercentage: 0.7,
              categoryPercentage: 0.5,
            },
          ],
        }}
      />
    </ComponentWrapper>
  );
};

const messages = defineMessages({
  oneDayLabel: {
    defaultMessage: 'Previous 24 hours',
  },
  oneWeekLabel: {
    defaultMessage: 'Previous 7 days',
  },
  oneMonthLabel: {
    defaultMessage: 'Previous 30 days',
  },
  dailyAverageLabel: {
    defaultMessage: 'Daily Average',
  },
  admissionsLabel: {
    defaultMessage: 'Admissions',
  },
  dischargeLabel: {
    defaultMessage: 'Discharge/Transfer',
  },
  abnormalityLabel: {
    defaultMessage: 'Abnormality Indications',
  },
});

export default Connector(injectIntl(EventsSummaryBars));
