import React, { lazy } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import Connector, { PropsFromRedux } from './V2Connector';
import { DetailedInfoContainer, MainTitle, Wrapper } from './styled';
import { Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { linkParamsType } from 'src/routes/MedicalReports/types';
import { deepEqual } from 'src/utils/comparators';
import dayjs from 'dayjs';
import { MEDICAL_REPORT_DATE_FORMAT } from 'src/utils/timeUtils';
import { removeDuplicatesFromArrayOfObjects } from 'src/utils/dataUtils';
import RenderOnViewportEntry from 'src/routes/MedicalReports/components/RenderOnViewportEntry';
import FooterComponent from 'src/routes/MedicalReports/components/FooterComponent';

const HeaderComponent = lazy(() => import('./components/HeaderComponent'));
const AbnormalityTableV2 = lazy(
  () => import('./components/AbnormalityTableV2'),
);
const IdbComponents = lazy(() => import('./components/IdbComponents'));
const DevicesPieChart = lazy(() => import('./components/DevicesPieChart'));
const EventsSummaryBars = lazy(() => import('./components/EventsSummaryBars'));

type Props = PropsFromRedux & { intl: IntlShape };
const MedicalReportPage = ({ intl, patientsData }: Props): JSX.Element => {
  const { tenantId, date } = useParams<linkParamsType>();
  const reportDate = dayjs(date, MEDICAL_REPORT_DATE_FORMAT);
  const prev24Hours = reportDate.subtract(1, 'day').format('MM/DD');
  const abnormalityStartDate = reportDate.subtract(4, 'day').format('MM/DD');
  const abnormalityEndDate = reportDate.subtract(2, 'day').format('MM/DD');
  return (
    <Wrapper>
      <RenderOnViewportEntry style={{ minHeight: '90px' }}>
        <HeaderComponent />
      </RenderOnViewportEntry>
      <RenderOnViewportEntry>
        <AbnormalityTableV2
          tenantId={tenantId}
          date={date}
          title={intl.formatMessage(messages.abnormalityTableV2Title, {
            prev24Hours,
            startDate: abnormalityStartDate,
            endDate: abnormalityEndDate,
          })}
        />
      </RenderOnViewportEntry>
      {!!patientsData?.length && (
        <DetailedInfoContainer>
          <MainTitle>
            <FormattedMessage {...messages.detailedInformationTitle} />
          </MainTitle>
          <Typography>
            <FormattedMessage {...messages.detailedInformationSubTitle} />
          </Typography>
          <Typography>
            <FormattedMessage {...messages.detailedInformationSecondSubTitle} />
          </Typography>
        </DetailedInfoContainer>
      )}
      {removeDuplicatesFromArrayOfObjects(patientsData, ['patientId'])
        .filter(patient => !!patient.isConsented)
        .map(patient => (
          <RenderOnViewportEntry
            key={patient.patientId}
            style={{ minHeight: '630px' }}
          >
            <IdbComponents
              tenantId={tenantId}
              date={date}
              key={patient.patientId}
              {...patient}
            />
          </RenderOnViewportEntry>
        ))}
      <RenderOnViewportEntry style={{ minHeight: '865px' }}>
        <DevicesPieChart
          showMonitoringDays={false}
          showDevicesConsent={true}
          title={intl.formatMessage(messages.operationalSummaryTitle)}
          tenantId={tenantId}
          date={date}
        />
      </RenderOnViewportEntry>
      <RenderOnViewportEntry style={{ minHeight: '760px' }}>
        <EventsSummaryBars
          tenantId={tenantId}
          date={date}
          title={intl.formatMessage(messages.adtStatusTitle)}
        />
      </RenderOnViewportEntry>
      <FooterComponent />
    </Wrapper>
  );
};

const messages = defineMessages({
  abnormalityTableV2Title: {
    defaultMessage:
      'Abnormality Indications over previous 24h Vs 72h ({prev24Hours} Vs {startDate}-{endDate})',
  },
  detailedInformationTitle: {
    defaultMessage: 'Graphical Presentation',
  },
  detailedInformationSubTitle: {
    defaultMessage:
      '7-day overview for patients with recorded medical events over prior 24 hours',
  },
  detailedInformationSecondSubTitle: {
    defaultMessage: 'Click on patient name to access individual dashboard',
  },
  operationalSummaryTitle: {
    defaultMessage: 'Device Status',
  },
  adtStatusTitle: {
    defaultMessage: 'ADT Status',
  },
});

export default Connector(
  injectIntl(
    React.memo(MedicalReportPage, (oldProps, newProps) =>
      deepEqual(oldProps, newProps),
    ),
  ),
);
