import { fork, all } from 'typed-redux-saga/macro';

import { sagas as dataSaga } from '../data';
import { saga as languageSaga } from 'src/components/LanguageMenu';
import { saga as monitorSaga } from 'src/routes/Monitor/index';
import { saga as patientHistorySaga } from '../../routes/Patients/HistoryModal';
import { saga as deviceSaga } from '../../routes/Devices/index';
import { saga as nursesStationSaga } from '../../routes/NursesStation';
import { saga as gmDashboardSaga } from '../../routes/GMDashboard';
import { saga as alertSidebarSaga } from 'src/components/Sidebars/AlertSidebar';
import { saga as alertSidebarGMSaga } from 'src/components/Sidebars/AlertSidebarGroupManager';
import { idb, statistics } from '../../routes/IndividualDashboard';
import { saga as gmSettingsSaga } from '../../routes/GMSettings';
import { saga as medicalReportsSaga } from '../../routes/MedicalReports';

export default function* root() {
  yield* all([
    fork(dataSaga),
    fork(languageSaga),
    fork(alertSidebarSaga),
    fork(alertSidebarGMSaga),
    fork(patientHistorySaga),
    fork(idb.saga),
    fork(statistics.saga),
    fork(monitorSaga),
    fork(deviceSaga),
    fork(nursesStationSaga),
    fork(gmDashboardSaga),
    fork(gmSettingsSaga),
    fork(medicalReportsSaga),
  ]);
}
