import { all, call, delay, put, takeLatest } from 'typed-redux-saga/macro';

import { actions } from './slice';
import { DATA_FETCHING_STATUS } from '../../constants';
import BackendService from 'src/services/BackendService';
import {
  actionSuccessNotification,
  notifyUserByActionTypeAndCode,
} from 'src/utils/errorHandling/notifications';

function* searchCustomers(action?: ReturnType<typeof actions.searchCustomers>) {
  try {
    const { searchKeyword = '' } = action?.payload || {};
    if (searchKeyword) {
      yield* delay(500);
    }
    yield* put(actions.setCustomersStatus(DATA_FETCHING_STATUS.LOADING));

    const { data: searchResults } = yield* call(
      BackendService.searchCustomers,
      action?.payload,
    );

    yield* put(
      actions.setCustomersList({
        data: searchResults?.data,
        status: DATA_FETCHING_STATUS.SUCCESS,
      }),
    );
  } catch (e) {
    console.error('error in searchCustomersList: ', e);
    yield* put(
      actions.setCustomersList({
        data: null,
        status: DATA_FETCHING_STATUS.ERROR,
      }),
    );
  }
}

function* getCustomerAccess(
  action: ReturnType<typeof actions.getCustomerAccess>,
) {
  try {
    const customerId = action.payload;
    if (!customerId) {
      return;
    }
    yield* put(actions.setCustomersAccessStatus(DATA_FETCHING_STATUS.LOADING));
    const { data } = yield* call(BackendService.getCustomerAccess, customerId);
    yield* put(
      actions.setCustomersAccess({
        data,
        status: DATA_FETCHING_STATUS.SUCCESS,
      }),
    );
  } catch (e) {
    console.error('error in getCustomersAccess: ', e);
    yield* put(
      actions.setCustomersAccess({
        data: null,
        status: DATA_FETCHING_STATUS.ERROR,
      }),
    );
    notifyUserByActionTypeAndCode(action.type, e, e);
  }
}

function* createCustomer(action: ReturnType<typeof actions.createCustomer>) {
  const customerForm = action.payload;

  try {
    const {
      name,
      address,
      contactName,
      contactEmail,
      ipAddresses,
      throughput,
      enabled,
      regionalAccess,
    } = customerForm;
    const customerData = {
      name,
      address,
      contactName,
      contactEmail,
      regionalAccess,
    };
    yield* put(actions.setFormStatus(DATA_FETCHING_STATUS.LOADING));
    const { data: createdCustomer } = yield* call(
      BackendService.createCustomer,
      customerData,
    );
    yield* put(actions.setFormStatus(DATA_FETCHING_STATUS.SUCCESS));
    if (!createdCustomer.id) {
      return;
    }

    const customerAccessPayload = {
      ipAddresses: ipAddresses?.split(`\n`).filter(elem => !!elem),
      throughput,
      enabled,
      customerId: createdCustomer.id,
    };

    const { data: updatedCustomerAccess } = yield* call(
      BackendService.updateCustomerAccess,
      createdCustomer.id,
      customerAccessPayload,
    );
    yield* put(actions.addCustomer(createdCustomer));
    yield* put(
      actions.setCustomersAccess({
        data: updatedCustomerAccess,
        status: DATA_FETCHING_STATUS.SUCCESS,
      }),
    );
    actionSuccessNotification(action.type, customerForm.name);
  } catch (e) {
    yield* put(actions.setFormStatus(DATA_FETCHING_STATUS.ERROR));
    yield* put(actions.setCustomersAccessStatus(DATA_FETCHING_STATUS.ERROR));
    yield* put(
      actions.setCustomersAccess({ status: DATA_FETCHING_STATUS.ERROR }),
    );
    console.error('error in fetchCusomtersList: ', e);
    notifyUserByActionTypeAndCode(action.type, e, e);
  }
}

function* editCustomer(action: ReturnType<typeof actions.editCustomer>) {
  const { customerId, ...customerData } = action.payload;
  if (!customerId || !customerData) {
    return;
  }
  try {
    const {
      name,
      address,
      contactName,
      contactEmail,
      ipAddresses,
      throughput,
      enabled,
      regionalAccess,
    } = customerData;
    const updateCustomerData = {
      name,
      address,
      contactName,
      contactEmail,
      regionalAccess,
    };
    yield* put(actions.setCustomersStatus(DATA_FETCHING_STATUS.LOADING));
    const { data: updatedCustomer } = yield* call(
      BackendService.updateCustomer,
      customerId,
      updateCustomerData,
    );
    yield* put(actions.updateCustomerData(updatedCustomer));
    yield* put(actions.setCustomersStatus(DATA_FETCHING_STATUS.SUCCESS));
    yield* put(actions.setCustomersAccessStatus(DATA_FETCHING_STATUS.LOADING));
    const customerAccessPayload = {
      ipAddresses: ipAddresses?.split(`\n`).filter(elem => !!elem),
      throughput,
      enabled,
      customerId,
    };
    const { data: updatedCustomerAccess } = yield* call(
      BackendService.updateCustomerAccess,
      customerId,
      customerAccessPayload,
    );
    yield* put(
      actions.setCustomersAccess({
        data: updatedCustomerAccess,
        status: DATA_FETCHING_STATUS.SUCCESS,
      }),
    );
    actionSuccessNotification(action.type, name);
  } catch (e) {
    yield* put(actions.setCustomersStatus(DATA_FETCHING_STATUS.ERROR));
    yield* put(
      actions.setCustomersAccess({
        status: DATA_FETCHING_STATUS.ERROR,
      }),
    );
    console.error('error in editCustomer: ', e);
    notifyUserByActionTypeAndCode(action.type, e, e);
  }
}

function* deleteCustomer(
  action: ReturnType<typeof actions.deleteCustomerById>,
) {
  const customerId = action.payload;
  try {
    yield* call(BackendService.deleteCustomer, customerId);
    yield* put(actions.searchCustomers());
  } catch (e) {
    console.error('error in fetchCusomtersList: ', e);
    notifyUserByActionTypeAndCode(action.type, e, e);
  }
}

export default function* watchCusomterActions() {
  yield* all([
    takeLatest(actions.getCustomerAccess, getCustomerAccess),
    takeLatest(actions.createCustomer, createCustomer),
    takeLatest(actions.editCustomer, editCustomer),
    takeLatest(actions.searchCustomers, searchCustomers),
    takeLatest(actions.deleteCustomerById, deleteCustomer),
  ]);
}
