import styled from 'styled-components';
import { Modal, Tabs } from 'antd';
import { media } from 'src/utils/mediaQueryUtils';

export const StyledTabs = styled(Tabs)`
  overflow: auto;
  display: block;
  color: ${props => props.theme.colors.individualDashboardLightText};
  height: 100%;
  &.ant-tabs {
    overflow: hidden;

    .ant-tabs-nav {
      z-index: 100;
      margin: 20px 0 0 0;
      border-bottom: 3px solid #e4e7e9;
      padding-bottom: 5px;
      ${media.small`
      padding: 0px 1%;
    `}
      ${media.extraLarge`
      padding: 0px 30px;
    `}
    }
  }
  .ant-tabs-content-holder {
    height: calc(100% - 60px);
  }
  .ant-table {
    height: calc(100% - 80px);
  }
  .ant-tabs-content,
  .ant-tabs-tabpane,
  .ant-tabs-tabpane > div,
  .ant-tabs-tabpane > div > div,
  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-table-container {
    height: 100%;
  }
  .ant-table-container {
    overflow: auto;
  }
  .ant-table-wrapper {
    height: calc(100% - 52px);
    .ant-table-body {
      height: auto;
    }
  }
  .ant-tabs-tab {
    margin: 0 0 0 12px;

    ${media.small`
      font-size: 8px;
      padding: 5px 8px;
    `}
    ${media.extraLarge`
      font-size: 12px;
      padding: 7px 14px;
    `}
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${props =>
      props.theme.colors.individualDashboardTabsTitle} !important;
  }
  .ant-tabs-ink-bar {
    border-bottom: 2px solid
      ${props => props.theme.colors.individualDashboardActiveBorder} !important;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 1rem 0;
`;
export const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 300px);
  height: calc(100vh - 115px);
  font-family: 'Inter';
  background: ${props => props.theme.colors.defaultBackgroundColor};
  color: #252525;
  padding: 1.4rem 1.25rem 0 1.25rem;
  .table-search-bar-wrapper {
    margin-top: -65px;
    z-index: 100;
  }
  .ant-table-wrapper {
    width: 100%;
  }
`;

export const CustomerModal = styled(Modal)`
  .ant-modal-footer {
    position: absolute;
    bottom: 3%;
    right: 5%;
    .modal-cancel-btn {
      margin-right: 5px;
    }
  }
  .ant-modal-body {
    display: flex;
    justify-content: center;
    padding: 1.4rem 0;
  }
`;
